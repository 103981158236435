<div class="main-right-content">
	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div
			class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4"
		>
			<h1 class="title mb-0">Manage products</h1>
			<a
				[routerLink]="['/manage/products/edit']"
				class="btn btn-primary w-sm-100"
			>
				Add new product
				<span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
					>
						<path
							d="M8.2002 13.6L11.8002 10L8.2002 6.40002"
							stroke="white"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</span>
			</a>
		</div>

		<div class="manage-user-list">
			<app-search
				[sortText]="getSortText()"
				[sortOptions]="sortOptions"
				(searchChanged)="updateSearchTerm($event)"
				(sortChanged)="updateSortField($event)"
				(scopeChanged)="updateScopeField($event)"
				[isScope]="true"
				[scopeText]="getScopeText()"
			></app-search>
			<div class="manage-table main-table d-none d-lg-block">
				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>Last modified</th>
							<th>Base price</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of queryResult.rows">
							<td>
								<div class="product-name">
									<span class="imgs">
										<img src="{{ item.imageUrl }}" alt="product-1" />
									</span>
									<h5>
										{{ item.name }}
									</h5>
								</div>
							</td>
							<td>
								<p>{{ item.updatedAt | date : "yyyy-MM-dd" }}</p>
								<p>{{ item.updatedAt | date : "HH:MM" }}</p>
							</td>
							<td>
								<p><span>$AUD</span> {{ item.basePrice || item.price }}</p>
							</td>
							<td>
								<p class="d-flex align-items-center">
									In stock
									<span class="badge bg-green-25 ps-3 ms-2 py-1">
										{{ item.inStock }}</span
									>
								</p>
							</td>
							<td>
								<div class="d-flex align-items-center justify-content-start">
									<a
										(click)="openRemoveModal(item.id, delete_confirm_modal)"
										class="btn btn-delete"
										role="button"
										*ngIf="!item.deletedAt && hasManageProductsAccess"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M3.25 5.5H4.75H16.75"
												stroke="#818898"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#818898"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</a>
									<a
										(click)="restoreProduct(item.id)"
										*ngIf="item.deletedAt && hasManageProductsAccess"
										class="btn btn-more"
										role="button"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M16 5.5L7.75 13.75L4 10"
												stroke="#818898"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</a>
									<a
										*ngIf="!item.deletedAt && hasManageProductsAccess"
										[routerLink]="['/manage/products/edit', item.id]"
										class="btn btn-edit"
										role="button"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
												stroke="#818898"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</a>
									<button
										*ngIf="!item.deletedAt && hasManageProductsAccess"
										(click)="cloneProduct(item)"
										class="btn btn-more"
										type="button"
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 604 604"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M560.273 549.512V183.625C560.273 180.71 559.208 178.188 557.079 176.058C554.949 173.928 552.426 172.863 549.512 172.863H183.625C180.71 172.863 178.188 173.928 176.058 176.058C173.928 178.188 172.863 180.71 172.863 183.625V549.512C172.863 552.426 173.928 554.949 176.058 557.079C178.188 559.208 180.71 560.273 183.625 560.273H549.512C552.426 560.273 554.949 559.208 557.079 557.079C559.208 554.949 560.273 552.426 560.273 549.512ZM603.319 183.625V549.512C603.319 564.309 598.05 576.976 587.513 587.513C576.976 598.05 564.309 603.319 549.512 603.319H183.625C168.828 603.319 156.161 598.05 145.624 587.513C135.086 576.976 129.818 564.309 129.818 549.512V183.625C129.818 168.828 135.086 156.161 145.624 145.624C156.161 135.086 168.828 129.818 183.625 129.818H549.512C564.309 129.818 576.976 135.086 587.513 145.624C598.05 156.161 603.319 168.828 603.319 183.625ZM474.182 54.4881V108.295H431.137V54.4881C431.137 51.5735 430.072 49.0513 427.942 46.9215C425.812 44.7916 423.29 43.7267 420.375 43.7267H54.4881C51.5735 43.7267 49.0513 44.7916 46.9215 46.9215C44.7916 49.0513 43.7267 51.5735 43.7267 54.4881V420.375C43.7267 423.29 44.7916 425.812 46.9215 427.942C49.0513 430.072 51.5735 431.137 54.4881 431.137H108.295V474.182H54.4881C39.6912 474.182 27.0241 468.914 16.4869 458.376C5.94975 447.839 0.681152 435.172 0.681152 420.375V54.4881C0.681152 39.6912 5.94975 27.0241 16.4869 16.4869C27.0241 5.94975 39.6912 0.681152 54.4881 0.681152H420.375C435.172 0.681152 447.839 5.94975 458.376 16.4869C468.914 27.0241 474.182 39.6912 474.182 54.4881Z"
												fill="#818898"
											/>
										</svg>
									</button>
								</div>
							</td>
						</tr>
						<tr *ngIf="queryResult.rows.length === 0 && !isLoading">
							<td colspan="6">
								<div class="no-results-content">
									<div class="no-result-img for-admin">
										<img
											src="../../../../assets/images/No-result.png"
											alt="No-result"
										/>
									</div>
									<h4 class="title">No Results Found!</h4>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="manage-cards d-lg-none">
				<div *ngFor="let item of queryResult.rows" class="card manage-card">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head imgs">
										<img src="{{ item.imageUrl }}" alt="product-1" />
									</span>
									<div class="user-name">
										<h5>
											{{ item.name }}
										</h5>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Last modified </span>
									<div>
										<p>{{ item.updatedAt | date : "yyyy-MM-dd" }}</p>
										<p>{{ item.updatedAt | date : "HH:MM" }}</p>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Base price </span>
									<p><span>$AUD</span> {{ item.basePrice || item.price }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Status </span>
									<p class="d-flex align-items-center">
										In stock
										<span class="badge bg-green-25 ps-3 ms-2 py-1 px-3">
											{{ item.inStock }}</span
										>
									</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Actions: </span>
									<div
										class="d-flex align-items-center w-100 justify-content-end"
									>
										<a
											(click)="openRemoveModal(item.id, delete_confirm_modal)"
											*ngIf="!item.deletedAt && hasManageProductsAccess"
											data-bs-toggle="modal"
											class="btn btn-delete"
											role="button"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M3.25 5.5H4.75H16.75"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</a>
										<a
											(click)="restoreProduct(item.id)"
											*ngIf="item.deletedAt && hasManageProductsAccess"
											class="btn btn-more"
											role="button"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M16 5.5L7.75 13.75L4 10"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</a>
										<a
											*ngIf="!item.deletedAt && hasManageProductsAccess"
											[routerLink]="['/manage/products/edit', item.id]"
											class="btn btn-edit"
											role="button"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</a>
										<button
											*ngIf="!item.deletedAt && hasManageProductsAccess"
											(click)="cloneProduct(item)"
											class="btn btn-more"
											type="button"
										>
											<svg
												width="20"
												height="20"
												viewBox="0 0 604 604"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M560.273 549.512V183.625C560.273 180.71 559.208 178.188 557.079 176.058C554.949 173.928 552.426 172.863 549.512 172.863H183.625C180.71 172.863 178.188 173.928 176.058 176.058C173.928 178.188 172.863 180.71 172.863 183.625V549.512C172.863 552.426 173.928 554.949 176.058 557.079C178.188 559.208 180.71 560.273 183.625 560.273H549.512C552.426 560.273 554.949 559.208 557.079 557.079C559.208 554.949 560.273 552.426 560.273 549.512ZM603.319 183.625V549.512C603.319 564.309 598.05 576.976 587.513 587.513C576.976 598.05 564.309 603.319 549.512 603.319H183.625C168.828 603.319 156.161 598.05 145.624 587.513C135.086 576.976 129.818 564.309 129.818 549.512V183.625C129.818 168.828 135.086 156.161 145.624 145.624C156.161 135.086 168.828 129.818 183.625 129.818H549.512C564.309 129.818 576.976 135.086 587.513 145.624C598.05 156.161 603.319 168.828 603.319 183.625ZM474.182 54.4881V108.295H431.137V54.4881C431.137 51.5735 430.072 49.0513 427.942 46.9215C425.812 44.7916 423.29 43.7267 420.375 43.7267H54.4881C51.5735 43.7267 49.0513 44.7916 46.9215 46.9215C44.7916 49.0513 43.7267 51.5735 43.7267 54.4881V420.375C43.7267 423.29 44.7916 425.812 46.9215 427.942C49.0513 430.072 51.5735 431.137 54.4881 431.137H108.295V474.182H54.4881C39.6912 474.182 27.0241 468.914 16.4869 458.376C5.94975 447.839 0.681152 435.172 0.681152 420.375V54.4881C0.681152 39.6912 5.94975 27.0241 16.4869 16.4869C27.0241 5.94975 39.6912 0.681152 54.4881 0.681152H420.375C435.172 0.681152 447.839 5.94975 458.376 16.4869C468.914 27.0241 474.182 39.6912 474.182 54.4881Z"
													fill="#818898"
												/>
											</svg>
										</button>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div
					class="no-results-content"
					*ngIf="queryResult.rows.length == 0 && !isLoading"
				>
					<div class="no-result-img for-admin">
						<img
							src="../../../../assets/images/No-result.png"
							alt="No-result"
						/>
					</div>
					<h4 class="title">No Results Found!</h4>
				</div>
			</div>

			<app-pagination
				[currentPage]="currentPageActive"
				[pageSize]="query.limit"
				[totalRows]="totalCountActive"
				(pageChange)="pageChangedActive($event)"
				(pageSizeChange)="pageSizeChanged($event)"
			></app-pagination>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>

<ng-template
	#delete_confirm_modal
	let-modal
	let-c="close"
	let-d="dismiss"
	class="deleteModal"
>
	<div class="modal-content">
		<div class="modal-body">
			<button
				type="button"
				class="btn btn-icon btn-cancel shadow-none"
				(click)="d('Cancel')"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
				>
					<path
						d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
						stroke="#666D80"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</button>
			<div class="delete-content">
				<div class="dlt-icon">
					<span
						><svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M3.25 5.5H4.75H16.75"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
				</div>
				<div>
					<h4 class="title">Remove Product</h4>
					<p class="desc">Would you like to remove this Product?</p>
				</div>
			</div>
			<div class="btns">
				<button type="button" class="btn btn-danger" (click)="c('Delete')">
					Remove
				</button>
				<button type="button" class="btn btn-secondary" (click)="d('Cancel')">
					Cancel
				</button>
			</div>
		</div>
	</div>
</ng-template>
