<section class="contactsec">
	<div class="max-width-container">
		<div class="container">
			<p>CONTACT</p>
			<h1>Let’s connect</h1>
		</div>
	</div>
</section>

<section class="conformsec" style="margin-bottom: 8rem">
	<div class="max-width-container">
		<div class="container">
			<h4 class="connect-title">
				You currently need to login to view our products, we’re finalising our
				public catalogue of products which will be available in the coming
				weeks, if you’re interested in see what products we currently offer
				please contact our sales team directly on sales@reali or call on 1300
				107 233
			</h4>
			<div class="rightsec">
				<div class="cardssec">
					<div class="itemn">
						<div class="tpicon">
							<span class="btn btn-secondary-icon">
								<img src="../../../../assets/images/phone.svg" alt="" />
							</span>
						</div>
						<div class="masw">
							<div class="cssecs">
								<div class="visues">Call us</div>
								<h5>1300 107 233</h5>
							</div>
							<a href="tel:1300 107 233" class="iconsns btn btn-secondary-icon">
								<img src="../../../../assets/images/arrow-right.svg" alt="" />
							</a>
						</div>
					</div>
					<div class="itemn">
						<div class="tpicon">
							<span class="btn btn-secondary-icon">
								<img src="../../../../assets/images/mail (1).svg" alt="" />
							</span>
						</div>
						<div class="masw">
							<div class="cssecs">
								<div class="visues">Send as an email</div>
								<h5>sales@reali.au</h5>
							</div>
							<a
								href="mailto:sales@reali.au"
								class="iconsns btn btn-secondary-icon"
							>
								<img src="../../../../assets/images/arrow-right.svg" alt="" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
