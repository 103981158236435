<div [class.product-detail-container]="isPreview" [class.pt-0]="isPreview">
	<div [class.max-width-container]="isPreview">
		<div [class.container]="isPreview">
			<div class="product-slide-content">
				<div class="product-item__gallery">
					<div class="product-item__slider-content-left" *ngIf="product.images">
						<ngx-slick-carousel class="carousel product-slider-nav" #slickModal="slick-carousel" [config]="slideConfig">
							<div ngxSlickItem *ngFor="let images of product.images" class="product-slider-nav__item"
								[class.slick-current]="isSelectedImage(images.name)">
								<img src="{{ images.url }}" alt="product-nav" (click)="cartItem.selectedColour = null" />
							</div>
						</ngx-slick-carousel>
					</div>
					<div class="product-item__slider-content-right">
						<ngx-slick-carousel class="carousel product-slider-for" [config]="secondSlideConfig">
							<div ngxSlickItem *ngFor="let images of product.images" class="product-item__slider-item details">
								<figure>
									<img src="{{ images.url }}" alt="product-nav" />
								</figure>
							</div>
						</ngx-slick-carousel>
					</div>
				</div>
				<div class="product-details">
					<div class="remaining-garments" *ngIf="allocationCollections.length">
						<span class="icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<path
									d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
									stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</span>
						<div>
							<p>
								You have
								<span>{{ allocationCollections[0].count }} garment{{
									allocationCollections[0].count > 1 ? "s" : ""
									}}</span>
								remaining in this collection
							</p>
							<p class="small">
								Your allocation resets:
								{{ findResetTime(allocationCollections[0]) }}
							</p>
						</div>
					</div>

					<div class="product-card card">
						<div class="card-body">
							<div class="product-detail-content">
								<app-favorite-select *ngIf="!isAdmin && checkAuthenticated()" [productId]="product.id"
									[type]="'detail'"></app-favorite-select>

								<div class="d-flex align-items-start justify-content-between">
									<div class="d-flex flex-column gap-25">
										<span class="uni-type" *ngIf="allocationCollections.length">
											{{ allocationCollections[0].name }}
										</span>
										<h1 class="title">{{ product.name }}</h1>
										<p>
											{{
											product.brand ? product.brand.name.toUpperCase() : ""
											}}
											<span>{{
												product.code ? "CODE: " + product.code : ""
												}}</span>
										</p>
									</div>
								</div>

								<div class="price">
									<span class="real-price" *ngIf="finalPrice()">
										{{ finalPrice() }}
									</span>
									<span class="price-gst" *ngIf="finalPrice()">(Gst ${{ finalPriceWithGst() }})</span>
								</div>
							</div>

							<div class="product-size-color">
								<div>
									<div class="head">
										<div>
											<h4>
												Colour
												<span class="divider"></span>
												<span>{{ cartItem.selectedColour || "Default" }}</span>
											</h4>
										</div>
									</div>

									<div class="color-size">
										<div class="form-check" *ngFor="
												let colour of cartItem.getUniqueColours();
												let i = index
											">
											<input class="form-check-input color-input" type="radio" name="color" id="color1 {{ colour }}"
												(click)="
													cartItem.selectedColour = colour; selectColor(i)
												" [checked]="
													cartItem.selectedColour == colour.toLowerCase()
												" />
											<label class="form-check-label color-label" for="color1 {{ colour }}">
												<img src="{{ fetchImages(colour) || product.imageUrl }}" alt="product" />
											</label>
										</div>
									</div>
								</div>
								<div *ngIf="cartItem.getUniqueSizes()">
									<div class="head">
										<div class="d-flex align-items-center justify-content-between">
											<h4>Size</h4>
											<button class="btn p-0" type="button" (click)="openSizeModel(findSizeModal)" *ngIf="
													product && (product.sizeChartUrl || product.sizeChart)
												">
												Find your size
											</button>
										</div>
									</div>

									<div class="color-size">
										<div class="form-check" *ngFor="let size of cartItem.getUniqueSizes()">
											<input class="form-check-input d-none" type="radio" name="size" id="size {{ size }}"
												(click)="cartItem.selectedSize = size.toString()" />
											<label class="form-check-label" for="size {{ size }}">
												{{ size }}
											</label>
										</div>
									</div>
								</div>
							</div>

							<div *ngIf="checkAuthenticated()" class="product-quantity">
								<div class="decorations" [style.align-items]="checkItems()">
									<button *ngIf="product.decorations && product.decorations.length" class="btn btn-secondary-icon"
										type="button" data-bs-toggle="collapse" data-bs-target="#DecorationCollapse" aria-expanded="false"
										aria-controls="DecorationCollapse">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
												<path class="plus" d="M12 5.5V19.5M5 12.5H19" stroke="#666D80" stroke-width="1.5"
													stroke-linecap="round" stroke-linejoin="round" />
												<path class="minus" d="M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
									<div *ngIf="product.decorations && product.decorations.length">
										<h4>
											Decoration
											<a href="javascript:void(0);" role="button" class="btn p-0">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
														fill="none">
														<path
															d="M8 8.5L8 11.5M8 6.27637V6.25M2 8.5C2 5.18629 4.68629 2.5 8 2.5C11.3137 2.5 14 5.18629 14 8.5C14 11.8137 11.3137 14.5 8 14.5C4.68629 14.5 2 11.8137 2 8.5Z"
															stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													</svg>
												</span>
											</a>
										</h4>
										<p class="mb-2" *ngFor="
												let decoration of cartItem.selectedDecorations;
												let i = index
											">
											<span *ngIf="hasCartAccess">+${{ utils.twoDecimalPlacesString(decoration.price) }}/ea -</span>
											{{ decoration.name }}
											<span *ngIf="decoration.isRequired"> - Required </span>
										</p>
									</div>
								</div>
								<div class="collapse" id="DecorationCollapse">
									<div class="edit-card decoration-collapse card-body">
										<div class="form-group mb-0" *ngIf="selectableDecorations.length">
											<ng-select #selectCustomer [virtualScroll]="true" placeholder="Add Decoration to your garment"
												[searchable]="true" [clearable]="true" [(ngModel)]="selectedDecoration" class="form-select-2"
												(change)="
													addDecorationToCart(selectedDecoration, true, false)
												">
												<ng-option *ngFor="
														let decoration of selectableDecorations;
														let isFirst = first
													" [value]="decoration.id">
													{{ decoration.name }} -
													<span *ngIf="hasCartAccess">${{ utils.twoDecimalPlacesString(decoration.price) }}/ea</span>
												</ng-option>
												>
											</ng-select>
										</div>
										<ul>
											<li *ngFor="
													let decoration of cartItem.selectedDecorations;
													let i = index
												">
												<p>
													<span>
														<span *ngIf="hasCartAccess">+${{ utils.twoDecimalPlacesString(decoration.price) }}/ea -</span>
														{{ decoration.name }}
														<span *ngIf="decoration.isRequired">
															- Required
														</span>
													</span>
													<button *ngIf="!decoration.isRequired" (click)="removeDecorationAtIndex(i)"
														class="btn btn-secondary-icon shadow-none p-0 rounded-0 ms-auto" type="button">
														<span>
															<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
																fill="none">
																<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5"
																	stroke-linecap="round" stroke-linejoin="round"></path>
															</svg>
														</span>
													</button>
												</p>
												<div class="form-group" *ngIf="
														decoration.options && decoration.options.length
													">
													<div *ngFor="let option of decoration.options">
														<label id="clientRequired {{ option.value }}" class="sele_color"
															*ngIf="option.name && option.name.length">{{ option.name }}
															<span *ngIf="option.required">
																- Required</span></label>
														<input type="text" class="form-control" id="clientRequired {{ option.value }}"
															placeholder="{{ option.name }}" [required]="option.required" [(ngModel)]="option.value" />
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>

								<div class="quantity flex-column flex-md-row">
									<div class="qty-input">
										<button (click)="cartItem.incrementQuantity(-1)" class="btn qty-count p-0" data-action="minus"
											type="button">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>
											</span>
										</button>
										<input class="product-qty" type="number" name="product-qty" value="1"
											[(ngModel)]="cartItem.selectedQuantity" appNoNegative />
										<button (click)="cartItem.incrementQuantity(1)" class="btn qty-count p-0" data-action="add"
											type="button">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M12 5V19M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>
											</span>
										</button>
									</div>
									<button [disabled]="!canAddToCart() || isLoading" (click)="addToCart()" *ngIf="hasCartAccess"
										class="btn btn-primary w-100" type="button">
										<span *ngIf="!isLoading">Add to cart</span>
										<div class="spinner-border" role="status" *ngIf="isLoading">
											<span class="visually-hidden">Loading</span>
										</div>
									</button>
								</div>
							</div>

							<!-- Packet Type/Size start here... -->
							<div *ngIf="usePackSizes() && checkAuthenticated()">
								<div class="form-group mb-3">
									<select #packSize class="form-select" aria-label="Default select example" id="Colour"
										(change)="setPackSize(packSize.value); (packSize.value)">
										<option *ngIf="!cartItem.selectedPack" [selected]="!cartItem.selectedPack">
											Select Packet
										</option>
										<option *ngFor="let unit of product.packSizes" [value]="unit.name">
											{{ unit.name }}: {{ unit.itemCount }}
										</option>
									</select>
								</div>
								<div class="form-group" *ngIf="cartItem.selectedPack">
									<input type="text" class="form-control" id="Type/Size" value="Total Quantity: {{
											cartItem.selectedPack.itemCount
										}}" readonly />
								</div>
							</div>
							<!-- Packet Type/Size end here... -->

							<button class="btn btn-secondary w-100 py-4" type="button" (click)="bulkModel(bulkOrder)" *ngIf="
									hasCartAccess &&
									product.variations &&
									product.variations.length > 1 &&
									cartItem.getUniqueColours() &&
									securityService.hasBulkOrderAccess()
								">
								Bulk order
							</button>
						</div>
						<div class="card-footer" *ngIf="!checkAuthenticated()">
							<p>To make a purchase you must log in</p>
							<button class="btn btn-primary w-100" type="button" (click)="setStickyMenu('account')">
								Create account
							</button>
							<button class="btn btn-secondary w-100" type="button" (click)="setStickyMenu('login')">
								Login
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div [class.container]="isPreview">
	<div class="descriptions">
		<h4 class="title">Description</h4>
		<div class="description-type">
			<div class="desc-1">
				<h5 class="title">General</h5>
				<ul>
					<li>
						<span class="desc-icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M10 16C10.4142 16 10.75 15.6642 10.75 15.25C10.75 14.8358 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.8358 9.25 15.25C9.25 15.6642 9.58579 16 10 16Z"
									stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
									stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M10 5.5C10.4142 5.5 10.75 5.16421 10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75C9.25 5.16421 9.58579 5.5 10 5.5Z"
									stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</span>
						<div>
							<h6>Category</h6>
							<p class="d-flex align-items-center gap-2" *ngIf="product.categories.length"
								[routerLink]="['/products', product.categories[0].name]">
								<a href="javascript:void(0)">{{ product.categories[0].name }}</a>
								<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
										<path d="M11.3002 5.19922L4.7002 11.7992" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
										<path d="M6.66699 5.16602L11.3003 5.19868L11.3337 9.83268" stroke="#818898" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg></span>
							</p>
						</div>
					</li>
					<li>
						<span class="desc-icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M14.5 3.25C13.9033 3.25 13.331 3.48705 12.909 3.90901C12.4871 4.33097 12.25 4.90326 12.25 5.5V14.5C12.25 15.0967 12.4871 15.669 12.909 16.091C13.331 16.5129 13.9033 16.75 14.5 16.75C15.0967 16.75 15.669 16.5129 16.091 16.091C16.5129 15.669 16.75 15.0967 16.75 14.5C16.75 13.9033 16.5129 13.331 16.091 12.909C15.669 12.4871 15.0967 12.25 14.5 12.25H5.5C4.90326 12.25 4.33097 12.4871 3.90901 12.909C3.48705 13.331 3.25 13.9033 3.25 14.5C3.25 15.0967 3.48705 15.669 3.90901 16.091C4.33097 16.5129 4.90326 16.75 5.5 16.75C6.09674 16.75 6.66903 16.5129 7.09099 16.091C7.51295 15.669 7.75 15.0967 7.75 14.5V5.5C7.75 4.90326 7.51295 4.33097 7.09099 3.90901C6.66903 3.48705 6.09674 3.25 5.5 3.25C4.90326 3.25 4.33097 3.48705 3.90901 3.90901C3.48705 4.33097 3.25 4.90326 3.25 5.5C3.25 6.09674 3.48705 6.66903 3.90901 7.09099C4.33097 7.51295 4.90326 7.75 5.5 7.75H14.5C15.0967 7.75 15.669 7.51295 16.091 7.09099C16.5129 6.66903 16.75 6.09674 16.75 5.5C16.75 4.90326 16.5129 4.33097 16.091 3.90901C15.669 3.48705 15.0967 3.25 14.5 3.25Z"
									stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</span>
						<div>
							<h6>Garment Type</h6>
							<p>{{ product.type }}</p>
						</div>
					</li>
					<li *ngFor="let data of description">
						<span class="desc-icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path d="M2.5 13.75L10 17.5L17.5 13.75" stroke="black" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M2.5 10L10 13.75L17.5 10" stroke="black" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M10 2.5L2.5 6.25L10 10L17.5 6.25L10 2.5Z" stroke="black" stroke-width="1.5"
									stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</span>
						<div [innerHTML]="data"></div>
					</li>
					<li>
						<div class="download_btn" *ngIf="product && product.dataSheetUrl">
							<a class="btn btn-secondary" role="button" href="{{ product && product.dataSheetUrl }}" target="_blank">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
										stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									<path d="M13.75 7L10 3.25L6.25 7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M10 3.25V12.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
								Download Product Datasheet</a>
						</div>
					</li>
				</ul>
			</div>
			<div class="desc-1">
				<h5 class="title">Features</h5>
				<ul>
					<li *ngFor="let data of features">
						<img src="../../../../assets/images/check-right.svg" alt="checkRight" />
						<div [innerHTML]="data"></div>
					</li>
					<div *ngIf="features.length == 0">
						<h5 class="text-muted">No Features added yet for this product</h5>
					</div>
				</ul>
			</div>
		</div>
	</div>
</div>

<div class="uniform-kit" *ngIf="checkAdmin() && !isPreview">
	<h4 class="title">Your Uniform Kit</h4>
	<div class="uniform-content">
		<div class="card balance-card">
			<div class="card-body">
				<h4 class="card-title d-flex align-items-center gap-25">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<path
								d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
								stroke="#C1C6D5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
					Balance
				</h4>
				<p class="desc" *ngFor="let item of allocationCollections">
					You have {{ item.count }} garments remaining in collection
					{{ item.name }}
				</p>
				<p class="desc muted">
					You have saved your details and delivery address, so you can use the
					quick order function.
				</p>
				<button class="btn btn-secondary w-100" type="button" (click)="scroll()" [routerLink]="['/quick-order']">
					Express order
				</button>
				<div class="d-flex align-items-center gap-25" *ngFor="let item of allocationCollections">
					<div class="radialProgressBar progress-70">
						<div class="overlay">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<path
									d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
									stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</div>
					</div>
					<p class="remain">
						Garment of collection({{ item.name }}) {{ item.count }}/{{
						findTotalAllocation(item)
						}}
					</p>
				</div>
				<hr />
				<div class="accordion" id="addressCollapse">
					<div class="accordion-item">
						<h2 class="accordion-header">
							<button class="accordion-button shadow-none" type="button" data-bs-toggle="collapse"
								data-bs-target="#addressCollapse1" aria-expanded="false" aria-controls="addressCollapse1">
								Address details
							</button>
						</h2>
						<div id="addressCollapse1" class="accordion-collapse collapse" data-bs-parent="#addressCollapse">
							<div class="accordion-body text-white" *ngIf="
									userShippingDetails &&
									userShippingDetails.addressName &&
									userShippingDetails.streetAddress
								">
								{{ addressDisplayText(userShippingDetails, true) }}
							</div>
							<div class="accordion-body text-white" *ngIf="!userShippingDetails">
								No Address Found
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="edit-card card" *ngIf="existingCartItems.length">
			<div class="card-body">
				<div *ngFor="let item of existingCartItems; let lastItem = last">
					<div class="product-list-card card-body p-0 align-items-start">
						<div class="uniform-md-content">
							<div class="card-img">
								<img [src]="item.variation.imageUrl" alt="item" />
							</div>
							<div class="uniform-md-titles d-md-none">
								<h4 class="title">{{ item.product.name }}</h4>
								<p class="price mb-0">
									$ {{ utils.twoDecimalPlacesString(getUnitPrice(item)) }}
								</p>
							</div>
						</div>
						<div class="content w-100" [class.align-items-start]="item.decorations && item.decorations.length">
							<div class="d-flex align-items-start justify-content-start justify-content-md-between"
								[class.w-md-100]="item.decorations && item.decorations.length">
								<div>
									<h4 class="title d-none d-md-block">
										{{ item.product.name }}
									</h4>
									<p class="price mb-0 d-none d-md-block">
										$ {{ utils.twoDecimalPlacesString(getUnitPrice(item)) }}
									</p>
								</div>
								<div class="d-flex align-items-center btns-card">
									<button (click)="deleteCartItem(item)" [disabled]="isRemoving && deleteItemId == item.id"
										class="btn btn-secondary shadow-none p-0 rounded-0 align-items-center" type="button">
										<span *ngIf="deleteItemId !== item.id">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<path d="M7.05033 7.05025L16.9498 16.9497M7.05033 16.9497L16.9498 7.05025" stroke="#666D80"
													stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
										<span *ngIf="deleteItemId !== item.id">Remove</span>
										<div class="spinner-border" role="status" *ngIf="isRemoving && deleteItemId == item.id">
											<span class="visually-hidden">Loading</span>
										</div>
									</button>
									<hr />
									<button [routerLink]="['/cart']" class="btn btn-secondary shadow-none p-0 rounded-0" type="button">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
												<path
													d="M9 14.9992H15.75M12.375 2.62419C12.6734 2.32582 13.078 2.1582 13.5 2.1582C13.7089 2.1582 13.9158 2.19936 14.1088 2.27931C14.3019 2.35926 14.4773 2.47646 14.625 2.62419C14.7727 2.77193 14.8899 2.94732 14.9699 3.14035C15.0498 3.33338 15.091 3.54026 15.091 3.74919C15.091 3.95813 15.0498 4.16501 14.9699 4.35804C14.8899 4.55107 14.7727 4.72646 14.625 4.87419L5.25 14.2492L2.25 14.9992L3 11.9992L12.375 2.62419Z"
													stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
										Edit
									</button>
								</div>
							</div>
							<div class="d-flex flex-column align-items-start justify-content-between gap-2">
								<ul class="order-detail-list">
									<li>
										<p>Color:</p>
										<span>
											<span class="color-dark" [style.background]="
													generateGradient(item.variation.colour)
												"></span>
											{{ item.variation.colour || "default" }}</span>
									</li>
									<li>
										<p>Size:</p>
										<span> {{ item.variation.size || "default" }}</span>
									</li>
									<li>
										<p>Quantity:</p>
										<span> {{ item.quantity }}</span>
									</li>
								</ul>
								<ul class="order-detail-list" *ngIf="item.decorations && item.decorations.length">
									<li class="d-block">
										<p>Decoration:</p>
										<span *ngFor="let dec of item.decorations">{{
											dec.decoration.name
											}}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<hr class="detail-row" />
				</div>
				<div class="d-block">
					<a class="btn btn-secondary w-sm-100" [routerLink]="['/products']" role="button">
						<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<path d="M8.0026 3.32812V12.6615M3.33594 7.99479H12.6693" stroke="#666D80" stroke-width="1.5"
									stroke-linecap="round" stroke-linejoin="round" />
							</svg></span>Add another item
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #findSizeModal let-modal let-c="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="modal-title" id="findSizeModalLabel">Find your size</h1>
			<button (click)="d('Cancel')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
				aria-label="Close">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="size-chart-img" *ngIf="product.sizeChart">
				<div class="img-text">
					<div class="img">
						<img src="../../../../assets/images/size-chart-product.png" alt="size-chart-product" />
					</div>
					<h4>Model is 1.87 cm and wears size M.</h4>
				</div>
				<div class="main-table">
					<table class="table">
						<thead>
							<tr>
								<th>Size</th>
								<th *ngFor="let header of product.sizeChart.columns">
									{{ header["title"] }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let row of product.sizeChart.rows; let i = index">
								<td>
									{{ row["name"] }}
								</td>
								<td *ngFor="let col of product.sizeChart.columns">
									{{ getCellData(row, col) }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="" *ngIf="!product.sizeChart">
				<img src="{{ product.sizeChartUrl }}" style="width: 100%" />
			</div>
		</div>
	</div>
</ng-template>

<ng-template #bulkOrder let-modal let-c="close" let-d="dismiss" class="deleteModal">
	<app-bulk-order-model [product]="product" [cartItem]="cartItem"
		(selectedCartItems)="addBulkOrder($event)"></app-bulk-order-model>
</ng-template>

<ng-template #collectionModal let-close="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="modal-title" id="allocationOrderLabel">
				Available Allocations
			</h1>
			<button (click)="d('Cross click')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
				aria-label="Close">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="modal-body">
			<h4 class="title mb-3">
				You have allocation available for this product.
			</h4>
			<div class="available-allocation d-flex align-items-stretch gap-4">
				<button class="btn btn-secondary w-100 align-items-center" type="button" (click)="close()">
					Do Not Use Allocation
				</button>
				<button class="btn btn-secondary w-100" type="button" *ngFor="let item of allocationCollections"
					(click)="close(item.id)">
					{{ item.name }}
					<br />
					Available Items: {{ item.count }}
				</button>
			</div>
		</div>
	</div>
</ng-template>