<section class="industries">
	<div class="max-width-container">
		<div class="container">
			<span class="text-wrapper">INDUSTRIES</span>
			<h1>Our offer</h1>
			<p>
				Through continued investment in technology investment and workforce
				output, we have set ourselves apart from our competitors in a way that
				adds immense value to our clients.
			</p>
		</div>
	</div>
</section>
<section class="indusbottom">
	<div class="max-width-container">
		<div class="container">
			<div class="inner-box">
				<div class="items" id="mining">
					<div class="content">
						<div class="button">
							<img class="img" src="../../../../assets/images/flag.svg" />
						</div>
						<div class="info">
							<h4 class="textwrapper">Mining</h4>
							<p>
								Recognising the challenging conditions within the Australian
								mining industry, we tailor our product range at Reali to
								prioritise both comfort and protection.
							</p>
						</div>
						<div class="brands">
							<div class="clients">
								<img src="../../../../assets/images/clients.svg" />
							</div>
							<div class="clients">
								<img src="../../../../assets/images/clients (1).svg" />
							</div>
							<div class="clients">
								<img src="../../../../assets/images/clients (2).svg" />
							</div>
						</div>
					</div>
					<div class="img-box">
						<img class="" src="../../../../assets/images/DSCF10671.png" />
					</div>
				</div>
				<div class="items" id="construction">
					<div class="content">
						<div class="button">
							<img class="img" src="../../../../assets/images/tool.svg" />
						</div>
						<div class="info">
							<h4 class="textwrapper">Construction</h4>
							<p>
								In the dynamic setting of the Australian construction industry,
								Reali is committed to providing our clients with the appropriate
								uniforms for on-site requirements.
							</p>
						</div>
						<div class="brands">
							<div class="clients">
								<img src="../../../../assets/images/clients (3).jpg" />
							</div>
							<div class="clients">
								<img src="../../../../assets/images/clients (4).svg" />
							</div>
							<div class="clients">
								<img src="../../../../assets/images/clients (5).svg" />
							</div>
						</div>
					</div>
					<div class="img-box">
						<img class="" src="../../../../assets/images/DSCF1177 1.png" />
					</div>
				</div>
				<div class="items" id="government">
					<div class="content">
						<div class="button">
							<img class="img" src="../../../../assets/images/briefcase.svg" />
						</div>
						<div class="info">
							<h4 class="textwrapper">Government</h4>
							<p>
								Reali values our partnerships with various councils, recognising
								them as highly valued clients. We understand the importance of
								on-site fitting days, intricate employee allocations, and
								catalogue management. SUMS is tailored to seamlessly integrate
								with their approval processes, ensuring efficient order
								management. 
							</p>
						</div>
						<div class="brands">
							<div class="clients">
								<img src="../../../../assets/images/clients (6).svg" />
							</div>
							<div class="clients max-8">
								<img src="../../../../assets/images/clients (8).svg" />
							</div>
							<div class="clients">
								<img src="../../../../assets/images/clients (7).svg" />
							</div>
						</div>
					</div>
					<div class="img-box">
						<img src="../../../../assets/images/DSCF1222 1.png" />
					</div>
				</div>
				<div class="items" id="infrastructure">
					<div class="content">
						<div class="button">
							<img class="img" src="../../../../assets/images/settings.svg" />
						</div>
						<div class="info">
							<h4 class="textwrapper">Infrastructure</h4>
							<p>
								In light of the numerous projects underway across Australia in
								the infrastructure industry, Reali guarantees that our uniform
								supply services extend to the most remote corners of the
								country. 
							</p>
						</div>
						<div class="brands">
							<div class="clients">
								<img src="../../../../assets/images/bmd-logo 1.svg" />
							</div>
							<div class="clients">
								<img src="../../../../assets/images/clients (9).svg" />
							</div>
							<div class="clients">
								<img src="../../../../assets/images/clients (10).svg" />
							</div>
						</div>
					</div>
					<div class="img-box">
						<img src="../../../../assets/images/DSCF0975 1.png" />
					</div>
				</div>
				<div class="items" id="healthcare">
					<div class="content">
						<div class="button">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="16"
								viewBox="0 0 18 16"
								fill="none"
							>
								<path
									d="M15.6301 2.45887C15.247 2.07562 14.7922 1.7716 14.2916 1.56418C13.791 1.35676 13.2545 1.25 12.7126 1.25C12.1707 1.25 11.6342 1.35676 11.1336 1.56418C10.633 1.7716 10.1782 2.07562 9.79509 2.45887L9.00009 3.25387L8.20509 2.45887C7.43132 1.6851 6.38186 1.2504 5.28759 1.2504C4.19331 1.2504 3.14386 1.6851 2.37009 2.45887C1.59632 3.23264 1.16162 4.28209 1.16162 5.37637C1.16162 6.47064 1.59632 7.5201 2.37009 8.29387L3.16509 9.08887L9.00009 14.9239L14.8351 9.08887L15.6301 8.29387C16.0133 7.9108 16.3174 7.45598 16.5248 6.95539C16.7322 6.45479 16.839 5.91823 16.839 5.37637C16.839 4.8345 16.7322 4.29795 16.5248 3.79735C16.3174 3.29676 16.0133 2.84194 15.6301 2.45887V2.45887Z"
									stroke="#666D80"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						<div class="info">
							<h4 class="textwrapper">Healthcare</h4>
							<p>
								Recognising the dedication of our healthcare professionals,
								among the hardest workers in the country, Reali is attuned to
								the specific requirements of each department. We ensure that
								uniforms are appropriately branded, facilitating easy
								identification within their respective fields. 
							</p>
						</div>
						<div class="brands">
							<div class="clients">
								<img src="../../../../assets/images/clients (2).svg" />
							</div>
							<div class="clients">
								<img src="../../../../assets/images/clients (12).svg" />
							</div>
						</div>
					</div>
					<div class="img-box">
						<img src="../../../../assets/images/DSCF1268 1.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
