<div
	class="existuser megamenu bg-light rounded-5"
	[class.active]="logginChallenge || forgotPassword"
>
	<div *ngIf="!createAccount">
		<form [formGroup]="loginForm">
			<div class="d-flex align-items-center justify-content-between mb-3">
				<button
					type="button"
					*ngIf="passwordResetRequired || forgotPassword"
					class="btn btn-back mb-0"
					(click)="back()"
				>
					<img src="../../../../assets/images/arrow.svg" alt="" />
				</button>
				<button
					class="btn btn-secondary-icon"
					*ngIf="passwordResetRequired || forgotPassword"
					type="button"
					(click)="close()"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
					>
						<path
							d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5"
							stroke="#666D80"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</button>
			</div>
			<div class="d-flex align-items-center justify-content-between">
				<h4 class="text-secondary" *ngIf="!passwordResetRequired">
					{{ heading }}
				</h4>
				<button
					class="btn btn-secondary-icon"
					type="button"
					*ngIf="!passwordResetRequired && !forgotPassword"
					(click)="close()"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
					>
						<path
							d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5"
							stroke="#666D80"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</button>
			</div>
			<div
				class="form-group"
				*ngIf="(!passwordResetRequired || forgotPassword) && !(useSSO$ | async)"
			>
				<label for="emailAddress1" class="form-label">Email address</label>
				<input
					type="email"
					class="form-control"
					id="emailAddress1"
					placeholder="Enter your email address"
					formControlName="username"
				/>
			</div>
			<div
				class="form-group"
				*ngIf="!passwordResetRequired && !forgotPassword && !(useSSO$ | async)"
			>
				<label for="Password1" class="form-label">Password</label>
				<input
					type="password"
					class="form-control"
					id="Password1"
					placeholder="Enter your password"
					formControlName="password"
				/>
			</div>
			<div class="form-group" *ngIf="passwordResetRequired">
				<label for="challengePassword" class="form-label">New Password </label>
				<input
					type="password"
					class="form-control"
					id="challengePassword"
					aria-describedby="emailHelp"
					placeholder="Enter your password"
					formControlName="password"
				/>
				<div id="emailHelp" class="form-text">
					Your password needs to be reset
				</div>
				<div
					*ngIf="
						loginForm.get('password')?.invalid &&
						loginForm.get('password')?.touched
					"
					class="form-text text-danger mt-1 ps-2"
				>
					Password is required
				</div>
			</div>
			<div class="form-group" *ngIf="logginChallenge">
				<label for="loginCode" class="form-label">Login Challenge Code</label>
				<input
					type="number"
					class="form-control"
					id="loginCode"
					placeholder="Login Challenge Code"
					formControlName="loginChallenge"
				/>
				<!-- <div id="emailHelp d-none" class="form-text">
          Code sent to your email address
        </div> -->
			</div>
			<div
				class="d-flex align-items-center justify-content-end mb-5"
				*ngIf="!passwordResetRequired && !forgotPassword && !(useSSO$ | async)"
			>
				<a (click)="forget()" class="btn btn-link"> Forgot password? </a>
			</div>
			<button
				type="submit"
				class="btn btn-primary mt-3"
				[disabled]="!(useSSO$ | async)"
				(click)="performSSO()"
				*ngIf="!passwordResetRequired && !forgotPassword && (useSSO$ | async)"
			>
				<p *ngIf="!isLoading">Login with SSO</p>
				<div class="spinner-border" role="status" *ngIf="isLoading">
					<span class="visually-hidden">Loading...</span>
				</div>
			</button>
			<button
				type="submit"
				class="btn btn-primary"
				[disabled]="(loginForm.invalid || isLoading) && !(useSSO$ | async)"
				(click)="login()"
				*ngIf="!passwordResetRequired && !forgotPassword && !(useSSO$ | async)"
			>
				<p *ngIf="!isLoading">Login</p>
				<p *ngIf="useSSO$ | async" (click)="performSSO()">Login with SSO</p>
				<div class="spinner-border" role="status" *ngIf="isLoading">
					<span class="visually-hidden">Loading...</span>
				</div>
			</button>
			<button
				type="button"
				class="btn btn-primary"
				[disabled]="loginForm.invalid || isLoading"
				(click)="reset()"
				*ngIf="passwordResetRequired"
			>
				<p *ngIf="!isLoading">Save</p>
				<div class="spinner-border" role="status" *ngIf="isLoading">
					<span class="visually-hidden">Loading...</span>
				</div>
			</button>
			<button
				type="submit"
				class="btn btn-primary"
				[disabled]="loginForm.invalid || isLoading"
				(click)="updatePassword()"
				*ngIf="forgotPassword"
			>
				<p *ngIf="!isLoading">Reset</p>
				<div class="spinner-border" role="status" *ngIf="isLoading">
					<span class="visually-hidden">Loading...</span>
				</div>
			</button>
		</form>
		<div
			class="login-new-acc"
			*ngIf="!passwordResetRequired && !forgotPassword && !(useSSO$ | async)"
		>
			<p>Don't have an account?</p>
			<a (click)="createAccount = true" class="btn btn-light">Create account</a>
		</div>
	</div>

	<div *ngIf="createAccount">
		<form [formGroup]="accountForm">
			<div class="d-flex align-items-center justify-content-between">
				<h4 class="text-secondary">request account</h4>
				<button class="btn btn-secondary-icon" type="button" (click)="close()">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
					>
						<path
							d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5"
							stroke="#666D80"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</button>
			</div>
			<div class="form-group">
				<label for="firstname" class="form-label">First Name</label>
				<input
					type="text"
					class="form-control"
					id="firstname"
					placeholder="Enter your first name"
					formControlName="firstname"
				/>
				<div
					*ngIf="
						accountForm.get('firstname')?.invalid &&
						accountForm.get('firstname')?.touched
					"
					class="form-text text-danger mt-1 ps-2"
				>
					First name is required
				</div>
			</div>
			<div class="form-group">
				<label for="lastname" class="form-label">Last Name</label>
				<input
					type="text"
					class="form-control"
					id="lastname"
					placeholder="Enter your last name"
					formControlName="lastname"
				/>
				<div
					*ngIf="
						accountForm.get('lastname')?.invalid &&
						accountForm.get('lastname')?.touched
					"
					class="form-text text-danger mt-1 ps-2"
				>
					Last name is required
				</div>
			</div>
			<div class="form-group">
				<label for="company" class="form-label">Company </label>
				<input
					type="text"
					class="form-control"
					id="company"
					placeholder="Enter company name"
					formControlName="company"
				/>
				<div
					*ngIf="
						accountForm.get('company')?.invalid &&
						accountForm.get('company')?.touched
					"
					class="form-text text-danger mt-1 ps-2"
				>
					Company name is required
				</div>
			</div>
			<div class="form-group">
				<label for="emailAddress2" class="form-label">Email Address</label>
				<input
					type="email"
					class="form-control"
					id="emailAddress2"
					placeholder="Enter your email address"
					formControlName="username"
				/>
				<div
					*ngIf="
						accountForm.get('username')?.invalid &&
						accountForm.get('username')?.touched
					"
					class="form-text text-danger mt-1 ps-2"
				>
					Email address is required and must be valid
				</div>
			</div>
			<div class="form-group">
				<label for="phone" class="form-label">Mobile Phone</label>
				<ngx-intl-tel-input
					[cssClass]="'custom form-control form-code shadow-none border-0 mb-0'"
					[preferredCountries]="preferredCountries"
					[enableAutoCountrySelect]="true"
					[enablePlaceholder]="true"
					[searchCountryFlag]="true"
					[searchCountryField]="[
						SearchCountryField.Iso2,
						SearchCountryField.Name
					]"
					[selectFirstCountry]="false"
					[selectedCountryISO]="CountryISO.Australia"
					[maxLength]="15"
					[phoneValidation]="true"
					[numberFormat]="PhoneNumberFormat.National"
					name="mobile"
					formControlName="mobile"
					[separateDialCode]="separateDialCode"
				>
				</ngx-intl-tel-input>
				<div
					*ngIf="
						accountForm.get('mobile')?.invalid &&
						accountForm.get('mobile')?.touched
					"
					class="form-text text-danger mt-1 ps-2"
				>
					Mobile Number must be valid
				</div>
			</div>

			<div class="form-group">
				<re-captcha
					(resolved)="resolved($event)"
					siteKey="6LeTtNYpAAAAAJMedwnoozdTodyBLkS2h9xkZiDC"
				></re-captcha>
			</div>

			<button
				type="button"
				class="btn btn-primary w-100"
				[disabled]="accountForm.invalid || !captcha || isLoading"
				(click)="create()"
			>
				<p *ngIf="!isLoading">Request account</p>
				<div class="spinner-border" role="status" *ngIf="isLoading">
					<span class="visually-hidden">Loading...</span>
				</div>
			</button>
		</form>
		<div class="login-new-acc">
			<p>Have an account?</p>
			<a (click)="createAccount = false" class="btn btn-light w-100">Login</a>
		</div>
	</div>
</div>
