<div class="edit-card card shadow-none rounded-0">
	<div class="row mx-0">
		<div class="col-12">
			<h5 class="form-title">Your addresses</h5>
		</div>
		<div class="checkout-address edit-card card px-0" [class.bg-secondary-subtle]="!hasSelectAccountAddress">
			<div class="card-body">
				<div class="d-flex align-items-start gap-4">
					<label for="addressSelected" class="address">
						<div class="w-50">
							<h4 class="title">
								{{ addressDisplayText(shippingDetails, true) }}
							</h4>
							<p>{{ shippingDetails.addressName }}</p>
							<p>{{ shippingDetails.postalCode }}</p>
						</div>
						<div class="w-50">
							<p>{{ shippingDetails.streetAddress }}</p>
							<p>{{ shippingDetails.streetAddress2 }}</p>
							<p>{{ shippingDetails.region }}</p>
						</div>
						<input *ngIf="hasSelectAccountAddress" class="form-check-input" type="radio" name="addressCheckUnique"
							id="addressSelected" checked />
					</label>
					<button *ngIf="
							hasSelectAccountAddress &&
							shippingDetailList.length &&
							shippingDetails
						" class="btn btn-secondary-icon" type="button" data-bs-toggle="collapse" data-bs-target="#addressCollapse"
						aria-expanded="false" aria-controls="addressCollapse">
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922" stroke="#36394A" stroke-width="1.5"
									stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</span>
					</button>
				</div>
				<div class="collapse" id="addressCollapse">
					<div class="edit-card card shadow-none rounded-0 mb-0 border-top pt-4 mt-4">
						<div class="form-group">
							<input type="search" class="form-control" id="search" name="search" placeholder="Search Address"
								[(ngModel)]="searchText" (ngModelChange)="serachAddress()" />
						</div>
						<div class="card-body p-0 max-h-account">
							<div class="address" *ngFor="let detail of filteredShippingDetailList" (click)="selectAddress(detail)">
								<div class="w-50">
									<h4 class="title">{{ addressDisplayText(detail, true) }}</h4>
									<p>{{ detail.addressName }}</p>
									<p>{{ detail.postalCode }}</p>
								</div>
								<div class="w-50">
									<p>{{ detail.streetAddress }}</p>
									<p>{{ detail.streetAddress2 }}</p>
									<p>{{ detail.region }}</p>
								</div>
								<input class="form-check-input" type="radio" name="addressCheck" id="addressCheck2 {{ detail.id }}"
									[checked]="shippingDetails.id == detail.id" />
							</div>
							<div class="address" *ngIf="filteredShippingDetailList.length == 0">
								No Result Found
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12">
			<button class="btn btn-secondary" *ngIf="hasEditShippingAddress" type="button"
				(click)="openAddressModel(addressModal)">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M10 4.75V15.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
						<path d="M4.75 10H15.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
				Add new address
			</button>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<h5 class="form-title">Purchase details</h5>
		</div>
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label for="PurchaseOrder" class="form-label">Purchase order number <span class="text-danger"
						*ngIf="poIsReq">*</span></label>
				<input type="text" class="form-control" id="PurchaseOrder" name="orderNo"
					[(ngModel)]="cartService.cart.attrs.orderReference" placeholder="Order Number" />
			</div>
		</div>
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label for="businessUnit" class="form-label">Business unit</label>
				<select [disabled]="isBusinessUnitDisabled" #buss class="form-select" aria-label="Default select example"
					id="businessUnit" (change)="selectBusinessUnit(buss.value)">
					<option selected="">Select Business Unit</option>
					<option *ngFor="let data of businessUnitsSelect2Data" [value]="data.id" [selected]="data.selected">
						{{ data.text }}
					</option>
				</select>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<h5 class="form-title">Personal details</h5>
		</div>
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label for="Name" class="form-label">Name <span class="text-danger">*</span></label>
				<input type="text" class="form-control" id="Name" name="name" placeholder="First Name"
					[(ngModel)]="attrs.contactFirstName" />
			</div>
		</div>
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label for="Surname" class="form-label">Surname <span class="text-danger">*</span></label>
				<input type="text" class="form-control" id="Surname" name="lastName" placeholder="Last Name"
					[(ngModel)]="attrs.contactLastName" />
			</div>
		</div>
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label for="EmailAddress" class="form-label">Email address <span class="text-danger">*</span></label>
				<input type="email" class="form-control" id="EmailAddress" name="email" placeholder="Contact Email"
					[(ngModel)]="attrs.contactEmail" />
			</div>
		</div>
		<div class="col-12 col-md-6">
			<div class="form-group">
				<label for="mobilePhone" class="form-label">Mobile phone</label>
				<form [formGroup]="phoneForm">
					<ngx-intl-tel-input id="mobilePhone" name="contact"
						[cssClass]="'custom form-control shadow-none border-0 mb-0'" [preferredCountries]="preferredCountries"
						[enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
							SearchCountryField.Iso2,
							SearchCountryField.Name
						]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Australia" [maxLength]="15"
						[phoneValidation]="true" [numberFormat]="PhoneNumberFormat.National" name="mobile"
						[(ngModel)]="attrs.contactPhone" formControlName="mobile" [separateDialCode]="separateDialCode"
						(ngModelChange)="onPhoneNumberChange($event)">
					</ngx-intl-tel-input>
					<!-- <div *ngIf="f.mobile.invalid && f.mobile.touched"
						class="text-danger form-text position-absolute">
						Mobile Number must be valid
					</div> -->
				</form>
			</div>
		</div>
	</div>
</div>

<ng-template #addressModal let-close="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="modal-title" id="allocationOrderLabel">
				Add Shipping Address
			</h1>
			<button (click)="d('Cross click')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
				aria-label="Close">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="edit-users">
				<div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path
											d="M16.75 8.5C16.75 13.75 10 18.25 10 18.25C10 18.25 3.25 13.75 3.25 8.5C3.25 6.70979 3.96116 4.9929 5.22703 3.72703C6.4929 2.46116 8.20979 1.75 10 1.75C11.7902 1.75 13.5071 2.46116 14.773 3.72703C16.0388 4.9929 16.75 6.70979 16.75 8.5Z"
											stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path
											d="M10 10.75C11.2426 10.75 12.25 9.74264 12.25 8.5C12.25 7.25736 11.2426 6.25 10 6.25C8.75736 6.25 7.75 7.25736 7.75 8.5C7.75 9.74264 8.75736 10.75 10 10.75Z"
											stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								Address Detail
							</h4>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="addressName" class="form-label">Name *</label>
										<input type="text" class="form-control" id="addressName" placeholder="Address Name"
											[(ngModel)]="address.addressName" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="enterCountry" class="form-label">Country *</label>
										<input type="text" class="form-control" id="enterCountry" placeholder="Enter Country"
											[(ngModel)]="address.country" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="addressLine1" class="form-label">Address Line 1 *</label>
										<input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
											[(ngModel)]="address.streetAddress" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="Suburb" class="form-label">Suburb *</label>
										<input type="text" class="form-control" id="Suburb" placeholder="Enter Suburb"
											[(ngModel)]="address.suburb" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="addressLine2" class="form-label">Address Line 2</label>
										<input type="text" class="form-control" id="addressLine2" placeholder="Address Line 2"
											[(ngModel)]="address.streetAddress2" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="City" class="form-label">City *</label>
										<input type="text" class="form-control" id="City" placeholder="Enter City"
											[(ngModel)]="address.city" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="State" class="form-label">State *</label>
										<input type="text" class="form-control" id="State" placeholder="Enter State"
											[(ngModel)]="address.region" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<label for="postalCode" class="form-label">Postal Code *</label>
										<input type="text" class="form-control" id="postalCode" placeholder="Enter Postal Code"
											[(ngModel)]="address.postalCode" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-warning btn-block" (click)="selectAddress(address)">
				Save
			</button>
			<button type="button" class="btn btn-outline-danger" (click)="d('Cross click')">
				Cancel
			</button>
		</div>
	</div>
</ng-template>