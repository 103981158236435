import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesApi } from 'src/app/main/api/roles.api';
import { ICustomerUser } from 'src/app/main/model/customer.user.model';
import { ICustomerDomain } from 'src/app/main/model/customerDomain';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { IRole } from 'src/app/main/model/role.model';
import { CustomerDomainService } from 'src/app/main/services/customerDomain.service';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';

@Component({
  selector: 'app-customer-domain',
  templateUrl: './customer-domain.component.html',
  styleUrls: []
})
export class CustomerDomainComponent {
  model: ICustomerDomain = {
    customerId: 0,
    domain: null,
    useSSO: false,
    cognitoUserPoolId: null,
    cognitoClientId: null,
    cognitoAuthDomain: null,
    cognitoProvider: null,
    defaultUserRoleId: null
  };
  public customerUsers: ICustomerUser[];
  public userRoles: QueryResult<IRole>;

  constructor(
    private route: ActivatedRoute,
    private modelService: CustomerDomainService,
    private customerUserService: CustomerUserService,
    private userRoleApi: RolesApi,
  ) {
  }

  ngOnInit() {

    this.route.params.subscribe(params => {

      if (params['customerId']) {
        this.model.customerId = parseInt(params.customerId);
        this.loadModel(this.model.customerId);
        this.loadCustomerUsers();
        this.fetchRoles();
      }
    })
  }

  /**
   * @description Does any pre-processing that needs to be done on the model before it is saved. 
   * Primarily, grants downstream permissions associated with any other permission
   */
  beforeSave() {
    if (this.model.contactId === 0) {
      delete this.model.contactId;
    }
  }


  /**
   * @description Validate and persist the product in the server, ignoring validating for Draft Products
   */
  saveModel() {
    this.beforeSave();
    const handler = this.model.id ? this.modelService.update(this.model.id, this.model) : this.modelService.create(this.model);

    handler.subscribe(model => {
      this.model.id = model.id;
    },
      err => {
      }
    );
  }

  /**
   * @description Loads the existing product data from the database
   */
  loadModel(id: number) {

    this.modelService.getByCustomerId(id)
      .subscribe({
        next: (model: ICustomerDomain) => {
          this.model = model;
        },
        error: err => {
        }
      });
  }

  fetchRoles() {
    let query: IQueryFilter = new IQueryFilter({
      sortBy: 'id',
      filter: {}
    });
    this.userRoleApi.list(query).subscribe(userRole => {
      this.userRoles = userRole;
    })
  }

  /**
   * @description Returns the supplied value with _ replaced with space and in sentence case
   */
  prettyName(value: string): string {
    if (!value.length) return '';
    let result = value.toLowerCase().replace(/_/g, ' ');
    return result.split(/\s/).map(val => val.length ? val[0].toUpperCase() + val.substr(1) : val).join(" ");
  }

  loadCustomerUsers() {
    let query: IQueryFilter = new IQueryFilter({
      filter: {},
      limit: 1000,
      order: 'desc',
      include: [{ association: 'user', required: true }]
    });
    query.filter['customerId'] = this.model.customerId;

    this.customerUserService.list(query).subscribe(res => {
      this.customerUsers = res.rows;
    });
  }
}
