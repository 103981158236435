import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NotificationsService } from 'angular2-notifications';
import { has } from 'lodash';
import { Subject, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { IBusinessUnit } from 'src/app/main/model/businessUnit.model';
import { ICustomerUser } from 'src/app/main/model/customer.user.model';
import { IQueryFilter } from 'src/app/main/model/query.filter.class';
import { User } from 'src/app/main/model/user.model';
import { BusinessUnitService } from 'src/app/main/services/businessUnit.service';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { SecurityService } from 'src/app/main/services/security.service';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';

@Component({
  selector: 'app-business-edit',
  templateUrl: './business-edit.component.html',
  styleUrls: []
})
export class BusinessEditComponent implements OnDestroy {
  public customerId: number | null;
  public readonly: boolean = true;
  public isAdmin: boolean = false;
  businessUnit: IBusinessUnit = {
    name: "",
    code: "",
    customerId: 0,
    shippingDetails: {
      city: '',
      country: '',
      postalCode: '',
      region: '',
      streetAddress: '',
      streetAddress2: '',
      suburb: '',
      addressName: '',
      isFreeHandling: false
    },
    contact: { user: { firstName: '', lastName: '' } }
  };

  users: Array<User>;
  public customerQuery = new IQueryFilter({
    limit: 10,
    sortBy: 'first_name',
    order: 'desc',
    include: [{ association: 'user', required: true }]
  });
  public noItemsFoundText: string;
  @ViewChild('customerUser') selectCustomerUser: NgSelectComponent;
  private searchTerms: Subject<string> = new Subject<string>();
  private readonly subscriptionGroup = new SubscriptionGroup();
  customerSelectOptions: { id: string | undefined, text: string }[];

  constructor(
    private readonly session: SessionApi,
    private readonly activatedRoute: ActivatedRoute,
    private readonly businessUnitService: BusinessUnitService,
    private readonly router: Router,
    private readonly securityService: SecurityService,
    private customerUserService: CustomerUserService,
    private notifications: NotificationsService,
    private cd: ChangeDetectorRef
  ) {
    this.search();
  }

  ngOnInit() {
    this.monitorAccess();

    this.subscriptionGroup.add(
      combineLatest(
        this.activatedRoute.params,
        this.session.$userData,
        this.session.$customerData
      ).subscribe(([params, userData, customerData]) => {
        this.customerId = null;

        if (userData?.isAdmin) {
          this.isAdmin = true;
          if (has(params, "customerId")) {
            this.customerId = Number(params["customerId"]);
          }
        } else if (customerData) {
          this.customerId = customerData.id;
        }

        if (has(params, 'id')) {
          this.businessUnitService.get(params["id"]).subscribe(businessUnit => {
            this.businessUnit = businessUnit;
            let data: { id: string, text: string } | undefined;
            if (this.businessUnit.contactId) {
              businessUnit.contactId = businessUnit.contactId?.toString();
              this.customerUserService.get(+this.businessUnit.contactId).subscribe((result: any) => {
                if (result) {
                  data = { id: result.id.toString(), text: result.user?.firstName }
                }
              })
            }
            this.handleCustomerListGet(false, data);
          })
        } else {
          this.handleCustomerListGet();
        }
      })
    );
  }

  monitorAccess() {
    this.subscriptionGroup.add(this.securityService.canEditAddresses().subscribe(canEditAddresses => this.readonly = !canEditAddresses))
  }

  /**
 * @description Turns response from Customer Get into options for select2
 *
 * @param {QueryResult<HasId & NewCustomer>} result
 */
  handleCustomerListGet(isScroll: boolean = false, data?: { id: string, text: string }) {
    this.noItemsFoundText = 'Fetching...'
    if (isScroll) {
      this.customerQuery.limit = this.customerQuery.limit + 10;
    }
    this.customerQuery.filter['customerId'] = this.customerId;
    this.customerUserService.list(this.customerQuery).subscribe(res => {
      if (res.rows.length == 0) {
        this.noItemsFoundText = 'User not found'
      }

      if (data) {
        this.customerSelectOptions = [
          data,
          ...res.rows.map(customerUser => ({ id: customerUser.id?.toString(), text: customerUser.user.firstName }))
        ];
      } else {
        this.customerSelectOptions = [
          ...res.rows.map(customerUser => ({ id: customerUser.id?.toString(), text: customerUser.user.firstName }))
        ];
      }
    });
  }

  onCustomerSearch(searchTerm: { term: string }) {
    this.searchTerms.next(searchTerm.term);
  }

  search() {
    this.searchTerms.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (this.customerQuery.include) {
        const userInclude = this.customerQuery.include[0];

        if (!userInclude.filter) userInclude.filter = {};

        if (searchTerm && searchTerm.length)
          userInclude.filter['$or'] = {
            firstName: { $like: '%' + searchTerm + '%' },
            lastName: { $like: '%' + searchTerm + '%' },
            email: { $like: '%' + searchTerm + '%' }
          }
        else
          delete userInclude.filter['$or'];
      }
      this.handleCustomerListGet();
    });
  }

  private readonly createOrUpdate = () => {
    if (!this.businessUnit.name) {
      this.businessUnit.name = '';
    }
    if (this.businessUnit.contactId) {
      this.businessUnit.contactId = +this.businessUnit.contactId;
    }
    if (!this.businessUnit.id) {
      return this.businessUnitService.create(this.businessUnit);
    }

    return this.businessUnitService.update(this.businessUnit.id, this.businessUnit);
  };

  selectUser() {
    if (this.selectCustomerUser.selectedValues.length) {
      this.businessUnit.contactId = this.selectCustomerUser.selectedValues[0].id;
      this.cd.detectChanges();
    }
  }


  saveBusinessUnit() {
    if (this.customerId) {
      this.businessUnit.customerId = this.customerId;
    }

    if (!this.businessUnit.contactId) {
      this.businessUnit.contactId = undefined;
    }

    if (!this.businessUnit.name || !this.businessUnit.name.trim().length || !this.businessUnit.code) {
      this.notifications.warn('Add Business Unit', 'Validation error');
      return;
    }

    if (this.businessUnit.name.trim().length > 50) {
      this.notifications.warn('Warning', 'Business unit name should be 50 characters or less.');
      return;
    }

    this.createOrUpdate()
      .subscribe({
        next: () => {
          const currentUser = this.session.$userData.value;

          if (!this.businessUnit.id) {
            if (currentUser && currentUser.isAdmin) {
              this.router.navigate(['/manage/customer/edit', this.customerId], {
                queryParams: { activeTab: 6 }
              });
            } else {
              this.router.navigate(['/account/business-unit']);
            }
          }
        },
        error: err => {

        }
      })
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
  }
}

//
