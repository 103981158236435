
import { environment } from "../../../environments/environment";

/**
 * @description Rudimentary logger
 */

const logLevels = {
	none: 0,
	silly: 1,
	debug: 2,
	info: 3,
	warn: 4,
	error: 5,
	all: 6
};

const logLevelName = environment.logLevel || "debug";
const logLevel = logLevels[logLevelName] || logLevels.debug;

export namespace logger {
	export function none() {
		// do nothing
	} 
	export function silly(...args:Array<unknown>) {
		if( logLevel <= logLevels.silly ) {
			console.log.call({}, 'silly:', ...args);
		}
	}
	export function debug(...args:Array<unknown>) {
		if( logLevel <= logLevels.debug ) {
			console.log.call({}, 'debug:', ...args);
		}
	}
	export function info(...args:Array<unknown>) {
		if( logLevel <= logLevels.info ) {
			console.log.call({}, 'info:', ...args);
		}
	}
	/**
	 * @description Warn has been mapped to log due to the nature
	 * of warn in the browser, which is often hidden by default.
	 * 
	 * @param {any[]} args
	 */
	export function warn(...args:Array<unknown>) {
		if( logLevel <= logLevels.warn ) {
			console.log.call({}, 'warn:', ...args);
		}
	}
	export function error(...args:Array<unknown>) {
		if( logLevel <= logLevels.error ) {
			console.log.call({}, 'error:');
			console.error.call({}, ...args);
		}
	}
	export function all(...args:Array<unknown>) {
		if( logLevel <= logLevels.all ) {
			console.log.call({}, 'all:', ...args);
		}
	}
}

logger.info(`Initalized Log at ${logLevelName} : ${logLevel}`);