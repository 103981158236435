import { Component, Input } from '@angular/core';
import { SizeChartColumn, SizeChartEntry, SizeChartRow } from '../../model/sizeChart.model';

@Component({
  selector: 'app-size-table',
  templateUrl: './size-table.component.html',
  styleUrls: []
})
export class SizeTableComponent {
  @Input()
  public sizeChart: SizeChartEntry;

  newSizeName = '';
  constructor(
  ) { }

  addColumn() {
    this.sizeChart.addColumn()
  }

  public removeSizeColumn(column: SizeChartColumn) {
    if (this.sizeChart.columnsOrdered.length >= 1) {
      this.sizeChart.removeColumn(column);
    }
  }

  moveUp(row: SizeChartRow) {
    this.sizeChart.moveUp(row);
  }

  moveDown(row: SizeChartRow) {
    this.sizeChart.moveDown(row);
  }

  removeRow(row: SizeChartRow) {
    this.sizeChart.removeRow(row);
  }

  addRow(newSizeName: string) {
    const rowsValid = this.sizeChart.rows.every(row => row.name && row.name.trim().length > 0) && this.sizeChart.rowsSaved.every(row => row._name && row._name.trim().length > 0);
    if (rowsValid) {
      this.sizeChart.addRowByName(newSizeName);
      this.newSizeName = '';
    }
  }
}
