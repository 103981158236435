import { Component } from '@angular/core';

@Component({
  selector: 'app-shipping-delivery',
  templateUrl: './shipping-delivery.component.html',
  styleUrls: []
})
export class ShippingDeliveryComponent {

}
