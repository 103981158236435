<section class="product-detail-container pt-0">
	<div class="max-width-container">
		<div class="container">
			<!-- ============================ -->
			<!-- paggination start here... -->
			<!-- ============================ -->
			<nav aria-label="breadcrumb" class="pageBreadcrumb d-none d-lg-block">
				<ol class="breadcrumb mb-0">
					<li class="breadcrumb-item">
						<a [routerLink]="['/home']">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M3.25 7.75L10 2.5L16.75 7.75V16C16.75 16.3978 16.592 16.7794 16.3107 17.0607C16.0294 17.342 15.6478 17.5 15.25 17.5H4.75C4.35218 17.5 3.97064 17.342 3.68934 17.0607C3.40804 16.7794 3.25 16.3978 3.25 16V7.75Z"
									stroke="#818898"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M7.75 17.5V10H12.25V17.5"
									stroke="#818898"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</a>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/products']"> All products </a>
					</li>
					<li
						class="breadcrumb-item"
						*ngFor="let link of categoryLinks; let i = index"
						[class.active]="i === categoryLinks.length - 1"
					>
						<a
							*ngIf="i !== categoryLinks.length - 1"
							[routerLink]="[link.link]"
							>{{ link.decodedText }}</a
						>
						<span *ngIf="i === categoryLinks.length - 1">{{
							link.decodedText
						}}</span>
					</li>
				</ol>
			</nav>
			<div class="backs mt-4 mt-lg-0 d-lg-none">
				<a
					*ngIf="categoryLinks.length"
					[routerLink]="[categoryLinks[0].link]"
					class="btn btn-back p-0 gap-2"
					role="button"
				>
					<span class="">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="6"
							height="10"
							viewBox="0 0 6 10"
							fill="none"
						>
							<path
								d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
					Back to {{ categoryLinks[0].decodedText }}
				</a>
			</div>
			<router-outlet></router-outlet>
		</div>
	</div>
</section>

<section class="product-slides" id="proSlides">
	<div class="max-width-container">
		<div class="container">
			<h4 class="title">Products</h4>
		</div>
	</div>
	<div class="container-fluid" [class.max-width-container]="isReSize">
		<ngx-slick-carousel
			class="carousel product-slide"
			#slickModal="slick-carousel"
			[config]="slideConfig"
			(init)="slickInit()"
			(afterChange)="afterChange()"
		>
			<div
				class="product-card card bg-transparent"
				ngxSlickItem
				*ngFor="let data of queryResult.rows"
			>
				<div class="card-img">
					<img src="{{ data.imageUrl }}" alt="" width="100%" />
					<div class="badges d-none">
						<span class="badge bg-aqua-25">New</span>
					</div>
				</div>

				<div class="card-content">
					<a
						class="btn p-0 mb-2 title shadow-none"
						role="button"
						(click)="handleRoute(data.uri)"
						>{{ data.name }}</a
					>
					<h6 class="brand">{{ data.brandName() }}</h6>
					<p class="price" *ngIf="data.price || data.basePrice">
						${{ data.price || data.basePrice }}
					</p>
				</div>
			</div>
		</ngx-slick-carousel>
	</div>
</section>
