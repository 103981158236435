import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { Brand } from '../model/ddb.model';
import { LabelApi } from '../api/label.api';

@Injectable()
export class LabelService {
  constructor(
    private notifications: NotificationsService,
    private labelApi: LabelApi
  ) {
  }


  public list(query: IQueryFilter): Observable<any> {
    return apiCallWrapper(
      this.labelApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Labels"
      }
    )
  }

  public create(data: any): Observable<Brand & HasId<string>> {
    return apiCallWrapper(
      this.labelApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Updating Labels"
      }
    )
  }
}
