import { Injectable } from "@angular/core";
import { interval, Observable, startWith, Subscription, switchMap, tap } from "rxjs";
import { logger } from "../../util/Logger";
import { environment } from "src/environments/environment";
import { ModalComponent } from "../../template/model.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StorageService, StorageServiceKey } from "../storage.service";

const className = 'VersionService';

@Injectable()
export class VersionService {

	private appVersionMonitor: Subscription | null = null;


	constructor(
		private modalService: NgbModal,
		private storageService: StorageService
	) { }

	/**
	 * @description Monitors the application version by periodically checking for updates and proposing an upgrade if necessary. Only one monitor can exist at a time.
	 *
	 * @returns {void}
	 */
	public monitorAppVersion = () => {
		const signature = className + '.monitorAppVersion: ';

		if (this.appVersionMonitor) {
			return this.appVersionMonitor;
		}

		this.storageService.removeItem(StorageServiceKey.app_version_skipped);

		this.appVersionMonitor = interval(5 * 1000 * 60).pipe(
			startWith(0),
			tap(() => logger.debug(signature + `Checking Application Version`)),
			switchMap(() => this.fetchAppVersion()),
		).subscribe(version => {
			if (this.requiresUpgrade(version)) {
				this.proposeUpgrade(version);
			}
		});

		return this.appVersionMonitor;
	}

	/**
	 * @description Performs an upgrade process by storing the current version, fetching updates, and reloading the page. Fetch is used to try to force caching behaviours.
	 *
	 * @returns {void}
	 */
	private performUpgrade = () => {
		const signature = className + '.performUpgrade: ';
		this.storageService.setItem(StorageServiceKey.app_version_before_upgrade, environment.version);
		const currentUrl = window.location.href;

		fetch(window.location.href, { cache: 'no-store' })
			.then(() => {
				logger.warn(signature + `Update Finished. Reloading...`);
				window.location.href = currentUrl + (currentUrl.includes('#') ? '' : '#');
				location.reload();
			})
			.catch(err => {
				logger.error(signature + `Error Performing Upgrade`);
				logger.error(err);
			});
	}

	/**
 * @description Skips the upgrade process by storing the current version and logging the action.
 *
 * @param {string} version - The version to store before skipping the upgrade.
 * @returns {void}
 */

	private skipUpgrade = (version: string) => {
		const signature = className + '.skipUpgrade: ';
		this.storageService.setItem(StorageServiceKey.app_version_skipped, version);
		logger.warn(signature + `Skipped Upgrade`);
		return;
	}

	/**
 * @description Proposes an upgrade by opening a modal dialog to ask the user to update to a new version and returns the reference to the modal.
 *
 * @param {string} newVersion - The new version to display in the upgrade proposal.
 * @returns {NgbModalRef} - The reference to the opened modal dialog.
 *
 * @example
 * ```
 * const upgradeModal = proposeUpgrade('2.0.0');
 * ```
 */
	private proposeUpgrade = (version: string): NgbModalRef => {
		const modal = this.modalService.open(ModalComponent, {
			scrollable: false,
			size: 'sm',
			centered: true,
			backdrop: true,
			windowClass: 'deleteModal'
		});

		if (modal.componentInstance) {
			const component = modal.componentInstance as ModalComponent;

			component.title = 'New Version Available.';
			component.data = `<p class='text-center'>Your version differs from the server version. Update Now?</p>`;
			component.buttons = [{
				text: 'Upgrade',
				action: 'close',
				value: true,
				class: 'btn-success'
			}, {
				text: 'Skip for now',
				action: 'close',
				value: false,
				class: 'btn-danger'
			}]
		}

		modal.result
			.then((approveUpgrade: boolean) => {
				if (approveUpgrade) {
					this.performUpgrade();
				} else {
					this.skipUpgrade(version);
				}
			})
			.catch(err => {
				if (err !== undefined) {
					logger.error(err);
				}
			});

		return modal;
	}

	/**
 * @description Determines if an upgrade is required based on the comparison between the server version and the current version.
 *
 * @param {string} serverVersion - The version of the server to compare against.
 * @returns {boolean} - Returns true if an upgrade is required; otherwise, false.
 */
	private requiresUpgrade = (serverVersion: string): boolean => {
		const signature = className + '.requiresUpgrade: ';
		// Check if we previously upgraded from this version or skipped this version
		const versionBeforeUpgrade = this.storageService.getItem(StorageServiceKey.app_version_before_upgrade);

		if (typeof versionBeforeUpgrade === 'string' && versionBeforeUpgrade.length > 0 && versionBeforeUpgrade === environment.version && versionBeforeUpgrade !== 'LOCAL') {
			logger.error(signature + `Preventing version check loop`);
			return false;
		}

		const skippedVersion = this.storageService.getItem(StorageServiceKey.app_version_skipped);

		if (typeof skippedVersion === 'string' && skippedVersion.length > 0 && skippedVersion === serverVersion && skippedVersion !== 'LOCAL') {
			logger.error(signature + `Skipped version check loop`);
			return false;
		}

		if (serverVersion === 'LOCAL') {
			return false;
		}

		if (serverVersion !== environment.version) {
			return true;
		}

		return false;
	}

	/**
 * @description Fetches the latest version information from a specified endpoint and returns it as an Observable.
 *
 * @returns {Observable<string>} - An Observable that emits the latest version information.
 */

	private fetchAppVersion = (): Observable<string> => {
		const signature = className + '.fetchAppVersion: ';

		return new Observable<string>(observer => {
			fetch('/assets/version.txt')
				.then(response => {
					if (!response.ok) {
						logger.error(signature + `Failed to fetch app version`);
						observer.next('UNKNOWN');
						observer.complete();
					}
					return response.text();
				})
				.then(data => {
					const trimmedData = data.trim() || 'UNKNOWN';
					logger.debug(signature + `Found Version[${trimmedData}]`);
					observer.next(trimmedData);
					observer.complete();
				})
				.catch(error => {
					logger.error(error);
					observer.next('UNKNOWN');
					observer.complete();
				});
		});
	};
}
