import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, combineLatest } from 'rxjs';
import { SessionApi } from '../../api/session.api';
import { IAuthDataResponse } from '../../model/session.model';
import { SecurityService } from '../../services/security.service';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { AppHeaderComponent } from '../app-header/app-header.component';
import { routePermissions } from '../../routing-security';
import { PermissionsGuard } from '../../guard/permissions.guard';

interface MenuItem {
  label: string;
  paths: any[];
  isAccess: boolean | null | undefined;
  active?: boolean;
  url: string;
}

interface AccordionItem {
  title: string;
  id: string;
  expanded: boolean;
  isAccess: boolean | null | undefined;
  items: MenuItem[];
}

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: []
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  public menuItems: MenuItem[] = [];
  public accordions: AccordionItem[] = [];
  public accountItem: MenuItem[] = [];
  public currentRoute = '';
  public accountManageItem: MenuItem[] = [];
  constructor(
    public securityService: SecurityService,
    public session: SessionApi,
    private router: Router,
    private appHeaderComponent: AppHeaderComponent,
		private permissionsGuard: PermissionsGuard
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.url.toLowerCase();
    });

    this.currentRoute = this.router.url.toLowerCase();
  }

  private readonly subscriptionGroup = new SubscriptionGroup();

  get isAdmin(): IAuthDataResponse | null {
    return this.session.$userData.getValue();
  }

  ngOnInit() {
    this.monitorAccess();
  }

  public hasManageCustomersAccess: boolean = false;
  public hasManageGroupsAccess: boolean = false;
  public hasManageProductsAccess: boolean = false;
  public hasManageDecorationsAccess: boolean = false;
  public hasManageCollectionsAccess: boolean = false;
  public hasManageSizeChartsAccess: boolean = false;
  public hasManageCategoriesAccess: boolean = false;
  public hasManageBrandsAccess: boolean = false;
  public isSuperAdmin: boolean | null = false;
  public canEditAddresses: boolean = false;
  public canEditBusinessUnits: boolean = false;
  public hasReportsAccess: boolean = false;
  public canListDocumentAccess: boolean = false;
  public canListUsers: boolean = false;
  public showOwnBalance: boolean = false;
  /**
   * @description Convenience method to determine if the user has ANY of the product management permissions
   * @returns
   */
  hasAnyProductAccess = () => {
    return this.hasManageProductsAccess ||
      this.hasManageDecorationsAccess ||
      this.hasManageCollectionsAccess ||
      this.hasManageSizeChartsAccess ||
      this.hasManageCategoriesAccess ||
      this.hasManageBrandsAccess ||
      this.isSuperAdmin;
  }

  /**
   * Monitors various access checks and updates the local viewstate where appropriate
   */
  monitorAccess() {
    this.subscriptionGroup.add(
      combineLatest(
        this.securityService.isSuperAdmin(),
        this.securityService.hasManageCustomerAccess(),
        this.securityService.hasManageGroupsAccess(),
        this.securityService.hasManageProductsAccess(),
        this.securityService.hasManageDecorationsAccess(),
        this.securityService.hasManageCollectionsAccess(),
        this.securityService.hasManageSizeChartsAccess(),
        this.securityService.hasManageCategoriesAccess(),
        this.securityService.hasManageBrandsAccess(),
        this.securityService.canEditAddresses(),
        this.securityService.canEditBusinessUnits(),
        this.securityService.hasReportsAccess(),
        this.securityService.canListDocumentAccess(),
        this.securityService.canListUsers(),
        this.securityService.showOwnBalance(),
				this.hasRouteAccess('/manage/users'),
				this.hasRouteAccess('/admin-reports'),
				this.hasRouteAccess('/manage/orders')
      ).subscribe(([
        isSuperAdmin,
        hasManageCustomersAccess,
        hasManageGroupsAccess,
        hasManageProductsAccess,
        hasManageDecorationsAccess,
        hasManageCollectionsAccess,
        hasManageSizeChartsAccess,
        hasManageCategoriesAccess,
        hasManageBrandsAccess,
        canEditAddresses,
        canEditBusinessUnits,
        hasReportsAccess,
        canListDocumentAccess,
        canListUsers,
        showOwnBalance,

				manageUserAccess,
				adminReportAccess,
				manageOrdersAccess,
      ]) => {
        this.isSuperAdmin = isSuperAdmin;
        this.hasManageCustomersAccess = this.isSuperAdmin || hasManageCustomersAccess;
        this.hasManageGroupsAccess = this.isSuperAdmin || hasManageGroupsAccess;
        this.hasManageProductsAccess = this.isSuperAdmin || hasManageProductsAccess;
        this.hasManageDecorationsAccess = this.isSuperAdmin || hasManageDecorationsAccess;
        this.hasManageCollectionsAccess = this.isSuperAdmin || hasManageCollectionsAccess;
        this.hasManageSizeChartsAccess = this.isSuperAdmin || hasManageSizeChartsAccess;
        this.hasManageCategoriesAccess = this.isSuperAdmin || hasManageCategoriesAccess;
        this.hasManageBrandsAccess = this.isSuperAdmin || hasManageBrandsAccess;
        this.canEditAddresses = canEditAddresses;
        this.canEditBusinessUnits = canEditBusinessUnits;
        this.hasReportsAccess = hasReportsAccess;
        this.canListDocumentAccess = canListDocumentAccess;
        this.canListUsers = canListUsers;
        this.showOwnBalance = showOwnBalance

        this.menuItems = [
          { label: 'Dashboard', paths: [{ d: 'M16.75 11.5H11.5V16.75H16.75V11.5Z' }, { d: 'M8.5 11.5H3.25V16.75H8.5V11.5Z' }, { d: 'M16.75 3.25H11.5V8.5H16.75V3.25Z' }, { d: 'M8.5 3.25H3.25V8.5H8.5V3.25Z' }], active: true, isAccess: true, url: '/manage/dashboard' },
          { label: 'Manage users', paths: [{ d: 'M12.6247 6.625L15.2497 4M16.7497 2.5L15.2497 4L16.7497 2.5ZM9.54222 9.7075C9.92947 10.0896 10.2373 10.5445 10.448 11.0461C10.6587 11.5477 10.7682 12.0859 10.77 12.63C10.7718 13.174 10.666 13.713 10.4587 14.216C10.2513 14.7189 9.94651 15.1759 9.56183 15.5606C9.17714 15.9453 8.72016 16.2501 8.21719 16.4574C7.71423 16.6648 7.17522 16.7706 6.63119 16.7688C6.08717 16.7669 5.54887 16.6575 5.04731 16.4468C4.54575 16.2361 4.09082 15.9283 3.70872 15.541C2.95732 14.763 2.54154 13.721 2.55094 12.6395C2.56034 11.5579 2.99416 10.5233 3.75897 9.7585C4.52377 8.99369 5.55838 8.55987 6.63994 8.55047C7.7215 8.54107 8.76348 8.95685 9.54147 9.70825L9.54222 9.7075ZM9.54222 9.7075L12.6247 6.625L9.54222 9.7075ZM12.6247 6.625L14.8747 8.875L17.4997 6.25L15.2497 4L12.6247 6.625Z' }], isAccess: manageUserAccess, url: '/manage/users' },
          { label: 'Manage customers', paths: [{ d: 'M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16M10.75 6.25C10.75 7.90685 9.40685 9.25 7.75 9.25C6.09315 9.25 4.75 7.90685 4.75 6.25C4.75 4.59315 6.09315 3.25 7.75 3.25C9.40685 3.25 10.75 4.59315 10.75 6.25Z' }], isAccess: this.isAdmin?.isAdmin, url: '/manage/customer' },
          { label: 'Manage orders', paths: [{ d: 'M12.25 15.25L10 17.5M10 17.5L7.75 15.25M10 17.5V2.5M15.25 7.75L17.5 10M17.5 10L15.25 12.25M17.5 10H2.5M4.75 7.75L2.5 10M2.5 10L4.75 12.25M7.75 4.75L10 2.5M10 2.5L12.25 4.75' }], isAccess: manageOrdersAccess, url: `/${this.isAdmin?.isAdmin ? 'manage' : 'account'}/orders` },
          { label: 'My lists', paths: [{ d: 'M11.25 14.75L6 11L0.75 14.75V2.75C0.75 2.35218 0.908035 1.97064 1.18934 1.68934C1.47064 1.40804 1.85218 1.25 2.25 1.25H9.75C10.1478 1.25 10.5294 1.40804 10.8107 1.68934C11.092 1.97064 11.25 2.35218 11.25 2.75V14.75Z' }], isAccess: !this.isAdmin?.isAdmin, url: '/account/favorites' },
        ];

        this.accordions = [
          {
            title: 'Manage',
            id: 'Manage',
            expanded: true,
            isAccess: this.hasAnyProductAccess(),
            items: [
              { label: 'Manage products', paths: [{ d: 'M2.5 13.75L10 17.5L17.5 13.75' }, { d: 'M2.5 10L10 13.75L17.5 10' }, { d: 'M10 2.5L2.5 6.25L10 10L17.5 6.25L10 2.5Z' }], isAccess: this.hasManageProductsAccess, url: '/manage/products' },
              { label: 'Decorations', paths: [{ d: 'M13.375 8.05041L6.625 4.15791M3.45251 6.22041L10 10.0079L16.5475 6.22041M10 17.5604V10.0004M16.75 13.0004V7.00041C16.7497 6.73737 16.6803 6.47902 16.5487 6.25129C16.417 6.02355 16.2278 5.83444 16 5.70291L10.75 2.70291C10.522 2.57126 10.2633 2.50195 10 2.50195C9.73669 2.50195 9.47803 2.57126 9.25 2.70291L4 5.70291C3.7722 5.83444 3.58299 6.02355 3.45135 6.25129C3.31971 6.47902 3.25027 6.73737 3.25 7.00041V13.0004C3.25027 13.2635 3.31971 13.5218 3.45135 13.7495C3.58299 13.9773 3.7722 14.1664 4 14.2979L9.25 17.2979C9.47803 17.4296 9.73669 17.4989 10 17.4989C10.2633 17.4989 10.522 17.4296 10.75 17.2979L16 14.2979C16.2278 14.1664 16.417 13.9773 16.5487 13.7495C16.6803 13.5218 16.7497 13.2635 16.75 13.0004Z' }], isAccess: this.hasManageDecorationsAccess, url: '/manage/decorations' },
              { label: 'Collections', paths: [{ d: 'M16.75 10C16.75 11.245 13.75 12.25 10 12.25C6.25 12.25 3.25 11.245 3.25 10M16.75 4.75C16.75 5.99264 13.7279 7 10 7C6.27208 7 3.25 5.99264 3.25 4.75M16.75 4.75C16.75 3.50736 13.7279 2.5 10 2.5C6.27208 2.5 3.25 3.50736 3.25 4.75M16.75 4.75V15.25C16.75 16.495 13.75 17.5 10 17.5C6.25 17.5 3.25 16.495 3.25 15.25V4.75' }], isAccess: this.hasManageCollectionsAccess, url: '/manage/collections' },
              { label: 'Size charts', paths: [{ d: 'M16.9078 12.918C16.4307 14.0464 15.6844 15.0407 14.7342 15.814C13.784 16.5873 12.6589 17.1161 11.4571 17.3541C10.2554 17.5921 9.01362 17.5322 7.84041 17.1794C6.6672 16.8266 5.59827 16.1919 4.72707 15.3305C3.85586 14.4692 3.20891 13.4076 2.84278 12.2385C2.47664 11.0694 2.40247 9.82841 2.62675 8.62402C2.85103 7.41963 3.36692 6.28852 4.12934 5.32957C4.89175 4.37062 5.87746 3.61304 7.0003 3.12305' }, { d: 'M17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5V10H17.5Z' }], isAccess: this.hasManageSizeChartsAccess, url: '/manage/sizeChart' },
              { label: 'Categories', paths: [{ d: 'M17.5 15.25C17.5 15.6478 17.342 16.0294 17.0607 16.3107C16.7794 16.592 16.3978 16.75 16 16.75H4C3.60218 16.75 3.22064 16.592 2.93934 16.3107C2.65804 16.0294 2.5 15.6478 2.5 15.25V4.75C2.5 4.35218 2.65804 3.97064 2.93934 3.68934C3.22064 3.40804 3.60218 3.25 4 3.25H7.75L9.25 5.5H16C16.3978 5.5 16.7794 5.65804 17.0607 5.93934C17.342 6.22064 17.5 6.60218 17.5 7V15.25Z' }], isAccess: this.hasManageCategoriesAccess, url: '/manage/categories' },
              { label: 'Brands', paths: [{ d: 'M4 17.5V12.25' }, { d: 'M4 12.25C4 12.25 4.75 11.5 7 11.5C9.25 11.5 10.75 13 13 13C15.25 13 16 12.25 16 12.25V3.25C16 3.25 15.25 4 13 4C10.75 4 9.25 2.5 7 2.5C4.75 2.5 4 3.25 4 3.25V12.25Z' }], isAccess: this.hasManageBrandsAccess, url: '/manage/brands' },
              { label: 'Labels', paths: [{ d: 'M16.4425 11.0575L11.065 16.435C10.9257 16.5745 10.7603 16.6851 10.5782 16.7606C10.3961 16.8361 10.2009 16.8749 10.0038 16.8749C9.80663 16.8749 9.61144 16.8361 9.42934 16.7606C9.24724 16.6851 9.08181 16.5745 8.9425 16.435L2.5 10V2.5H10L16.4425 8.9425C16.7219 9.22354 16.8787 9.60372 16.8787 10C16.8787 10.3963 16.7219 10.7765 16.4425 11.0575V11.0575Z' }, { d: 'M6.25 6.25H6.25667' }], isAccess: this.hasManageBrandsAccess, url: '/manage/labels' }
            ]
          },
          {
            title: 'Admin',
            id: 'Admin',
            expanded: false,
            isAccess: this.isSuperAdmin,
            items: [
              { label: 'User roles', paths: [{ d: 'M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16M10.75 6.25C10.75 7.90685 9.40685 9.25 7.75 9.25C6.09315 9.25 4.75 7.90685 4.75 6.25C4.75 4.59315 6.09315 3.25 7.75 3.25C9.40685 3.25 10.75 4.59315 10.75 6.25Z' }], isAccess: this.isSuperAdmin, url: '/manage/userRoles' },
              { label: 'Admin roles', paths: [{ d: 'M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z' }, { d: 'M13.1803 6.82031L11.5903 11.5903L6.82031 13.1803L8.41031 8.41031L13.1803 6.82031Z' }], isAccess: this.isSuperAdmin, url: '/manage/adminRoles' },
              { label: 'System', paths: [{ d: 'M14.5 3.25C13.9033 3.25 13.331 3.48705 12.909 3.90901C12.4871 4.33097 12.25 4.90326 12.25 5.5V14.5C12.25 15.0967 12.4871 15.669 12.909 16.091C13.331 16.5129 13.9033 16.75 14.5 16.75C15.0967 16.75 15.669 16.5129 16.091 16.091C16.5129 15.669 16.75 15.0967 16.75 14.5C16.75 13.9033 16.5129 13.331 16.091 12.909C15.669 12.4871 15.0967 12.25 14.5 12.25H5.5C4.90326 12.25 4.33097 12.4871 3.90901 12.909C3.48705 13.331 3.25 13.9033 3.25 14.5C3.25 15.0967 3.48705 15.669 3.90901 16.091C4.33097 16.5129 4.90326 16.75 5.5 16.75C6.09674 16.75 6.66903 16.5129 7.09099 16.091C7.51295 15.669 7.75 15.0967 7.75 14.5V5.5C7.75 4.90326 7.51295 4.33097 7.09099 3.90901C6.66903 3.48705 6.09674 3.25 5.5 3.25C4.90326 3.25 4.33097 3.48705 3.90901 3.90901C3.48705 4.33097 3.25 4.90326 3.25 5.5C3.25 6.09674 3.48705 6.66903 3.90901 7.09099C4.33097 7.51295 4.90326 7.75 5.5 7.75H14.5C15.0967 7.75 15.669 7.51295 16.091 7.09099C16.5129 6.66903 16.75 6.09674 16.75 5.5C16.75 4.90326 16.5129 4.33097 16.091 3.90901C15.669 3.48705 15.0967 3.25 14.5 3.25Z' }], isAccess: this.isSuperAdmin, url: '/manage/system' },
              { label: 'Reports', paths: [{ d: 'M10.75 2.5H5.5C5.10218 2.5 4.72064 2.65804 4.43934 2.93934C4.15804 3.22064 4 3.60218 4 4V16C4 16.3978 4.15804 16.7794 4.43934 17.0607C4.72064 17.342 5.10218 17.5 5.5 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V7.75L10.75 2.5Z' }, { d: 'M10.75 2.5V7.75H16' }], isAccess: adminReportAccess, url: '/manage/reports' },
              { label: 'Admin reports', paths: [{ d: 'M11.5 2.5H5.5C5.10218 2.5 4.72064 2.65804 4.43934 2.93934C4.15804 3.22064 4 3.60218 4 4V16C4 16.3978 4.15804 16.7794 4.43934 17.0607C4.72064 17.342 5.10218 17.5 5.5 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V7L11.5 2.5Z' }, { d: 'M10 14.5V10' }, { d: 'M7.75 12.25H12.25' }, { d: 'M11.5 2.5V7H16' }], isAccess: this.isSuperAdmin, url: '/manage/admin-reports' },
              { label: 'Email test', paths: [{ d: 'M4 4H16C16.825 4 17.5 4.675 17.5 5.5V14.5C17.5 15.325 16.825 16 16 16H4C3.175 16 2.5 15.325 2.5 14.5V5.5C2.5 4.675 3.175 4 4 4Z' }, { d: 'M17.5 5.5L10 10.75L2.5 5.5' }], isAccess: this.isSuperAdmin, url: '/manage/email-test' }
            ]
          }
        ];

        this.accountItem = [
          { label: 'Users', paths: [{ d: 'M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16M10.75 6.25C10.75 7.90685 9.40685 9.25 7.75 9.25C6.09315 9.25 4.75 7.90685 4.75 6.25C4.75 4.59315 6.09315 3.25 7.75 3.25C9.40685 3.25 10.75 4.59315 10.75 6.25Z' }], isAccess: !this.isAdmin?.isAdmin && canListUsers, url: '/account/users' },
          { label: 'business Unit', paths: [{ d: 'M16 6.25H4C3.17157 6.25 2.5 6.92157 2.5 7.75V15.25C2.5 16.0784 3.17157 16.75 4 16.75H16C16.8284 16.75 17.5 16.0784 17.5 15.25V7.75C17.5 6.92157 16.8284 6.25 16 6.25Z' }, { d: 'M13 16.75V4.75C13 4.35218 12.842 3.97064 12.5607 3.68934C12.2794 3.40804 11.8978 3.25 11.5 3.25H8.5C8.10218 3.25 7.72064 3.40804 7.43934 3.68934C7.15804 3.97064 7 4.35218 7 4.75V16.75' }], isAccess: canEditBusinessUnits, url: '/account/business-unit' },
          { label: 'Addresses', paths: [{ d: 'M16.75 8.5C16.75 13.75 10 18.25 10 18.25C10 18.25 3.25 13.75 3.25 8.5C3.25 6.70979 3.96116 4.9929 5.22703 3.72703C6.4929 2.46116 8.20979 1.75 10 1.75C11.7902 1.75 13.5071 2.46116 14.773 3.72703C16.0388 4.9929 16.75 6.70979 16.75 8.5Z' }, { d: 'M10 10.75C11.2426 10.75 12.25 9.74264 12.25 8.5C12.25 7.25736 11.2426 6.25 10 6.25C8.75736 6.25 7.75 7.25736 7.75 8.5C7.75 9.74264 8.75736 10.75 10 10.75Z' }], isAccess: canEditAddresses, url: '/account/addresses' },
        ]

        this.accountManageItem = [
          { label: 'Collections', paths: [{ d: 'M16.75 10C16.75 11.245 13.75 12.25 10 12.25C6.25 12.25 3.25 11.245 3.25 10M16.75 4.75C16.75 5.99264 13.7279 7 10 7C6.27208 7 3.25 5.99264 3.25 4.75M16.75 4.75C16.75 3.50736 13.7279 2.5 10 2.5C6.27208 2.5 3.25 3.50736 3.25 4.75M16.75 4.75V15.25C16.75 16.495 13.75 17.5 10 17.5C6.25 17.5 3.25 16.495 3.25 15.25V4.75' }], isAccess: !this.isAdmin?.isAdmin, url: '/account/collections' },
          { label: 'Reports', paths: [{ d: 'M13 4H14.5C14.8978 4 15.2794 4.15804 15.5607 4.43934C15.842 4.72064 16 5.10218 16 5.5V16C16 16.3978 15.842 16.7794 15.5607 17.0607C15.2794 17.342 14.8978 17.5 14.5 17.5H5.5C5.10218 17.5 4.72064 17.342 4.43934 17.0607C4.15804 16.7794 4 16.3978 4 16V5.5C4 5.10218 4.15804 4.72064 4.43934 4.43934C4.72064 4.15804 5.10218 4 5.5 4H7' }, { d: 'M12.25 2.5H7.75C7.33579 2.5 7 2.83579 7 3.25V4.75C7 5.16421 7.33579 5.5 7.75 5.5H12.25C12.6642 5.5 13 5.16421 13 4.75V3.25C13 2.83579 12.6642 2.5 12.25 2.5Z' }], isAccess: hasReportsAccess, url: '/account/reports' },
          { label: 'Documents', paths: [{ d: 'M10.75 2.5H5.5C5.10218 2.5 4.72064 2.65804 4.43934 2.93934C4.15804 3.22064 4 3.60218 4 4V16C4 16.3978 4.15804 16.7794 4.43934 17.0607C4.72064 17.342 5.10218 17.5 5.5 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V7.75L10.75 2.5Z' }, { d: 'M10.75 2.5V7.75H16' }], isAccess: canListDocumentAccess, url: '/account/documents' },
        ]

      })
    );
  }

  // Returns true if lowercase url is equal to this.currentRoute or this.currentRoute starts with the lowercase url
  public isActiveMenuItem(url: string | undefined) {
    if (!url) return false;
    let lowerCaseUrl = url.toLowerCase();
    let currentRouteWithoutQuery = this.currentRoute;
    return currentRouteWithoutQuery.includes(lowerCaseUrl);
  }

  closeAll() {
    this.appHeaderComponent.closeAll();
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
  }

	private hasRouteAccess = (k: keyof typeof routePermissions) => {
		return this.permissionsGuard.shouldActivate(routePermissions[k]);
	}
}
