import { Component } from '@angular/core';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-stale-product-report',
  templateUrl: './stale-product-report.component.html',
  styleUrls: []
})
export class StaleProductReportComponent {
  public isDataLoaded: boolean;
  public reportData: {
    name: string,
    code: string,
    brand_name: string,
    type: string,
    base_price: string,
    total_sold: string,
    most_recent_sale: string
  }[] = [];

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit() {
    this.loadStaleProducts();
  }

  loadStaleProducts() {
    this.reportData = []
    this.reportService.getStaleProductAdminReport().subscribe(data => {
      this.reportData = data[0];
      this.isDataLoaded = true;
    });
  }

  /**
   * @description Exports the current contents of reportData to CSV
   */
  downloadFile() {
    this.reportService.createCSV({ data: {}, type: 'staleProduct' }).subscribe();
  }
}
