import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewDecoration, NewProduct, NewProductVariation } from '../../model/ddb.model';
import { NewCartItem } from '../../model/cart.model';
import { BulkOrderContainer, BulkOrderItem } from "../../model/bulkOrder.model";
import { isNullOrUndefined } from 'util';
import { Utils } from '../../util/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-bulk-order-model',
  templateUrl: './bulk-order-model.component.html',
  styleUrls: []
})
export class BulkOrderModelComponent {
  @Input()
  public product: NewProduct = new NewProduct();

  @Input()
  public cartItem: NewCartItem = new NewCartItem();

  @Output()
  public selectedCartItems = new EventEmitter<NewCartItem[]>();

  public bulkOrderData: Array<BulkOrderContainer> = [];
  public currentVarient: BulkOrderContainer;
  public currColor = '';
  public isEdit: boolean = false;
  public isLoading: boolean;
  constructor(
    public utils: Utils,
    public activeModal: NgbModal,
    public notifications: NotificationsService
  ) { }

  dismiss() {
    this.activeModal.dismissAll();
  }

  pushChain(obj: BulkOrderContainer, isAdd: boolean) {

    if (obj.Colour == 'Choose Color') {
      alert('Please Select Colour.');
      return;
    }
    /* Check if a bulk order already exists for this colour */
    if (this.bulkOrderData.length && this.bulkOrderData.find(_ => _.Colour === obj.Colour) && this.isEdit) {
      let existOrder = this.bulkOrderData.find(_ => _.Colour === obj.Colour);
      if (existOrder) {
        existOrder = this.currentVarient;
      }
      this.isEdit = false;
      this.currentVarient = {};
      return;
    } else if (this.bulkOrderData.length && this.bulkOrderData.find(_ => _.Colour === obj.Colour) && !this.isEdit) {
      this.currColor = 'Choose color';

      alert('Colour already selected. Please select a different colour.');

      return;
    }

    obj.Items = [];
    this.product.variations.forEach(variation => {

      if (variation.colour === obj.Colour) {
        let productImage = this.product.images.find(image => image.name === variation.colour);
        const image = productImage ? productImage.url : this.product.imageUrl as string | undefined;
        obj.Items?.push(<BulkOrderItem>{
          Guid: variation.guid,
          Size: variation.size,
          Units: 0
        });
        obj.imageUrl = image;
      }
    });

    this.currentVarient = obj;
    this.currentVarient.imageUrl = obj.imageUrl;
    this.bulkOrderData.push(this.currentVarient);
    this.addRequiredDecorations();
  }

  /**
 * @description Iterates through the decorations available on the product, and adds all required
 * decorations to all bulk order containers that do not have the required decoration
 */
  private addRequiredDecorations() {
    if (!this.product) throw new Error("Cannot add required decorations without a product");
    if (!this.product.decorations || !this.product.decorations.length) return;

    this.product.decorations.forEach(decoration => {
      if (decoration.isRequired) {
        this.bulkOrderData.forEach(bulkOrderItem => {
          if (!bulkOrderItem.Decorations) bulkOrderItem.Decorations = [];
          const hasRequiredDecoration = bulkOrderItem.Decorations.find(bulkDecoration => bulkDecoration.id === decoration.id);
          if (!hasRequiredDecoration) {
            this.addDecorationToCart(decoration.id, bulkOrderItem, false, true);
          }
        });
      }
    });
    this.setSelectableDecorations();
  }

  /**
 * @description Adjusts the list of selectable decorations relative to the maximum number of each decoration that can appear on a product
 * @returns {void}
 */
  public setSelectableDecorations(): void {
    this.bulkOrderData.forEach(bulkOrderItem => {
      if (!this.product.decorations || !this.product.decorations.length) {
        bulkOrderItem.selectableDecorations = [];
        this.currentVarient.selectableDecorations = [];
        return;
      }

      if (!bulkOrderItem.Decorations || !bulkOrderItem.Decorations.length) {
        bulkOrderItem.selectableDecorations = this.product.decorations;
        this.currentVarient.selectableDecorations = this.product.decorations;
        return;
      }

      bulkOrderItem.selectableDecorations = this.product.decorations.filter(decoration => {
        if (isNullOrUndefined(decoration.maxPerProduct)) {
          return true;
        }

        // Ensure the product doesn't have the maxed out decorations
        const instances = bulkOrderItem.Decorations?.filter(
          selected => selected.id === decoration.id
        ).length;

        if (instances === 0) {
          return true;
        }

        const result = instances && instances < decoration.maxPerProduct;
        return result;
      });

      this.currentVarient.selectableDecorations = bulkOrderItem.selectableDecorations;
    });
  }

  selectPackSize(name: string) {
    return this.product.packSizes.find(pack => pack.name == name)
  }

  /**
* @description Updates the cart item by cloning the decoration and adding it to the selected decoration list
* @param {NewDecoration} decoration the decoration to add to the cart
* @param {boolean} updateSelectable false will cause the method to not trigger the update of the decorations. Used for bulk adding behaviour. Defaults to true.
* @param {boolean} isRequired sets the required state of the decoration. Should be false for subsequent required decorations after initial setup. So that
*  if multiple copies a required decoration exist, the subsequent ones can be removed
*/
  public addDecorationToCart(decorationId: string | number | undefined, model: BulkOrderContainer, updateSelectable: boolean = true, isRequired: boolean) {
    if (!model.hasOwnProperty('Decorations')) model['Decorations'] = [];

    if (decorationId) {
      let decoration = this.product.decorations.find(decc => decc.id == +decorationId);

      const decorationClone: NewDecoration = JSON.parse(JSON.stringify(decoration));

      decorationClone.isRequired = isRequired;

      model.Decorations?.push(decorationClone);

      if (updateSelectable) {
        this.setSelectableDecorations();
      }
    }
  }

  /**
 * @description Removes the decoration at the supplied idx. Does not match on id as decorations may occasionally appear more than once on a bulkOrderItem
 * @param {BulkOrderContainer} model
 * @param {number} decorationIdx
 */
  removeBulkDecoration(model: BulkOrderContainer, targetIdx: number) {
    if (!model.hasOwnProperty('Decorations')) model['Decorations'] = [];

    if (model.Decorations) {
      if (targetIdx > -1 && (model.Decorations.length - 1) >= targetIdx) {
        model.Decorations.splice(targetIdx, 1);

        this.setSelectableDecorations();
      }
    }
  }

  spliceChain = (index: number) => this.bulkOrderData.splice(index, 1);

  usePackSizes = (): boolean => !!(this.product.packSizes && this.product.packSizes.length);

  pushBulkOrder(evt: MouseEvent) {
    this.isLoading = true;
    this.notifications.warn('Performing Checks', 'Validating new cart item');

    for (let n = 0; n < this.bulkOrderData.length; n++) {
      const bulkOrderItem = this.bulkOrderData[n];

      if (!bulkOrderItem.Decorations || !bulkOrderItem.Decorations.length) {
        continue;
      }

      let hasDecorationOptionError = false;
      for (let i = 0; i < bulkOrderItem.Decorations.length; i++) {
        const decoration = bulkOrderItem.Decorations[i];
        if (!decoration.options || !decoration.options.length) { continue; }

        const requiredMissingData = decoration.options.filter(option => option.required && (!option.value || !option.value.length));
        if (requiredMissingData && requiredMissingData.length) {
          hasDecorationOptionError = true;
          break;
        }
      }

      if (hasDecorationOptionError) {
        alert("Ensure required options for all selected decorations have been correctly filled.");
        evt.preventDefault();
        evt.stopPropagation();
        this.isLoading = false;
        return false;
      }
    }

    let packSizeError: boolean = false;
    let CartItems: NewCartItem[] = [];
    let hasUnitsGreaterThanZero = false;
    this.bulkOrderData.forEach(bulkOrder => {
      bulkOrder.Items?.forEach(data => {
        if (data.hasOwnProperty('Units') && data.Units && data.Units > 0) {
          hasUnitsGreaterThanZero = true;
          let item = new NewCartItem(this.product);

          let variation: NewProductVariation | undefined = this.product.variations.find(d => d.guid === data.Guid);

          if (!variation) {
            return;
          }

          // This will also set the size and colour
          item.selectedVariation = variation;
          item.selectedQuantity = data.Units;

          if (bulkOrder.Pack) {
            item.selectedPack = bulkOrder.Pack;
          } else if (this.usePackSizes()) {
            packSizeError = true;
          }

          const selectedVariation = this.product.variations.find(variation => variation.guid === data.Guid);

          if (selectedVariation) {
            item.selectedVariation = selectedVariation;
          }

          item.selectedQuantity = data.Units;

          /*
          if (bulkOrder.Pack && Array.isArray(bulkOrder.Pack) && bulkOrder.Pack.length) {
            item.selectedPack = bulkOrder.Pack;
            item.Quantity = item.Pack[1] * item.Units
          }
          */

          if (bulkOrder.Decorations && bulkOrder.Decorations.length) {
            if (!item.selectedDecorations) item.selectedDecorations = [];

            item.selectedDecorations.push(...bulkOrder.Decorations);
          }

          CartItems.push(item);
        }
      });
    });

    if (!hasUnitsGreaterThanZero) {
      this.notifications.warn('Performing Checks', 'Please increase quantity of any item');
      evt.preventDefault();
      evt.stopPropagation();
      this.isLoading = false;
      return false;
    }

    if (packSizeError) {
      alert('Invalid Pack Size Selection. Please select a pack size for all bulk order products');
      evt.preventDefault();
      evt.stopPropagation();
      this.isLoading = false;
      return false;
    } else {
      this.selectedCartItems.emit(CartItems);
      this.bulkOrderData = [];
    }

    this.bulkOrderData = [];
  }

  generateGradient(colorString: string | undefined): string {
    if (colorString) {
      const colors = colorString.split('/');
      if (colors.length >= 2) {
        const firstColor = colors[0].trim();
        const secondColor = colors[1].trim();
        return `linear-gradient(0deg, ${firstColor} 50%, ${secondColor} 50%)`;
      } else if (colorString) {
        return 'black';
      } else {
        return 'black';
      }
    } else {
      return 'black';
    }
  }
}
