<!-- ================================== -->
<!-- About Banner content start here... -->
<!-- ================================== -->
<section class="aboutbannersec">
	<div class="max-width-container">
		<div class="container">
			<div class="banner-content">
				<div class="text-secondary abhd">ABOUT US</div>
				<h1>We’re Reali</h1>
				<p id="about">
					Our passion is uniforms. Since our inception in 2010 we have
					experienced a mix of challenges and success but most of all, knowledge
					of what we believe will serve our clients best. What once started as a
					small retail store in Currumbin on the Gold Coast has grown and
					evolved into an industry leader in innovative, technology-backed
					business-to-business supply of uniforms and safety equipment.
				</p>
			</div>
			<div class="banner-img">
				<img src="../../../../assets/images/aboutframe.png" alt="aboutframe" />
			</div>
		</div>
	</div>
</section>
<!-- ================================= -->
<!-- About Banner content end here... -->
<!-- ================================== -->

<!-- ======================== -->
<!-- Our Vision start here... -->
<!-- ======================== -->
<section class="our-vision">
	<div class="max-width-container py-0">
		<div class="container">
			<div class="rinner">
				<div class="box">
					<div class="vision-content">
						<h4 class="mb-4">
							Our commitment is to transform how you view the cost of uniforms.
							To Reali, you invest in a great uniform. We believe investing in
							great uniforms builds a connection between your team and the
							company. We believe a great uniform fosters and builds teams. We
							believe your uniform represents your brand. Our passion is
							uniforms because uniforms matter.
						</h4>
						<div class="minhead text-warning justify-content-md-center">
							<img src="../../../../assets/images/infoicon.svg" alt="" />
							OUR VISION
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ======================== -->
<!-- Our Vision end here... -->
<!-- ======================== -->

<!-- ============================ -->
<!-- Team & Leaders section start here... -->
<!-- ============================ -->
<section class="ourTeam" id="team">
	<div class="max-width-container">
		<div class="container">
			<div class="top-heading">
				<h2><span class="inhh"> Meet </span> our Leaders</h2>
				<p class="desc">
					Our Founder, Kane Thompson, has a mantra that great teams build
					successful, innovative organisations, not great people. Meet the team
					that is committed to the success of Reali. They share a passion for
					uniforms and alignment with our Four Pillars of Success.
				</p>
			</div>
			<div class="contents">
				<div class="items">
					<a href="javascript:void(0);" class="pe-none" role="button">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<img
										class="rounded-4"
										src="../../../../assets/images/Team (1).png"
										alt="team"
									/>
								</div>
								<h4 class="title">Kane Thompson</h4>
								<span>Managing Director</span>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);" class="pe-none" role="button">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<img
										class="rounded-4"
										src="../../../../assets/images/Team (7).png"
										alt="team"
									/>
								</div>
								<h4 class="title">Tiffany Brown</h4>
								<span>Director of Sales & Marketing</span>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);" class="pe-none" role="button">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<img
										class="rounded-4"
										src="../../../../assets/images/Team (6).png"
										alt="team"
									/>
								</div>
								<h4 class="title">Jacob Bridgford</h4>
								<span>Head of Client Experience</span>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);" class="pe-none" role="button">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<img
										class="rounded-4"
										src="../../../../assets/images/Team (5).png"
										alt="team"
									/>
								</div>
								<h4 class="title">Belinda Richards</h4>
								<span>Head of Accounts</span>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);" class="pe-none" role="button">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<img
										class="rounded-4"
										src="../../../../assets/images/Team (4).png"
										alt="team"
									/>
								</div>
								<h4 class="title">Rachel Minton</h4>
								<span>Head of Sales</span>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);" class="pe-none" role="button">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<img
										class="rounded-4"
										src="../../../../assets/images/Team (3).png"
										alt="team"
									/>
								</div>
								<h4 class="title">Billy Finlay</h4>
								<span>QLD Warehouse Leader</span>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);" class="pe-none" role="button">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<img
										class="rounded-4"
										src="../../../../assets/images/Team (2).png"
										alt="team"
									/>
								</div>
								<h4 class="title">Brenda Facey</h4>
								<span>Head of Decoration</span>
							</div>
						</div>
					</a>
				</div>
			</div>
			<!-- Team Slide content start here... -->
			<div class="team-slide-content">
				<div class="teams_content">
					<div class="card-item">
						<div class="team-img">
							<img src="../../../../assets/images/Team (1).png" alt="team" />
						</div>
						<h4>
							<span
								><svg
									xmlns="http://www.w3.org/2000/svg"
									width="134"
									height="119"
									viewBox="0 0 134 119"
									fill="none"
								>
									<path
										d="M55.0984 118H1V60.5714L29.1311 1H56.8295L31.7279 64.4286H55.0984V118ZM131.269 118H77.1705V60.5714L105.302 1H133L107.898 64.4286H131.269V118Z"
										stroke="#DFE1E7"
									/>
								</svg>
							</span>
							At Reali we stand by our values which are represented in our four
							pillars, these four pillars are the core of our company. They
							guide us individually and unite us as a team in all our trials and
							tribulations.
						</h4>
						<div class="bynw">
							<h5>Kane Thompson</h5>
							<span>Managing Director</span>
						</div>
					</div>
				</div>
			</div>
			<!-- Team Slide content end here... -->
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- Team & Leaders section end here... -->
<!-- ============================ -->

<!-- ============================ -->
<!-- Partner section start here... -->
<!-- ============================ -->
<section class="about">
	<div class="max-width-container">
		<div class="container">
			<div class="contents">
				<div class="items">
					<h5 class="text-secondary text-center fs-4 mx-auto">
						Some of our partners
					</h5>
					<ul class="brns">
						<li>
							<img src="../../../../assets/images/partners.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-1.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-2.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-3.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-4.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-5.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-6.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-7.svg" alt="" />
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- Partner section end here... -->
<!-- ============================ -->

<!-- ============================ -->
<!-- Our Pillars section start here... -->
<!-- ============================ -->
<section class="our-pillars" id="pillar">
	<div class="max-width-container">
		<div class="container">
			<div class="pillars-content">
				<h2><span class="inhh"> Our </span> Pillars</h2>
				<div class="inner">
					<p>
						A company’s culture will be reflected by their team when it matters.
						When a team member is completing tasks when no one is watching or
						when the team may not get recognition for their work. <br />
						<br />
						At Reali, we stand by our pillars. They are the foundations that we
						stand on to continue to build Reali. Our team consists of members
						from all different walks of life, with different perspectives and
						views. That is what makes our company great. What we share in common
						is our alignment to our Four Pillars of Success; Respect, Teamwork,
						High Performance and Communication.
					</p>
					<div class="pillars-cards">
						<div class="card">
							<div class="card-img">
								<span>
									<img
										src="../../../../assets/images/Communication.png"
										alt="Communication"
									/>
								</span>
							</div>
							<h4>Communication</h4>
						</div>
						<div class="card">
							<div class="card-img">
								<span>
									<img
										src="../../../../assets/images/Teamwork.png"
										alt="Teamwork"
									/>
								</span>
							</div>
							<h4>Teamwork</h4>
						</div>
						<div class="card">
							<div class="card-img">
								<span>
									<img
										src="../../../../assets/images/Respect.png"
										alt="Respect"
									/>
								</span>
							</div>
							<h4>Respect</h4>
						</div>
						<div class="card">
							<div class="card-img">
								<span>
									<img
										src="../../../../assets/images/High-Performance.png"
										alt="High-Performance"
									/>
								</span>
							</div>
							<h4>High Performance</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="hrline">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="1200"
					height="2"
					viewBox="0 0 1200 2"
					fill="none"
				>
					<path
						d="M0 1L1200 1.0001"
						stroke="#DFE1E7"
						stroke-dasharray="8 8"
					></path>
				</svg>
			</div>
			<div class="content">
				<div class="items">
					<div class="box">
						<div class="prominent-card">
							<div class="prominent-img">
								<span>
									<img
										src="../../../../assets/images/prominent-icon.svg"
										alt="prominent-icon"
									/>
								</span>
							</div>
							<p>
								Our logo's prominent "i" represents all of our Four Pillars of
								Success. These pillars; Communication, Teamwork, Respect & High
								Performance form the strong foundation upon which we grow.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- Our Pillars section end here... -->
<!-- ============================ -->

<!-- ================================= -->
<!-- Our Location section start here.. -->
<!-- ================================= -->
<section class="ourLocation" id="location">
	<div class="max-width-container">
		<div class="container">
			<div class="contents">
				<div class="inner">
					<div class="pillars-content">
						<h2><span class="inhh"> Our </span> Locations</h2>
					</div>
				</div>
				<div class="inner">
					<div class="locations">
						<img src="../../../../assets/images/our-location.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ================================= -->
<!-- Our Location section end here.. -->
<!-- ================================= -->

<!-- ================================= -->
<!--  social-responsibility end here.. -->
<!-- ================================= -->
<section class="social-responsibility" id="responsibility">
	<div class="max-width-container">
		<div class="container">
			<h2>Social Responsibility</h2>
			<div class="hdde"><span>Sustainability&nbsp;</span></div>
			<div class="content">
				<div class="item">
					<div class="img-box">
						<img
							src="../../../../assets/images/social-responsible-2.png"
							alt="Uniform recycling"
						/>
					</div>
					<h5>Reducing Landfill</h5>
					<p>
						Upon unpacking garments for customisation, we meticulously sort all
						packaging materials into separate bins, implementing a rigorous
						recycling process. This practice not only ensures a streamlined
						customization workflow but also actively contributes to our
						commitment to environmental sustainability by diverting waste away
						from landfills.
					</p>
				</div>
				<div class="item">
					<div class="img-box">
						<img
							src="../../../../assets/images/social-responsible-3.png"
							alt="Uniform recycling"
						/>
					</div>
					<h5>Uniform recycling</h5>
					<p>
						Embark on a sustainable journey with our uniform recycling program,
						designed for clients eager to embrace circular initiatives. Our
						program ensures the elimination of all textile waste for cotton and
						poly-based products, contributing to a greener and more
						eco-conscious approach to uniform management. Through this
						initiative, clients can actively participate in reducing their
						environmental footprint by diverting uniforms from landfills and
						enabling the transformation of discarded textiles into valuable
						resources.
					</p>
				</div>
				<div class="item">
					<div class="img-box">
						<img
							src="../../../../assets/images/social-responsible-1.png"
							alt="Social responsibility"
						/>
					</div>
					<h5>Packaging</h5>
					<p>
						In our commitment to environmental responsibility, we are actively
						steering towards plastic-free packaging solutions. By collaborating
						with manufacturers who share our vision for reducing single-use
						plastics, we are proud to introduce paper and compostable garment
						and shipping bags. This strategic shift ensures that our packaging
						aligns with sustainability goals, minimising our ecological
						footprint and contributing to a healthier planet.
					</p>
				</div>
			</div>
		</div>
	</div>
</section>
