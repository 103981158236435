import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { combineLatest } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart-cancel',
  templateUrl: './cart-cancel.component.html',
  styleUrls: []
})
export class CartCancelComponent implements OnInit, AfterViewInit {
  public isProcessing: Boolean = true;
  public error = {
    hasError: false,
    missingPassword: false,
    missingCart: false,
    genericError: false
  };
  public cartId: number;
  public secret: string;
  public cancelReason: string
  @ViewChild('cancel_model') public cancel_model: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public notifications: NotificationsService,
    private cartService: CartService,
    private modelService: NgbModal
  ) {
  }

  ngOnInit() {
    console.log('CartApproveComponent: Processing Cart Approval');
    this.isProcessing = true;
    this.error.hasError = false;
    this.error.genericError = false;
    this.error.missingPassword = false;
    this.error.missingCart = false;

    combineLatest(
      this.route.params,
      this.route.queryParams
    ).subscribe(([params, qParams]) => {
      let cartId: number | null = params.hasOwnProperty('cartId') && params.cartId.length ? Number(params.cartId) : null;
      let secret: string | null = qParams.hasOwnProperty('secret') && qParams.secret.length ? qParams.secret : null;

      if (!cartId || !secret) {
        this.isProcessing = false;
        this.error.hasError = true;
        this.error.missingCart = (cartId === null);
        this.error.missingPassword = (secret === null);
        return;
      }
      this.cartId = cartId;
      this.secret = secret;
    });
  }

  ngAfterViewInit() {
    this.modelService.open(this.cancel_model, { size: 'sm', centered: true, backdrop: true, windowClass: 'deleteModal' });
  }

  save() {
    if (!this.cancelReason || !this.cancelReason.trim().length) {
      this.notifications.warn('Cancel Order', 'Reason is Required');
      return;
    }
    if (this.cancelReason.trim().length > 50) {
      this.notifications.warn('Warning', 'Reason should be 50 characters or less.');
      return;
    }
    this.cartService.cancel('reject', this.cartId, this.secret, this.cancelReason).subscribe(order => {
      if (!order) {
        return;
      }
      this.isProcessing = false;
      this.error.hasError = false;
      this.modelService.dismissAll();
    },
      err => {
        this.modelService.dismissAll();
        this.isProcessing = false;
        this.error.hasError = true;
        this.error.genericError = true;
        console.error(err);
      });
  }
}
