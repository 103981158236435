import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { logger } from '../util/Logger';

const className = "UnAuthGuard";

@Injectable()
export class UnAuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService
	) {
	}

	canActivate(): Observable<boolean> {
		const signature = className + '.canActivate: ';
		return from(of(this.authService.isAuthenticated()))
			.pipe(
				map(isAuthenticated => !isAuthenticated),
				tap(isUnauthenticated => {
					logger.silly(signature + `isUnauthenticated[${isUnauthenticated}]`);
					if (!isUnauthenticated) {
						this.router.navigate(['/home']);
					}
				})
			);
	}
}
