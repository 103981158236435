<section class="product-detail-container pt-0">
	<div class="max-width-container">
		<div class="container">
			<!-- ============================ -->
			<!-- paggination start here... -->
			<!-- ============================ -->
			<nav aria-label="breadcrumb" class="pageBreadcrumb d-none d-lg-block">
				<ol class="breadcrumb mb-0">
					<li class="breadcrumb-item">
						<a [routerLink]="['/home']">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M3.25 7.75L10 2.5L16.75 7.75V16C16.75 16.3978 16.592 16.7794 16.3107 17.0607C16.0294 17.342 15.6478 17.5 15.25 17.5H4.75C4.35218 17.5 3.97064 17.342 3.68934 17.0607C3.40804 16.7794 3.25 16.3978 3.25 16V7.75Z"
									stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M7.75 17.5V10H12.25V17.5" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</a>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/Account']"> Account </a>
					</li>
					<li class="breadcrumb-item">
						<span>Accept Invite</span>
					</li>
				</ol>
			</nav>
			<!-- ============================ -->
			<!-- paggination end here... -->
			<!-- ============================ -->

			<div class="row">
				<div class="col-sm-12">
					<div class="d-flex flex-column align-items-center gap-5 justify-content-center error-h-100">
						<div class="cart-error">
							<!-- Deny start here... -->
							<svg *ngIf="isAccepted" xmlns="http://www.w3.org/2000/svg" version="1.1"
								xmlns:xlink="http://www.w3.org/1999/xlink" width="140" height="140" x="0" y="0"
								viewBox="0 0 682.667 682.667" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
								<g>
									<defs>
										<clipPath id="a" clipPathUnits="userSpaceOnUse">
											<path d="M0 512h512V0H0Z" fill="#000000" opacity="1" data-original="#000000"></path>
										</clipPath>
									</defs>
									<g clip-path="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
										<path d="M0 0a7.584 7.584 0 1 0-15.168 0A7.584 7.584 0 0 0 0 0"
											style="fill-opacity: 1; fill-rule: nonzero; stroke: none" transform="translate(115.829 301.568)"
											fill="#000000" data-original="#000000"></path>
										<path
											d="M0 0a7.5 7.5 0 0 0-7.5 7.5v82.085a7.5 7.5 0 0 0 7.5 7.5 7.5 7.5 0 0 0 7.5-7.5V7.5A7.5 7.5 0 0 0 0 0"
											style="fill-opacity: 1; fill-rule: nonzero; stroke: none" transform="translate(108.356 319.847)"
											fill="#000000" data-original="#000000"></path>
										<path
											d="M0 0c0-47.209-38.271-85.479-85.479-85.479-47.209 0-85.479 38.27-85.479 85.479 0 47.209 38.27 85.479 85.479 85.479C-38.271 85.479 0 47.209 0 0Z"
											style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(193.738 355.458)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path d="M0 0v-48.04c0-2.21 1.79-4 4-4h21.63" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(102.96 59.54)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0v-48.04c0-2.21-1.79-4-4-4h-288.65" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(456.24 59.54)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0h20.25c2.21 0 4-1.79 4-4v-268.22" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(431.99 504.5)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0v59.73c0 2.21 1.79 4 4 4h290.03" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(102.96 440.77)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0v37.87" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(102.96 232.28)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path
											d="M0 0v-114.85c0-3.309-2.69-6-6-6h-407.24c-3.32 0-6 2.691-6 6V45.891c0 3.319 2.68 6 6 6H-6c3.31 0 6-2.681 6-6V35"
											style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(489.22 180.39)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0v61.81L41.789 1.297c.775-1.12 1.605-.571 1.605.79l-.579 59.839" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(242.4 114.951)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0v-61.926" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(316.794 176.877)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path d="M0 0h-25.854v-61.36H0" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(211.4 176.594)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0h-23.948" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(209.495 145.914)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path
											d="M0 0c0-16.558-9.19-29.66-23.77-29.915-4.853-.084-17.396-.132-17.396-.132S-41.244-8.28-41.244.066c0 6.855-.043 29.981-.043 29.981h17.019C-8.276 30.047 0 16.558 0 0Z"
											style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(154.546 145.914)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path d="M0 0h-25.854v-61.36H0" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(373.649 176.594)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path d="M0 0h-23.948" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(371.743 145.914)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path
											d="M0 0c0-16.558-9.18-29.66-23.745-29.915-4.848-.084-17.377-.132-17.377-.132S-41.2-8.28-41.2.066c0 6.855-.044 29.981-.044 29.981h17.002C-8.267 30.047 0 16.558 0 0Z"
											style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(445.937 145.914)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path d="M0 0h242.948" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(161.7 202.877)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0h242.948" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(171.614 88.951)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path
											d="M0 0c0-25.459-20.638-46.098-46.097-46.098S-92.195-25.459-92.195 0s20.639 46.098 46.098 46.098S0 25.459 0 0Z"
											style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(367.26 421.402)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path d="m0 0 26.937-26.937" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(307.695 434.871)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path d="m0 0 26.937 26.937" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(307.695 407.934)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path
											d="M0 0c4.7-1.439 6.74-4.149 14.44-4.149C24.92-4.149 24.92.88 35.41.88c10.48 0 10.48-5.029 20.96-5.029"
											style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(362.87 441.05)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path
											d="M0 0c3.89-1.479 6.22-3.72 13.21-3.72 10.48 0 10.48 5.031 20.97 5.031 10.48 0 10.48-5.031 20.96-5.031"
											style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(364.1 404.59)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="m0 0-168.22 1.86" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(308.18 465.64)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0h-99.7" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(278.67 403.5)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0h-138.96" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(277.27 435.5)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0v-82a2 2 0 0 1 2-2h131.445" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(285.794 346.565)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
										<path d="M0 0h-62.62" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(255.9 346.57)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0h-57.56" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(240.9 314.57)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="M0 0h-62.98" style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(215.9 282.57)" fill="none" stroke="#000000" stroke-width="15" stroke-linecap="round"
											stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none" stroke-opacity=""
											data-original="#000000" class=""></path>
										<path d="m0 0 46.904 34.841a2 2 0 0 0 2.607-.192L85.992-1.832a2 2 0 0 1 2.603-.193L131.63 29.81"
											style="
												stroke-width: 15;
												stroke-linecap: round;
												stroke-linejoin: round;
												stroke-miterlimit: 10;
												stroke-dasharray: none;
												stroke-opacity: 1;
											" transform="translate(285.798 292.58)" fill="none" stroke="#000000" stroke-width="15"
											stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="none"
											stroke-opacity="" data-original="#000000" class=""></path>
									</g>
								</g>
							</svg>
							<!-- Deny end here... -->

							<!-- Process start here... -->
							<svg *ngIf="isLoading" xmlns="http://www.w3.org/2000/svg" version="1.1"
								xmlns:xlink="http://www.w3.org/1999/xlink" width="140" height="140" x="0" y="0" viewBox="0 0 48 48"
								style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
								<g>
									<g data-name="Processing information data">
										<path
											d="M43.73 37.66A24.1 24.1 0 0 0 41 7a23.83 23.83 0 0 0-33.28-.61c-3.2.35-3.75.22-4 1s.18 1 1.23 2C-7.24 25.28 4.28 48 24 48a23.86 23.86 0 0 0 17.1-7.16c3.15-.35 3.7-.22 4-1s-.21-1.01-1.37-2.18zm-6.12-5.31c-.78.27-.66.79-1 4A17.62 17.62 0 0 1 9.49 14c.7.7 1 1.12 1.68.88s.65-.77 1-3.91a17.6 17.6 0 0 1 26.94 22.08c-.52-.52-.84-.93-1.5-.7zm.1 8.86A22 22 0 0 1 7 10a1 1 0 0 0 0-1.34c-.21-.22-.72-.11 3.51-.57l-.41 3.66a1 1 0 0 0-1.48.11C-1.6 24.76 7.74 43.62 24 43.62a19.5 19.5 0 0 0 12.26-4.3l-.09.83a1 1 0 0 0 .64 1 1.69 1.69 0 0 0 .9.06zm.59-2.08.41-3.68a1 1 0 0 0 1.39-.24 19.62 19.62 0 0 0-27.61-27.1l.11-1A1 1 0 0 0 11.36 6a21.85 21.85 0 0 1 28.2 2.46 22.1 22.1 0 0 1 2.06 28.73 1 1 0 0 0 .09 1.31c.36.33.81.17-3.41.63z"
											fill="#c67f19" opacity="1" data-original="#000000" class=""></path>
										<path
											d="M33.72 18.57c-.32-.68-.1-.37-5.43-5.69C27.16 11.75 26.58 12 17 12a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3c0-14.19.12-13.59-.28-14.43zM30.59 18H28v-2.59zM31 34H17a1 1 0 0 1-1-1V15a1 1 0 0 1 1-1h9v5a1 1 0 0 0 1 1h5v13a1 1 0 0 1-1 1z"
											fill="#c67f19" opacity="1" data-original="#000000" class=""></path>
										<path
											d="M25 21h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM25 25h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM25 29h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z"
											fill="#c67f19" opacity="1" data-original="#000000" class=""></path>
									</g>
								</g>
							</svg>
							<!-- Process end here... -->

							<!-- Error start here... -->
							<svg *ngIf="!isAccepted && !isLoading" xmlns="http://www.w3.org/2000/svg" version="1.1"
								xmlns:xlink="http://www.w3.org/1999/xlink" width="140" height="140" x="0" y="0" viewBox="0 0 32 32"
								style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
								<g>
									<path
										d="M7 30h18a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H13.83a3 3 0 0 0-2.12.88L4.88 9.71A3 3 0 0 0 4 11.83V27a3 3 0 0 0 3 3zM26 5v22a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V12h5a3 3 0 0 0 3-3V4h11a1 1 0 0 1 1 1zm-14 .41V9a1 1 0 0 1-1 1H7.41z"
										fill="#7f1515" opacity="1" data-original="#000000" class=""></path>
									<path
										d="M14.63 13.88a1 1 0 0 0-1.41 0l-.71.71-.71-.71a1 1 0 0 0-1.41 1.41l.7.71-.7.71a1 1 0 0 0 0 1.41 1 1 0 0 0 1.41 0l.71-.71.71.71a1 1 0 0 0 1.41 0 1 1 0 0 0 0-1.41l-.71-.71.71-.71a1 1 0 0 0 0-1.41zM17.37 18.12a1 1 0 0 0 1.41 0l.71-.71.71.71a1 1 0 0 0 1.41 0 1 1 0 0 0 0-1.41l-.7-.71.7-.71a1 1 0 1 0-1.41-1.41l-.71.71-.71-.71a1 1 0 0 0-1.41 1.41l.71.71-.71.71a1 1 0 0 0 0 1.41zM10.59 22.41a1 1 0 0 0 1.32.5 9.6 9.6 0 0 1 8.18 0 1 1 0 0 0 .41.09 1 1 0 0 0 .41-1.91 11.63 11.63 0 0 0-9.82 0 1 1 0 0 0-.5 1.32z"
										fill="#7f1515" opacity="1" data-original="#000000" class=""></path>
								</g>
							</svg>
							<!-- Error end here... -->
						</div>
						<h2 class="fw-bold" *ngIf="!title">
							<span>PROCESSING</span> your invite!
						</h2>
						<h2 class="fw-bold" *ngIf="title">{{ title }}</h2>
						<p *ngIf="pendingApproval">
							Please wait while we cancel your invite.
						</p>
						<p *ngIf="!pendingApproval">
							<span *ngIf="!message">Your invite has been cancelled.</span>
							<span *ngIf="message">{{ message }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>