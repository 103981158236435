import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { CartService } from '../services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartAttrs } from '../model/cart.model';
import { CartApi } from '../api/cart.api';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-shared-order-model',
  templateUrl: './shared-order-model.component.html',
  styleUrls: []
})
export class SharedOrderModelComponent {
  @ViewChild('cancelModel') cancelModel!: TemplateRef<any>;
  @ViewChild('modifyModel') modifyModel!: TemplateRef<any>;
  @ViewChild('delete_confirm_modal') deleteModel!: TemplateRef<any>;

  @Input() cancelReason: string = '';
  @Input() detailEditForm: { orderReference: string | null, notes: string | null } = {
    orderReference: '',
    notes: ''
  };
  @Input() currentCart!: CartAttrs;
  @Input() deletedOrderId!: number;

  @Output() saveCompleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: NgbModal,
    private cartApi: CartApi,
    private cartService: CartService,
    private notification: NotificationsService
  ) { }

  openCancelModal(item: CartAttrs) {
    this.deletedOrderId = item.orderId;
    this.modalService.open(this.cancelModel, { size: 'sm', centered: true, backdrop: true, windowClass: 'deleteModal' });
  }

  deleteOrder(item: CartAttrs) {
    this.deletedOrderId = item.orderId;
    this.modalService.open(this.deleteModel, {
      size: 'sm', centered: true, backdrop: true, windowClass: 'deleteModal'
    }).result.then(() => {
      this.save('delete', true);
    }, () => { });
  }

  save(type: string, isDelete = false) {
    if (!isDelete && (!this.cancelReason || !this.cancelReason.trim().length)) {
      this.notification.warn('Cancel Order', 'Reason is Required');
      return;
    }

    if (!isDelete && this.cancelReason.trim().length > 50) {
      this.notification.warn('Warning', 'Reason should be 50 characters or less.');
      return;
    }

    this.cartService.cancel(type, this.deletedOrderId, undefined, this.cancelReason).subscribe(
      () => {
        this.loadOrders();
        this.cancelReason = '';
        this.modalService.dismissAll();
      },
      () => { this.loadOrders(); }
    );
  }

  openModifyModal(item: CartAttrs) {
    this.detailEditForm.orderReference = item.orderReference;
    this.detailEditForm.notes = item.notes;
    this.currentCart = item;
    this.modalService.open(this.modifyModel, { size: 'sm', centered: true, backdrop: true, windowClass: 'bulk-order' });
  }

  saveCartAttrs() {
    if (this.currentCart.id) {
      this.cartApi.saveCartAttrsAdmin(this.currentCart.id, this.detailEditForm as any).subscribe({
        next: () => {
          this.approveOrder(this.currentCart);
          this.modalService.dismissAll();
        }
      });
    }
  }

  loadOrders() {
    this.saveCompleted.emit()
  }

  /**
   * @description Approves an order
   *
   * @param item
   */
  approveOrder(item: CartAttrs) {
    this.cartService.approveCart(item.orderId)
      .subscribe(() => {
        this.loadOrders();
      },
        (err) => {
          console.log(err);
        })
  }
}
