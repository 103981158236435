import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { apiCallWrapper } from '../api/api.util';
import { CustomerMergeApi } from '../api/customerMerge.api';
import { ICustomerMerge } from '../model/customerMerge.model';

@Injectable()
export class CustomerMergeService {
  constructor(
    private notifications: NotificationsService,
    private customermergeApi: CustomerMergeApi
  ) {
  }


  public create(data: ICustomerMerge) {
    return apiCallWrapper(
      this.customermergeApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Customer Merge"
      }
    )
  }
}
