import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputMaxLength]'
})
export class InputMaxLengthDirective {
  @Input() appInputMaxLength: string = '120'; // Default to 120 characters

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value.length > +this.appInputMaxLength) {
      input.value = input.value.slice(0, +this.appInputMaxLength);
    }
  }
}
