import { Component, Input } from "@angular/core";
import { UnleashedCustomer } from "src/app/main/model/unleashed.model";

@Component({
  selector: 'landing-page-edit',
  styles: [],
  template: `
  <div class="col-12">
  <div class="form-group">
    <label for="accessMode" class="form-label">Default Landing Page</label>
    <select #address class="form-select" aria-label="Default select example" id="accessMode"
     (change)="setLandingPage(address.value)">
      <option *ngFor="let option of landingPageOptions" [selected]="customer && option.id == customer.defaultUri" [value]="option.id">{{option.text}}</option>
    </select>
    <div class="form-text">
      Copy the last part from the url to change
      the default landing page. Eg:
      /products/clothing from
      https://portal.reali.supply/#/products/clothing
    </div>
  </div>
</div>
	`
})
export class LandingPageEditComponent {
  private _customer: UnleashedCustomer | null = null;

  @Input()
  set customer(value: UnleashedCustomer | null) {
    this._customer = value;
  }

  get customer(): UnleashedCustomer | null {
    return this._customer;
  }

  landingPageOptions: { id: string, text: string, selected?: boolean }[] = [
    { id: "/manage/dashboard", text: "Home (Default)", selected: true },
    { id: "/products", text: "Product List (#/products)" },
    { id: "/account", text: "Account Details (#/account)" },
    { id: "/account/users", text: "User List (#/account/users)" },
    { id: "/account/collections", text: "Collections (#/account/collections)" },
    { id: "/products/clothing", text: "Clothing Products (#/products/clothing)" },
  ];

  constructor(
  ) { }

  setLandingPage(landingPage: string): void {
    if (this._customer && this.customer) {
      this.customer.defaultUri = landingPage;
    }
  }
}