<div class="add-products">
	<div class="form-group d-flex align-items-center justify-content-center">
		<a class="btn btn-secondary" [routerLink]="['/manage/users/groups', group.id]" role="button">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
					<path d="M10.5 4.75V15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
					<path d="M5.25 10H15.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
			Add collection
		</a>
	</div>
</div>
<!-- add collection end here... -->

<div *ngFor="let data of group.collections">
	<div class="detail-head">
		<h5 class="title">
			{{ data.collection.name }} {{ data.collection.products.length }}
		</h5>
		<a [routerLink]="['/manage/collections/edit', data.collection.id]" role="button" class="btn btn-secondary px-0">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M10 4.75V15.25" stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M4.75 10H15.25" stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
			Add product
		</a>
	</div>

	<div class="products-cards">
		<div class="product-card card bg-transparent" *ngFor="let products of data.collection.products">
			<div class="card-img">
				<img [src]="products.product?.imageUrl" alt="collection-product" />
				<div class="badges">
					<span class="badge bg-aqua-25">New</span>
				</div>
			</div>
			<div class="card-content">
				<h4 class="title">{{ products.product?.name }}</h4>
				<h6 class="brand">Biz Corporates</h6>
				<p class="price">$ {{ products.product?.basePrice }}</p>
			</div>
		</div>
	</div>
</div>