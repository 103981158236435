<ng-template #cancelModel let-modal let-c="close" let-d="dismiss" class="deleteModal">
  <div class="modal-header">
    <h1 class="modal-title">Reject Order</h1>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-4">
          <label for="reason" class="form-label">Reason:</label>
          <input type="text" name="reason" class="form-control" id="reason" [(ngModel)]="cancelReason" />
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3">
          <a (click)="d('Cross click')" class="btn btn-secondary text-nowrap w-100">
            Cancel
          </a>
          <button (click)="save('reject')" class="btn btn-primary text-nowrap w-100">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modifyModel let-modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h1 class="modal-title">ORDER DETAILS</h1>
    <button (click)="d('Cross click')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
      aria-label="Close">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="orderNum" class="form-label">Purchase Order Number</label>
          <input type="text" id="orderNum" class="form-control" placeholder="Order Number"
            [(ngModel)]="detailEditForm.orderReference" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="Notes" class="form-label">Shipping Note </label>
          <textarea type="text" class="form-control" rows="5" id="Notes" [(ngModel)]="detailEditForm.notes"
            placeholder="Shipping Notes..."></textarea>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3">
          <a (click)="d('Cross click')" class="btn btn-secondary text-nowrap w-100">
            Cancel
          </a>
          <button (click)="saveCartAttrs()" class="btn btn-primary text-nowrap w-100">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
            Approve Order
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #delete_confirm_modal let-modal let-c="close" let-d="dismiss" class="deleteModal">
  <div class="modal-content">
    <div class="modal-body">
      <button type="button" class="btn btn-icon btn-cancel shadow-none" (click)="d('Cancel')">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </button>
      <div class="delete-content">
        <div class="dlt-icon">
          <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
                stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </div>
        <div>
          <h4 class="title">Delete Order</h4>
          <p class="desc">Would you like to delete this Order?</p>
        </div>
      </div>
      <div class="btns">
        <button type="button" class="btn btn-danger" (click)="c('Delete')">
          delete
        </button>
        <button type="button" class="btn btn-secondary" (click)="d('Cancel')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>