import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NotificationsService } from 'angular2-notifications';
import { has, hasIn } from 'lodash';
import moment from 'moment-mini';
import { Subject, combineLatest, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs';
import { RolesApi } from 'src/app/main/api/roles.api';
import { SessionApi } from 'src/app/main/api/session.api';
import { IBusinessUnit } from 'src/app/main/model/businessUnit.model';
import { ICustomerUser } from 'src/app/main/model/customer.user.model';
import { IRequiredField } from 'src/app/main/model/ddb.model';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { IRole } from 'src/app/main/model/role.model';
import { ShippingDetailsClass } from 'src/app/main/model/shippingDetail.model';
import { BusinessUnit } from 'src/app/main/model/unleashed.model';
import { BusinessUnitService } from 'src/app/main/services/businessUnit.service';
import { CustomerService } from 'src/app/main/services/customer.service';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { SecurityService } from 'src/app/main/services/security.service';
import { ShippingDetailService } from 'src/app/main/services/shippingDetail.service';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: []
})
export class UserProfileEditComponent {
  public customerUser: Partial<ICustomerUser> = {
    user: {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      jobTitle: '',
      secureLoginType: '',
      shippingDetails: {
        city: '',
        country: '',
        postalCode: '',
        region: '',
        streetAddress: '',
        streetAddress2: '',
        suburb: '',
        addressName: '',
        isFreeHandling: false
      }
    }
  }
  public tab: string | null;
  public customerUsers: QueryResult<ICustomerUser>;
  public businessUnit: BusinessUnit[] = [];
  public userRoles: IRole[] = [];
  public customerId: number | undefined = undefined;
  public approverId: number | null | undefined = undefined;
  public editSelf = false;
  public isGuest: boolean = false;
  public canHaveAllocation = false;
  public canHaveApprover = false;
  public userRoleId: number | undefined;
  public businessUnitId: number | undefined;
  public secureLoginType: string | undefined = 'none';
  public isEdit: boolean = true;
  public tfaBlock: boolean = false;
  public tfaVerifyBlock: boolean = false;
  public tfaRequiresVerification: boolean = false;
  public tfaVerificationSuccess: boolean = false;
  public wrongOtp: boolean = false;
  public qrImage: string = "";
  public secretCode: string = "";
  public otp: number;
  public isAdmin: boolean = false;
  private searchTerms: Subject<string> = new Subject<string>();
  public userRoleQuery = new IQueryFilter({
    limit: 25
  });
  @ViewChild('role') selectRole: NgSelectComponent;
  public companyAddress: string = 'own';
  public customerAddress: ShippingDetailsClass[] = [];
  private searchBussinessTerms: Subject<string> = new Subject<string>();
  public businessQuery = new IQueryFilter({
    sortBy: 'id',
    limit: 100,
    order: 'desc'
  });
  @ViewChild('business') selectBusineessUnit: NgSelectComponent;
  public permissions = "order_approver";

  public customerQuery = new IQueryFilter({
    limit: 1000,
    filter: {
      customerId: this.customerId
    },
    include: [{
      association: 'user',
      required: true
    }],
  });
  enableUsersRequireApproval: boolean;
  requiredField: IRequiredField = {
    contact: false,
    costAccount: false,
    employeNumber: false,
    jobTittle: false,
    businessUnit: false,
    startDate: false,
    address: false
  };
  private searchTermsBusinessUnit: Subject<string> = new Subject<string>();
  noBusinessUnitFoundText = "Fetching...";

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly customerUserService: CustomerUserService,
    private readonly session: SessionApi,
    private readonly businessUnitService: BusinessUnitService,
    private userRoleApi: RolesApi,
    public router: Router,
    private notification: NotificationsService,
    private shippingDetailService: ShippingDetailService,
    private customerService: CustomerService,
    private securityService: SecurityService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (has(params, "customerId")) {
        this.customerId = Number(params.customerId);
        this.shippingDetailService.getShippingDetailsList(this.customerId || 0).subscribe(res => {
          this.customerAddress = res.sort((a, b) => {
            const addressNameA = a.addressName ?? '';
            const addressNameB = b.addressName ?? '';

            if (addressNameA < addressNameB) return -1;
            if (addressNameA > addressNameB) return 1;
            return 0;
          });
        })
      }
      if (!params['id']) {
        this.isEdit = false;
        this.fetchBusinessUnit();
        this.fetchCustomerUsers();
        this.fetchRoles();
      } else {
        this.fetchExistingData();
        this.customerUser.customerId = params['id'];
      }

      this.getCustomerDetails();
    })

    if (this.activatedRoute.snapshot.queryParams.isGuest == 'true') {
      this.isGuest = true;
    } else {
      this.isGuest = false;
    }

    this.search();
    this.searchBusiness();
  }
  ngOnInit(): void {
    this.tab = this.activatedRoute.snapshot.queryParamMap.get('tab');
    this.isAdmin = !!this.session.$userData.getValue()?.isAdmin;
  }

  getCustomerDetails() {
    if (this.customerId && this.isAdmin) {
      this.customerService.getById(this.customerId).subscribe(res => {
        this.enableUsersRequireApproval = res.enableUsersRequireApproval;
      });
    } else {
      this.securityService.isUserRequireApprovalManager().subscribe(approval => {
        this.enableUsersRequireApproval = approval;
      });

      const customerData = this.session.$customerData.value;
      if (customerData && customerData.requiredField) {
        this.requiredField = JSON.parse(customerData.requiredField);
      }
    }
  }

  public get customerUserId(): number | null {
    if (!this.customerUser) return null;

    return this.customerUser.id || null;
  }

  /**
* Populates the roleIds property (if applicable) with target user roles
*/
  private fetchRoleIds = async () => {
    if (!this.permissions || !this.permissions.length)
      return;

    return new Promise<void>((resolve, reject) => {
      this.userRoleApi.rolesWithPermission(...this.permissions.split(",")).subscribe(roles => {
        const roleIds = roles.map(role => role.id);
        if (roleIds && roleIds.length) {
          this.customerQuery.filter['$or'] = roleIds.map(roleId => ({ userRoleId: roleId }));
        }
        resolve();
      });
    })
  }

  fetchExistingData() {
    return combineLatest(
      this.activatedRoute.params,
      this.activatedRoute.data
    ).pipe(
      map(([params, data]) => {
        const modifiedParams = JSON.parse(JSON.stringify(params));

        if (has(params, "customerId")) {
          this.customerId = Number(params.customerId);
        }

        if (has(data, "guests")) {
          this.isGuest = data.guests;
        }

        if (has(data, 'self')) {
          this.editSelf = data.self;

          if (!has(params, "id")) {
            const customerUser = this.session.getCustomerUser();

            if (customerUser) {
              modifiedParams.customerUserId = customerUser.id;
            }
          }
        }

        return modifiedParams;
      }),
      filter(params => has(params, 'id')),
      switchMap(params => this.customerUserService.get(params.id))
    ).subscribe(existingData => {
      this.customerUser = existingData;
      if (this.customerUser.user && !this.customerUser.user.shippingDetails) {
        this.customerUser.user.shippingDetails = {
          city: '',
          country: '',
          postalCode: '',
          region: '',
          streetAddress: '',
          streetAddress2: '',
          suburb: '',
          addressName: '',
          isFreeHandling: false
        }
      }
      const exist = this.customerAddress.find(address => address.id == this.customerUser.user?.shippingDetailsId);
      exist ? this.companyAddress = 'company' : this.companyAddress = 'own';

      if (this.customerUser.isGuest) {
        this.isGuest = this.customerUser.isGuest;
      }

      this.customerId = existingData.customerId;
      this.secureLoginType = existingData.user?.secureLoginType;
      this.fetchCustomerUsers();
      this.roleUpdated(existingData.userRoleId);
      this.fetchBusinessUnit();
      this.fetchRoles();
    });

  }

  checkAddressType() {
    const exist = this.customerAddress.find(address => address.id == this.customerUser.user?.shippingDetailsId);
    if (this.companyAddress == 'own' && exist) {
      this.customerUser.user!.shippingDetails = {
        city: '',
        country: '',
        postalCode: '',
        region: '',
        streetAddress: '',
        streetAddress2: '',
        suburb: '',
        addressName: '',
        isFreeHandling: false
      }
      this.customerUser.user!.shippingDetailsId = null;
    }
  }

  changsAddress(id: string) {
    const address = this.customerAddress.find(address => address.id == +id);
    if (address) {
      this.customerUser.user!.shippingDetails = address;
      this.customerUser.user!.shippingDetailsId = +id;
    }
  }

  fetchBusinessUnit(isScroll: boolean = false) {
    if (this.customerId) {
      if (isScroll) {
        this.businessQuery.limit += 10;
      } else {
        this.businessQuery.limit = 10;
        this.noBusinessUnitFoundText = "Fetching...";
      }

      this.businessQuery.filter.customerId = this.customerId;
      this.customerService.getBusinessUnit(this.customerId, this.businessQuery)
        .subscribe(queryResult => {
          const fetchedUnits = queryResult.rows;

          if (fetchedUnits.length === 0) {
            this.noBusinessUnitFoundText = "No Business Unit found";
          }

          if (this.customerUser.businessUnitId && !isScroll) {
            this.businessUnitService.get(this.customerUser.businessUnitId).subscribe((res: any) => {
              if (res) {
                this.businessUnit = [res, ...fetchedUnits];
                this.sortBusinessUnit(this.businessUnit);
              }
            });
          } else {
            this.businessUnit = isScroll
              ? [...this.businessUnit, ...fetchedUnits]
              : [...fetchedUnits];
            this.sortBusinessUnit(this.businessUnit);
          }
        });
    }
  }

  sortBusinessUnit(businessUnit: BusinessUnit[]) {
    this.businessUnit = businessUnit.sort((a, b) => a.code.localeCompare(b.code));
    this.businessUnit = businessUnit.map(item => ({
      ...item,
      name: `${item.code} - ${item.name}`
    }));
  }

  onBusinessUnitSearch(searchTerm: { term: string; items: any[]; }) {
    this.searchTermsBusinessUnit.next(searchTerm.term);
  }

  fetchMoreBusinessUnits() {
    this.fetchBusinessUnit(true);
  }

  searchBusiness() {
    this.searchTermsBusinessUnit.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      this.businessQuery.filter['$or'] = [
        { name: { $like: '%' + searchTerm + '%' } },
        { code: { $like: '%' + searchTerm + '%' } },
      ];
      this.fetchBusinessUnit();
    });
  }

  onClear() {
    delete this.businessQuery.filter['$or'];
    this.fetchBusinessUnit();
  }


  async fetchCustomerUsers() {
    await Promise.all([
      this.fetchRoleIds(),
    ]);
    this.customerQuery.filter['customerId'] = this.customerId;
    this.customerUserService.list(this.customerQuery).subscribe(res => {
      this.customerUsers = res;
    });
  }

  fetchRoles() {
    this.userRoleQuery.filter = {
      customerId: this.customerId
    }
    this.userRoleApi.list(this.userRoleQuery).subscribe(userRole => {
      this.userRoles = userRole.rows;
    })
  }

  roleUpdated(id?: number) {
    let roleId: number | undefined;
    if (!id) {
      roleId = this.customerUser.userRoleId;
    } else {
      roleId = id;
    }
    if (roleId) {
      this.userRoleApi.roleById(+roleId).subscribe(userRole => {
        if (userRole && userRole.permissions) {
          this.canHaveAllocation = this.userRoleApi.roleHasPermission(userRole, 'user_allocation');
          this.canHaveApprover = this.userRoleApi.roleHasPermission(userRole, 'order_approval');
        }
      })
    }
  }

  checkSecureLoginType(type?: string) {
    if (this.secureLoginType == 'none') {
      this.secureLoginType = 'email';
    } else {
      this.secureLoginType = 'none';
    }

    if (type) {
      this.secureLoginType = type;
    }

    if (this.secureLoginType == 'app' && this.isEdit) {
      if (this.customerUser.user?.email) {
        this.customerUserService.generateTfaSecret(this.customerUser.user?.email).subscribe((data) => {
          if (data) {
            this.openTFABlock(data);
          } else {
            this.clearTFABlock();
          }
        });
      }
    } else {
      this.clearTFABlock();
    }
    // this.customerUserForm.get('user.secureLoginType')?.setValue(this.secureLoginType);
    if (this.customerUser.user) {
      this.customerUser.user.secureLoginType = this.secureLoginType;
    }
  }

  verifyOtp() {
    if (this.otp && this.customerUser.user?.id) {
      this.customerUserService.verifyOtp({
        code: this.secretCode,
        otp: this.otp.toString(),
        type: this.customerUser.user?.secureLoginType,
        userId: +this.customerUser.user.id,
        qrImage: this.qrImage,
      }).subscribe((data) => {
        if (data && data.verify) {
          this.tfaRequiresVerification = false;
          this.tfaBlock = false;
          this.tfaVerificationSuccess = true;
        } else {
          this.wrongOtp = true;
        }
      });
    }
  }

  /**
    * @todo replace with angular forms
    * @description Performs validations on the current data to ensure it is ready for transmission
    * @returns {boolean}
    */
  isValid() {
    if (!this.customerUser.user?.email || !this.customerUser.user.email.length)
      return false;

    if (!this.customerUser.userRoleId)
      return false;

    if (!this.customerUser.isGuest) {
      if (!this.customerUser.user.firstName || !this.customerUser.user.firstName.length)
        return false;

      if (!this.customerUser.user.firstName || !this.customerUser.user.firstName.length)
        return false;

      if (!this.customerUser.user.lastName || !this.customerUser.user.lastName.length)
        return false;

      if (this.requiredField.contact && !this.customerUser.user.contactNumber) {
        return false;
      }

      if (this.requiredField.costAccount && (!this.customerUser.costAccount || !this.customerUser.costAccount.length)) {
        return false;
      }

      if (this.requiredField.employeNumber && (!this.customerUser.employeeNumber || !this.customerUser.employeeNumber.length)) {
        return false;
      }

      if (this.requiredField.jobTittle && (!this.customerUser.user.jobTitle || !this.customerUser.user.jobTitle.length)) {
        return false;
      }

      if (this.requiredField.businessUnit && !this.customerUser.businessUnitId) {
        return false;
      }

      if (this.requiredField.startDate && !this.getStartDate()) {
        return false;
      }

      if (this.enableUsersRequireApproval && !this.customerUser.approverId) {
        return false;
      }

      if (this.requiredField.address) {
        const { shippingDetails } = this.customerUser.user;
        if (
          !shippingDetails?.addressName?.length ||
          !shippingDetails?.streetAddress?.length ||
          !shippingDetails?.suburb?.length ||
          !shippingDetails?.country?.length ||
          !shippingDetails?.region?.length ||
          !shippingDetails?.postalCode
        ) {
          return false;
        }
      }
    }

    return true;
  }

  searchUserRole(searchTerm: { term: string; items: any[]; }) {
    this.searchTerms.next(searchTerm.term);
  }

  search() {
    this.searchTerms.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.userRoleQuery.filter.name = { $like: searchTerm + '%' };
      this.fetchRoles();
    });
  }

  userRoleChange() {
    this.customerUser.userRoleId = this.selectRole.selectedValues.length && this.selectRole.selectedValues[0].id;
    this.roleUpdated();
  }

  businessUnitChange() {
    this.customerUser.businessUnitId = this.selectBusineessUnit.selectedValues.length && this.selectBusineessUnit.selectedValues[0].id;
  }

  /**
 * @description Performs updates on the current data to ensure it is ready for transmission (must occur after validation so that validating does not destroy data)
 * @returns {void}
 */
  beforeSave() {
    if (!this.customerUser.approverId) {
      this.customerUser.approverId = null;
    }

    if (!this.customerUser.businessUnitId) {
      this.customerUser.businessUnitId = null;
    }

    if (!this.customerUser.customerId) {
      this.customerUser.customerId = this.customerId;
    }

    if (!this.canHaveApprover) {
      this.customerUser.approverId = null;
    }

    if (!this.canHaveApprover || !this.canHaveAllocation) {
      this.customerUser.enableOrdersRequireApproval = false;
    }

  }


  createUser() {
    if (!this.customerUser.id) {
      return;
    }
    this.beforeSave();
    if (this.isGuest) {
      this.customerUser.isGuest = true;
    }

    if (!this.isValid()) {
      window.scroll(0, 0);
      this.notification.warn('Add User', 'Please fill all required fields');
      return;
    }

    this.customerUser.costAccount = this.customerUser.costAccount?.toString();
    this.customerUser.customerId = this.customerId;
    return this.customerUserService.update(this.customerUser.id, this.customerUser).subscribe((res) => {
      // this.router.navigate(['/manage/dashboard']);

    }, err => {
      if (err.status === 400 || err.status === 406) {
        console.log(err);
        if (has(err, 'error')) {
          if (has(err.error, 'errors'))
            this.notification.error("Error", err.error.errors);
          if (has(err.error, 'message'))
            this.notification.error("Error", err.error.message);
        }
      }

      if (err.status === 404) {
        if (hasIn(err, 'error.message') && err.error.message === 'Approver Id does not exist') {
          this.notification.error("Error - Invalid Approver",
            "The selected approver could not be found. If this unexpexcted, clear the approver from the user and try again.",
            { timeOut: 5000 });
        }
      }

      return Promise.reject(err);
    });
  }

  /**
 * @description Opens the two factor auth block for validation
 * @param data
 * @returns {void}
 */
  private openTFABlock(data: { qr: string, secret: string }) {
    this.tfaBlock = true;
    this.tfaVerifyBlock = true;
    this.tfaRequiresVerification = true;
    this.qrImage = data.qr;
    this.secretCode = data.secret;
  }

  /**
   * @description Clears the two factor auth block
   * @returns {void}
   */
  private clearTFABlock() {
    this.tfaBlock = false;
    this.tfaVerifyBlock = false;
    this.qrImage = "";
    this.secretCode = "";
    this.tfaRequiresVerification = false;
  }

  /**
 * @description Sets the startDate property
 * @param {string} startDate
 */
  setStartDate(startDate: string): void {
    this.customerUser.startDate = moment(startDate).startOf('day').toISOString();
  }

  /**
   * @description Gets the startDate
   * @param {User} user
   * @returns {string}
   */
  getStartDate(): string { return this.customerUser.startDate && moment(this.customerUser.startDate).format("YYYY-MM-DD") || '' };
}
