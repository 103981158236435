import { Component, Input } from '@angular/core';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';
import { KVArray } from '../../../../model/shared.model';
import { IUserAllocation, IUserAllocationCollection } from 'src/app/main/model/userAllocation.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { Observable, Subject, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { ICustomerUser } from 'src/app/main/model/customer.user.model';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import moment, { Moment } from 'moment-mini';
import { CollectionService } from 'src/app/main/services/collection.service';
import { ICollection } from 'src/app/main/model/collection.model';
import { SessionApi } from 'src/app/main/api/session.api';

enum AllocationFrequency {
  disabled = 'Disabled',
  inactive = "Ad Hoc (No Automatic Allocation)",
  weekly = 'First Day of Week (Weekly)',
  monthly = 'First Day of Month (Monthly)',
  annually = 'First Day of Year (Yearly)',
  financialYear = "First Day of Financial Year (Yearly)",
  arbitraryYear = "Yearly from Start Date",
  twoYear = "Bi-Yearly from Start Date",
  threeYear = "Three Yearly from Start Date"
}

enum AllocationMode {
  reset = 'Balance set to Allocation Amount each Period',
  add = 'Allocation Amount added each period'
}

@Component({
  selector: 'app-user-allocation-edit',
  templateUrl: './user-allocation-edit.component.html',
  styleUrls: []
})
export class UserAllocationEditComponent {
  private readonly subscriptionGroup = new SubscriptionGroup();
  public tab: string | null;
  public noFoundText: string;
  allocationFrequencyOptions: KVArray = [];
  allocationModeOptions: KVArray = [];
  selectedAllocationType: string = "dollar";
  customerUserId: number;
  customerId: number;
  selectedItems: number[];
  public userName: string | null;
  public isAdmin: boolean;
  @Input() allocations: IUserAllocation[] = [];
  @Input() readonly = false;
  @Input() showTitle: boolean = true;
  @Input() showSubmit: boolean = true;
  @Input() showStartDate: boolean = true;
  @Input() showProcessNow: boolean = true;
  @Input() loadCustomerUser: boolean = true;

  @Input() allowDollarAllocation: boolean = true;
  @Input() allowGarmetAllocation: boolean = true;
  @Input() allowGroupAllocationEdit: boolean = false;
  private collectionTerms: Subject<string> = new Subject<string>();

  queryResult: QueryResult<ICollection> = new QueryResult();

  canAccessLogs = false;

  public collectionQuery: IQueryFilter = new IQueryFilter({
    sortBy: 'name'
  });

  constructor(
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    public readonly customerUserService: CustomerUserService,
    private readonly session: SessionApi,
    private collectionsService: CollectionService
  ) {
    this.userName = this.route.snapshot.queryParamMap.get('userName');
    this.tab = this.route.snapshot.queryParamMap.get('tab');
    this.session.$userData.subscribe(data => {
      if (data && data.isAdmin) {
        this.isAdmin = true;
        this.canAccessLogs = true;
      }
    });
  }

  isReadOnly(allocation: IUserAllocation): boolean {
    if (this.readonly) return true;

    if (allocation.groupAllocationId && !this.allowGroupAllocationEdit) return true;

    return false;
  }

  ngOnInit() {
    if (this.loadCustomerUser) {
      this.subscriptionGroup.add(
        this.route.params.subscribe(params => {
          // TODO: If params.UserId is not found, or is blank, return the user to another screen
          this.customerUserId = Number(params['id']);
          this.customerId = Number(params['customerId']);
          this.subscriptionGroup.add(
            this.getCustomerUser().subscribe(customerUser => {

              if (customerUser?.userAllocations) {
                this.allocations = customerUser.userAllocations;
              }
              this.getCollection();
            })
          )
        })
      );
    }
    this.collectionSearch()


    this.allocationFrequencyOptions = Object.keys(AllocationFrequency).map(key => ({ key: key, value: AllocationFrequency[key] }));
    this.allocationModeOptions = Object.keys(AllocationMode).map(key => ({ key: key, value: AllocationMode[key] }));
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
  }

  getCollection(isScroll: boolean = false) {
    this.noFoundText = "Fetching...";
    if (isScroll) {
      this.collectionQuery.limit = this.collectionQuery.limit + 10;
    }
    this.collectionQuery.filter.customerId = this.customerId;
    this.collectionsService.list(this.collectionQuery)
      .subscribe(queryResult => {
        this.queryResult = queryResult;
        if (queryResult.rows.length == 0) {
          this.noFoundText = "No collections found";
        }
      });


  }

  collectionSearch() {
    this.collectionTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.collectionQuery.filter.name = { $like: searchTerm + '%' };
      this.getCollection();
    });
  }

  onCollectionSearch(searchTerm: { term: string; items: any[]; }) {
    this.collectionTerms.next(searchTerm.term);

  }

  saveAllocations() {

    this.subscriptionGroup.add(
      this.customerUserService.saveCustomerUserAllocations(this.customerUserId, this.allocations)
        .subscribe(result => {
          this.navigateToUserEdit();
        })
    )
  }

  public navigateToUserEdit(): void {
    const currentUrlSegments = this.route.snapshot.url.map(segment => segment.path);
    const editIndex = currentUrlSegments.indexOf('edit');
    let baseUrl: string, editSegment: string, navigateOptions;
    if (editIndex !== -1 && this.tab) {
      if (this.tab == 'view') {
        baseUrl = 'manage/customer/view/';
        editSegment = currentUrlSegments[editIndex + 1];
        navigateOptions = {
          queryParams: { activeTab: 1 }
        };
      } else {
        baseUrl = 'manage/customer/edit/';
        editSegment = currentUrlSegments[editIndex + 1];
        navigateOptions = {
          queryParams: { activeTab: this.tab === 'user' ? 1 : 2 }
        };
      }

      const finalUrl = `${baseUrl}${editSegment}`;
      this.router.navigate([finalUrl], this.tab ? navigateOptions : undefined);
    } else {
      this.router.navigate(this.isAdmin ? ['/manage/users'] : ['/account/users']);
    }
  }

  /**
   * Encapsulates the fetching of the customer user from the known customerUserId
   */
  private getCustomerUser(): Observable<ICustomerUser | null> {
    const filter = new IQueryFilter({
      filter: { id: this.customerUserId },
      include: [{
        association: 'userAllocations',
        include: [{
          association: 'allocationCollections',
          include: [{
            association: 'collection'
          }]
        }]
      }]
    });

    return this.customerUserService.list(filter)
      .pipe(
        map(result => {
          if (result.hasOwnProperty('rows') && result.rows.length > 0) {
            return result.rows[0];
          }

          return null;
        })
      )
  }

  changeMode(allocation: IUserAllocation, modeValue: string) {
    const modeKey = Object.keys(AllocationMode).find(key => AllocationMode[key] === modeValue);

    if (modeKey) {
      allocation.mode = modeKey;
    }
  }

  changeFrequency(allocation: IUserAllocation, frequency: string) {
    const frequencyKey = Object.keys(AllocationFrequency).find(key => AllocationFrequency[key] === frequency);

    if (frequencyKey) {
      allocation.frequency = frequencyKey;
    }
  }

  addCollections(collection?: IUserAllocationCollection[]) {
    for (const selectedCollection of this.selectedItems) {
      let existing = collection?.find((existingItem) => existingItem.collectionId === selectedCollection);
      if (!existing) {
        let foundRow = this.queryResult.rows.find((row) => row.id === selectedCollection);
        if (foundRow) {
          collection?.push({
            name: foundRow.name,
            itemCount: 0, // You can initialize itemCount as required
            remainingItemCount: 0, // You can initialize remainingItemCount as required
            collectionId: foundRow.id
          });
        }
      }
    }
  }


  addAllocation = () => {
    this.allocations.push({
      customerUserId: this.customerUserId,
      maxBalance: null,
      balance: 0,
      processedTo: null,
      processAt: null,
      type: this.selectedAllocationType,
      amount: this.selectedAllocationType === 'dollar' ? 0 : undefined,
      mode: this.selectedAllocationType === 'dollar' ? 'reset' : undefined,
      frequency: 'arbitraryYear',
      startAt: moment().startOf('day').add(1, "day").toISOString(),
      processImmediately: false,
      allocationCollections: this.selectedAllocationType === 'garment' ? [] : undefined,
    });
  }

  frequencyText = (frequency): string => frequency ? AllocationFrequency[frequency] : '';
  modeText = (mode): string => mode ? AllocationMode[mode] : '';
  setStartDate = (allocation: IUserAllocation, startDate: string) => {
    // Updating the start date blows away the current processedTo date
    allocation.processedTo = null;
    allocation.startAt = moment(startDate).startOf('day').toISOString();
  }
  getStartDate = (allocation: IUserAllocation) => moment(allocation.startAt).format("YYYY-MM-DD");
}
