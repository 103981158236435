import { Injectable, OnDestroy } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, combineLatest, first } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { CategoryApi } from '../api/category.api';
import { Brand, ICategory } from '../model/ddb.model';
import { SecurityService } from './security.service';
import { SubscriptionGroup } from '../util/subscriptionGroup';
import { SessionApi } from '../api/session.api';
import { logger } from '../util/Logger';

const className = 'CategoryService';

@Injectable()
export class CategoryService implements OnDestroy {
	public readonly allCategories$ = new BehaviorSubject(new QueryResult<ICategory & HasId<string>>());
	private readonly subscriptionGroup = new SubscriptionGroup();

	constructor(
		private notifications: NotificationsService,
		private categoryApi: CategoryApi,
		private security: SecurityService,
		private sessionApi: SessionApi
	) {
		// Monitor a list of every category for the current user
		this.monitorCategories();
	}

	ngOnDestroy(): void {
		if (this.subscriptionGroup) {
			this.subscriptionGroup.unsubscribe();
		}
	}

	monitorCategories() {
		this.subscriptionGroup.add(
			this.security.isAuthenticated().subscribe({
				next: () => {
					this.refreshCategoryCache();
				}
			})
		);
	}

	public refreshCategoryCache() {
		const signature = className + '.refreshCategoryCache: ';
		const authState = !!this.sessionApi.$userData.getValue();
		if (authState) {
			this.categoryApi.list(new IQueryFilter({
				limit: 10000
			}))
				.pipe(first())
				.subscribe({
					next: val => {
						logger.silly(signature + `Refreshed Category Cache`);
						this.allCategories$.next(val instanceof QueryResult ? val : new QueryResult(val));
					}
				})
		} else {
			this.allCategories$.next(new QueryResult<ICategory & HasId<string>>());
		}
	}


	public list(query: IQueryFilter) {
		return apiCallWrapper(
			this.categoryApi.list(query),
			{
				notificationsService: this.notifications,
				action: "Fetching category"
			}
		)
	}

	public create(data: any): Observable<Brand & HasId<string>> {
		return apiCallWrapper(
			this.categoryApi.create(data),
			{
				notificationsService: this.notifications,
				action: "Updating Category"
			}
		)
	}
}
