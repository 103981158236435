export function sortObjectsByField(list: any[], sortField: string, direction: 'ASC' | 'DESC' = 'ASC') {
  return list.sort((a, b) => {
    const aName = (a[sortField] || "").toLowerCase();
    const bName = (b[sortField] || "").toLowerCase();
    if (aName < bName) {
      return direction === 'ASC' ? -1 : 1;
    }
    if (aName > bName) {
      return direction === 'ASC' ? 1 : -1;
    }
    return 0;
  });
}

export const isNumberString = (val: any): boolean => {
  return !!numberFromString(val);
}

export const numberFromString = (val: any): number | null => {
  if (typeof val === "number") { return val; }

  const numVal = new Number(val);

  if (isNaN(numVal.valueOf())) {
    return null;
  }

  return numVal.valueOf();
}
