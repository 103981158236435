import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionGroup } from '../util/subscriptionGroup';
import { combineLatest } from 'rxjs';
import { SecurityService } from '../services/security.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: []
})
export class UsersComponent implements OnDestroy {
  constructor(
    public route: Router,
    public activatedRoute: ActivatedRoute,
    public securityService: SecurityService
  ) {
    this.monitorAccess();
    const activeTab = this.activatedRoute.snapshot.queryParamMap.get('activeTab');
    if (activeTab) {
      this.activeTabIdx = +activeTab;
      this.activeTag = this.tags[this.activeTabIdx];
    }
  }
  public tags = ['Users', 'Groups', 'Admin'];
  public activeTabIdx: number = 0;
  public activeTag: string = this.tags[this.activeTabIdx];
  private readonly subscriptionGroup = new SubscriptionGroup();
  public hasManageCustomersAccess: boolean = false;
  public hasManageGroupsAccess: boolean = false;
  public isSuperAdmin: boolean | null = false;

  getTabs(): string[] {
		if( this.isSuperAdmin ) {
    return ['All', 'Groups', 'Admin'];
		} else {
			return ['All', 'Groups'];
		}
  }

  onTabChange(event: number): void {
    this.activeTabIdx = event;
    this.activeTag = this.tags[this.activeTabIdx];
  }

  manageRoute() {
    if (this.activeTabIdx == 1 && this.hasManageGroupsAccess) {
      this.route.navigate(['/manage/users/groups']);
    }
    if (this.activeTabIdx == 2 && this.isSuperAdmin) {
      this.route.navigate(['/manage/users/admin/edit']);
    }
  }

  /**
 * Monitors various access checks and updates the local viewstate where appropriate
 */
  monitorAccess() {
    this.subscriptionGroup.add(
      combineLatest(
        this.securityService.isSuperAdmin(),
        this.securityService.hasManageCustomerAccess(),
        this.securityService.hasManageGroupsAccess()
      ).subscribe(([
        isSuperAdmin,
        hasManageCustomersAccess,
        hasManageGroupsAccess
      ]) => {
        this.isSuperAdmin = isSuperAdmin;
        this.hasManageCustomersAccess = this.isSuperAdmin || hasManageCustomersAccess;
        this.hasManageGroupsAccess = this.isSuperAdmin || hasManageGroupsAccess;
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
  }
}
