import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from '../../services/group.service';
import { CustomerService } from '../../services/customer.service';
import { IQueryFilter } from '../../model/query.filter.class';
import { IGroup } from '../../model/group.model';

@Component({
  selector: 'app-group-tab',
  templateUrl: './group-tab.component.html',
  styleUrls: []
})
export class GroupTabComponent implements OnInit {
  public groupId: string;
  public count: IGroup;
  public queryResult: IGroup;
  public activeTabIdx: number = 0;
  customerId: string | null;
  constructor(
    public route: ActivatedRoute,
    private groupService: GroupService,
    private router: Router
  ) {
    this.customerId = this.route.snapshot.queryParamMap.get('customerId');
  }

  getTabs(): string[] {
    return ['Overview', 'Collections'];
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.groupId = params['groupId'];

      if (this.groupId) {
        this.loadGroup(this.groupId);
      }
    });
  }

  loadGroup(groupId: string) {
    this.groupService.get(groupId)
      .subscribe((group: IGroup) => {
        this.queryResult = group;
        this.count = group;
      });
  }

  deleteGroup(groupId: string | undefined) {
    if (groupId) {
      this.groupService.delete(+groupId).subscribe(() => {
        this.getRoute();
      });
    }
  }

  getRoute() {
    if (!this.customerId) {
      this.router.navigate(['/manage/users'], { queryParams: { activeTab: 1 } });
    } else {
      this.router.navigate(['/manage/customer/edit', this.customerId], { queryParams: { activeTab: 5 } });
    }
  }
}
