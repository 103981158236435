import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { createUrl } from './api.util';
import { Observable } from 'rxjs';

// Convenience Type
type T = User;

@Injectable()
export class AdminApi extends GenericApi<T> {
  public path = "admin";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  readonly sendTestEmail = (emailAddress: string, testEmail: string) : Observable<{emailSent: boolean}>=> {
    return this.httpClient.post<{emailSent: boolean}>(
      createUrl(this.path, 'sendTestEmail'),
      {
        emailAddress,
        testEmail
      }
    );
  }

  readonly getServerMessage = () => {
    return this.httpClient.get<{ message: string, severity: string }>(
      createUrl(this.path, 'serverMessage')
    );
  }

  readonly processAllocations = () => {
    return this.httpClient.get<any>(
      createUrl(this.path, 'processAllocations')
    );
  }

  readonly syncDiscountRates = () => {
    return this.httpClient.get<any>(
      createUrl(this.path, 'syncDiscountRates')
    );
  }

  readonly syncProductPrice = (productId?: number) => {

    const params: { [param: string]: string } = {};

    if (productId) {
      params.productId = productId.toString()
    }

    return this.httpClient.get<any>(
      createUrl(this.path, 'syncProductPrice'),
      { params }
    );
  }

  readonly setServerMessage = (message: string, severity: string) => {
    return this.httpClient.put<any>(
      createUrl(this.path, 'serverMessage'),
      {
        message,
        severity
      }
    );
  }
}
