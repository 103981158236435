import { environment } from "../../../environments/environment";
import { IQueryFilter } from '../model/query.filter.class';
import { HttpParams, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { NotificationsService, Notification } from 'angular2-notifications';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorMessage } from '../model/api.model';
import { HeaderTokenEnum } from '../model/auth.model';
import { logger } from "../util/Logger";
import { hasKey } from "../util/object.util";

const className = "apiCallWrapper";

export const apiHost = environment.endpoint + '/api';
export const apiPrefix = '';

/**
 * @description Creates an application URL by concatenating the host, prefix, and additional path parameters.
 * @param {string} host - The host part of the URL.
 * @param {string} prefix - The prefix to be added before the path parameters.
 * @returns {(params: Array<string | number>) => string} - A function that accepts an array of path parameters and returns the generated application URL.
 * @example
 * ```
 * const generateUrl = createAppUrl('https://example.com', 'api');
 *
 * const url1 = generateUrl('users'); // 'https://example.com/api/users'
 * const url2 = generateUrl('posts', 123); // 'https://example.com/api/posts/123'
 * ```
 */
export const createAppUrl = (host: string, prefix: string) => (...params: Array<string | number>): string =>
	`${host}${prefix.length ? prefix + '/' : ''}/${params.join('/')}`;

/**
* @description Creates a URL using the `createAppUrl` function with pre-defined parameters for the API host and prefix.
* @returns {(params: Array<string | number>) => string} - A function that accepts an array of path parameters and returns the generated URL.
* @example
* ```
* const generateUrl = createUrl;
*
* const url1 = generateUrl('users'); // 'https://example.com/api/users'
* const url2 = generateUrl('posts', 123); // 'https://example.com/api/posts/123'
* ```
*/
export const createUrl = createAppUrl(apiHost, apiPrefix);

/**
 * @description Creates a new instance of `HttpParams` to build HTTP request parameters.
 * @returns {HttpParams} - A new instance of `HttpParams`.
 * @example
 * ```
 * const params = httpParams();
 *
 * params = params.append('param1', 'value1');
 * params = params.append('param2', 'value2');
 *
 * console.log(params.toString()); // 'param1=value1&param2=value2'
 * ```
 */
export const httpParams = () => new HttpParams();

/**
 * @description Converts a query object into an instance of `HttpParams` to be used in an HTTP request.
 * @param {IQueryFilter} query - The query object containing key-value pairs.
 * @returns {HttpParams} - An instance of `HttpParams` with the converted query parameters.
 * @example
 * ```
 * const query = {
 *   param1: 'value1',
 *   param2: { nestedParam: 'nestedValue' },
 *   param3: (param) => param > 10
 * };
 *
 * const params = queryToParams(query);
 *
 * console.log(params.toString());
 * // 'param1=value1&param2={"nestedParam":"nestedValue"}'
 * ```
 */
export const queryToParams = (query: IQueryFilter) => (Object).entries(query).reduce(
	(params, [key, value]) =>
		typeof value === 'function' ? params : params.set(key, typeof value === 'object' ? JSON.stringify(value) : value),
	httpParams()
);

/**
 * @description Retrieves the headers for public routes by creating a new instance of `HttpHeaders` and appending the 'tokenType' header with the value 'NoToken'.
 * @returns {HttpHeaders} - An instance of `HttpHeaders` with the headers for public routes.
 * @example
 * ```
 * const publicHeaders = getPublicRoutesHeaders();
 *
 * console.log(publicHeaders.get('tokenType')); // 'NoToken'
 * ```
 */

export const getPublicRoutesHeaders = () => {
	const headers = new HttpHeaders();

	return headers.append('tokenType', HeaderTokenEnum.NoToken);
};

let offlineNotification: Notification | null = null;

/**
 * @description Wraps an API call observable with error handling, notifications, and success notifications.
 * @param {Observable<N>} observable - The API call observable to be wrapped.
 * @param {Object} opts - Options for configuring the behavior of the wrapper.
 * @param {NotificationsService} opts.notificationsService - The notifications service used for displaying notifications.
 * @param {string} opts.action - The action being performed by the API call.
 * @param {string} [opts.title] - The title for the notification displayed during the API call.
 * @param {string} [opts.message] - The message for the notification displayed during the API call.
 * @param {string} [opts.successTitle] - The title for the success notification displayed after a successful API call.
 * @param {string} [opts.failTitle] - The title for the fail notification displayed after a failed API call.
 * @param {string} [opts.successMessage] - The message for the success notification displayed after a successful API call.
 * @param {D} [opts.defaultValue] - The default value to return if the entity is not found, instead of an error (eg: null)
 * @returns {Observable<N>} - The wrapped API call observable.
 * @example
 * ```
 * const observable = apiCallWrapper(
 *   someApiCallObservable,
 *   {
 *     notificationsService: myNotificationsService,
 *     action: 'Get Data',
 *     title: 'Fetching data',
 *     message: 'Please wait...',
 *     successTitle: 'Data Retrieved',
 *     failTitle: 'Error retrieving data',
 *     successMessage: 'Data retrieval completed',
 *     defaultValue: []
 *   }
 * );
 *
 * observable.subscribe(
 *   data => console.log('API call success:', data),
 *   error => console.error('API call error:', error)
 * );
 * ```
 */
export const apiCallWrapper = <D, N>(observable: Observable<N>, opts: {
	notificationsService?: NotificationsService,
	action: string,
	title?: string,
	message?: string,
	successTitle?: string,
	failTitle?: string,
	successMessage?: string,
	defaultValue?: D
}): typeof observable => {
	const signature = className + ".apiCallWrapper: ";
	const options = Object.assign({},
		opts,
		{
			title: opts.action,
			successTitle: `${opts.action} complete`,
			failTitle: `${opts.action} failed`,
			message: '',
			successMessage: opts.message ? `${opts.message} completed` : ''
		}
	);
	const notifcation = options.notificationsService ? options.notificationsService.warn(options.title, options.message) : null;

	const removeExistingNotification = () => options.notificationsService && notifcation ? options.notificationsService.remove(notifcation.id) : void (0);

	// Sent to true when there was a gracefully handled error and the default value was returned.
	let didNotSucceed = false;

	// Ensure no offline notifications are being displayed if an offline state is not detected
	if (options.notificationsService && offlineNotification && window.navigator.onLine) {
		options.notificationsService.remove(offlineNotification.id);
		offlineNotification = null;
	}

	if (!window.navigator.onLine) {
		removeExistingNotification();

		// Set an offline notification if one doesn't already exist

		if (options.notificationsService && (!offlineNotification || offlineNotification.destroyedOn)) {
			offlineNotification = options.notificationsService.error(options.failTitle, "No internet connection available.");

			if (offlineNotification.click) {
				offlineNotification.click.subscribe(() => {
					if( !options.notificationsService ) return;

					options.notificationsService.remove(offlineNotification!.id);
					offlineNotification = null;
				});
			}

			if (offlineNotification.timeoutEnd) {
				offlineNotification.timeoutEnd.subscribe(() => {
					if( !options.notificationsService ) return;

					options.notificationsService.remove(offlineNotification!.id);
					offlineNotification = null;
				});
			}
		}

		logger.silly(signature + `Ignoring API Request for offline connection`);

		const handledError = new ErrorMessage({ message: "Browser is offline", handled: true });
		return throwError(handledError);
	}

	return observable.pipe(
		catchError(err => {
			logger.silly(signature + 'Handling Error');

			removeExistingNotification();

			// Prevent duplicate handling of the error
			if (err instanceof ErrorMessage) {
				if (err.handled) {
					return NEVER;
				}

				return throwError(err);
			}

			if (hasKey(err, 'error') && hasKey(err.error, 'message') && hasKey(err.error, 'statusCode')) {
				const error = new ErrorMessage().deserialize(err.error as Partial<ErrorMessage>);

				if( options.notificationsService ) options.notificationsService.error(options.failTitle, error.message);

				if (error.statusCode === 404 && opts.defaultValue) {
					logger.warn(signature + `Gracefully 404 Handled Error`);
					didNotSucceed = true;
					return of(options.defaultValue);
				}

				return throwError(error);
			}

			if (err instanceof HttpErrorResponse && err.status === 0) {
				if( options.notificationsService ) options.notificationsService.error(options.failTitle, "Error communicating with server");

				const handledError = new ErrorMessage({ handled: true });

				return throwError(handledError);
			}

			if( options.notificationsService ) options.notificationsService.error(options.failTitle, "Unknown Error has Occurred");

			return throwError(new ErrorMessage());
		}),
		map(
			result => {
				removeExistingNotification();

				if (!didNotSucceed) {
					if( options.notificationsService ) options.notificationsService.success(options.successTitle, options.successMessage);
				}

				return result as N;
			}
		)
	);
}
