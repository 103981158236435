import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { SessionApi } from '../../api/session.api';
import { FavoriteService } from '../../services/favorites.service';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { combineLatest } from 'rxjs';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { IFavorite } from '../../model/favorites.model';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-favorite-select',
  templateUrl: './favorite-select.component.html',
  styleUrls: []
})
export class FavoriteSelectComponent implements OnChanges, OnDestroy {
  @Input()
  productId: number | undefined;
  favoritesListResult: QueryResult<IFavorite> = new QueryResult();
  listsContainingProduct: IFavorite[] = [];
  adddedProducts: number[] = [];
  public userId: number;
  public customerId: number;
  public isAdmin: boolean = false;
  private readonly subscriptionGroup = new SubscriptionGroup();
  public query: IQueryFilter = new IQueryFilter({
    sortBy: 'id',
    skip: 0,
    limit: 5000
  });

  @Input() type: string;

  constructor(
    private session: SessionApi,
    private favoritesService: FavoriteService,
    private notifications: NotificationsService
  ) {
    this.subscriptionGroup.add(
      combineLatest(
        this.session.$userData,
        this.session.$customerData
      ).subscribe(([
        userData,
        customerData
      ]) => {
        if (!userData?.isAdmin && userData?.id) {
          this.query.filter.userId = userData?.id;
          this.userId = userData?.id;
          if (customerData && customerData.id) {
            this.query.filter.customerId = customerData.id;
            this.customerId = customerData.id;
          }

          this.fetchFavorites();
        } else {
          this.isAdmin = true;
        }
      })
    );
  }

  ngOnChanges() {
    this.renderSelectedValues();
  }

  fetchFavorites() {
    this.favoritesService.list(this.query)
      .subscribe(queryResult => {
        this.favoritesListResult = queryResult;
      });
  }

  addFav(id: number | undefined | string) {
    if (!this.adddedProducts.find(data => data == id) && id && this.productId && !this.isProductInList(id)) {

      this.adddedProducts.push(+id);
      this.favoritesService.addProductToFavoriteList(this.productId.toString(), id.toString())
        .subscribe(() => {
          const list = this.favoritesListResult.rows.find(list => list.id == id);
          if (list) {
            this.listsContainingProduct.push(list);
          }
          this.renderSelectedValues();
        }, err => {
          console.log(err);
        })
    } else {
      if (this.productId && id) {
        const parsedId = typeof id === 'string' ? parseInt(id) : id;
        const index = this.adddedProducts.indexOf(parsedId);
        if (index !== -1) {
          this.adddedProducts.splice(index, 1);
        }
        this.favoritesService.removeProductFromFavoriteList(this.productId.toString(), id.toString())
          .then(() => {
            this.notifications.success('Remove Product from Favourites')
            const list = this.favoritesListResult.rows.find(list => list.id == id);
            if (list) {
              this.listsContainingProduct.push(list);
            }
            this.renderSelectedValues();
          }).catch(err => {
            console.log(err);
          });
      }
    }
  }
  isProductInList(id): boolean {
    let flag = false;
    for (let index = 0; index < this.listsContainingProduct.length; index++) {
      const list = this.listsContainingProduct[index];
      if (list.id === id || id === null) {
        flag = list.products?.some(ele => ele.productId === this.productId) || false;
      }
    }
    return flag

  }

  private renderSelectedValues = async () => {
    this.listsContainingProduct = []
    const targetIds: number[] = [];

    if (this.productId) {
      targetIds.push(this.productId);
    }


    if (targetIds.length && !(targetIds.length === 1 && targetIds[0] === 0)) {
      let query: IQueryFilter = new IQueryFilter({
        limit: 1000,
        filter: {
          userId: this.userId,
          customerId: this.customerId
        },
        include: [{
          association: 'products',
          required: true,
          filter: {
            $or: targetIds.map(targetId => ({ productId: targetId }))
          }
        }],
      });

      this.favoritesService.list(query).subscribe(res => {
        this.listsContainingProduct = res.rows

      });


    }
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      if (this.subscriptionGroup) {
        this.subscriptionGroup.unsubscribe();
      }
    }
  }
}
