import { Component } from '@angular/core';

@Component({
  selector: 'app-returns-exchanges',
  templateUrl: './returns-exchanges.component.html',
  styleUrls: []
})
export class ReturnsExchangesComponent {

}
