import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../util/utils';
import { ProductService } from '../../services/product.service';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { NewProduct } from '../../model/ddb.model';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-product-breadcrumbs',
  templateUrl: './product-breadcrumbs.component.html',
  styleUrls: []
})
export class ProductBreadcrumbsComponent {
  public categoryLinks: Array<any>;
  queryResult: QueryResult<NewProduct> = new QueryResult();
  public isReSize: boolean = false;
  constructor(
    public utils: Utils,
    public route: ActivatedRoute,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private productService: ProductService,
    private router: Router,
    private notifications: NotificationsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const innerWidth = window.innerWidth;
    if (innerWidth > 1799) {
      this.isReSize = true;
    }
    this.categoryLinks = [];

    let uri = '/products';
    let path = window.location.href.replace(/^.*\/products?\//, '').split('/');

    // The last element in the list is the product name
    if (path.length)
      path.pop();

    path.forEach(c => {
      uri += '/' + encodeURIComponent(c);
      let formattedC = this.utils.fromUrlFormat(c);
      this.categoryLinks.push({
        text: formattedC,
        link: uri,
        decodedText: decodeURIComponent(decodeURIComponent(c)).replace('%2F', '\/'),
      });
    });

    this.getProducts();
  }

  slideConfig = {
    prevArrow: '<button type="button" class="slick-prev">Previous</button>',
    nextArrow: '<button type="button" class="slick-next">Next</button>',
    slidesToShow: 4.9,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    cssEase: 'ease-in-out',
    pauseOnHover: true,
    draggable: true,
    swipe: true,
    centerMode: false,
    variableWidth: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1499,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  slickInit() {
    this.checkButtonState();
  }

  afterChange() {
    this.checkButtonState();
  }

  checkButtonState() {
    const slickPrevDisabled = this.elementRef.nativeElement.querySelector('#proSlides .slick-prev')?.classList.contains('slick-disabled');
    const slickNextDisabled = this.elementRef.nativeElement.querySelector('#proSlides .slick-next')?.classList.contains('slick-disabled');
    const sliderList = this.elementRef.nativeElement.querySelector('#proSlides .slick-list');

    if (slickPrevDisabled) {
      if (window.innerWidth >= 1199) {
        this.renderer.setStyle(sliderList, 'transform', 'translateX(16rem)');
      } else if (window.innerWidth >= 600) {
        this.renderer.setStyle(sliderList, 'transform', 'translateX(2rem)');
      } else {
        this.renderer.setStyle(sliderList, 'transform', 'translateX(0rem)');
      }
    } else if (slickNextDisabled) {
      this.renderer.setStyle(sliderList, 'transform', 'translateX(0rem)');
    } else {
      this.renderer.setStyle(sliderList, 'transform', 'translateX(0rem)');
    }
  }

  /**
* @description Retrives products from the server and attaches them to the component
*/
  getProducts = () => {
    const query: IQueryFilter = new IQueryFilter({
      sortBy: 'name',
      limit: 50
    });
    if (this.authService.isAuthenticated()) {
      this.productService.getProductList(query, {})
        .subscribe(queryResult => {
          this.queryResult = queryResult;
        }, (error) => {
          this.notifications.error('Fetch Product', error.error && error.error.message);
        });
    } else {
      this.productService.getUnAuthProductList(query, {})
        .subscribe(queryResult => {
          this.queryResult = queryResult;
        }, (error) => {
          this.notifications.error('Fetch Product', error.error && error.error.message);
        });
    }
  }

  handleRoute(uriString: string) {
    this.categoryLinks = [];
    const path = uriString.split('/');
    let uri = '/products';
    path.forEach(c => {
      uri += '/' + encodeURIComponent(c);
      let formattedC = this.utils.fromUrlFormat(c);
      this.categoryLinks.push({
        text: formattedC,
        link: uri,
        decodedText: decodeURIComponent(decodeURIComponent(c)).replace('%2F', '\/'),
      });
    });
    this.router.navigate(['/product/' + uriString]);
    window.scroll(0, 0);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const innerWidth = window.innerWidth;
    if (innerWidth > 1799) {
      this.isReSize = true;
    } else {
      this.isReSize = false;
    }
  }

}
