<div class="main-right-content">
	<!-- Back-button start here... -->
	<div class="backs d-lg-flex align-items-center justify-content-between">
		<a
			[routerLink]="['../']"
			class="btn btn-back p-0 d-none d-lg-flex"
			role="button"
		>
			<span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="6"
					height="10"
					viewBox="0 0 6 10"
					fill="none"
				>
					<path
						d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039"
						stroke="#666D80"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</span>
			Back to reports
		</a>
		<!-- Page Breadcrumb start here... -->
		<nav aria-label="breadcrumb" class="pageBreadcrumb mb-0">
			<ol class="breadcrumb mb-0">
				<li class="breadcrumb-item"><a [routerLink]="['../']">Reports</a></li>
				<li class="breadcrumb-item active" aria-current="page">
					Abnormal Cart Report
				</li>
			</ol>
		</nav>
		<!-- Page Breadcrumb end here... -->
	</div>
	<!-- Back-button end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div
			class="d-flex flex-column flex-lg-row align-items-start justify-content-between mb-lg-5 mb-4 gap-4"
		>
			<div>
				<h1 class="title mb-0">Abnormal cart report</h1>
				<p class="title-desc">
					Lists all orders that have products in them attached to allocation
					collections. Only shows carts where the item is not in the collection
					they have been assigned to. (EG: Boots attached to a Shirts
					Collection)
				</p>
			</div>
			<div class="edit-btns w-sm-100">
				<button
					class="btn btn-secondary w-sm-100"
					type="button"
					(click)="downloadFile()"
					*ngIf="reportData.length"
				>
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M13.75 7L10 3.25L6.25 7"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10 3.25V12.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
					Export CSV
				</button>
			</div>
		</div>

		<div class="manage-user-list">
			<!-- table-content for lg screen start here... -->
			<div class="manage-table main-table d-none d-lg-block">
				<table class="table">
					<colgroup>
						<col style="width: 7.11%" />
						<col style="width: 24.11%" />
						<col style="width: 8.11%" />
						<col style="width: 25.11%" />
						<col style="width: 8.11%" />
						<col style="width: 8.11%" />
						<col style="width: 8.11%" />
						<col style="width: 8.11%" />
						<col style="width: 3.11%" />
					</colgroup>
					<thead>
						<tr>
							<th>Order</th>
							<th>Customer</th>
							<th>PO#</th>
							<th>Approver</th>
							<th>Order date</th>
							<th>Status</th>
							<th>Order placed</th>
							<th>Grand total</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of reportData">
							<td colspan="9" class="p-0">
								<table class="bg-transparent shadow-none rounded-0 w-100">
									<colgroup>
										<col style="width: 7.11%" />
										<col style="width: 24.11%" />
										<col style="width: 8.11%" />
										<col style="width: 25.11%" />
										<col style="width: 8.11%" />
										<col style="width: 8.11%" />
										<col style="width: 8.11%" />
										<col style="width: 8.11%" />
										<col style="width: 3.11%" />
									</colgroup>
									<tbody>
										<tr>
											<td>
												<div class="user-name">
													<h5>{{ item.orderNumber || "Not Specified" }}</h5>
												</div>
											</td>
											<td>
												<p>{{ item.customer || "N/A" }}</p>
											</td>
											<td>
												<p>{{ item.orderReference || "N/A" }}</p>
											</td>
											<td>
												<div class="user-name">
													<span *ngIf="item.approver">{{
														getInitials(item.approver)
													}}</span>
													<div>
														<h5>{{ item.approver || "N/A" }}</h5>
													</div>
												</div>
											</td>
											<td>
												<p>{{ getOrderDate(item.orderCreated) }}</p>
											</td>
											<td>
												<span class="badge bg-green-25">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
													>
														<circle
															cx="10"
															cy="10"
															r="2"
															fill="transparent"
														></circle>
													</svg>
													{{ item.orderStatus }}</span
												>
											</td>
											<td>
												<p>
													{{
														item.orderedAt
															? getOrderDate(item.orderedAt)
															: "N/A"
													}}
												</p>
											</td>
											<td>
												<p>${{ item.grandTotal }}</p>
											</td>
											<td>
												<div
													class="d-flex align-items-center justify-content-start"
												>
													<div class="user-name">
														<button
															class="btn btn-secondary-icon btn-table btn-order shadow-none"
															type="button"
															data-bs-toggle="collapse"
															[attr.data-bs-target]="
																'#tableCollapse' + item.orderNumber
															"
															aria-expanded="false"
															aria-controls="tableCollapse"
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="20"
																height="20"
																viewBox="0 0 20 20"
																fill="none"
															>
																<path
																	d="M6.3999 8.19922L9.9999 11.7992L13.5999 8.19922"
																	stroke="#666D80"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																/>
															</svg>
														</button>
													</div>
												</div>
											</td>
										</tr>
										<tr
											class="collapse-tr collapse"
											[id]="'tableCollapse' + item.orderNumber"
										>
											<td class="collapse-td p-0" colspan="9">
												<div class="order-report-detail-content">
													<div class="order-detail">
														<ul>
															<li>
																<p class="head">Order</p>
																<p>{{ item.orderNumber }}</p>
															</li>
															<li>
																<p class="head">Customer</p>
																<p>
																	{{ item.customer || "N/A" }}
																</p>
															</li>
															<li>
																<p class="head">Approver</p>
																<p>
																	{{ item.approver || "N/A" }}
																</p>
															</li>
															<li>
																<p class="head">Order date</p>
																<p>
																	{{ getOrderDate(item.orderCreated) }}
																</p>
															</li>
															<li>
																<p class="head">Status</p>
																<p>
																	<span class="badge bg-green-25">
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="20"
																			height="20"
																			viewBox="0 0 20 20"
																			fill="none"
																		>
																			<circle
																				cx="10"
																				cy="10"
																				r="2"
																				fill="transparent"
																			></circle>
																		</svg>
																		{{ item.orderStatus }}</span
																	>
																</p>
															</li>
															<li>
																<p class="head">Order placed</p>
																<p>
																	{{
																		item.orderedAt
																			? getOrderDate(item.orderedAt)
																			: "N/A"
																	}}
																</p>
															</li>
															<li>
																<p class="head">Grand total</p>
																<p>${{ item.grandTotal }}</p>
															</li>
														</ul>
													</div>
													<div class="order-lists">
														<div
															class="order-card"
															*ngFor="let productItem of item.orderItems"
														>
															<div class="order-img">
																<img
																	[src]="productItem.productImage"
																	alt="No Image"
																	*ngIf="productItem.productImage"
																/>
															</div>
															<div class="order-content">
																<div
																	class="d-flex align-items-center justify-content-between"
																>
																	<h4 class="title">
																		{{ productItem.productName }}
																	</h4>
																	<div class="order-badges">
																		<p>
																			Quantity
																			<span class="badge bg-alpha-25 ps-3">{{
																				productItem.productQuantity
																			}}</span>
																		</p>
																	</div>
																</div>
																<p class="desc">
																	<b>Selected Collection</b>
																	{{ productItem.collectionName }}
																</p>
																<span
																	class="badge px-3 py-1"
																	[class.bg-orange-25]="
																		productItem.invalidCollection
																	"
																	[class.d-none]="!productItem.invalidCollection"
																>
																	Collection is Invalid
																</span>
																<span
																	class="badge px-3 py-1"
																	[class.bg-blue-25]="
																		!productItem.invalidCollection
																	"
																	[class.d-none]="productItem.invalidCollection"
																>
																	Collection is Valid
																</span>
																<ul class="proudct-features">
																	<li>
																		<p>
																			<span>Product Type</span>
																			{{ productItem.productType }}
																		</p>
																	</li>
																	<li>
																		<p>
																			<span>Code</span>
																			{{ productItem.productCode }}
																		</p>
																	</li>
																	<li>
																		<p>
																			<span>Colour</span>
																			{{
																				productItem.productColor || "Default"
																			}}
																		</p>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- table-content for lg screen end here... -->

			<!-- Cards for sm screen start here... -->
			<div class="manage-cards d-lg-none">
				<div class="card manage-card" *ngFor="let item of reportData">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Order </span>
									<div class="user-name align-items-start">
										<h5>{{ item.orderNumber || "Not Specified" }}</h5>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Customer </span>
									<p>{{ item.customer || "N/A" }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> PO# </span>
									<p>{{ item.orderReference || "N/A" }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Approver </span>
									<div class="user-name">
										<span *ngIf="item.approver">{{
											getInitials(item.approver)
										}}</span>
										<div>
											<h5>{{ item.approver || "N/A" }}</h5>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Order date </span>
									<p>{{ getOrderDate(item.orderCreated) }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Status </span>
									<span class="badge bg-green-25">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<circle cx="10" cy="10" r="2" fill="transparent"></circle>
										</svg>
										{{ item.orderStatus }}</span
									>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Order placed </span>
									<p>
										{{ item.orderedAt ? getOrderDate(item.orderedAt) : "N/A" }}
									</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Grand Total </span>
									<p>${{ item.grandTotal }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Actions: </span>
									<div
										class="d-flex align-items-center w-100 justify-content-end"
									>
										<button
											class="btn btn-secondary-icon btn-table btn-order shadow-none"
											type="button"
											data-bs-toggle="collapse"
											[attr.data-bs-target]="
												'#tableCollapse' + item.orderNumber
											"
											aria-expanded="false"
											aria-controls="tableCollapse"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M6.3999 8.19922L9.9999 11.7992L13.5999 8.19922"
													stroke="#666D80"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</button>
									</div>
								</div>
							</li>
						</ul>
						<div class="collapse" [id]="'tableCollapse' + item.orderNumber">
							<div class="report-order-detail-content">
								<div class="order-lists">
									<div
										class="order-card"
										*ngFor="let productItem of item.orderItems"
									>
										<div class="order-head">
											<div class="order-img">
												<img
													[src]="productItem.productImage"
													alt="order-report"
												/>
											</div>
											<div class="d-flex flex-column gap-4 align-items-start">
												<h4 class="title">{{ productItem.productName }}</h4>
												<p>
													Quantity
													<span class="badge bg-alpha-25 ps-3">{{
														productItem.productQuantity
													}}</span>
												</p>
											</div>
										</div>
										<hr class="bg-dash" />
										<div class="order-content">
											<p
												class="desc"
												[innerHTML]="productItem.productDescription"
											></p>
											<ul class="product-features">
												<li>
													<p>
														<span>Code</span>
														{{ productItem.productCode }}
													</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Cards for sm screen end here... -->
		</div>
	</div>
</div>
