import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IShippingDetails, ShippingDetails, ShippingDetailsClass } from '../model/shippingDetail.model';
import { Observable } from 'rxjs';
import { createUrl, queryToParams } from './api.util';
import { IQueryFilter } from '../model/query.filter.class';

// Convenience Type
type T = IShippingDetails;

@Injectable()
export class ShippingDetailApi extends GenericApi<T> {
  public path = "shipping_details";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public readonly getShippingDetailsList = (customerId: string | number): Observable<ShippingDetailsClass[]> => {
    return this.httpClient.get<ShippingDetailsClass[]>(
      createUrl('customer', customerId, 'address')
    );
  };

  public readonly deleteAddress = (id: number, customerId: number): Observable<{ success: boolean, deleted: number }> => {
    return this.httpClient.delete<{ success: boolean, deleted: number }>(
      createUrl(this.path, id),
      {
        params: {
          customerId: String(customerId)
        }
      }
    );
  };

}
