<div class="modal-content">
	<div class="modal-header">
		<h1 class="modal-title" id="bulkOrderLabel">Bulk order</h1>
		<button type="button" (click)="dismiss()" class="btn btn-secondary-icon" data-bs-dismiss="modal" aria-label="Close">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="order-bulk">
			<div class="order-md-img-content">
				<div class="order-img">
					<img src="{{ product.imageUrl }}" alt="Bulk-product" />
				</div>
				<div class="content d-lg-none">
					<h1 class="title">{{ product.name }}</h1>
					<p class="price">
						${{ product ? utils.twoDecimalPlacesString(product.price) : "" }}
					</p>
				</div>
			</div>
			<div class="order-content">
				<h1 class="title d-none d-lg-block">
					{{ product.name }}
				</h1>
				<p class="price d-none d-lg-block">
					${{ product ? utils.twoDecimalPlacesString(product.price) : "" }}
				</p>
				<div class="form-group">
					<label for="Colour" class="form-label">Colour</label>
					<select #color class="form-select" aria-label="Default select example" id="Colour" (change)="
							currColor = color.value; pushChain({ Colour: color.value }, false)
						" [disabled]="isEdit">
						<option selected>Choose Color</option>
						<option *ngFor="let selectColor of cartItem.getUniqueColours()"
							[selected]="isEdit && currentVarient.Colour == selectColor" [value]="selectColor">
							{{ selectColor || "Default" | titlecase }}
						</option>
					</select>
				</div>

				<div *ngIf="currentVarient">
					<div class="form-group" *ngIf="product.packSizes.length">
						<label for="Colour" class="form-label">Packet Type/Size</label>
						<select #packSize class="form-select" (change)="
								currentVarient.Pack = selectPackSize(packSize.value);
								packSize.value = 'Select Pack'
							" id="Colour">
							<option selected>Select Pack</option>
							<option *ngFor="let unit of product.packSizes" [value]="unit.name" [selected]="
									isEdit &&
									currentVarient.Pack &&
									currentVarient.Pack.name == unit.name
								">
								{{ unit.name | titlecase }}:
								{{ unit.itemCount }}
							</option>
						</select>
					</div>
					<div class="form-group" *ngIf="product.packSizes.length && currentVarient.Pack">
						<input type="text" class="form-control" id="Type/Size" value="{{ currentVarient.Pack.name }} {{
								currentVarient.Pack.itemCount
							}}" readonly />
					</div>

					<div class="form-group" *ngIf="
							product.decorations &&
							product.decorations.length &&
							currentVarient.selectableDecorations?.length
						">
						<label for="Colour" class="form-label">Decoration</label>
						<select #decoration class="form-select" aria-label="Default select example" id="Colour" (change)="
								addDecorationToCart(
									decoration.value,
									currentVarient,
									true,
									false
								);
								decoration.value = 'Select decoration option'
							">
							<option selected>Select decoration option</option>
							<option *ngFor="let decoration of currentVarient.selectableDecorations" [value]="decoration.id">
								{{ decoration.name }} - ${{
								utils.twoDecimalPlacesString(decoration.price)
								}}/ea
							</option>
						</select>
					</div>
					<div class="edit-card card-body decoration-collapse" *ngIf="
							product.decorations &&
							product.decorations.length &&
							currentVarient.Decorations
						">
						<ul>
							<li *ngFor="
									let decoration of currentVarient.Decorations;
									let idx = index
								">
								<p>
									<span>
										<span>+${{ utils.twoDecimalPlacesString(decoration.price) }}/ea
											-</span>
										{{ decoration.name }}
										<span class="badge bg-red-25 ps-3 py-1" *ngIf="decoration.isRequired">
											- Required
										</span>
									</span>
									<button *ngIf="!decoration.isRequired" (click)="removeBulkDecoration(currentVarient, idx)"
										class="btn btn-secondary-icon shadow-none p-0 rounded-0 ms-auto" type="button">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5"
													stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
								</p>
								<div class="form-group" *ngIf="decoration.options && decoration.options.length">
									<div *ngFor="let option of decoration.options">
										<label id="clientRequired {{ option.value }}" class="sele_color"
											*ngIf="option.name && option.name.length">{{ option.name }}
											<span *ngIf="option.required"> - Required</span></label>
										<input type="text" class="form-control" id="clientRequired {{ option.value }}"
											placeholder="{{ option.name }}" [required]="option.required" [(ngModel)]="option.value" />
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div class="form-group" *ngIf="currentVarient.Items?.length">
						<label for="Colour" class="form-label">Quantity & Size</label>
						<div class="order-size-qty">
							<div class="form-check ps-0" *ngFor="let item of currentVarient.Items; let j = index">
								<input class="form-check-input d-none" type="radio" name="size" value="{{ item.Size }}"
									id="sizeQTY {{item.Size}}" />
								<label class="form-check-label" for="sizeQTY {{item.Size}}">
									{{ item.Size }}
									<div class="qty-input">
										<button (click)="item.Units > 0 && (item.Units = item.Units - 1)" class="btn qty-count p-0"
											data-action="minus" type="button">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round"></path>
												</svg>
											</span>
										</button>
										<input class="product-qty" type="number" value="1" name="product-qty" #val [(ngModel)]="item.Units"
											appNoNegative />
										<button (click)="item.Units = item.Units + 1" class="btn qty-count p-0" data-action="add"
											type="button">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M12 5V19M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round"></path>
												</svg>
											</span>
										</button>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button class="btn btn-primary w-sm-100 d-none" type="button" (click)="
					pushChain({ Colour: color.value }, true); color.value = 'Choose Color'
				">
				{{ isEdit ? "Update" : "Add" }} Product
			</button>
		</div>
	</div>
	<div class="modal-footer d-block border-0">
		<div class="count-add m-0">
			<h4 class="added-item">
				ADDED PRODUCTS
				<span class="badge bg-white">{{ bulkOrderData.length }}</span>
			</h4>
		</div>
		<!-- added-product-card start here... -->
		<div
			class="add-product d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between gap-4"
			*ngFor="let bulkdata of bulkOrderData; let i = index">
			<div class="img-content">
				<div class="card-img">
					<img [src]="bulkdata.imageUrl" alt="Bulk-Product" />
				</div>
				<div class="content">
					<h4 class="title">{{ product.name }}</h4>
					<p class="price">
						${{ utils.twoDecimalPlacesString(product.price) }}
					</p>

					<p class="color">
						<span [style.background]="generateGradient( bulkdata.Colour )"> </span>
						{{ bulkdata.Colour }}
					</p>
					<div class="sizes">
						<h5 *ngFor="let item of bulkdata.Items; let j = index">
							{{ item.Size }}
							<span>x {{ item.Units }}</span>
						</h5>
						<h5>
							<span class="color mb-0" [style.background]="generateGradient( bulkdata.Colour )"></span>
							{{ bulkdata.Colour || "Default" }}
						</h5>
					</div>
				</div>
			</div>
			<div class="btns d-flex align-items-center gap-2">
				<button (click)="isEdit = true; currentVarient = bulkdata" class="btn btn-secondary d-none" type="button">
					<span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path
								d="M13.75 3.25023C13.947 3.05324 14.1808 2.89699 14.4382 2.79038C14.6956 2.68378 14.9714 2.62891 15.25 2.62891C15.5286 2.62891 15.8044 2.68378 16.0618 2.79038C16.3192 2.89699 16.553 3.05324 16.75 3.25023C16.947 3.44721 17.1032 3.68106 17.2098 3.93843C17.3165 4.1958 17.3713 4.47165 17.3713 4.75023C17.3713 5.0288 17.3165 5.30465 17.2098 5.56202C17.1032 5.81939 16.947 6.05324 16.75 6.25023L6.625 16.3752L2.5 17.5002L3.625 13.3752L13.75 3.25023Z"
								stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg></span>
					Edit
				</button>
				<button (click)="spliceChain(i); this.currentVarient = {};" class="btn btn-red" type="button">
					<span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M3.25 5.5H4.75H16.75" stroke="white" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path
								d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
								stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg></span>
					Remove
				</button>
			</div>
		</div>
		<!-- added-product-card end here... -->
		<div class="mt-4 mb-0 mx-0">
			<button [disabled]="isLoading" class="btn btn-primary w-100" type="button" (click)="pushBulkOrder($event)">
				<span *ngIf="!isLoading"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg></span>
				<span *ngIf="!isLoading">Add to cart</span>
				<div class="spinner-border" role="status" *ngIf="isLoading">
					<span class="visually-hidden">Loading</span>
				</div>
			</button>
		</div>
	</div>
</div>