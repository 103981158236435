import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { ReportApi } from '../api/report.api';
import { ICustomerUser } from '../model/customer.user.model';
import { IBusinessUnit } from '../model/businessUnit.model';
import { INewUser } from '../model/user.model';

export type ICustomerUserOptionalUser = ICustomerUser & { user?: INewUser };
export type IBusinessUnitOptionalContact = IBusinessUnit & { contact?: ICustomerUserOptionalUser };
export type IGetBusinessUnitReport = IBusinessUnitOptionalContact[];

@Injectable()
export class ReportService {
  constructor(
    private notifications: NotificationsService,
    private reportApi: ReportApi,
  ) {
  }


  public getUserAllocationReport(query: { customerId?: number | string }): Observable<QueryResult<ICustomerUser & HasId>> {
    return apiCallWrapper(
      this.reportApi.getUserAllocationReport(query),
      {
        notificationsService: this.notifications,
        action: "Fetching User Allocation Report"
      }
    )
  }

  public getUserListReport(query: { customerId?: number | string }): Observable<QueryResult<ICustomerUser & HasId>> {
    return apiCallWrapper(
      this.reportApi.getUserListReport(query),
      {
        notificationsService: this.notifications,
        action: "Fetching User List Report"
      }
    )
  }

  public getOrderListReport(query: { customerId?: number | string | null | undefined }): Observable<QueryResult<ICustomerUser & HasId>> {
    return apiCallWrapper(
      this.reportApi.getOrderListReport(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Order List Report"
      }
    )
  }

  public getBusinessUnitReport(query: { customerId?: number | string }): Observable<IGetBusinessUnitReport> {
    return apiCallWrapper(
      this.reportApi.getBusinessUnitReport(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Business Unit Report"
      }
    )
  }

  public getSalesReport(query: { customerId?: number | string }) {
    return apiCallWrapper(
      this.reportApi.getSalesReport(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Sales Report"
      }
    )
  }

  public getOverspendReport(query: { customerId?: number | string | null | undefined }) {
    return apiCallWrapper(
      this.reportApi.getOverspendReport(query),
      {
        notificationsService: this.notifications,
        action: "Fetching OverSpend Report"
      }
    )
  }

  public getStaleProductAdminReport() {
    return apiCallWrapper(
      this.reportApi.getStaleProductAdminReport(),
      {
        notificationsService: this.notifications,
        action: "Fetching Stale Product Report"
      }
    )
  }

  public getAbnormalCartAdminReport() {
    return apiCallWrapper(
      this.reportApi.getAbnormalCartAdminReport(),
      {
        notificationsService: this.notifications,
        action: "Fetching Abnormal Carts"
      }
    )
  }

  public createCSV(opts: { data: any, type: string }) {
    return apiCallWrapper(
      this.reportApi.createCSV(opts),
      {
        notificationsService: this.notifications,
        action: "Your report is preparing."
      }
    )
  }

}
