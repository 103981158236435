import { Component, Input, OnDestroy } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { logger } from "../util/Logger";

type ModalButton<T = unknown> = { text: string, action: 'dismissAll' | 'close', value?: T, class?: string };

const className = 'ModalComponent';

@Component({
	selector: 'modal',
	template: `
            <div class="modal-header">
            <h1 class="modal-title" id="twoFactorauthLabel">{{title}}</h1>
            <button (click)="dismissAll()" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal" aria-label="Close">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <p class="desc" [innerHTML]="data">
              </p>
            </div>
            <div class="btns d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-25">
              <button *ngFor="let button of buttons" type="button" class="btn {{button.class || 'btn-secondary'}} w-sm-100" (click)="buttonAction(button)">
                {{button.text}}
              </button>
            </div>
          </div>
    `
})
export class ModalComponent implements OnDestroy {
	@Input() data: any;
	@Input() title: string = 'Your report is ready.';
	@Input() buttons: ModalButton[] = [{
		text: 'Close',
		action: 'dismissAll'
	}]

	constructor(
		private ngbModal: NgbModal,
		private ngbActiveModal: NgbActiveModal
	) { }

	buttonAction(button: ModalButton) {
		const signature = className + '.buttonAction: ';
		if (typeof button.action === 'string') {
			switch (button.action) {
				case 'dismissAll': {
					this.ngbModal.dismissAll();
					return;
				}
				case 'close': {
					this.ngbActiveModal.close(typeof button.value !== 'undefined' ? button.value : button.text);
					return;
				}
				default:
					logger.error(signature + `Unknown ButtonAction[${button.action}]`);
			}
		}

		logger.error(signature + `Unsure how to handle ButtonAction with Type[${typeof button.action}]`);
	}

	ngOnDestroy(): void {
		this.ngbModal.dismissAll();
	}

	dismissAll() {
		this.ngbModal.dismissAll();
	}
}
