import { BlankAPISuccess } from "./generics";

export enum HeaderTokenEnum {
  NoToken = 'NoToken',
  Bearer = "Bearer"
}

export enum AuthStorageKey {
  jwt = 'jwt',
  jwt_refresh = 'jwt_refresh',
  jwt_token_type = 'jwt_token_type',
}

export interface IJWTPayload {
  accessToken: string;
  refreshToken: string;
  tokenType: "Bearer"
}

export interface IJWTPayloadCode {
  status: boolean
}

export interface IJWTPayloadDecoded {
  userId: string;
  customerId?: number;
  emulateUser?: number;
  emulateCustomerUserId?: number;
  iat: number;
  exp: number;
}

export interface IAuthPayload {
  email: string,
  password: string,
}

export type IForgotPayload = {
  email: string;
}

export type IForgotResponse = BlankAPISuccess;

export type IResetPayload = {
  resetToken: string;
  email: string;
  password: string;
}

export type IResetResponse = IJWTPayload;

export class IWellFormattedError {
  message: string;
  success: boolean;
  errorCode: number;
  detail?: string[];
}