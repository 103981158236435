<div class="size-card card">
	<div class="card-body">
		<div class="row-chart">
			<div class="adds justify-content-start">
				<h4 class="head-title">Size</h4>
			</div>
			<div
				class="adds align-items-start"
				*ngFor="let column of sizeChart.columnsOrdered; let i = index"
			>
				<div class="form-group">
					<input
						type="text"
						class="form-control mb-3"
						name="title{{ i }}"
						id="title{{ i }}"
						placeholder="Title"
						[(ngModel)]="column.title"
					/>
					<input
						type="text"
						class="form-control"
						name="subtitle{{ i }}"
						id="subtitle{{ i }}"
						placeholder="Subtitle"
						[(ngModel)]="column.subtitle"
					/>
				</div>
				<button
					class="btn btn-secondary-icon"
					type="button"
					(click)="removeSizeColumn(column)"
				>
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M3.25 5.5H4.75H16.75"
								stroke="#272835"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
							<path
								d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
								stroke="#272835"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</span>
				</button>
			</div>
			<div class="adds">
				<button (click)="addColumn()" class="btn btn-secondary w-sm-100" type="button">
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M10 4.75V15.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M4.75 10H15.25"
								stroke="#272835"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
					Add column
				</button>
			</div>
		</div>
		<div
			class="row-chart"
			*ngFor="
				let data of sizeChart.rowsOrdered;
				let rowIdx = index;
				first as isFirst;
				last as isLast
			"
		>
			<div class="adds justify-content-start">
				<h4 class="head-title">{{ data.row.name }}</h4>
			</div>
			<div class="adds" *ngFor="let cell of data.cells; let colIdx = index">
				<div class="form-group">
					<input
						type="text"
						class="form-control"
						name="cell"
						id="cellValue"
						placeholder="Add Text"
						[(ngModel)]="cell.value"
					/>
				</div>
			</div>
			<div class="adds">
				<div class="d-flex align-items-center justify-content-end gap-3">
					<button
						(click)="moveUp(data.row)"
						*ngIf="!isFirst"
						class="btn btn-secondary-icon"
						type="button"
					>
						<span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M13.6004 11.8002L10.0004 8.2002L6.40039 11.8002"
									stroke="#272835"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</span>
					</button>
					<button
						(click)="removeRow(data.row)"
						class="btn btn-secondary-icon"
						type="button"
					>
						<span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M3.25 5.5H4.75H16.75"
									stroke="#272835"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								></path>
								<path
									d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
									stroke="#272835"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								></path>
							</svg>
						</span>
					</button>
					<button
						(click)="moveDown(data.row)"
						*ngIf="!isLast"
						class="btn btn-secondary-icon"
						type="button"
					>
						<span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M6.40039 8.2002L10.0004 11.8002L13.6004 8.2002"
									stroke="#272835"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</span>
					</button>
				</div>
			</div>
		</div>
		<div class="row-chart">
			<div class="adds">
				<div class="form-group">
					<input
						type="text"
						class="form-control"
						name="newSize"
						id="newSizeName"
						placeholder="Add Text"
						[(ngModel)]="newSizeName"
					/>
				</div>
			</div>
			<div class="adds">
				<button
					class="btn btn-secondary w-sm-100"
					type="button"
					(click)="addRow(newSizeName)"
				>
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M10 4.75V15.25"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M4.75 10H15.25"
								stroke="#272835"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
					Add row
				</button>
			</div>
		</div>
	</div>
</div>
