import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { filter, of, tap } from "rxjs";
import { logger } from "src/app/main/util/Logger";
import { ICustomUserField } from '../../../model/custom.user.fileld.model';
import { CustomerUserService } from "src/app/main/services/customerUser.service";
import { CartService } from "src/app/main/services/cart.service";

const className = 'CustomUserFieldComponent';

@Component({
  selector: 'custom-user-field',
  styles: [],
  template: `
  <div class="row" *ngIf="customeFields && customeFields.length">
<div class="col-12">
	<h5 class="form-title" *ngIf="!isCheckout">Custom Field</h5>
	<h5 class="form-title" *ngIf="isCheckout">Client Information</h5>
</div>
 <ng-container *ngFor="let inputSet of customeFields; let i = index">
    <div class="col-12 col-md-6" *ngIf="!isCheckout || i < 2">
      <div class="form-group">
        <label class="form-label">{{inputSet.fieldName}} <span class="text-danger"
						*ngIf="isCheckout && inputSet.fieldRequired">*</span></label>
        <input [(ngModel)]="inputValues[i]" [type]="inputSet.fieldType" class="form-control" (keydown)="onChange()" (change)="onChange()"
        [ngStyle]="{'border': isCheckout && inputSet.fieldRequired && (!inputValues[i] || !inputValues[i].trim()) ? '1px solid red' : ''}" >
      </div>
    </div>
  </ng-container>
<div class="col-lg-12">
<button (click)="onSaveClick()" *ngIf="!isCheckout" class="btn btn-primary">Save Custom Fields</button>
</div>
</div>
	`
})
export class CustomUserFieldComponent implements OnInit {
  inputValues: any[] = [];
  public customeFields: ICustomUserField[] = [];

  @Input()
  public customerId: number | undefined | null = null;

  @Input()
  public customerUserId: number | null = null;

  @Input()
  public isCheckout: boolean | undefined;
  public isDirty = false;

  constructor(
    private readonly customerUserService: CustomerUserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public notifications: NotificationsService,
    private cartService: CartService
  ) {
  }

  ngOnInit() {
    this.getFields();
  }

  getFields() {
    if (this.customerUserId && this.customerId) {
      if (!this.isCheckout) {
        this.customerUserService.getFields(this.customerUserId).subscribe({
          next: res => {
            this.customeFields = res;
            this.inputValues = res.map(userField => {
              if (!userField.customerUserValues || !userField.customerUserValues.length) return '';

              return userField.customerUserValues[0].fieldValue || '';
            });

            this.changeDetectorRef.detectChanges();
          },
          error: err => {
            console.log(err, "errr")
          }
        });
      } else {
        this.cartService.getFields(this.customerId).subscribe({
          next: res => {
            this.customeFields = res;
            this.inputValues = res.map(orderField => {
              if (!orderField.orderValues || !orderField.orderValues.length) return '';

              return orderField.orderValues[0].fieldValue || '';
            });

            this.changeDetectorRef.detectChanges();
          },
          error: err => {
            console.log(err, "errr")
          }
        });
      }
    }
  }

  onChange() {
    this.isDirty = true;
  }

  onSaveClick() {
    const signature = className + '.onSaveClick: ';
    if (!this.isDirty) {
      logger.info(`${signature}Not saving data due to no changes detected`);
      return of(null);
    }

    this.save()?.pipe(
      filter(resp => !!resp)
    ).subscribe({
      next: res => {
        this.notifications.success('Saving User Values', 'Custom values saved');
      },
      error: err => {
        console.log(err, "errr")
      }
    })

  }

  save() {
    const signature = className + '.save: ';

    if (!this.isDirty) {
      logger.info(`${signature}Not saving data due to no changes detected`);
      return of(null);
    }

    const formattedInputValues = this.customeFields.map((inputSet, index) => {
      const inputValue = this.inputValues[index];
      if (inputValue) {
        return { [inputSet.id]: inputValue };
      }
      return null;
    }).filter(item => item !== null);

    if (this.customerUserId && this.customerId) {
      const genratedApi = this.isCheckout ? this.cartService.postOrderFieldValues(formattedInputValues, this.customerId) : this.customerUserService.postFieldValues(formattedInputValues, this.customerUserId);
      return genratedApi.pipe(
        tap(() => {
          this.isDirty = false;
          this.getFields();
        })
      )
    }
  }

  areAllFieldsFilled() {
    const areFieldsFilled = this.customeFields.slice(0, 2).every((field, index) => {
      return !field.fieldRequired || (this.inputValues[index] && this.inputValues[index].trim() !== '');
    });
    return areFieldsFilled;
  }
}
