import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from '../../services/report.service'
import { logger } from '../../util/Logger';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { CartStatus } from '../../model/cart.model';
import moment from 'moment-mini';
import { isNumber } from 'util';
import { IQueryFilter } from '../../model/query.filter.class';
import { CustomerUserService } from '../../services/customerUser.service';
import { ICustomerUser } from '../../model/customer.user.model';
import { SessionApi } from '../../api/session.api';
import { RolesApi } from '../../api/roles.api';
const className = 'OverspendComponent';


type OrdersReportForm = {
  status: { [key: string]: string; };
  approverId: number | null;
};

@Component({
  selector: 'app-overspend',
  templateUrl: './overspend.component.html'
})
export class OverspendComponent {
  private updateOrders$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public customerUsers: ICustomerUser[];
  public pathBase = '/account/reports';
  public customerId: number | null = null;
  public reportData: any = []
  public initialReportData: any = []
  public orderStatuses = Object.keys(CartStatus)
    .filter(status => status.toLowerCase() !== 'pending');

  public ordersReportForm: OrdersReportForm = {
    status: this.orderStatuses.reduce((self, val) => {
      self[val] = true;
      return self;
    }, {}),
    approverId: null
  };
  public permissions = "order_approver";
  public customerQuery = new IQueryFilter({
    limit: 1000,
    filter: {
      customerId: this.customerId
    },
    include: [{
      association: 'user',
      required: true
    }],
  });
  name: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    public reportService: ReportService,
    private customerUserService: CustomerUserService,
    private session: SessionApi,
    private userRoleApi: RolesApi
  ) {
    if (this.session.$userData.value?.isAdmin) {
      this.pathBase = '/manage/reports';
    }
  }

  ngOnInit() {
    this.customerId = this.activatedRoute.snapshot.queryParams.customerId || null;
    this.name = this.activatedRoute.snapshot.queryParams.name || null;

    this.updateOrders$.pipe(
      debounceTime(250),
    ).subscribe(() => {
      this.loadOrders();
    })

    this.loadCustomerUsers();
  }

  getOrderDate(orderedAt: string | null | undefined): string {
    if (!orderedAt) { return ''; }

    return moment(orderedAt).local().format('DD MMM, YYYY');
  }
  /**
   * @description Exports the current contents of reportData to CSV
   */
  downloadFile() {
    const reportParams = this.getReportParams();
    this.reportService.createCSV({ data: reportParams, type: 'overSpend' }).subscribe();
  }

  getReportParams() {
    const filterStatus = Object.keys(this.ordersReportForm.status)
      .filter(status => !!this.ordersReportForm.status[status])
      .join(",");

    const reportParams: { customerId: number | null | undefined, status: string, approverId?: number } = {
      customerId: this.customerId,
      approverId: this.ordersReportForm.approverId || undefined,
      status: filterStatus
    };

    if (!reportParams.approverId) delete reportParams.approverId;

    return reportParams;
  }

  HTMLToText(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  loadOrders() {
    const reportParams = this.getReportParams();

    this.reportService.getOverspendReport(reportParams).subscribe(data => {
      this.initialReportData = data;
      this.reportData = data;
    });
  }

  filterChange() {
    this.updateOrders$.next(true);
  }

  async loadCustomerUsers() {
    await Promise.all([
      this.fetchRoleIds(),
    ]);
    this.customerQuery.filter['customerId'] = this.customerId;
    this.customerUserService.list(this.customerQuery).subscribe(res => {
      this.customerUsers = res.rows;
    });
  }


  /**
* Populates the roleIds property (if applicable) with target user roles
*/
  private fetchRoleIds = async () => {
    if (!this.permissions || !this.permissions.length)
      return;

    return new Promise<void>((resolve, reject) => {
      this.userRoleApi.rolesWithPermission(...this.permissions.split(",")).subscribe(roles => {
        const roleIds = roles.map(role => role.id);
        if (roleIds && roleIds.length) {
          this.customerQuery.filter['$or'] = roleIds.map(roleId => ({ userRoleId: roleId }));
        }
        resolve();
      });
    })
  }

  submitValues(min: string | number | null, max: string | number | null): void {
    const signature = className + ".submitValues: ";
    this.reportData = this.initialReportData;

    const minValue = min ? Number(min) : null;
    const maxValue = max ? Number(max) : null;

    if (isNumber(minValue) && !isNaN(minValue)) {
      logger.debug(signature + `Filtering by MinValue[${minValue}]`);
      console.log(this.reportData);
      this.reportData = this.reportData.filter(order => order.overspend >= minValue);
    }

    if (isNumber(maxValue) && !isNaN(maxValue)) {
      logger.debug(signature + `Filtering by MaxValue[${maxValue}]`);
      this.reportData = this.reportData.filter(order => order.overspend <= maxValue);
    }

    logger.debug(signature + `Found[${this.reportData.length}] matching records from Total[${this.initialReportData.length}]`);
  }

}
