import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomerMerge } from '../model/customerMerge.model';

// Convenience Type
type T = ICustomerMerge;

@Injectable()
export class CustomerMergeApi extends GenericApi<T> {
  public path = "customer-merge";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
