import { Component, ViewChild } from '@angular/core';
import { IRole, IRoleUserCustomer } from '../../model/role.model';
import { RolesApi } from '../../api/roles.api';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { has } from 'lodash';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { CustomerService } from '../../services/customer.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Observed } from 'src/app/types';
import { logger } from '../../util/Logger';
import { NotificationsService } from 'angular2-notifications';

type Permission = {
	description: string;
	permission: string;
	checked?: boolean;
	implies?: string[];
}

type PermissionOptions = {
	[categoryLabel: string]: Permission[]
};

const className = 'UserRoleEditComponent';

@Component({
	selector: 'app-user-role-edit',
	templateUrl: './user-role-edit.component.html',
	styleUrls: []
})
export class UserRoleEditComponent {
	// Holds customer details for the customer list panel
	private existingCustomerList: Observed<ReturnType<CustomerService['list']>>['rows'] = [];
	private lastCustomerListResult: Observed<ReturnType<CustomerService['list']>> = new QueryResult();

	customerSelectOptions: { id: string, text: string }[];
	selectedCustomerOption: string;
	getOptionName = (category: string, option: Permission): string => `${category}-option-${option.permission}`;

	/**
	 * General
	 */
	model: IRole = {
		name: '',
		permissions: '',
		customers: [],
		accessMode: 0,
		id: 0
	};


	@ViewChild('customer') selectCustomer: NgSelectComponent;
	public customerQuery: IQueryFilter = new IQueryFilter({
		sortBy: 'name',
		limit: 10
	});
	private searchTerms: Subject<string> = new Subject<string>();
	public noCustomerFoundText: string;
	readonly options: PermissionOptions = {
		General: [
			{ permission: "always_edit_addresses", description: "User can always edit addresses in the account, even when disabled in account options" },
			{ permission: "edit_account_users", description: "User can edit users and guests in the account (Automatically grants List Account Users)", implies: ['list_account_users'] },
			{ permission: "list_account_users", description: "User view a list of account users and their allocation balance" },
			{ permission: "switch_account_users", description: "User can assume the identity of another user in the account (Automatically grants List Account Users)", implies: ['list_account_users'] },
			{ permission: "edit_business_units", description: "User can edit business units in the account" },
		],
		Products: [
			{ permission: "product_access", description: "User can view products available to the account" },
			// { permission: "view_own_favourites", description: "User can create and update their own favourites lists" },
		],
		User: [
			{ permission: "update_own_detail", description: "User can update their own details such as Name, Email, Contact Number" },
			{ permission: "edit_shipping_address", description: "User may enter an arbitrary shipping address (Automatically grants Select Account Address)", implies: ['select_account_address'] },
			{ permission: "select_account_address", description: "User may select an address from the account addresses" },
			{ permission: "modify_user_groups", description: "User can add or edit into groups" },
			{ permission: "list_document_access", description: "User can list and view documents" },
			{ permission: "edit_document_access", description: "User can add or edit documents" },
			// { permission: "update_own_address", description: "User can update their own address" },
		],
		Orders: [
			// { permission: "update_own_shipping", description: "User can update their own shipping addresses" },
			// { permission: "view_own_orders", description: "User can see their own previous orders" },
			{ permission: "cart_access", description: "User has a cart and can add items into it" },
			{ permission: "order_approver", description: "User can approve orders of other users of the account" },
			{ permission: "order_approval", description: "User can be assigned an order approver" },
			{ permission: "user_allocation", description: "User can be given a user allocation of collections and fixed values" },
			{ permission: "payment_gateway_access_always", description: "User can always use the payment gateway" },
			{ permission: "account_orders", description: "User can see orders across the entire account, not just their own" }
		],
		Reports: [
			{ permission: "report_allocation", description: "User can report on user allocations across the entire account" },
			{ permission: "report_user_list", description: "User can report on a complete user list across the entire account" },
			{ permission: "report_order", description: "User can report on a complete order list across the entire account" },
			{ permission: "report_business_unit", description: "User can report on a complete business unit list across the entire account" },
			{ permission: "report_overspend", description: "User can report on a overspand report" }
		]
	}

	readonly optionCategories = Object.keys(this.options).filter(key => !!this.options[key].length);
	public activeClassId: number = 1;
	constructor(
		private modelApi: RolesApi,
		private route: ActivatedRoute,
		private customerService: CustomerService,
		public router: Router,
		public notifications: NotificationsService
	) { }

	ngOnInit() {
		this.handleCustomerListGet();
		this.searchCustomer();
		this.route.params.subscribe(params => {
			if (has(params, 'id')) {
				this.model.id = params.id;

				this.loadModel(this.model.id);
			}
		});

	}

	/**
 * @description Turns response from Customer Get into options for select2
 *
 * @param {QueryResult<HasId & NewCustomer>} result
 */
	handleCustomerListGet = (isScroll: boolean = false) => {
		this.noCustomerFoundText = 'Fetching...';
		if (isScroll) {
			this.customerQuery.limit = this.customerQuery.limit + 10;
		}
		this.customerService.list(this.customerQuery)
			.subscribe((result) => {
				this.lastCustomerListResult = result;

				if (result.rows.length == 0) {
					this.noCustomerFoundText = "No customer found";
				}
				this.customerSelectOptions = [
					...result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name }))
				];
			})

	}

	isSelectedCustomer(idStr: string) {
		const idInt = Number(idStr);

		if (Number.isNaN(idInt)) {
			return false;
		}

		return !!(this.model.customers || []).find(c => c.customerId === idInt);
	}

	customerChange() {
		this.selectedCustomerOption = this.selectCustomer.selectedValues.length && this.selectCustomer.selectedValues[0].id;
	}


	/**
	 * @description Loads the existing product data from the database
	 */
	loadModel(id: number) {
		this.modelApi.get(id)
			.subscribe((model: IRole) => {
				this.model = model;
				this.afterLoad();
			});
	}

	/**
 * @description The opposite of beforeSave - Performs any pre-possing that needs to be done on the model after it is loaded
 */
	afterLoad() {
		const permissionCodes = (this.model.permissions || "").split(",");
		const allCategories = this.optionCategories.map(category => this.options[category]);
		const allPermissions: Permission[] = allCategories.reduce((acc, val) => acc.concat(val), []);

		permissionCodes.forEach(code => {
			const foundPermission = allPermissions.find(obj => obj.permission === code);
			if (!!foundPermission) {
				foundPermission.checked = true;
			}
		});

		// Fetch customers that are already attached to the role
		const allCustomerIds = (this.model.customers || []).map(c => c.customerId);
		if (allCustomerIds && allCustomerIds.length) {
			this.customerService.list(new IQueryFilter({ limit: 1000, filter: { id: { $in: allCustomerIds } } })).subscribe(result => {
				this.existingCustomerList = result.rows;
			});
		}
	}

	/**
 * @description Takes the temporarily stored selected customer ID and adds it to the group customers
 */
	addSelectedCustomer = () => {
		const signature = className
		if (!this.selectedCustomerOption || !this.selectedCustomerOption.length)
			return;

		if (!this.model.customers)
			this.model.customers = [];

		const selectedCustomerId = Number(this.selectedCustomerOption);

		if (Number.isNaN(selectedCustomerId)) {
			logger.error(`${signature}selectedCustomerId[${selectedCustomerId}] is not a number`);
			this.selectCustomer.handleClearClick();
			this.selectedCustomerOption = "";
			return;
		}

		const selectedCustomer = this.lastCustomerListResult.rows.find(c => c.id === selectedCustomerId);

		if (!selectedCustomer) {
			logger.error(`${signature}unable to find selectedCustomerId[${selectedCustomerId}]`);
			this.selectCustomer.handleClearClick();
			this.selectedCustomerOption = "";
			return;
		}

		const existingModelCustomer = this.model.customers.find(customer => customer.customerId === selectedCustomerId);

		if (existingModelCustomer) {
			logger.warn(`${signature}selectedCustomerId[${selectedCustomerId}] has already been selected`);
			this.selectCustomer.handleClearClick();
			this.selectedCustomerOption = "";
			return;
		}

		this.model.customers.push({ customerId: selectedCustomerId });

		// Performs a shallow copy of the model
		this.existingCustomerList.push({ ...selectedCustomer });
		this.selectCustomer.handleClearClick();
	}

	searchCustomer() {
		this.searchTerms.pipe(
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(searchTerm => {
			this.customerQuery.filter.name = { $like: '%' + searchTerm + '%' };
			this.handleCustomerListGet();
		});
	}

	onCustomerSearch(searchTerm: { term: string; items: any[]; }) {
		this.searchTerms.next(searchTerm.term);
	}

	/**
 * @description Gets the dispay text for any given customer
 *
 * @param modelCustomer
 */
	getCustomerName(modelCustomer: { customerId: number }) {
		const existingCustomer = this.existingCustomerList.find(c => c.id === modelCustomer.customerId);

		if (existingCustomer) {
			return existingCustomer.name;
		}
		if (!this.customerSelectOptions)
			return;

		let customerSelectOption = <any>this.customerSelectOptions.find(selectOption => {
			return selectOption.id === modelCustomer.customerId.toString()
		});

		if (!customerSelectOption)
			return "";

		return <any>customerSelectOption.text;
	}

	/**
 * @description Removes the selected customer from the list customers and re-indexes the
 *              customer array to maintain the existing order
 *
 * @param modelCustomer
 */
	removeCustomer(modelCustomer: IRoleUserCustomer) {
		if (this.model.customers) {
			for (let i = 0; i < this.model.customers.length; i++) {
				if (this.model.customers[i].customerId === modelCustomer.customerId) {
					this.model.customers.splice(i, 1);
					break;
				}
			}
		}
	}

	/**
 * @description Does any pre-processing that needs to be done on the model before it is saved.
 * Primarily, grants downstream permissions associated with any other permission
 */
	beforeSave() {
		const selectedPermissions: any = this.optionCategories
			.map(key => this.options[key].filter(option => !!option.checked))
			.reduce((accumulator: Permission[], val) => {
				accumulator.push(...val);
				return accumulator;
			}, [] as Permission[]);

		const impliedPermissions: any = selectedPermissions
			.filter(permission => permission.implies)
			.map(permission => permission.implies)
			.reduce((accumulator: string[], val) => {
				if (val) {
					accumulator.push(...val);
				}
				return accumulator;
			}, [] as string[]);

		this.model.permissions = []
			.concat(...selectedPermissions.map(permission => permission.permission))
			.concat(...impliedPermissions)
			.reduce((accumulator: string[], val) => {
				if (accumulator.indexOf(val) === -1) {
					accumulator.push(val);
				}
				return accumulator;
			}, [] as string[])
			.join(",");
	}

	/**
 * @description Validate and persist the product in the server, ignoring validating for Draft Products
 */
	saveModel() {
		this.activeClassId = this.activeClassId + 1;
		if (this.activeClassId == 7) {
			this.activeClassId = this.activeClassId - 1;
			if (this.model.name.trim().length < 3 || this.model.name.trim().length >= 50) {
				this.notifications.error('Error', 'User Role name must be between 3 and 50 characters long.');
				return;
			}
			this.beforeSave();
			const handler = this.model.id ? this.modelApi.update(this.model.id, this.model) : this.modelApi.create(this.model);

			handler.subscribe(() => {
				if (!this.model.id) {
					this.router.navigate(['/manage/userRoles']);
				}
			},
				err => {
					console.error(err);
				}
			);
		}
	}

	setActive(id: number) {
		this.activeClassId = id;
	}

}
