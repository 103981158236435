import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewCustomer } from '../model/ddb.model';
import { BusinessUnit, UnleashedCustomer } from '../model/unleashed.model';
import { Observable } from 'rxjs';
import { CustomerUserRolesInterface } from '../model/role.model';
import { createUrl, queryToParams } from './api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';

// Convenience Type
type T = NewCustomer;

@Injectable()
export class CustomerApi extends GenericApi<T & UnleashedCustomer> {
  public path = "customer";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  readonly getCustomerUserRolesList = (): Observable<CustomerUserRolesInterface[]> => {
    return this.httpClient.get<CustomerUserRolesInterface[]>(createUrl('customer_user_roles'));
  };

  readonly getBusinessUnit = (id: number, query: IQueryFilter): Observable<QueryResult<BusinessUnit & HasId<string>>> => {
    return this.httpClient.get<QueryResult<BusinessUnit & HasId<string>>>(
      createUrl('business_unit'),
      {
        params: queryToParams(query)
      }
    );
  };

  readonly deleteBusinessUnit = (customerId: number, id: number): Observable<{}> => {
    return this.httpClient.delete<{}>(
      createUrl(this.path, customerId, 'business_unit', id)
    );
  };

  public readonly getUserField = (id: number): any => {
    return this.httpClient.get<any>(
      createUrl(this.path, id, 'userField'),
    );
  };

  public readonly createUserField = (id: number, model: object): any => {
    return this.httpClient.post<any>(
      createUrl(this.path, id, 'userField'),
      model
    );
  };

  public readonly deleteUserField = (id: number): Observable<{ message: string }> => {
    return this.httpClient.get<{ message: string }>(
      createUrl(this.path, 'deleteuserField', id),
    );
  };

  delete = (id: number | string): Observable<{ success: boolean, deleted: number }> => {
    return this.httpClient.delete<{ success: boolean, deleted: number }>(
      createUrl(this.path, id)
    );
  }

}
