import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'src/app/main/services/auth.service';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat
} from "ngx-intl-tel-input";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: []
})
export class ContactUsComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean = false;
  captcha: boolean;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Australia];
  PhoneNumberFormat = PhoneNumberFormat;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public notifications: NotificationsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      setTimeout(() => {
        if (fragment === 'ourLocation') {
          const element = document.getElementById(fragment);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 100)
    });
    this.form = this.fb.group({
      firstname: ['', [Validators.required, noWhitespaceValidator]],
      lastname: ['', [Validators.required, noWhitespaceValidator]],
      username: ['', [Validators.required, Validators.email, noWhitespaceValidator]],
      mobile: [undefined, [Validators.required]],
      company: [''],
      generalDesc: ['']
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    if (this.form.valid && this.captcha) {
      const mobileNumber = this.form.value.mobile.e164Number || '';
      this.form.patchValue({
        mobile: mobileNumber
      });
      this.notifications.warn("Contact Us", 'Sending Your Request');
      this.authService.contactUs(this.form.value).subscribe(
        () => {
          this.captcha = false;
          this.notifications.success("Contact Us", 'Your message was sent');
          this.form.reset();
          grecaptcha.reset();
          this.isLoading = false;
        },
        (error) => {
          this.notifications.error("ERROR", error && error.detail && error.detail.message || error.message || {});
        }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  resolved(res: string) {
    if (res) {
      this.captcha = true;
    }
  }
}

export const noWhitespaceValidator: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
  if (control.value && control.value.trim().length === 0) {
    return { 'required': true };
  }
  return null;
};
