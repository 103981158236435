import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { has } from 'lodash';
import { Subject, Subscription, catchError, debounceTime, distinctUntilChanged, first, throwError } from 'rxjs';
import { KVArray } from 'src/app/main/model/shared.model';
import { Reminder, UnleashedCustomer, UnleashedCustomerListResult, UnleashedPaginationData } from 'src/app/main/model/unleashed.model';
import { CustomerService } from 'src/app/main/services/customer.service';
import { CustomerUserRoleService } from 'src/app/main/services/customerUserRole.service';
import { FileUploadService } from 'src/app/main/services/fileUpload.service';
import { UnleashedService } from 'src/app/main/services/unleashed.service';
import { v4 as uuid } from 'uuid';
import { Promise as promise } from '../../../util/object.util';
import { NotificationsService } from 'angular2-notifications';
import { IRequiredField } from 'src/app/main/model/ddb.model';

enum ReminderUnits {
	days = 'Days',
	weeks = 'Weeks',
	months = 'Months',
	years = 'Years',
}

@Component({
	selector: 'app-customer-overview-edit',
	templateUrl: './overview-edit.component.html',
	styleUrls: []
})
export class OverviewEditComponent implements OnInit {
	public AnyPurchaseOrderNumberPattern = '^.+$';
	public CustomPurchaseOrderNumberPattern = '^[0-9]+$';
	noItemsFoundText: string;
	public unleashedCustomerListResult: UnleashedCustomerListResult = new UnleashedCustomerListResult();
	public customer: UnleashedCustomer = new UnleashedCustomer();
	public customerGuid: string;
	private isAdmin: boolean = false;
	roles: any = [];
	reminderUnitsOptions: KVArray = [];
	isLoading = true;
	reminder: Reminder = new Reminder();
	warehouseList: Array<{ text: string, id: string }> = [];
	defaultWarehouse: string | null;
	page: number = 0;
	private searchTerms = new Subject<string>();
	private searchSubscription: Subscription;
	get handlingFeeDisplayText() {
		return (this.customer.HandlingFee || this.customer.HandlingFee == null) ? 'Fee on Purchases Below Threshold' : 'No  Small Order Fee';
	}

	@ViewChild('selectCustomer') selectCustomer: NgSelectComponent;
	public activeClassId: number = 1;
	iscustomerLoading: boolean = false;
	requiredField: IRequiredField = {
		contact: false,
		costAccount: false,
		employeNumber: false,
		jobTittle: false,
		businessUnit: false,
		startDate: false,
		address: false
	};
	constructor(
		public router: Router,
		public route: ActivatedRoute,
		private readonly customerService: CustomerService,
		private unleashedService: UnleashedService,
		private fileUploadService: FileUploadService,
		private readonly customerUserRoleService: CustomerUserRoleService,
		private notifications: NotificationsService
	) {
		this.searchSubscription = this.searchTerms.pipe(
			debounceTime(2000),
			distinctUntilChanged(),
		).subscribe(term => {
			this.searchUnleashedCustomers({ term: term, page: ++this.page });
		}, err => {
			console.error(err);
		});
	}


	public customerPurchasePattern = {
		purchaseOrderRequired: false,
		purchaseOrderPattern: '',
		purchaseOrderOption: ''
	};


	ngOnInit() {
		this.searchTerms.next('');

		this.reminderUnitsOptions = Object.keys(ReminderUnits).map(key => ({ key: key, value: ReminderUnits[key] }));

		this.customerUserRoleService.items
			.subscribe((roles) => {
				this.roles = roles;
			});
		this.route.params.subscribe(params => {
			this.customerGuid = params['customerId'];
			if (this.customerGuid) {

				this.customerService.getById(this.customerGuid)
					.subscribe(customer => {
						if (!customer.requiredField) {
							this.requiredField = {
								contact: false,
								costAccount: false,
								employeNumber: false,
								jobTittle: false,
								businessUnit: false,
								startDate: false,
								address: false
							}
						} else {
							this.requiredField = JSON.parse(customer.requiredField);
						}
						this.customer = Object.assign(new UnleashedCustomer(), customer);
						this.setPurchaseOrderConfig(this.customer.PurchaseOrderPattern);
					});
			}
		});

		this.unleashedService.getWarehouseList().subscribe(warehouseList => { this.setWarehouseList(warehouseList); });
	}

	processResults(data: any) {
		let result = { results: [{}], pagination: { more: false } };

		this.unleashedCustomerListResult.pagination = new UnleashedPaginationData(data['Pagination']);

		if (this.unleashedCustomerListResult.pagination.hasMorePages()) {
			result.pagination.more = true;
		}

		if (this.unleashedCustomerListResult.pagination.PageNumber === 1) {
			this.unleashedCustomerListResult.items = [];
		}

		data.Items.forEach((customer: UnleashedCustomer) => {
			this.unleashedCustomerListResult.items = [...this.unleashedCustomerListResult.items, customer];
			this.isLoading = false;
			result.results.push({
				id: customer.Guid,
				text: customer.CustomerName
			});
		});
	}

	enablePurchaseOrderNumber() {
		this.setPurchaseOrderConfig(this.customer.PurchaseOrderPattern || this.AnyPurchaseOrderNumberPattern);
	}

	enableCustomPurchaseOrderNumber() {
		this.setPurchaseOrderConfig(this.customer.PurchaseOrderPattern || this.CustomPurchaseOrderNumberPattern);
	}

	setPurchaseOrderConfig(pattern: string | null = this.AnyPurchaseOrderNumberPattern) {
		if (!pattern || !pattern.length || pattern === '.*') {
			this.customerPurchasePattern.purchaseOrderRequired = false;
			this.customerPurchasePattern.purchaseOrderOption = 'any';
			this.customerPurchasePattern.purchaseOrderPattern = '';
			return;
		}

		if (pattern === this.AnyPurchaseOrderNumberPattern) {
			this.customerPurchasePattern.purchaseOrderRequired = true;
			this.customerPurchasePattern.purchaseOrderOption = 'any';
			this.customerPurchasePattern.purchaseOrderPattern = pattern;
			return;
		}

		this.customerPurchasePattern.purchaseOrderRequired = true;
		this.customerPurchasePattern.purchaseOrderOption = 'custom';
		this.customerPurchasePattern.purchaseOrderPattern = pattern;
	}

	getRoles = () => this.roles;

	/* Searching and Selecting products from Unleashed */
	searchUnleashedCustomers(opts: any) {
		this.iscustomerLoading = true;
		this.noItemsFoundText = 'Fetching...'
		let $promise = new promise();
		this.unleashedService.getUnleashedCustomers(opts)
			.pipe(debounceTime(500), distinctUntilChanged())
			.subscribe((data): void => {
				if (data.Items.length == 0) {
					this.noItemsFoundText = 'No Items Found';
				}
				this.iscustomerLoading = false;
				let result = { results: [{}], pagination: { 'more': false } };

				this.unleashedCustomerListResult.pagination = new UnleashedPaginationData(data['Pagination']);

				if (this.unleashedCustomerListResult.pagination.hasMorePages()) {
					result.pagination.more = true;
				}

				if (this.unleashedCustomerListResult.pagination.PageNumber === 1) {
					this.unleashedCustomerListResult.items = [];
				}

				data.Items.forEach((customer: UnleashedCustomer) => {
					this.unleashedCustomerListResult.items = [...this.unleashedCustomerListResult.items, customer];
					this.isLoading = false;
					result.results.push({
						id: customer.Guid,
						text: customer.CustomerName
					});
				});

				$promise.success(result);
			},
				err => {
					this.noItemsFoundText = 'No Items Found';
					this.iscustomerLoading = false;
					$promise.failure(err);
				}
			);

		return $promise;
	}

	onSearch(event: { term: string }) {
		this.searchTerms.next(event.term);
	}

	changeSelectedProduct(val: string) {
		this.customer.AssignedProductsOnly = val == 'true' ? true : false;
	}


	/* Self Registration Functionality */
	updateRegistrationSecret(force: boolean = false) {
		if (this.customer.RegisterEnabled && (!this.customer.RegisterSecret || force)) {
			this.customer.RegisterSecret = uuid('v4');
		}
	}

	getRegisterURL(): string {
		let currentAbsoluteUrl = window.location.href;
		let currentRelativeUrl = this.router.url;
		let index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
		let baseUrl = currentAbsoluteUrl.substring(0, index);
		let callbackUrl = this.router.createUrlTree(['/register', (this.customer.RegisterSecret || '')]);

		return baseUrl + callbackUrl.toString();
	}

	addReminder() {
		if (!this.reminder.noOfUnit || !this.reminder.unit) {
			return;
		}

		this.reminder.noOfUnit = Number(this.reminder.noOfUnit);
		if (this.customer.id) {
			this.reminder.customerId = this.customer.id;
		}

		if (!this.customer.reminders) {
			this.customer.reminders = { rows: [] };
		}

		this.customer.reminders.rows.push(this.reminder);

		this.reminder = new Reminder();
	}

	removeReminder(i: number) {
		this.customer.reminders.rows.splice(i, 1);
	}

	syncCustomerFromUnleashed() {
		const Guid = this.selectCustomer.selectedValues[0] && this.selectCustomer.selectedValues[0];
		if (Guid && Guid.length) {
			const customer = this.unleashedCustomerListResult.items.find((c) => c.Guid == Guid);

			Object.keys(this.customer)
				.forEach(prop => {
					if (!customer?.hasOwnProperty(prop)) {
						return;
					}

					this.customer[prop] = customer[prop];
				});

			//@ts-ignore
			if (customer && customer.DiscountAmount) {
				//@ts-ignore
				const discountAmount = Number(customer.DiscountAmount);

				if (discountAmount && !isNaN(discountAmount)) {
					this.customer.discountAmount = discountAmount;
				}
			}
		} else {
			let imageUrl: string | undefined;
			if (this.customer.imageUrl) {
				imageUrl = this.customer.imageUrl;
			}
			this.customer = new UnleashedCustomer();
			if (imageUrl) {
				this.customer.imageUrl = imageUrl;
			}
		}
	}

	saveCustomer() {
		this.activeClassId = this.activeClassId + 1;
		if (this.activeClassId == 5) {
			this.customer.PurchaseOrderPattern = this.customerPurchasePattern.purchaseOrderRequired ? this.customerPurchasePattern.purchaseOrderPattern : '';

			this.customer.defaultWarehouse = this.defaultWarehouse;
			if (!this.customer.CustomerCode) {
				this.activeClassId = 1;
				return this.notifications.error('Invalid Customer', 'Please select customer');
			}

			this.customer.beforeSave();

			let errHandler = err => {

				if (err.status === 400 || err.status === 406) {
					console.log(err);
					if (has(err, 'error')) {
					}
				}

				this.activeClassId = 1;
				return throwError(err)
			};

			this.createOrSave()
				.pipe(
					catchError(errHandler),
					first()
				)
				.subscribe(this.saveCustomerComplete);
		}
	}

	//noinspection JSUnusedGlobalSymbols
	removeImage() {
		this.customer.imageUrl = '';
	}

	//noinspection JSUnusedGlobalSymbols
	persistS3Image(event: any) {
		let theFile = event.srcElement.files[0];

		this.fileUploadService.uploadCustomerImage(theFile, (err, data) => {
			let s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static.reali.supply/';
			let cfDistribution = 'https://static.reali.supply/';
			let url = data.Location.replace(s3Prefix, cfDistribution);

			this.customer.imageUrl = url;
		});
	}

	setRoleToCustomer = (customerRole: string) => {
		this.customer.RegisterUserRoleId = +customerRole;
	};

	private readonly createOrSave = () => {
		this.customer.requiredField = JSON.stringify(this.requiredField);
		/**
		 * Check whether it is new record or not
		 */
		if (!this.customerGuid) {
			return this.customerService.create(this.customer);
		}

		return this.customerService.update(this.customer);
	};


	private readonly saveCustomerComplete = (customer: UnleashedCustomer) => {
		if (!this.customerGuid) {
			if (this.isAdmin) {
				this.router.navigate(['/manage/customer']);
			} else {
				this.router.navigate(['/manage/customer']);
			}
		}
	};

	private async setWarehouseList(warehouseList: any) {
		let UnleashedGlobalDefaultWarehouse = 'Brisbane';
		this.warehouseList = await warehouseList
			.map(warehouse => {
				if (warehouse.IsDefault) { UnleashedGlobalDefaultWarehouse = warehouse.WarehouseCode; }
				return {
					id: warehouse.WarehouseCode, text: warehouse.WarehouseName + (warehouse.Obsolete ? ' (Obsolete)' : '')
				}
			});
		if (this.warehouseList.length > 0) {
			if (this.customer && this.customer.defaultWarehouse != null) {
				setTimeout(() => {
					this.defaultWarehouse = this.customer.defaultWarehouse;
					this.setWarehouseDefault(this.defaultWarehouse);
				});
			}
			else {
				setTimeout(() => {
					this.defaultWarehouse = UnleashedGlobalDefaultWarehouse
				});
			}
		}
	}

	setWarehouseDefault(defaultWarehouseNew: string | null): void {
		this.defaultWarehouse = defaultWarehouseNew;
	}

	fetchMore() {
		this.isLoading = true;
		this.searchUnleashedCustomers({
			term: undefined,
			page: ++this.page
		});
	}

	setActive(id: number) {
		this.activeClassId = id;
	}

}
