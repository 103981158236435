import { Injectable } from '@angular/core';
import { WebSocketService } from '../services/webSocket.service';
import { logger } from '../util/Logger';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class WebsocketInterceptor implements HttpInterceptor {
	constructor(
		private readonly webSocketService: WebSocketService
	) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const signature = "WebsocketInterceptor.intercept: ";

		const sessionToken = this.webSocketService._connectionSession;

		if (sessionToken) {
			logger.silly(signature + `Adding Socket Session Data to ${request.url}`);

			request = request.clone({
				setHeaders: {
					'Spark-Socket-Session': sessionToken.id,
					'Spark-Socket-Key': sessionToken.key
				}
			});
		}

		return next.handle(request);
	}
}
