import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { GroupApi } from '../api/group.api';
import { IGroup } from '../model/group.model';

@Injectable()
export class GroupService {
  constructor(
    private notifications: NotificationsService,
    private groupApi: GroupApi
  ) {
  }


  public list(query: IQueryFilter): Observable<QueryResult<IGroup & HasId>> {
    return apiCallWrapper(
      this.groupApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching User Groups"
      }
    )
  }

  public get(id: string): Observable<IGroup & HasId> {
    return apiCallWrapper(
      this.groupApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching User Groups"
      }
    )
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.groupApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Groups"
      }
    )
  }

  public update(id: number, data: IGroup) {
    return apiCallWrapper(
      this.groupApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Update group"
      }
    )
  }

  public create(data: IGroup) {
    return apiCallWrapper(
      this.groupApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Create Group"
      }
    )
  }
}
