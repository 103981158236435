import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { createUrl, getPublicRoutesHeaders, queryToParams } from './api.util';
import { QueryResult } from '../model/query.filter.class';
import { Cart, CartAttrs, NewCartItem, ServerCartItem } from '../model/cart.model';
import { IOrderCardPaymentPrepared } from '../model/order.model';
import { ICustomOrderField } from '../model/custom.user.fileld.model';

// Convenience Type
type T = CartAttrs;

@Injectable()
export class CartApi extends GenericApi<T> {
  public path = "cart";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getOrderAttrsList = (params: HttpParams): Observable<QueryResult<CartAttrs>> => {
    return this.httpClient.get<QueryResult<CartAttrs>>(
      createUrl(this.path),
      { params }
    );
  };

  readonly sendOrderEmail = (cartId: number) => {
    return this.httpClient.get<any>(
      createUrl(this.path, cartId, 'orderEmail')
    );
  };

  readonly sendOrderApprovalEmail = (cartId: number) => {
    return this.httpClient.get<any>(
      createUrl(this.path, cartId, 'approvalEmail')
    );
  };

  readonly approveCart = (cartId: number, secret?: string) => {
    let body: { [key: string]: any } = {};
    let options: { [key: string]: any } = {};

    if (secret) {
      body.secret = secret;

      options.headers = getPublicRoutesHeaders();
    }

    return this.httpClient.put<any>(
      createUrl(this.path, cartId, 'approve'),
      body,
      options
    );
  };

  readonly cancel = (type: string, cartId: number, secret?: string, rejectReason?: string) => {
    let body: { [key: string]: any } = {};
    let options: { [key: string]: any } = {};
    if (secret) {
      body.secret = secret;
      options.headers = getPublicRoutesHeaders();
    }

    body.type = type;

    if (rejectReason) {
      body.rejectReason = rejectReason;
    }

    return this.httpClient.put<any>(
      createUrl(this.path, cartId, 'cancel'),
      body,
      options
    );
  };

  readonly getCurrentCartItems = (customerId: number) => this.httpClient.get<ServerCartItem[]>(createUrl('customer', customerId, 'cart', 'items'))
    .pipe(
      map(results => {
        return results.map(result => {
          let instance = new ServerCartItem();

          Object.assign(instance, result);

          // @ts-ignore
          if (result.allocationCollection && result.allocationCollection.collection) {
            // @ts-ignore
            instance.allocationCollectionName = result.allocationCollection.collection.name;
          }

          return instance;
        });
      })
    );

  readonly getCurrentCartForUser = (customerId: number): Observable<Cart> =>
    this.httpClient.get<Cart>(createUrl('customer', customerId, 'cart'))
      .pipe(
        map(result => {
          const instance = Object.assign(new Cart(), result);

          if (result.attrs)
            instance.attrs = Object.assign(new CartAttrs(), result.attrs);

          return instance;
        })
      );

  readonly addItemToCurrentCart = (customerId: number, cartItem: NewCartItem): Observable<any> =>
    this.httpClient.post<any>(
      createUrl('customer', customerId, 'cart', 'item'),
      {
        quantity: Number(cartItem.selectedQuantity),
        variationId: cartItem.selectedVariation?.guid,
        productId: cartItem.product.id,
        packSize: cartItem.selectedPack,
        allocationCollectionId: cartItem.allocationCollectionId,
        decorations: cartItem.selectedDecorations?.map(decoration => ({
          id: decoration.id,
          options: decoration.options
            .filter(decorationOption => decorationOption.value && decorationOption.value.length)
            .map(decorationOption => ({
              id: decorationOption.id,
              value: decorationOption.value
            }))
        }))
      }
    );

  readonly updateCartItem = (customerId: number, cartItem: NewCartItem): Observable<any> =>
    this.httpClient.put<any>(
      createUrl('customer', customerId, 'cart', 'item', cartItem.source!.id),
      {
        quantity: Number(cartItem.selectedQuantity),
        packSize: cartItem.selectedPack,
        color: cartItem.selectedColour
      }
    );

  readonly deleteCartItem = (customerId: number, cartItemId: number): Observable<any> =>
    this.httpClient.delete<any>(createUrl('customer', customerId, 'cart', 'item', cartItemId));

  readonly saveCartAttrs = (customerId: number, attrs: Partial<CartAttrs>) =>
    this.httpClient.post<CartAttrs>(
      createUrl('customer', customerId, 'cart'),
      attrs
    );

  readonly prepareCCTransaction = (cartId: number) => {
    return this.httpClient.get<IOrderCardPaymentPrepared>(
      createUrl(this.path, cartId, 'prepareCCTransaction')
    );
  };

  readonly placeOrder = (notifyRequestUser: boolean, customerId: number): Observable<any> =>
    this.httpClient.post<any>(
      createUrl('customer', customerId, 'cart', 'place'),
      { notifyRequestUser }
    );

  readonly completeCCTransaction = (transactionResponse: { [key: string]: any; }) => {
    return this.httpClient.post<any>(
      createUrl('completeCCTransaction'),
      transactionResponse
    );
  }

  readonly fetchChartData = (params: HttpParams) => {
    return this.httpClient.get<[{ key: { date: string, grand_total: number } }]>(
      createUrl('order-chart'),
      { params }
    );
  }

  readonly getLastAddress = () => {
    return this.httpClient.get<any>(
      createUrl('last_address'),
    );
  }

  readonly saveCartAttrsAdmin = (cartId: number, attrs: Partial<CartAttrs>): Observable<any> => {
    return this.httpClient.post<CartAttrs>(
      createUrl(this.path, cartId, 'attrs'),
      attrs
    );
  }

  public readonly updateOrderShippingDetails = (shipmentID: string | number | null, trackingDetail: string | null, trackingLink: string | null): Observable<any> => {

    if (!shipmentID || shipmentID == '')
      throw new Error("Invalid order shipment ID for api call");

    return this.httpClient.put(
      createUrl(this.path, 'update_order_shipment_details', shipmentID), { 'trackingDetail': trackingDetail, 'trackingLink': trackingLink }
    );
  };

  readonly getCart = (id: number): Observable<Cart | undefined> => {
    return this.httpClient.get<Cart>(
      createUrl(this.path, id)
    );
  };

  readonly reOrder = (id: number): Observable<Cart | undefined> => {
    return this.httpClient.get<Cart>(
      createUrl(this.path, id, 'reorder')
    );
  };

  public postOrderFieldValues(model: any, id: number | string): Observable<any> {
    return this.httpClient.post<any>(
      createUrl(this.path, id, 'orderFieldValue'),
      model
    );
  };

  public getFields(customerId: number | string) {
    return this.httpClient.get<ICustomOrderField[]>(
      createUrl(this.path, customerId, 'orderField')
    );
  };
}
