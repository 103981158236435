import { GenericApi } from './generic.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnleashedCustomer } from '../model/unleashed.model';
import { createUrl } from './api.util';
import { Observable } from 'rxjs';
import { UnleashedProductListResult } from '../model/productListResult.model';

// Convenience Type
type T = UnleashedCustomer;

@Injectable()
export class UnleashedApi extends GenericApi<T> {
  handleErrors(err: any) {
    throw new Error('Method not implemented.');
  }
  public path = "unleashed";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  readonly getCustomerById = (customerGuid: string): Observable<any> => {
    return this.httpClient.get<any>(
      createUrl(this.path, 'customers', customerGuid)
    );
  }

  readonly getWarehouseList = (): Observable<any> => {
    return this.httpClient.get<any>(
      createUrl(this.path, 'get_warehouses')
    );
  }

  readonly getSalesShipmentsByOrderNumber = (orderNumber: string): Observable<any> => {
    return this.httpClient.get<any>(
      createUrl(this.path, 'get_sales_shipments'),
      {
        params: {
          orderNumber: orderNumber.toString()
        }
      }
    );
  }

  readonly getUnleashedCustomers = (param): Observable<any> => {
    return this.httpClient.get<any>(
      createUrl(this.path, 'customers'),
      {
        params: Object.keys(param).reduce(
          (predicate, key) => predicate.set(key, param[key]), new HttpParams()
        )
      }
    )
  }

  readonly getUnleashedProducts = (parameters: { [key: string]: any }): Observable<UnleashedProductListResult> => {
    return this.httpClient.get<any>(
      createUrl(this.path, 'products'),
      {
        params: Object.keys(parameters).reduce(
          (predicate, key) => predicate.set(key, parameters[key]), new HttpParams()
        )
      }
    )
  }
}
