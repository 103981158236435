<div class="edit-users edit-allocation">
	<div>
		<!-- No allocations section start here... -->
		<div class="no-content" *ngIf="allocations.length === 0">
			<div class="no-content-icon">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
						<path
							d="M12.625 6.66602L15.25 4.04102M16.75 2.54102L15.25 4.04102L16.75 2.54102ZM9.54246 9.74852C9.92972 10.1306 10.2376 10.5855 10.4483 11.0871C10.659 11.5887 10.7684 12.127 10.7702 12.671C10.7721 13.215 10.6662 13.754 10.4589 14.257C10.2515 14.76 9.94676 15.2169 9.56207 15.6016C9.17738 15.9863 8.7204 16.2911 8.21744 16.4985C7.71447 16.7058 7.17546 16.8116 6.63144 16.8098C6.08741 16.808 5.54912 16.6985 5.04755 16.4878C4.54599 16.2771 4.09106 15.9693 3.70896 15.582C2.95756 14.804 2.54178 13.762 2.55118 12.6805C2.56058 11.5989 2.9944 10.5643 3.75921 9.79951C4.52402 9.03471 5.55862 8.60088 6.64018 8.59149C7.72174 8.58209 8.76373 8.99786 9.54171 9.74927L9.54246 9.74852ZM9.54246 9.74852L12.625 6.66602L9.54246 9.74852ZM12.625 6.66602L14.875 8.91602L17.5 6.29102L15.25 4.04102L12.625 6.66602Z"
							stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>
			</div>
			<h4 class="no-content-title">No allocations</h4>
		</div>
		<!-- No allocations section end here... -->

		<!-- allocations -1 start here... -->
		<div class="edit-card card" *ngFor="let allocation of allocations; let i = index">
			<div class="card-head">
				<h4 class="head-title">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M10 1.75V18.25" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path
								d="M13.75 4.75H8.125C7.42881 4.75 6.76113 5.02656 6.26884 5.51884C5.77656 6.01113 5.5 6.67881 5.5 7.375C5.5 8.07119 5.77656 8.73887 6.26884 9.23116C6.76113 9.72344 7.42881 10 8.125 10H11.875C12.5712 10 13.2389 10.2766 13.7312 10.7688C14.2234 11.2611 14.5 11.9288 14.5 12.625C14.5 13.3212 14.2234 13.9889 13.7312 14.4812C13.2389 14.9734 12.5712 15.25 11.875 15.25H5.5"
								stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
					Allocation details
				</h4>
				<button class="btn btn-secondary-icon" type="button" (click)="allocations.splice(i, 1)"
					*ngIf="!isReadOnly(allocation)">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path
								d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
								stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
				</button>
			</div>
			<div class="card-body">
				<form action="">
					<div class="row">
						<div class="col-12 col-lg-6" *ngIf="allocation.type === 'dollar'">
							<div class="form-group">
								<label for="amount" class="form-label">Allocation Amount including GST</label>
								<input *ngIf="!isReadOnly(allocation)" type="text" class="form-control" id="amount" name="amount"
									[(ngModel)]="allocation.amount" />
								<div *ngIf="isReadOnly(allocation)">
									${{ allocation.amount }}
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6" *ngIf="allocation.mode === 'add' && allocation.type === 'dollar'">
							<div class="form-group">
								<label for="allocation_amount" class="form-label">Max Balance</label>
								<div *ngIf="!isReadOnly(allocation)">
									<input type="text" class="form-control" placeholder="0.00" name="maxBalance"
										[(ngModel)]="allocation.maxBalance" numbersOnly />
								</div>
								<div *ngIf="isReadOnly(allocation)">
									${{ allocation.maxBalance }}
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<div class="form-group">
								<label for="Frequency" class="form-label">Frequency</label>
								<select *ngIf="!isReadOnly(allocation)" class="form-select" #frequency
									aria-label="Default select example" id="Frequency"
									(change)="changeFrequency(allocation, frequency.value)">
									<option *ngFor="let frequency of allocationFrequencyOptions"
										[selected]="frequency.key == allocation.frequency">
										{{ frequency.value }}
									</option>
								</select>
								<div *ngIf="isReadOnly(allocation)">
									{{ frequencyText(allocation.frequency) }}
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6" *ngIf="allocation.type === 'dollar'">
							<div class="form-group">
								<label for="mode" class="form-label">Mode</label>
								<select *ngIf="!isReadOnly(allocation)" #mode class="form-select" aria-label="Default select example"
									id="mode" (click)="changeMode(allocation, mode.value)">
									<option *ngFor="let mode of allocationModeOptions" [selected]="allocation.mode == mode.key">
										{{ mode.value }}
									</option>
								</select>
								<div *ngIf="isReadOnly(allocation)">
									{{ modeText(allocation.mode) }}
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6" *ngIf="showStartDate">
							<div class="form-group">
								<label for="Dates" class="form-label">Start date</label>
								<input *ngIf="!isReadOnly(allocation)" type="date" #date class="form-control" id="Dates"
									placeholder="dd.mm.rrrr" aria-describedby="dateRequired"
									(change)="setStartDate(allocation, date.value)" [value]="getStartDate(allocation)" />
								<div id="dateRequired" class="form-text" *ngIf="!isReadOnly(allocation)">
									Date will be assumed to be (00:00am AEST)
								</div>
								<div *ngIf="isReadOnly(allocation)" class="form-text">
									{{ getStartDate(allocation) }}
								</div>
							</div>
						</div>

						<div class="col-12 col-lg-6" *ngIf="allocation.type === 'dollar' && !isReadOnly(allocation)">
							<div class="form-group">
								<label for="allocation_amount" class="form-label">Allocate Now including GST</label>
								<input type="text" class="form-control" placeholder="0.00" name="allocateNow"
									[(ngModel)]="allocation.allocateNow" numbersOnly />
								<span class="form-text" *ngIf="!isReadOnly(allocation)">
									Add to the current balance
								</span>
							</div>
						</div>

						<div class="col-12 col-lg-6" *ngIf="allocation.type === 'dollar'">
							<div class="form-group">
								<label class="d-block" class="form-label">Current Balance including GST</label>
								<div class="form-control">
									<h3>${{ allocation.balance || 0 }}</h3>
								</div>
							</div>
						</div>

						<div class="col-12 col-l g-8" *ngIf="allocation.type === 'garment'">
							<div class="form-group" *ngIf="!isReadOnly(allocation)">
								<label for="Collections" class="form-label">Collections</label>
								<ng-select #collection [multiple]="true" name="collection" [virtualScroll]="true"
									(scrollToEnd)="getCollection(true)" [searchable]="true" [(ngModel)]="selectedItems"
									(search)="onCollectionSearch($event)" [notFoundText]="noFoundText" [clearable]="true">
									<ng-option *ngFor="let data of queryResult.rows" [value]="data.id">{{data.name}}</ng-option>
								</ng-select>
							</div>
						</div>
						<div class="col align-self-end" *ngIf="allocation.type === 'garment' && !isReadOnly(allocation)">
							<div class="form-group">
								<button class="btn btn-secondary w-100" type="button"
									(click)="addCollections(allocation.allocationCollections)">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path d="M4.75 10H15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
										</svg>
									</span>
									Add collections
								</button>
							</div>
						</div>
						<div class="col-12" *ngIf="allocation.type === 'garment'">
							<h5 class="form-title">allocations</h5>
						</div>
						<div class="col-12 px-0" *ngIf="allocation.type === 'garment'">
							<div class="row" *ngFor="
									let collection of allocation.allocationCollections;
									let c = index
								">
								<div class="col-12 col-lg-5">
									<div class="form-group">
										<label for="Name" class="form-label">Name</label>
										<input type="text" class="form-control" id="Name" placeholder="" disabled
											[value]="collection.name" />
									</div>
								</div>
								<div class="col-12 col-lg-4">
									<div class="form-group">
										<label for="Allocation {{ c }}" class="form-label">Allocation</label>
										<input type="text" class="form-control" id="Allocation {{ c }}" name="count {{ c }}"
											[(ngModel)]="collection.itemCount" *ngIf="!isReadOnly(allocation)" />
										<h3 *ngIf="isReadOnly(allocation)">
											{{ collection.itemCount }}
										</h3>
									</div>
								</div>
								<div class="col-12 col-lg-3 align-self-end">
									<div class="form-group remaining">
										<div>
											<h6>Remaining:</h6>
											<span class="badge bg-alpha-25">{{ collection.remainingItemCount }} product{{
												collection.remainingItemCount > 1 ? 's' : ''}}</span>
										</div>
										<button class="btn btn-secondary-icon" *ngIf="!isReadOnly(allocation)" type="button"
											(click)="allocation.allocationCollections?.splice(c, 1)">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
													<path
														d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12" *ngIf="showProcessNow">
							<div *ngIf="!isReadOnly(allocation)" class="form-group form-check">
								<input class="form-check-input" type="checkbox" id="flexCheckDefault" [name]="'processImmediately_' + i"
									[(ngModel)]="allocation.processImmediately" />
								<label class="form-check-label" for="flexCheckDefault">
									Process Immediately: Run this allocation as soon as this page
									is saved.
									<span class="d-block mt-1">This will next run on ...
										{{
										customerUserService.describeRunSequence(allocation)
										}}</span>
								</label>
							</div>
						</div>

						<span class="d-block mt-1" *ngIf="readonly">This will next run on ...
							{{ customerUserService.describeRunSequence(allocation) }}</span>

						<div class="col-12" *ngIf="canAccessLogs && allocation.id">
							<a [routerLink]="['./logs', allocation.id]" [queryParams]="{ userName: userName, tab: tab }"
								class="btn btn-primary" role="button">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path
											d="M16.7499 16.75L13.4874 13.4875M15.25 9.25C15.25 12.5637 12.5637 15.25 9.25 15.25C5.93629 15.25 3.25 12.5637 3.25 9.25C3.25 5.93629 5.93629 3.25 9.25 3.25C12.5637 3.25 15.25 5.93629 15.25 9.25Z"
											stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								View Logs
							</a>
						</div>
					</div>
				</form>
			</div>
		</div>
		<!-- allocations -1 end here... -->

		<!-- New allocations start here... -->
		<div class="edit-card card" *ngIf="!readonly">
			<div class="card-head">
				<h4 class="head-title">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M10 1.75V18.25" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path
								d="M13.75 4.75H8.125C7.42881 4.75 6.76113 5.02656 6.26884 5.51884C5.77656 6.01113 5.5 6.67881 5.5 7.375C5.5 8.07119 5.77656 8.73887 6.26884 9.23116C6.76113 9.72344 7.42881 10 8.125 10H11.875C12.5712 10 13.2389 10.2766 13.7312 10.7688C14.2234 11.2611 14.5 11.9288 14.5 12.625C14.5 13.3212 14.2234 13.9889 13.7312 14.4812C13.2389 14.9734 12.5712 15.25 11.875 15.25H5.5"
								stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
					Add new allocation
				</h4>
			</div>
			<div class="card-body">
				<form action="">
					<div class="row">
						<div class="col-12 col-lg-8">
							<div class="form-group">
								<label for="Amounts" class="form-label">Amount</label>
								<select class="form-select" aria-label="Default select example" id="Amounts"
									[(ngModel)]="selectedAllocationType" name="accessMode">
									<option selected value="dollar">Dollar Amount</option>
									<option value="garment">Product Collections</option>
								</select>
							</div>
						</div>
						<div class="col align-self-end">
							<div class="form-group">
								<button class="btn btn-secondary w-100" type="button" (click)="addAllocation()">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path d="M4.75 10H15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
										</svg>
									</span>
									Add allocations
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
		<!-- New allocations end here... -->

		<!-- submit allocation button start here... -->
		<div class="mt-3" *ngIf="!readonly && showSubmit">
			<button class="btn btn-primary w-sm-100" type="button" (click)="saveAllocations()">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path
							d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
							stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>
				Submit
			</button>
		</div>
		<!-- submit allocation button end here... -->
	</div>
</div>