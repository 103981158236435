import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-template',
  templateUrl: './app-template.component.html',
  styleUrls: []
})
export class AppTemplateComponent implements OnInit {
  showFooter = false;

  constructor(
    private router: Router
  ) {
    this.showFooter = this.router.url === '/manage/dashboard';
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.showFooter = event.url === '/manage/dashboard';
    });
  }

  ngOnInit(): void {}
}
