import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { ShippingDetailApi } from '../api/shippingDetail.api';
import { IShippingDetails, ShippingDetails, ShippingDetailsClass } from '../model/shippingDetail.model';
import { SessionApi } from '../api/session.api';

@Injectable()
export class ShippingDetailService {
  constructor(
    private notifications: NotificationsService,
    private shippingDetailApi: ShippingDetailApi,
    private session: SessionApi
  ) {
  }


  public list(query: IQueryFilter): Observable<QueryResult<IShippingDetails & HasId>> {
    return apiCallWrapper(
      this.shippingDetailApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Shipping Details"
      }
    )
  }


  public read(id: number) {
    return apiCallWrapper(
      this.shippingDetailApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching Shipping Details"
      }
    )
  }

  public getShippingDetailsList(customrId: string | number): Observable<ShippingDetailsClass[]> {
    customrId = this.guaranteeCustomerId(customrId);

    return this.shippingDetailApi.getShippingDetailsList(customrId);
  }

  /** Used to guarantee that the passed customer id is acceptable and valid */
  private readonly guaranteeCustomerId = (customerId?: number | string | null): string => {
    if (!customerId || isNaN(Number(customerId)) || !this.session.$userData.value?.isAdmin) {
      const authCustomer = this.session.$customerData.value;
      if (!authCustomer || !authCustomer.id)
        throw new Error("Invalid Customer for api call");

      return String(authCustomer.id);
    }

    return customerId.toString();
  }

  public create(customerId: string | number | null, model: any) {
    customerId = this.guaranteeCustomerId(customerId);
    model.customerId = Number(customerId);

    return apiCallWrapper(
      this.shippingDetailApi.create(model),
      {
        notificationsService: this.notifications,
        action: "Creating Shipping Details"
      }
    )
  }

  public update(customerId: string | number | null, id: string | number, model: any) {
    customerId = this.guaranteeCustomerId(customerId);

    return apiCallWrapper(
      this.shippingDetailApi.update(id, model),
      {
        notificationsService: this.notifications,
        action: "Updating Shipping Details"
      }
    )
  }

  public delete(id: number, customerId: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.shippingDetailApi.deleteAddress(id, customerId),
      {
        notificationsService: this.notifications,
        action: "Delete Shipping Details"
      }
    )
  }

}
