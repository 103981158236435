import { Component } from '@angular/core';
import { environment } from "../../../environments/environment";
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-email-test',
  templateUrl: './email-test.component.html',
  styleUrls: []
})
export class EmailTestComponent {
  public testEmailOptions = [{
    value: "account-invite",
    label: "Account Invite"
  }, {
    value: "email-changed",
    label: "Email Changed"
  }, {
    value: "order-approval",
    label: "Order Approval / Order Sent for Approval"
  }, /*{
		value: "order-dispatched-at-star-ship-it",
		label: "Order Dispatched (StarShipIT)"
	},*/ {
    value: "order-dispatched",
    label: "Order Dispatched"
  }, /*{
		value: "order-error",
		label: "Order Error"
	},*/ {
    value: "order",
    label: "Order"
  }, {
    value: "orders-with-dollar-allocations",
    label: "Orders With Dollar Allocations"
  }, {
    value: "reset-password",
    label: "Reset Password"
  }, {
    value: "welcome",
    label: "Welcome"
  }, {
    value: "order-approval-reminder",
    label: "Order Approval Reminder"
  }, {
    value: "allocation-reminder",
    label: "Allocation Reminder"
  }, {
    value: "new-sso-user",
    label: "New SSO User"
  }];

  /**
   * General
   */
  public model = {
    emailAddress: environment.defaultTestEmailAddress,
    testEmail: this.testEmailOptions[0].value
  };

  constructor(
    private adminService: AdminService
  ) { }

  /**
 * @description Validate and persist the product in the server, ignoring validating for Draft Products
 */
  saveModel() {
    const handler = this.adminService.sendTestEmail(this.model.emailAddress, this.model.testEmail);

    handler.subscribe(() => {
    },
      err => {
        console.error(err);
      }
    );
  }
}
