import { Component, Input, OnChanges } from '@angular/core';
import { DecorationOrProduct } from 'src/app/main/model/ddb.model';

@Component({
  selector: 'app-customer-decoration',
  templateUrl: './customer-decoration.component.html',
  styleUrls: []
})
export class CustomerDecorationComponent {
  @Input() customerDecoration: { decoration: DecorationOrProduct, customPrice: number }[];
}
