<!-- table-content for lg screen start here... -->
<div class="manage-table main-table d-none d-lg-block">
  <table class="table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Custom Price</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of customerDecoration">
        <td>
          <div class="user-name">
            <h5 class="mx-100">
              {{ data.decoration ? data.decoration.name : '' }}
            </h5>
          </div>
        </td>
        <td>
          <h5 class="mx-100">
            {{ data.customPrice }}
          </h5>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- table-content for lg screen end here... -->

<!-- Cards for sm screen start here... -->
<div class="manage-cards d-lg-none">
  <div class="card manage-card" *ngFor="let data of customerDecoration">
    <div class="card-body">
      <ul class="card-list">
        <li>
          <div class="manage-grid">
            <span class="head"> Name </span>
            <div class="user-name">
              <h5>
                {{ data.decoration ? data.decoration.name : '' }}
              </h5>
            </div>
          </div>
        </li>
        <li>
          <div class="manage-grid">
            <span class="head">Custom Price</span>
            <p>
              {{ data.customPrice }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Cards for sm screen end here... -->