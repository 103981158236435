import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable, map, of } from 'rxjs';
import { apiCallWrapper, queryToParams } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { CustomerUserApi } from '../api/customerUser.api';
import { ICustomerUser, IVerifyOTPPayload } from '../model/customer.user.model';
import { IUserAllocation } from '../model/userAllocation.model';
import { IUserGroup } from '../model/group.model';
import { defaults } from 'lodash';
import moment, { Moment } from 'moment-mini';

interface GetCustomerUserOptions {
  allocations: boolean; //User Allocations
}

@Injectable()
export class CustomerUserService {
  constructor(
    private notifications: NotificationsService,
    private customerUserApi: CustomerUserApi
  ) {
  }

  public getAllUsers(query: IQueryFilter, options: Partial<{
    params: { [key: string]: any }
  }> = {}): Observable<QueryResult<ICustomerUser>> {

    let params = queryToParams(query);

    if (options.params) {
      for (let prop in options.params) {
        params = params.set(prop, options.params[prop]);
      }
    }

    return this.customerUserApi.getUsers(params);
  }

  public get(id: number): Observable<Partial<ICustomerUser>> {
    return apiCallWrapper(
      this.customerUserApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching User By Id"
      }
    )
  }

  public list(query: IQueryFilter, options: Partial<{
    params: { [key: string]: any }
  }> = {}) {
    let params = queryToParams(query);

    if (options.params) {
      for (let prop in options.params) {
        params = params.set(prop, options.params[prop]);
      }
    }

    return this.customerUserApi.getCustomersUsers(params);
  }

  public update(id: number, data: Partial<ICustomerUser>) {
    return apiCallWrapper(
      this.customerUserApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Update customer user"
      }
    )
  }

  public saveCustomerUserAllocations(customerUserId: number, userAllocations: IUserAllocation[]) {
    return apiCallWrapper(
      this.customerUserApi.saveCustomerUserAllocations(customerUserId, userAllocations),
      {
        notificationsService: this.notifications,
        action: "Update customer user allocation"
      }
    )
  }

  public saveCustomerUserGroups(customerUserId: number, userGroups: IUserGroup[] | undefined): Observable<any> {
    return apiCallWrapper(
      this.customerUserApi.saveCustomerUserGroups(customerUserId, userGroups),
      {
        notificationsService: this.notifications,
        action: "Update customer user group"
      }
    )
  }

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.customerUserApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete User"
      }
    )
  }

  public generateTfaSecret(data: string): Observable<{ qr: string, secret: string, uri: string }> {
    return apiCallWrapper(
      this.customerUserApi.generateTfaSecret(data),
      {
        notificationsService: this.notifications,
        action: "Genrate TFa Secret"
      }
    )
  }

  public verifyOtp(data: IVerifyOTPPayload): Observable<{ verify: boolean }> {
    return this.customerUserApi.verifyOtp(data);
  }

  public create(data: Partial<ICustomerUser>) {
    return apiCallWrapper(
      this.customerUserApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Create customer user"
      }
    )
  }
  public getFields(customerUserId: number | string) {
    return this.customerUserApi.getFields(customerUserId);
  }

  public postFieldValues(model: any, id: number | string) {
    return this.customerUserApi.postFieldValues(model, id);
  }

  public importUser(data: { customerId: number | string, fileContent: string }) {
    return apiCallWrapper(
      this.customerUserApi.importUser(data),
      {
        notificationsService: this.notifications,
        action: "Import customer user"
      }
    )
  }

  /**
 * Convenience method for getting a single customerUser (or null) with some additional information. Each configuration option should be added
 * to default to false, and be activated by the calling method only. The opts arg must never accept anything other than a partial to guarantee
 * no value can be forced.
 *
 * @param {number|null} customerUserId Allows for null/undefined to allow for properties to be blindly passed
 * @param {Partial<GetCustomerUserOptions>} argOptions All should default false. It is named argOptions to prevent ambiguity
 */
  public getCustomerUser(customerUserId: number | null | undefined, argOptions?: Partial<GetCustomerUserOptions>): Observable<ICustomerUser | null> {

    if (!customerUserId) return of(null);

    const opts: GetCustomerUserOptions = defaults(argOptions, {
      allocations: false
    });

    let include: any = [];

    if (opts.allocations) {
      include.push({
        association: 'userAllocations',
        include: [{
          association: 'allocationCollections',
          include: [{
            association: 'collection'
          }]
        }]
      });
    }

    const filter = new IQueryFilter({
      filter: { id: customerUserId },
      include
    });

    return this.list(filter)
      .pipe(
        map(result => {
          if (result.hasOwnProperty('rows') && result.rows.length > 0) {
            return result.rows[0];
          }

          return null;
        })
      );
  }

  describeRunSequence = (allocation: IUserAllocation) => {
    let runSequence = '';

    if (allocation.processImmediately) runSequence = "Straight away (" + moment().format("DD/MM/YYYY HH:mm") + ")";
    if (
      allocation.frequency === 'disabled'
      || allocation.frequency === 'inactive'
      || (Number(allocation.amount) === 0 && allocation.type !== 'garment')
      || (!allocation.frequency || !allocation.frequency.length)
      || (!allocation.mode || !allocation.mode.length) && allocation.type !== 'garment'
    ) {
      return runSequence;
    }

    if (runSequence.length > 0) runSequence += ", ";

    let startDate: Date | null = null;

    if (allocation.processedTo) {
      startDate = moment(allocation.processedTo).toDate();
    }

    if (allocation.startAt && (!startDate || (allocation.processedTo && moment(allocation.startAt).toDate() > moment(allocation.processedTo).toDate()))) {
      startDate = moment(allocation.startAt).toDate();
    }

    if (!startDate) { startDate = new Date(); }

    let fromDate = moment(startDate);
    let now = moment();

    const maxIterations = 500;
    let iterations = 0;

    let nextAllocationDate: Moment | null = null;

    if (fromDate.diff(now, 'days') >= 0) {
      nextAllocationDate = fromDate;
    } else {
      while (!nextAllocationDate || nextAllocationDate.diff(now, 'days') < 0) {
        if (nextAllocationDate) {
          fromDate = nextAllocationDate;
        }

        nextAllocationDate = this.getNextAllocationDate(allocation.frequency, fromDate);

        iterations++;
        if (iterations > maxIterations) {
          throw new Error(`Allocation[${allocation.id}] reached max iterations when calculating periods elapsed`);
        }
      }

      fromDate = nextAllocationDate;
    }

    runSequence += nextAllocationDate.format("DD/MM/YYYY");

    const futureAllocationDate = this.getNextAllocationDate(allocation.frequency, moment(nextAllocationDate));
    if (futureAllocationDate) {
      runSequence += ", " + futureAllocationDate.format("DD/MM/YYYY");
    }
    if (futureAllocationDate) {
      const distantFutureAllocationDate = this.getNextAllocationDate(allocation.frequency, moment(futureAllocationDate));
      runSequence += ", " + distantFutureAllocationDate?.format("DD/MM/YYYY");

    }

    return runSequence;
  }

  getNextAllocationDate = (frequency: string, start = moment()): Moment | null => {
    switch (frequency.toLowerCase()) {
      case "weekly":
        return this.getNextMonday(start);
      case "monthly":
        return this.getNextMonth(start);
      case "annually":
        return this.getNextYear(start);
      case "financialyear":
        return this.getNextFinancialYear(start);
      case "arbitraryyear":
        return this.getNextArbitraryYear(start, 1);
      case "twoyear":
        return this.getNextArbitraryYear(start, 2);
      case "threeyear":
        return this.getNextArbitraryYear(start, 3);
      case "inactive":
        return null;
      default:
        throw new Error(`Unexpected Allocation Frequency ${frequency}`);
    }
  };

  /**
   * Returns the next occurrence of a Monday
   * 
   * @param {moment} start 
   */
  getNextMonday = (start = moment()) => {
    const targetDay = 1; //Monday
    const currentDay = start.isoWeekday();

    if (currentDay < targetDay)
      return moment(start).isoWeekday(targetDay).startOf("day");

    return moment(start).isoWeekday(targetDay).add(1, 'weeks').startOf("day");
  };

  /**
   * Returns the next occurrence of 1 July
   * 
   * @param {moment} start 
   */
  getNextFinancialYear = (start = moment()) => {
    const targetMonth = 6; //June
    const currentMonth = start.month();

    if (currentMonth < targetMonth)
      return moment(start).month(targetMonth).date(1).startOf("day");

    return this.getNextArbitraryYear(start).month(targetMonth).date(1).startOf("day");
  }
  getNextMonth = (start = moment()) => moment(start).add(1, 'month').date(1).startOf("day");
  getNextYear = (start = moment()) => this.getNextArbitraryYear(start).month(0).date(1).startOf("day");
  getNextArbitraryYear = (start = moment(), increment = 1) => moment(start).add(increment, 'year').startOf("day");
  get12HoursFromNow = (start = moment()) => moment(start).add(12, 'hour').startOf("hour");

}
