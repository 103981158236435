<div class="add-products">
	<div class="form-group">
		<label for="selectProduct" class="form-label d-none"></label>
		<ng-select id="selectProduct" #product [virtualScroll]="true" (scrollToEnd)="loadProducts(true)" [searchable]="true"
			(search)="onProductSearch($event)" [notFoundText]="noItemsFoundText" [clearable]="true">
			<ng-option *ngFor="let data of productResult.rows" [value]="data">{{data.name}}</ng-option>
		</ng-select>
	</div>
	<div class="form-group">
		<a class="btn btn-primary w-100" (click)="addSelectedProduct()" role="button">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
			Add Product
		</a>
	</div>
</div>

<app-search [sortText]="getSortText()" [sortOptions]="sortOptions" (searchChanged)="updateSearchTerm($event)"
	(sortChanged)="updateSortField($event)"></app-search>

<div class="edit-users">
	<div class="edit-card card" *ngFor="let item of queryResult.rows; let i = index">
		<div class="card-head">
			<h4 class="head-title">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path
							d="M13.375 8.05041L6.625 4.15791M3.45251 6.22041L10 10.0079L16.5475 6.22041M10 17.5604V10.0004M16.75 13.0004V7.00041C16.7497 6.73737 16.6803 6.47902 16.5487 6.25129C16.417 6.02355 16.2278 5.83444 16 5.70291L10.75 2.70291C10.522 2.57126 10.2633 2.50195 10 2.50195C9.73669 2.50195 9.47803 2.57126 9.25 2.70291L4 5.70291C3.7722 5.83444 3.58299 6.02355 3.45135 6.25129C3.31971 6.47902 3.25027 6.73737 3.25 7.00041V13.0004C3.25027 13.2635 3.31971 13.5218 3.45135 13.7495C3.58299 13.9773 3.7722 14.1664 4 14.2979L9.25 17.2979C9.47803 17.4296 9.73669 17.4989 10 17.4989C10.2633 17.4989 10.522 17.4296 10.75 17.2979L16 14.2979C16.2278 14.1664 16.417 13.9773 16.5487 13.7495C16.6803 13.5218 16.7497 13.2635 16.75 13.0004Z"
							stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>
				Product {{ item.product.name }}
			</h4>
			<button (click)="removeProductCustomer(i)" class="btn btn-secondary-icon" type="button">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
						<path
							d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
							stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label for="Name" class="form-label">Name</label>
						<input type="text" class="form-control" id="Name" value="{{ item.product.name }}" disabled />
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label for="Code" class="form-label">Code</label>
						<input type="text" class="form-control" id="Code" value="{{ item.product.code }}" disabled />
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label for="customPrice" class="form-label">Custom Price</label>
						<input type="number" class="form-control" id="customPrice" placeholder="Custom Price"
							[(ngModel)]="item.customPrice" (keyup)="queueUpdateProductCustomer(i)" />
						<div class="form-text">
							Custom Price - Total price (Client + Employee Contribution)
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label for="clientContribution" class="form-label">Client Contribution</label>
						<input type="number" class="form-control" id="clientContribution" placeholder="Enter Client Contribution"
							[(ngModel)]="item.subsidyAmount" (keyup)="queueUpdateProductCustomer(i)" />
						<div class="form-text">
							The amount the client will contribute to the total price
							{{ item.subsidyAmount }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-pagination [currentPage]="currentPageActive" [pageSize]="query.limit" [totalRows]="totalCountActive"
	(pageChange)="pageChangedActive($event)" (pageSizeChange)="pageSizeChanged($event)"></app-pagination>