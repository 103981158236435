import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable, combineLatest } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { AdminApi } from '../api/admin.api';
import { User } from '../model/user.model';

@Injectable()
export class AdminService {
  constructor(
    private notifications: NotificationsService,
    private adminApi: AdminApi
  ) {
  }

  convertFromAdmin(model: User): object {
    const obj: { [key: string]: any } = {};

    obj.firstName = model.firstName;
    obj.lastName = model.lastName;
    obj.email = model.email;
    obj.contactNumber = model.ContactNumber;
    obj.jobTitle = model.jobTitle;
    obj.startDate = model.startDate;
    obj.permissions = model.permissions;
    obj.adminRoleId = model.adminRoleId;
    obj.secureLoginType = model.SecureLoginType;
    obj.isAdmin = model.IsAdmin;
    obj.loginSecretToken = model.loginSecretToken
    return obj;
  }


  public list(query: IQueryFilter): Observable<any> {
    return apiCallWrapper(
      this.adminApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching User"
      }
    )
  }

  public readonly getById = (id: number | string): Observable<any> => {
    return apiCallWrapper(
      this.adminApi.get(id),
      {
        notificationsService: this.notifications,
        action: "Fetching User"
      }

    );
  };

  public delete(id: number): Observable<{ success: boolean, deleted: number }> {
    return apiCallWrapper(
      this.adminApi.delete(id),
      {
        notificationsService: this.notifications,
        action: "Delete Admin"
      }
    )
  }

  public update(id: number, data: User) {
    return apiCallWrapper(
      this.adminApi.update(id, this.convertFromAdmin(data)),
      {
        notificationsService: this.notifications,
        action: "Update admin"
      }
    )
  }

  public create(data: User) {
    return apiCallWrapper(
      this.adminApi.create(this.convertFromAdmin(data)),
      {
        notificationsService: this.notifications,
        action: "Created admin"
      }
    )
  }

  public sendTestEmail(emailAddress: string, testEmail: string) {
    return apiCallWrapper(
      this.adminApi.sendTestEmail(emailAddress, testEmail),
      {
        notificationsService: this.notifications,
        action: "Sending Email for testing"
      }
    )
  }

  getServerMessage() {
    return apiCallWrapper(
      this.adminApi.getServerMessage(),
      {
        notificationsService: this.notifications,
        action: "Getting Server Message"
      }
    )
  }

  processAllocations() {
    return apiCallWrapper(
      this.adminApi.processAllocations(),
      {
        notificationsService: this.notifications,
        action: "Running Employee Allocations"
      }
    )
  }

  syncDiscountRates() {
    return apiCallWrapper(
      this.adminApi.syncDiscountRates(),
      {
        notificationsService: this.notifications,
        action: "Syncing Customer Discount Rates from Unleashed"
      }
    )
  }

  syncProductPrice(productId?: number) {
    return apiCallWrapper(
      this.adminApi.syncProductPrice(productId),
      {
        notificationsService: this.notifications,
        action: "Syncing Product Prices from Unleashed. This may take a few minutes"
      }
    )
  }

  setServerMessage(message: string, severity: string) {
    return apiCallWrapper(
      this.adminApi.setServerMessage(message, severity),
      {
        notificationsService: this.notifications,
        action: "Updating System Message"
      }
    )
  }

}
