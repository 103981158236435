import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { has } from 'lodash';
import { combineLatest, takeUntil } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { IBusinessUnit } from 'src/app/main/model/businessUnit.model';
import { BusinessUnitService } from 'src/app/main/services/businessUnit.service';
import { CustomerService } from 'src/app/main/services/customer.service';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';

@Component({
  selector: 'app-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: []
})
export class BusinessDetailComponent {
  @Input() isEmploye: boolean;
  public activeTabIdx: number = 0;
  private readonly subscriptionGroup = new SubscriptionGroup();
  public customerId: number | null;
  businessUnit: IBusinessUnit = {
    name: "",
    code: "",
    customerId: 0,
    shippingDetails: {
      city: '',
      country: '',
      postalCode: '',
      region: '',
      streetAddress: '',
      streetAddress2: '',
      suburb: '',
      addressName: '',
      isFreeHandling: false
    },
    contact: { user: { firstName: '', lastName: '' } }
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private session: SessionApi,
    private businessUnitService: BusinessUnitService,
    private customerService: CustomerService,
    private router: Router
  ) { }

  ngOnInit() {
    const activeTab = this.activatedRoute.snapshot.queryParamMap.get('activeTab');
    if (activeTab) {
      this.activeTabIdx = +activeTab;
    }

    this.subscriptionGroup.add(
      combineLatest(
        this.activatedRoute.params,
        this.session.$userData,
        this.session.$customerData
      ).subscribe(([params, userData, customerData]) => {
        this.customerId = null;

        if (userData?.isAdmin) {
          if (has(params, "customerId")) {
            this.customerId = Number(params["customerId"]);
          }
        } else if (customerData) {
          this.customerId = customerData.id;
        }

        if (has(params, 'id')) {
          this.businessUnitService.get(params["id"]).subscribe(businessUnit => {
            this.businessUnit = businessUnit;
          })
        }
      })
    );
  }

  getTabs(): string[] {
    return ['All', 'Processing', 'Delivered', 'Cancelled', 'Pending'];
  }

  onTabChange(event: number): void {

    this.activeTabIdx = event;
  }

  deleteItem(id: number | undefined) {
    if (id && this.customerId) {
      this.customerService.deleteBusinessUnit(this.customerId, id).subscribe(() => {
        this.router.navigate(['/account/business-unit'])
      })
    }
  }
}
