import { Component, OnInit } from '@angular/core';
import { FavoriteService } from '../../services/favorites.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { IFavorite } from '../../model/favorites.model';
import { SessionApi } from '../../api/session.api';

@Component({
  selector: 'app-favorites-list',
  templateUrl: './favorites-list.component.html',
  styleUrls: []
})
export class FavoritesListComponent implements OnInit {

  public listName: string = '';
  public modalMode = 'new';
  public newListName = '';
  public isListPrivate = false;
  public isLoading: boolean;
  public isRemove: boolean;
  public listId: number | undefined;
  queryResult: QueryResult<IFavorite> = new QueryResult();

  constructor(
    public favoriteService: FavoriteService,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private router: Router,
		private session: SessionApi
  ) { }

  ngOnInit(): void {
    this.getFavList();
  }

  getFavList() {
    const query: IQueryFilter = new IQueryFilter({
      sortBy: 'name'
    });

    this.favoriteService.list(query).subscribe((res) => {
      this.queryResult = res;
      this.isLoading = false;
    });
  }

	showLockIcon(list: IFavorite): boolean {
		if( list.isShared ) return false;

		const currentUser = this.session.$userData.getValue();
		if( !currentUser ) return true;

		return list.userId === currentUser.id;
	}

  getProductCountRange(count: number): number[] {
    const maxIterations = Math.min(count, 4);
    return Array.from({ length: maxIterations }, (_, index) => index + 1);
  }

  public openRemoveModal(list: IFavorite, content) {
    this.listName = list.name;
    this.modalService.open(content, {
      size: 'sm', centered: true, backdrop: true, windowClass: 'deleteModal'
    }).result.then(() => {
      if (list.id) {
        this.notifications.warn('Processing', 'Deleting the list.');
        this.isRemove = true;
        this.listId = list.id;
        this.favoriteService.removeFavoriteList(list.id.toString()).then(() => {
          this.getFavList()
          this.isRemove = false;
          this.notifications.success('Success', 'Favorite List Was Removed.');
        }).catch((err) => {
          this.isRemove = false;
          this.notifications.error('Error', 'Error deleting the list');
        });
      }
    }, () => { });
  }

  openAddModal(content) {
    this.modalMode = 'new';
    this.newListName = '';
    this.isListPrivate = false;
    this.modalService.open(content, { size: 'md', centered: true, windowClass: 'bulk-order' }).result.then(() => {
      if (this.newListName.trim().length > 20) {
        this.notifications.warn('Warning', 'List name should be 20 characters or less.');
        return;
      }
      this.notifications.warn('Processing', 'Creating a list.');
      this.favoriteService.createFavoriteList(this.newListName.trim().slice(0, 100), !this.isListPrivate).then((res) => {
        this.getFavList()
        this.notifications.success('Success', 'Favorite List Was Created.');
      }).catch((err) => {
        console.error('Error while creating favorite list', err);
        this.notifications.error('Error', 'Error Creating a New List');
      });
    }, () => { });

  }

  public openRenameModal(list: IFavorite, content) {
    this.modalMode = 'rename';
    this.newListName = list.name;
    this.modalService.open(content, { size: 'md', centered: true, windowClass: 'bulk-order' }).result.then(() => {
      if (this.newListName.trim().length > 20) {
        this.notifications.warn('Warning', 'List name should be 20 characters or less.');
        return;
      }
      this.notifications.warn('Processing', 'Renaming the list.');
      if (list.id) {
        this.favoriteService.renameFavoriteList(list.id.toString(), this.newListName.trim().slice(0, 100), this.isListPrivate).then(() => {
          this.getFavList()
          this.notifications.success('Success', 'Favorite List Was Renamed.');
        }).catch((err) => {
          console.error('Error while renaming favorite list', err);
          this.notifications.error('Error', 'Error Renaming the List');
        });
      }
    }, () => { });
  }

  viewFavoritesList = (list: IFavorite) => {
    this.router.navigate(['products'], {
      queryParams: {
        list: list.id
      }
    });
  }
}
