<div class="main-right-content">
	<nav aria-label="breadcrumb" class="pageBreadcrumb d-none d-lg-block">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a>manage {{ activeTag.toLowerCase() }}</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">main page</li>
		</ol>
	</nav>

	<div class="manageUser">
		<div
			class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4"
		>
			<h1 class="title mb-0">Manage {{ activeTag }}</h1>
			<a
				*ngIf="activeTabIdx !== 0"
				(click)="manageRoute()"
				class="btn btn-primary"
				>Add new {{ activeTag }}
				<span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
					>
						<path
							d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
							stroke="white"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</span>
			</a>
		</div>
		<app-tab-set
			[activeTabIdx]="activeTabIdx"
			#tabSet
			[tabs]="getTabs()"
			(click)="onTabChange(tabSet.activeTabIdx)"
		></app-tab-set>

		<div>
			<div
				class="manage-user-list"
				[ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }"
			>
				<app-user-list
					*ngIf="activeTabIdx == 0 && hasManageCustomersAccess"
				></app-user-list>
			</div>
			<!-- <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }">
				<app-customers-list *ngIf="activeTabIdx == 1 && hasManageCustomersAccess"></app-customers-list>
			</div> -->
			<div
				class="manage-user-list"
				[ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }"
			>
				<app-group-list
					*ngIf="activeTabIdx == 1 && hasManageGroupsAccess"
				></app-group-list>
			</div>
			<div
				class="manage-user-list"
				[ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2 }"
			>
				<app-admin-list
					*ngIf="activeTabIdx == 2 && isSuperAdmin"
				></app-admin-list>
			</div>
		</div>
	</div>
</div>
