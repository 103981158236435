import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { SelectOption } from 'src/app/types';
import { IAuthCustomer } from '../../model/customer.model';
import { IQueryFilter } from '../../model/query.filter.class';
import { CustomerService } from '../../services/customer.service';

enum LifecycleStatus {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: []
})
export class CustomSelectComponent {
  private searchTerms: Subject<string> = new Subject<string>();
  public lifecycleStatusSubject = new BehaviorSubject<LifecycleStatus>(LifecycleStatus.LOADED);

  @ViewChild('customSelect')
  customeSelect: NgSelectComponent;

  @Input()
  pageSize: number = 10;

  @Input()
  multiple: boolean = false;

  @Input()
  data: SelectOption[];

  @Input()
  type: string;

  @Output()
  onChange = new EventEmitter<SelectOption[]>();

  @Output()
  clearQuery = new EventEmitter<string>();

  @Output()
  loadData = new EventEmitter<{ isScroll?: boolean, searchTerm?: string }>();

  get notFoundText(): string {
    switch (this.lifecycleStatusSubject.getValue()) {
      case LifecycleStatus.LOADING:
        return 'Loading....';
      default:
        return 'No Data Found';
    }
  }

  customerSelectOptions: SelectOption[] = [];
  @Input() customQuery: IQueryFilter<IAuthCustomer>;

  constructor(
    private readonly customerService: CustomerService
  ) { }

  ngOnInit() {
    this.search();
  }

  onSelectChange() {
    const selectedValues = this.customeSelect.selectedValues as { id: string, text: string }[];
    this.onChange.emit(selectedValues);

    if (selectedValues.length && this.multiple) {
      setTimeout(() => {
        this.customeSelect.open();
      });
    }
  }

  onCustomeSearch(searchTerm: { term: string }) {
    this.searchTerms.next(searchTerm.term);
  }

  search() {
    this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      this.lifecycleStatusSubject.next(LifecycleStatus.LOADING);
      this.loadData.emit({ searchTerm });
    });
  }

  loadMoreData() {
    this.loadData.emit({ isScroll: true });
  }

  onClear() {
    this.clearQuery.emit(this.type);
  }

  clear() {
    this.customeSelect.clearModel();
  }
}
