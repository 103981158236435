import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, Observable, startWith, Subject } from 'rxjs';
import { IAuthCustomer } from 'src/app/main/model/customer.model';
import { IQueryFilter } from 'src/app/main/model/query.filter.class';
import { CustomerService } from 'src/app/main/services/customer.service';
import { SelectOption } from 'src/app/types';

enum LifecycleStatus {
	'LOADING',
	'LOADED'
}

@Component({
	moduleId: module.id,
	selector: 'app-customer-select',
	templateUrl: 'customer-select.component.html'
})
export class CustomerSelectComponent implements OnInit {
	private searchTerms: Subject<string> = new Subject<string>();
	private lifecycleStatusSubject = new BehaviorSubject<LifecycleStatus>(LifecycleStatus.LOADED);
	private _currentPage: number = 1;

  @ViewChild('customerSelect')
	customerSelect: NgSelectComponent;

  @Input()
	pageSize: number = 10;

	@Input()
	multiple: boolean = false;

	@Output()
	onChange = new EventEmitter<SelectOption[]>();

	get notFoundText(): string {
		switch (this.lifecycleStatusSubject.getValue()) {
			case LifecycleStatus.LOADING:
				return 'Loading....';
			default:
				return 'No Customers Found';
		}
	}

	customerSelectOptions: { id: string, text: string }[];
	customerQuery: IQueryFilter<IAuthCustomer>;

	constructor(
		private readonly customerService: CustomerService
	) { }

	ngOnInit() {
		this.resetQuery();

		this.loadCustomerList();
		this.search();
	}

	onSelectChange() {
		const selectedValues = this.customerSelect.selectedValues as { id: string, text: string}[];
		this.onChange.next(selectedValues);

		if( selectedValues.length && this.multiple ) {
			setTimeout(() => {
				this.customerSelect.open();
			});
		}
	}

	resetQuery() {
		this.customerQuery = new IQueryFilter({
			limit: this.pageSize,
			sortBy: 'name'
		});
	}

	/**
	 * @description Turns response from Customer Get into options for select2
	 *
	 * @param {QueryResult<HasId & NewCustomer>} result
	 */
	loadCustomerList(isScrollEvent?:boolean) {
		this.lifecycleStatusSubject.next(LifecycleStatus.LOADING);

		this._currentPage = isScrollEvent ? this._currentPage+1 : 1;
		this.customerQuery.skip = this.customerQuery.limit * (this._currentPage-1);

		this.customerService.list(this.customerQuery)
			.subscribe((result) => {
				this.lifecycleStatusSubject.next(LifecycleStatus.LOADED);

				const newOptions = result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name }));

				if( isScrollEvent ) {
					this.customerSelectOptions.push(...newOptions);
				} else {
					this.customerSelectOptions = newOptions;
				}
			});
	}

	onCustomerSearch(searchTerm: { term: string }) {
		this.searchTerms.next(searchTerm.term);
	}

	search() {
		this.searchTerms.pipe(
			debounceTime(500),
			distinctUntilChanged(),
		).subscribe(searchTerm => {
			this.customerQuery.filter.name = { $like: '%' + searchTerm + '%' };
			this.loadCustomerList();
		});
	}

	onClear() {
		this.resetQuery();
		this.loadCustomerList();
	}

	clear() {
		this.customerSelect.clearModel();
	}
}
