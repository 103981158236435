<section class="contactsec">
	<div class="max-width-container">
		<div class="container">
			<p>CONTACT</p>
			<h1>Let’s connect</h1>
		</div>
	</div>
</section>

<section class="conformsec">
	<div class="max-width-container">
		<div class="container">
			<div class="formcontent edit-card">
				<h4>Contact us</h4>
				<form [formGroup]="form" (ngSubmit)="onSubmit()">
					<div class="row">
						<div class="col-12 col-lg-6">
							<div class="form-group">
								<label for="firstName" class="form-label">First Name</label>
								<input type="text" class="form-control" id="firstName" formControlName="firstname" />
								<div *ngIf="
										form.get('firstname')?.invalid &&
										form.get('firstname')?.touched
									" class="text-danger">
									First name is required
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<div class="form-group">
								<label for="lastName" class="form-label">Last Name</label>
								<input type="text" class="form-control" id="lastName" formControlName="lastname" />
								<div *ngIf="
										form.get('lastname')?.invalid &&
										form.get('lastname')?.touched
									" class="text-danger">
									Last name is required
								</div>
							</div>
						</div>

						<div class="col-12">
							<div class="form-group">
								<label for="emailAddress" class="form-label">Email Address</label>
								<input type="email" class="form-control" id="emailAddress" formControlName="username" />
								<div *ngIf="
										form.get('username')?.invalid &&
										form.get('username')?.touched
									" class="text-danger">
									Email address is required and must be valid
								</div>
							</div>
						</div>

						<div class="col-12">
							<div class="form-group">
								<label for="contactNumber" class="form-label">Mobile Phone</label>
								<ngx-intl-tel-input [cssClass]="'custom form-control shadow-none border-0 mb-0'"
									[preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
									[searchCountryFlag]="true" [searchCountryField]="[
										SearchCountryField.Iso2,
										SearchCountryField.Name
									]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Australia" [maxLength]="15"
									[phoneValidation]="true" [numberFormat]="PhoneNumberFormat.National" name="mobile"
									formControlName="mobile" [separateDialCode]="separateDialCode">
								</ngx-intl-tel-input>
								<div *ngIf="
										form.get('mobile')?.invalid && form.get('mobile')?.touched
									" class="text-danger">
									Mobile Number must be valid
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="Company" class="form-label">Company</label>
								<input type="email" class="form-control" id="Company" placeholder="" formControlName="company" />
							</div>
						</div>
						<div class="col-12">
							<div class="form-group">
								<label for="generalDesc" class="form-label">Message</label>
								<textarea class="form-control" name="" id="generalDesc" formControlName="generalDesc"
									placeholder="Message"></textarea>
							</div>
						</div>
						<div class="col-12">
							<re-captcha (resolved)="resolved($event)" [size]="'compact'"
								siteKey="6LeTtNYpAAAAAJMedwnoozdTodyBLkS2h9xkZiDC"></re-captcha>
						</div>
						<div class="col-12">
							<button class="btn btn-primary" [disabled]="form.invalid || !captcha || isLoading">
								<p *ngIf="!isLoading"> Send a request </p>
								<div class="spinner-border" role="status" *ngIf="isLoading">
									<span class="visually-hidden">Loading...</span>
								</div>
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="rightsec">
				<div class="cardssec">
					<div class="itemn">
						<div class="tpicon">
							<span class="btn btn-secondary-icon">
								<img src="../../../../assets/images/phone.svg" alt="" />
							</span>
						</div>
						<div class="masw">
							<div class="cssecs">
								<div class="visues">Call us</div>
								<h5>1300 107 233</h5>
							</div>
							<a href="tel:1300 107 233" class="iconsns btn btn-secondary-icon">
								<img src="../../../../assets/images/arrow-right.svg" alt="" />
							</a>
						</div>
					</div>
					<div class="itemn">
						<div class="tpicon">
							<span class="btn btn-secondary-icon">
								<img src="../../../../assets/images/mail (1).svg" alt="" />
							</span>
						</div>
						<div class="masw">
							<div class="cssecs">
								<div class="visues">Send as an email</div>
								<h5>sales@reali.au</h5>
							</div>
							<a href="mailto:sales@reali.au" class="iconsns btn btn-secondary-icon">
								<img src="../../../../assets/images/arrow-right.svg" alt="" />
							</a>
						</div>
					</div>
				</div>
				<div class="bottommd">
					<div class="itemn">
						<div class="tpicon">
							<span href="#" class="btn btn-secondary-icon">
								<img src="../../../../assets/images/home.svg" alt="" />
							</span>
						</div>
						<div class="masw">
							<div class="cssecs">
								<div class="visues">VISIT US</div>
								<h5>Check out our 3 locations</h5>
							</div>
							<a [routerLink]="['/contactus']" fragment="ourLocation" class="iconsns btn btn-secondary-icon">
								<img src="../../../../assets/images/arrow-down.svg" alt="" />
							</a>
						</div>
					</div>
				</div>
				<div class="visitsocial gap-3">
					<p>VISIT US ON SOCIAL MEDIA</p>
					<ul>
						<li>
							<a target="_blank" class="btn btn-secondary-icon" href="https://www.facebook.com/realiuniforms/"><svg
									fill="none" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 310 310" xml:space="preserve">
									<g id="XMLID_834_">
										<path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
                 c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
                 V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
                 C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
                 c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z" fill="#666D80" />
									</g>
								</svg>
							</a>
						</li>
						<li>
							<a target="_blank" class="btn btn-secondary-icon" href="https://www.instagram.com/realiuniforms/"><svg
									xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M7.29086 10.0003C7.29086 8.50458 8.50342 7.29202 9.99919 7.29202C11.495 7.29202 12.7075 8.50458 12.7075 10.0003C12.7075 11.4961 11.495 12.7087 9.99919 12.7087C8.50342 12.7087 7.29085 11.4961 7.29086 10.0003Z"
										fill="#666D80"></path>
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M5.83334 2.5L14.1667 2.5C16.0076 2.5 17.5 3.99239 17.5 5.83333L17.5 14.1667C17.5 16.0076 16.0076 17.5 14.1667 17.5L5.83333 17.5C3.99238 17.5 2.5 16.0076 2.5 14.1667L2.5 5.83333C2.5 3.99238 3.99239 2.5 5.83334 2.5ZM15.4166 5.625C15.4166 5.97018 15.1367 6.25 14.7916 6.25C14.4464 6.25 14.1666 5.97018 14.1666 5.625C14.1666 5.27982 14.4464 5 14.7916 5C15.1367 5 15.4166 5.27982 15.4166 5.625ZM9.99919 6.04202C7.81306 6.04202 6.04086 7.81422 6.04086 10.0003C6.04085 12.1865 7.81306 13.9587 9.99919 13.9587C12.1853 13.9587 13.9575 12.1865 13.9575 10.0004C13.9575 7.81422 12.1853 6.04202 9.99919 6.04202Z"
										fill="#666D80"></path>
								</svg></a>
						</li>
						<li>
							<a target="_blank" class="btn btn-secondary-icon" href="https://www.tiktok.com/@realiuniforms"><svg
									xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M13.749 1.66797H10.9539V13.0206C10.9539 14.3733 9.87883 15.4844 8.54099 15.4844C7.20316 15.4844 6.12812 14.3733 6.12812 13.0206C6.12812 11.6921 7.17927 10.6051 8.46934 10.5569V7.70663C5.62643 7.75491 3.33301 10.0979 3.33301 13.0206C3.33301 15.9675 5.67421 18.3346 8.5649 18.3346C11.4555 18.3346 13.7967 15.9433 13.7967 13.0206V7.19936C14.8479 7.97232 16.1379 8.43126 17.4997 8.45542V5.60517C15.3974 5.5327 13.749 3.79357 13.749 1.66797Z"
										fill="#666D80"></path>
								</svg></a>
						</li>
						<li>
							<a target="_blank" class="btn btn-secondary-icon"
								href="https://www.linkedin.com/company/reali-supply/"><svg xmlns="http://www.w3.org/2000/svg" width="20"
									height="20" viewBox="0 0 20 20" fill="none">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M5.8227 17.5C5.84339 17.5 5.86016 17.4832 5.86016 17.4625L5.86017 7.51291C5.86017 7.49219 5.84339 7.47539 5.8227 7.47539L2.78712 7.47539C2.76643 7.47539 2.74966 7.49219 2.74966 7.51291L2.74966 17.4625C2.74966 17.4832 2.76643 17.5 2.78712 17.5L5.8227 17.5ZM2.5 4.30476C2.5 5.30147 3.30628 6.10951 4.30491 6.10951C5.29946 6.10951 6.10573 5.29737 6.10573 4.30476C6.10573 3.30804 5.29946 2.5 4.30491 2.5C3.30628 2.5 2.5 3.30804 2.5 4.30476ZM17.4625 17.5C17.4832 17.5 17.5 17.4832 17.5 17.4625L17.5 11.9996C17.5 9.30065 16.9188 7.22518 13.7715 7.22518C12.2699 7.22518 11.2579 8.04833 10.84 8.83184C10.8356 8.84022 10.8269 8.84536 10.8175 8.84536C10.8034 8.84536 10.792 8.83392 10.792 8.8198L10.792 7.51292C10.792 7.49219 10.7752 7.47539 10.7545 7.47539L7.84988 7.47539C7.82919 7.47539 7.81242 7.49219 7.81242 7.51291L7.81242 17.4625C7.81242 17.4832 7.82919 17.5 7.84988 17.5L10.8773 17.5C10.898 17.5 10.9147 17.4832 10.9147 17.4625L10.9147 12.5451C10.9147 11.2367 11.1603 9.96923 12.7769 9.96923C14.3731 9.96923 14.3936 11.4664 14.3936 12.6272L14.3936 17.4625C14.3936 17.4832 14.4104 17.5 14.4311 17.5L17.4625 17.5Z"
										fill="#666D80"></path>
								</svg></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<section id="ourLocation" class="ourlocationsec">
	<div class="max-width-container">
		<div class="container">
			<h2><span>Our</span> Locations</h2>
			<div class="location-info">
				<div class="info">
					<div class="img-box">
						<img src="../../../../assets/images/conlocimg1.webp" alt="" />
					</div>
					<div class="office-loc active">Head Office</div>
					<h5>Brisbane</h5>
					<p>
						41-43 Nealdon Drive <br />
						Meadowbrook <br />
						QLD, 4131
					</p>
				</div>
				<div class="info">
					<div class="img-box">
						<img src="../../../../assets/images/conlocimg2.webp" alt="" />
					</div>
					<div class="office-loc">West Coast</div>
					<h5>Perth</h5>
					<p>
						398C Victoria Road <br />
						Malaga <br />
						WA, 6090
					</p>
				</div>
				<div class="info">
					<div class="img-box">
						<img src="../../../../assets/images/conlocimg3.webp" alt="" />
					</div>
					<div class="office-loc">North QLD</div>
					<h5>Mackay</h5>
					<p>
						90 Connors Road <br />
						Paget <br />
						QLD, 4740
					</p>
				</div>
			</div>
			<div class="hrline d-none">
				<svg xmlns="http://www.w3.org/2000/svg" width="1200" height="2" viewBox="0 0 1200 2" fill="none">
					<path d="M0 1L1200 1.0001" stroke="#DFE1E7" stroke-dasharray="8 8"></path>
				</svg>
			</div>
			<div class="mapinfo d-none">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.6424069401396!2d153.13182827525225!3d-27.666533276205914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9141673c1ac445%3A0xf88e8693a1211be4!2s41-43%20Nealdon%20Dr%2C%20Meadowbrook%20QLD%204131%2C%20Australia!5e0!3m2!1sen!2sin!4v1709295131511!5m2!1sen!2sin"
					width="1200" height="538" style="border: 0" allowfullscreen="" loading="lazy"
					referrerpolicy="no-referrer-when-downgrade"></iframe>
			</div>
		</div>
	</div>
</section>