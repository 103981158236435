import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInformationEditComponent } from './user-information-edit/user-information-edit.component';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { UserGroupEditComponent } from './user-group-edit/user-group-edit.component';
import { SessionApi } from 'src/app/main/api/session.api';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';
import { Subject, Subscription, combineLatest, takeUntil } from 'rxjs';
import { SecurityService } from 'src/app/main/services/security.service';
import { IUserGroup } from 'src/app/main/model/group.model';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-user-edit',
  template: `
  <div class="main-right-content">
  <nav aria-label="breadcrumb" class="pageBreadcrumb">
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item">
        <a *ngIf="!this.isAdmin"  [routerLink]="['/account/users']">Users</a>
        <a *ngIf="this.isAdmin"  (click)="navigateToUserEdit()">Users</a>
      </li>
      <li class="breadcrumb-item">
        <a *ngIf="!this.isAdmin"  [routerLink]="['/account/users']">{{userName}}</a>
        <a *ngIf="this.isAdmin" (click)="navigateToUserEdit()">{{userName}}</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{activeTag}}
      </li>
    </ol>
  </nav>

  <div class="manageUser">
    <div class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4">
      <h1 class="title mb-0">{{activeTag}}</h1>
      <div class="edit-btns d-none d-lg-flex">
        <button *ngIf="activeTabIdx !== 1 && activeTabIdx !== 3" class="btn btn-secondary" type="button" (click)="navigateToUserEdit()">Cancel</button>
        <button *ngIf="activeTabIdx !== 1 && activeTabIdx !== 3" class="btn btn-primary" type="button" (click)="onSubmit()">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          Submit
        </button>
      </div>
    </div>

    <app-tab-set #tabSet [tabs]="getTabs()" [activeTabIdx]="activeTabIdx" (click)="onTabChange(tabSet.activeTabIdx)"></app-tab-set>
    <div>
      <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }">
        <app-user-information-edit *ngIf="activeTabIdx == 0"></app-user-information-edit>
        <div class="d-grid d-lg-none gap-3">
          <button class="btn btn-secondary w-100" type="button" (click)="navigateToUserEdit()">Cancel</button>
          <button  class="btn btn-primary w-100" type="button" (click)="onSubmit()">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            Submit
          </button>
        </div>
      </div>
      <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }" *ngIf="enableAllocationLink || isAdmin">
        <app-user-allocation-edit *ngIf="activeTabIdx == 1"></app-user-allocation-edit>
      </div>
      <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2 }" *ngIf="enableGroupAccess || isAdmin">
        <app-user-group-edit *ngIf="activeTabIdx == 2"></app-user-group-edit>
        <div class="d-grid d-lg-none gap-3">
        <button class="btn btn-secondary w-100" type="button" (click)="navigateToUserEdit()">Cancel</button>
        <button  class="btn btn-primary w-100" type="button" (click)="onSubmit()">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          Submit
        </button>
      </div>
      </div>
      <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 2 }" *ngIf="!enableGroupAccess&& isAdmin === false">
      <div class="no-results-content" *ngIf="enableGroupAccess == false && isAdmin === false ">
        <div class="no-result-img">
          <img src="../../../../assets/images/No-result.png" alt="No-result" />
        </div>
        <h4 class="title">No Results!</h4>
        <p class="description">
          You don't have access for edit groups.
        </p>
      </div>
      </div>

      <div class="manage-user-list" [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 3 }">
        <app-order-list  *ngIf="activeTabIdx == 3"></app-order-list>
      </div>
    </div>
  </div>
</div>
  `,
  styleUrls: []
})
export class UserEditComponent implements OnInit {
  public tab: string | null;
  userName: string | null;
  activeTabIdx: number = 0;
  activeTag: string = 'Edit user';
  isAdmin: boolean = false;
  enableAllocationLink: boolean = false;
  enableGroupAccess: boolean = false;
  public tags = ['Edit user', 'Edit Allocation', 'Edit Groups', 'Order History'];
  @ViewChild(UserInformationEditComponent) userInformationEditComponent!: UserInformationEditComponent;
  @ViewChild(UserGroupEditComponent) userGroupEditComponent!: UserGroupEditComponent;

  private subscriptionGroup: Subscription = new Subscription();
  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private customerUserService: CustomerUserService,
    private router: Router,
    private session: SessionApi,
    private securityService: SecurityService,
    private notifications: NotificationsService
  ) { }

  ngOnInit(): void {
    this.userName = this.route.snapshot.queryParamMap.get('userName');
    const activeTab = this.route.snapshot.queryParamMap.get('activeTab');
    this.tab = this.route.snapshot.queryParamMap.get('tab');
    if (activeTab) {
      this.activeTabIdx = +activeTab;
      this.activeTag = this.tags[this.activeTabIdx];
    }
    this.isAdmin = !!this.session.$userData.getValue()?.isAdmin;
    this.monitorAccess();
  }

  onTabChange(event: number): void {
    this.activeTabIdx = event;
    this.activeTag = this.getTabs()[this.activeTabIdx];
  }

  getTabs(): string[] {
    return this.tags;
  }

  onSubmit(): void {
    switch (this.activeTabIdx) {
      case 0:
        this.updateUserInformation();
        break;
      case 2:
        this.saveUserGroups();
        break;
      default:
        break;
    }
  }

  updateUserInformation(): void {
    const formValue = this.userInformationEditComponent.customerUser;
    const userId = formValue?.id;

    if (!this.userInformationEditComponent.isValid()) {
      window.scroll(0, 0);
      this.notifications.warn('Edit User', 'Please fill all required fields');
      return;
    }

    if (userId) {
      formValue.costAccount = formValue.costAccount?.toString();
      this.customerUserService.update(userId, formValue).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        // this.navigateToUserEdit();
      });
    }
  }

  saveUserGroups(): void {
    const userGroups = this.userGroupEditComponent.model.userGroups;
    const userId = this.userGroupEditComponent.customerUserId;

    if (!userGroups || userGroups.length === 0 && !this.userGroupEditComponent.isRemoved) {
      this.notifications.warn('Add Group', 'Please select group');
      return;
    }

    this.customerUserService.saveCustomerUserGroups(userId, userGroups).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      // this.navigateToUserEdit();
    });
  }

  public navigateToUserEdit(): void {
    const currentUrlSegments = this.route.snapshot.url.map(segment => segment.path);
    const editIndex = currentUrlSegments.indexOf('edit');
    let baseUrl: string, editSegment: string, navigateOptions;
    if (editIndex !== -1 && this.tab) {
      if (this.tab == 'view') {
        baseUrl = 'manage/customer/view/';
        editSegment = currentUrlSegments[editIndex + 1];
        navigateOptions = {
          queryParams: { activeTab: 1 }
        };
      } else {
        baseUrl = 'manage/customer/edit/';
        editSegment = currentUrlSegments[editIndex + 1];
        navigateOptions = {
          queryParams: { activeTab: this.tab === 'user' ? 1 : 2 }
        };
      }

      const finalUrl = `${baseUrl}${editSegment}`;
      this.router.navigate([finalUrl], this.tab ? navigateOptions : undefined);
    } else {
      this.router.navigate(this.isAdmin ? ['/manage/users'] : ['/account/users']);
    }
  }

  monitorAccess(): void {
    this.subscriptionGroup.add(
      combineLatest(
        this.securityService.canAllocationAccess(),
        this.securityService.canModifyUserGroups()
      ).pipe(takeUntil(this.unsubscribe$)).subscribe(([isAllocationAccess, isModifyUserGroups]) => {
        this.enableAllocationLink = isAllocationAccess;
        this.enableGroupAccess = isModifyUserGroups;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
