<section class="solutsec">
	<div class="max-width-container">
		<div class="container">
			<div class="sheads">
				<h1 class="u-select-none">SUMS</h1>
				<p class="u-select-none">
					Is a powerful custom-built Safety Uniform Management System designed
					for medium to large organisations empowering them to procure uniforms
					and manage expenditure effectively
				</p>
			</div>
			<div class="content">
				<div class="items">
					<h2>Manage uniform allocations with ease</h2>
					<p>
						Simplify the process of uniform allocations with our streamlined
						system. With our efficient solution, you can easily manage complex
						employee allocations and streamline the approval process through
						tailored workflow approval. Take control of uniform spending across
						employees or business units with ease.
					</p>
				</div>
				<div class="items">
					<div class="mainbox">
						<div class="img-boxa">
							<img src="../../../../assets/images/solutsec1.png" alt="" />
						</div>
						<div class="img-boxb">
							<img src="../../../../assets/images/solutsec2.png" alt="" />
						</div>
						<div class="img-boxc">
							<img src="../../../../assets/images/solutsec3.png" alt="" />
						</div>
						<button>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M10 4.75V15.25"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M4.75 10H15.25"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</button>
						<div class="credbox">
							<span>
								<div class="radialProgressBar progress-70">
									<div class="overlay">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
										>
											<path
												d="M11.8396 2.02734H2.59516C2.04533 2.02734 1.60079 2.61844 1.60028 3.22734C1.59961 4.02734 2.04533 4.81845 2.59516 4.81845H12.9774C13.7629 4.81845 14.3996 4.65521 14.3996 5.44068V12.2673C14.3996 13.2099 13.6355 13.974 12.6929 13.974H3.30628C2.36371 13.974 1.59961 13.2099 1.59961 12.2673V3.62734M10.9974 8.84287L10.9863 8.85401"
												stroke="black"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											></path>
										</svg>
									</div>
								</div>
							</span>
							<div class="infos">
								<div class="blance">Balance</div>
								<div class="qgar">2 garments</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="solutsecabs">
	<div class="max-width-container">
		<div class="container">
			<div class="rinner">
				<div class="box">
					<div class="vision-content">
						<div class="minhead text-warning justify-content-md-center">
							<img src="../../../../assets/images/infoicon.svg" alt="" />
							ABOUT SUMS
						</div>
						<h4>
							With SUMS, you can grant your employees the convenience of placing
							their own uniform orders while retaining complete control and
							visibility over what they can order and spend. Say goodbye to the
							manual, time-consuming process of managing uniform contracts, and
							say hello to seamless efficiency.
						</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="stget">
	<div class="max-width-container">
		<div class="container">
			<div class="content">
				<div class="infoleft">
					<h4 className="str">Streamline Uniform Purchasing with Automation</h4>
					<p>
						Empower your employees by giving them the ability to place their own
						uniform orders. Experience faster order processing times with orders
						instantly appearing in our system. Say goodbye to the manual process
						of keying in PDF purchase orders, saving valuable time and
						eliminating potential errors.
					</p>
					<div class="imginfo">
						<div class="topimgbox">
							<div class="imgsa">
								<img src="../../../../assets/images/strimgn.png" alt="" />
							</div>
							<button class="addtocart btn btn-light">Add to cart</button>
							<div class="buttonaddcart">
								<div class="cartqty"><span class="qty">3</span></div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M6 15.5L3 1.5H1"
										stroke="#323232"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M6.25 15.5H16.25"
										stroke="#323232"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<circle
										cx="16.25"
										cy="17.25"
										r="1.75"
										stroke="#323232"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<circle
										cx="6.25"
										cy="17.25"
										r="1.75"
										stroke="#323232"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M3.64307 4.5H17.9996C18.3103 4.5 18.6033 4.64438 18.7926 4.89073C18.9819 5.13708 19.0459 5.45741 18.9659 5.75759L17.3664 11.7576C17.2497 12.1954 16.8533 12.5 16.4002 12.5H5.35737"
										stroke="#323232"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
						<div class="bottomimgbox">
							<div class="infobox">
								<div class="backcards">
									<div class="detailedcard">
										<div class="content">
											<p class="sono">SO-RS-0046973</p>
											<span class="badge bg-aqua-25">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<circle
														cx="10"
														cy="10"
														r="2"
														fill="transparent"
													></circle>
												</svg>
												Processing</span
											>
										</div>
										<div class="pros">
											<p class="sotime">4:30pm, Sep 25,2023</p>
											<div class="amount">$518.76</div>
										</div>
									</div>
								</div>
								<div class="maincards">
									<div class="content">
										<p class="sono">SO-RS-0046973</p>
										<span class="badge bg-aqua-25">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<circle
													cx="10"
													cy="10"
													r="2"
													fill="transparent"
												></circle>
											</svg>
											Processing</span
										>
									</div>
									<div class="pros">
										<p class="sotime">4:30pm, Sep 25,2023</p>
										<div class="amount">$518.76</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="inforight">
					<h4 className="str">Get Information on Demand</h4>
					<p>
						Access an array of reports that can be exported at any time. With
						complete order history easily accessible, you can stay compliant
						with record-keeping requirements. View your employees’ year-to-date
						spending limit, ensuring they stay within their allocated uniform
						budget. Keep a watchful eye on orders being approved and quickly
						monitor orders that exceed budget expectations.
					</p>
					<div class="imginfo">
						<div class="top-img-box">
							<button class="btn btn-primary">
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M4 10V16C4 16.3978 4.15804 16.7794 4.43934 17.0607C4.72064 17.342 5.10218 17.5 5.5 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V10"
											stroke="white"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M13 5.5L10 2.5L7 5.5"
											stroke="white"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M10 2.5V12.25"
											stroke="white"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								Export
							</button>
							<div class="card-contents">
								<div class="cardnew">
									<div class="roundicon">
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M14.8 2.53516H3.24444C2.55716 2.53516 2.00148 3.27402 2.00084 4.03516C2 5.03516 2.55716 6.02405 3.24444 6.02405H16.2222C17.2041 6.02405 18 5.81998 18 6.80182V15.3352C18 16.5134 17.0449 17.4685 15.8667 17.4685H4.13333C2.95513 17.4685 2 16.5134 2 15.3352V4.53516M13.7473 11.0546L13.7333 11.0685"
													stroke="#666D80"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</span>
									</div>
									<div class="conhead">
										<p>User allocation report</p>
										<div class="lineloader">
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div>
								</div>
								<div class="cardnew">
									<div class="roundicon">
										<span>
											<img src="../../../../assets/images/truck.svg" alt="" />
										</span>
									</div>
									<div class="conhead">
										<p>Order details report</p>
										<div class="lineloader">
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div>
								</div>
								<div class="bottom-card-contents">
									<div class="cardnew">
										<div class="roundicon">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M14.8 2.53516H3.24444C2.55716 2.53516 2.00148 3.27402 2.00084 4.03516C2 5.03516 2.55716 6.02405 3.24444 6.02405H16.2222C17.2041 6.02405 18 5.81998 18 6.80182V15.3352C18 16.5134 17.0449 17.4685 15.8667 17.4685H4.13333C2.95513 17.4685 2 16.5134 2 15.3352V4.53516M13.7473 11.0546L13.7333 11.0685"
														stroke="#666D80"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
										</div>
										<div class="conhead">
											<p>User allocation report</p>
											<div class="lineloader">
												<div></div>
												<div></div>
												<div></div>
											</div>
										</div>
									</div>
									<div class="cardnew">
										<div class="roundicon">
											<span>
												<img src="../../../../assets/images/truck.svg" alt="" />
											</span>
										</div>
										<div class="conhead">
											<p>Order details report</p>
											<div class="lineloader">
												<div></div>
												<div></div>
												<div></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="solslid">
	<div class="max-width-container">
		<div class="container">
			<h2>Enhanced Security and Connectivity</h2>
			<p>
				User authentication is made easy with Single Sign-On (SSO) integration
				with popular platforms such as Office 365 and Gsuite. This streamlines
				the login process and improves the overall user experience. Integrate
				with other systems seamlessly using our API integration. This enables
				smooth data transfer and enhanced functionality.
			</p>

			<div class="mdslide">
				<div class="sliding">
					<ul class="leftimgs justify-content-end">
						<li><img src="../../../../assets/images/compimg1.svg" alt="" /></li>
						<li><img src="../../../../assets/images/compimg2.svg" alt="" /></li>
						<li><img src="../../../../assets/images/compimg3.svg" alt="" /></li>
						<li><img src="../../../../assets/images/compimg4.svg" alt="" /></li>
					</ul>
					<span
						><img src="../../../../assets/images/compmain.svg" alt=""
					/></span>
					<ul class="rightimgs justify-content-start">
						<li><img src="../../../../assets/images/compimg5.svg" alt="" /></li>
						<li><img src="../../../../assets/images/compimg6.svg" alt="" /></li>
						<li><img src="../../../../assets/images/compimg7.svg" alt="" /></li>
						<li><img src="../../../../assets/images/compimg8.svg" alt="" /></li>
					</ul>
				</div>
				<div class="btmsbr">
					<div class="que">+12</div>
					<div class="apiint">API integrations</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="taylorsec">
	<div class="max-width-container">
		<div class="container">
			<div class="content">
				<h2>Tailored Uniforms for Every Department</h2>
				<p>
					Simplify the management of uniform requirements across various
					departments with our sophisticated catalogue management system. With
					SUMS, you can effortlessly accommodate the unique uniform needs of
					each department, ensuring that every team receives the appropriate
					attire. Rest easy knowing that our comprehensive cataloguing features
					will streamline your uniform contract management. With SUMS, you can
					easily organise and update uniform options, making it effortless to
					meet the specific needs of each department. Experience seamless
					efficiency and precision in managing your uniform program.
				</p>
			</div>
			<div class="imgboxinfo">
				<div class="plsec">
					<div class="departde">
						<div class="plusbbtn">
							<img src="../../../../assets/images/medicalplus.svg" alt="" />
						</div>
						<div class="clientinfo">
							<div class="img-box">
								<img src="../../../../assets/images/ssumt1.png" alt="" />
							</div>
							<div class="clname">Peter Wood</div>
							<div class="clposition">Procurement Manager at Med-Tech</div>
						</div>
					</div>
					<div class="departinnerbox">
						<div class="nmesw">
							<div class="img-box">
								<img src="../../../../assets/images/denam.png" alt="" />
							</div>
							<div class="innames">
								<div class="nmde">Peter Wood</div>
								<div class="nmpro">Procurement Manager at Med-Tech</div>
							</div>
						</div>
					</div>
					<div class="poabsolutebox">
						<div class="otherimg imgposa">
							<div class="img-box">
								<img src="../../../../assets/images/ssumt2.png" alt="" />
							</div>
							<span>Engineering</span>
						</div>
						<div class="otherimg imgposb">
							<div class="img-box">
								<img src="../../../../assets/images/ssumt4.png" alt="" />
							</div>
							<span>Management</span>
						</div>
						<div class="otherimg imgposc">
							<div class="img-box">
								<img src="../../../../assets/images/ssumt3.png" alt="" />
							</div>
							<span>Drivers</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
