import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { logger } from '../util/Logger';
import { createUrl } from '../api/api.util';
import { IAuthDataResponse } from '../model/session.model';
import { SessionApi } from '../api/session.api';
import { SecurityService } from '../services/security.service';

@Injectable()
export class RoleGuard implements CanActivate {
  protected permissions: string[] = [];
  protected roles: string[] = [];

  constructor(
    private readonly router: Router,
		private readonly session: SessionApi,
		private readonly securityService: SecurityService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		const signature = 'RoleGuard.canActivate: ';
    this.permissions = route.data.permissions || [];
    this.roles = route.data.roles || [];

		return this.session.$sessionChanged.pipe(
			filter(ready => ready),
			switchMap(() => this.securityService.isAuthenticated()),
			filter(authenticated => {
				if( !authenticated ) {
					logger.info(signature + 'User is not authenticated');
					return false;
				}

				return true;
			}),
			switchMap(() => this.session.$userData),
			filter( userData => {
				if( !userData ) {
          logger.info(signature + 'Current userData could not be found');
          return false;
				}

				return true;
			}),
			map(userData => userData!),
			map(userData => {
        if (userData.isAdmin && userData.adminRole === null) {
          logger.silly('Bypassing role check due to Super Admin User');
          return true;
        }

        if (!userData.adminRole || typeof userData.adminRole.permissions !== 'string') {
          logger.error('Current Admin Role or Permissions presented in an unexpected format');
          return false;
        }

				const currentPermissions = String(userData.adminRole.permissions).split(",");
				const hasPermission = this.permissions.some(permission => permission === 'all' || currentPermissions.includes(permission));

        if (!hasPermission) {
          logger.error(`User does not have any of role ${this.permissions.join(",")}. Redirecting to Login`);
          this.router.navigate(['/home']);
        }

        return hasPermission;
			})
    );
  }
}
