import { ISupportingDocument } from "../model/supporting.document.model";
import { IBusinessUnit } from "../model/businessUnit.model";
import { ShippingDetailsClass } from "./shippingDetail.model";
import { NewDecoration, NewPackSize, NewProduct, NewProductVariation } from "./ddb.model";
import { DecorationOption } from "./decoration.model";
import * as exactMath from "exact-math";
import { EventEmitter } from "@angular/core";

export class CartAttrs {
  id?: number;
  comment: string | null;
  notes: string | null;
  orderReference: string | null;
  contactFirstName: string | null;
  contactLastName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  businessUnitId: number;
  orderNumber: string;
  orderedAt: string;
  status: string;
  statusLastChecked: string | null;
  approvedById: number | null;
  orderId: number;
  trackingDetail: string | null;
  trackingLink: string | null;
  supportingDocuments: ISupportingDocument[] | null;
  businessUnit: IBusinessUnit | null;
  salesContactEmail: string | null;
  salesContactName: string | null;
  trackingDetailUrl: string | null;
  grandTotal: number;
  shippingDetails?: ShippingDetailsClass = new ShippingDetailsClass();
  approvedBy?: {first_name: string, last_name: string, email: string};
  /**
   * @description Checks if the cart has a comment attached to it
   */
  get hasComment(): boolean {
    return !!(this.comment && this.comment.length);
  }
}

export class ServerCartItem {
  id: number;

  allocationCollectionId: number | null;
  allocationCollectionConsumption: number | null;
  allocationCollectionName: string;

  quantity: number;
  product: NewProduct;
  variation: NewProductVariation;
  packSize?: NewPackSize;
  decorations?: {
    id: number;
    decorationId: number;
    decoration: NewDecoration;
    options?: {
      decorationOption: DecorationOption,
      value: string;
    }[]
  }[];

  asNewCartItem = (): NewCartItem => {
    const result = new NewCartItem(this.product, this);
    result.selectedQuantity = this.quantity;
    if (this.packSize)
      result.selectedPack = this.packSize;
    if (this.variation) {
      result.selectedVariation = this.variation;
    }

    result.selectedDecorations = [];
    if (this.decorations) {
      result.selectedDecorations = this.decorations.map(orderItemDecoration => orderItemDecoration.decoration);
    }

    if (this.allocationCollectionId) {
      result.allocationCollectionId = this.allocationCollectionId;
      if (this.allocationCollectionConsumption)
        result.allocationCollectionConsumption = this.allocationCollectionConsumption;
    }

    return result;
  }
}

/**
* @description Name should remain as NewCartItem as this is the client-side class which will handle the properties
* required for building a CartItem for transmission
*/
export class NewCartItem {
  readonly product: NewProduct;
  private _selectedColour: string | null;
  public selectedColourString: string | null;
  private _selectedSize: string | null;
  public selectedSizeString: string | null;
  private _selectedPack: NewPackSize;
  private _selectedQuantity: number = 1;
  private _selectedVariation: NewProductVariation | undefined;

  public selectedDecorations: NewDecoration[] | undefined = [];

  quantityChanged: EventEmitter<NewCartItem> = new EventEmitter();

  /**
   * @description Allows for a newCartItem to push its quantity value back to the serverCartItem
   */
  public source: ServerCartItem;

  constructor(
    product?: NewProduct,
    source?: ServerCartItem
  ) {
    if (product)
      this.product = product;
    if (source)
      this.source = source;
  }

  get totalQuantity() {
    if (!this.selectedQuantity)
      return 0;

    if (!this.selectedPack)
      return Number(this.selectedQuantity);

    return Number(this.selectedQuantity) * this.selectedPack.itemCount;
  }

  /**
   * @description Updates the selected quantity and creates events to notify subscribers the quantity changed
   *
   * @param val
   */
  set selectedQuantity(val: string | number) {
    let qty = val;
    if (typeof qty === 'string') {
      qty = Number(qty.replace(/[^0-9]/, ''))
    } else if (val === 0) {
      qty = 1;
    }
    const newVal = Number.isNaN(qty) ? 1 : qty;
    const valChanged = this._selectedQuantity !== newVal;

    this._selectedQuantity = newVal;

    if (valChanged) {
      this.quantityChanged.emit(this);
    }
  }

  get selectedQuantity(): number | string {
    return this._selectedQuantity;
  }

  /**
   * @description Sets the selected packet (size)
   *
   * @param val
   */
  set selectedPack(val: NewPackSize) {
    this._selectedPack = val;
  }

  /**
   * @description Gets the selected packet (size)
   *
   * @returns {NewPackSize|null}
   */
  get selectedPack() {
    return this._selectedPack;
  }

  /**
   * @description Sets the selected colour in lowercase
   *
   * @param val
   */
  set selectedColour(val: string | null) {
    this._selectedColour = val ? val.toLowerCase() : val;
    this.selectedColourString = val;;
  }

  /**
   * @description Gets the current lowercase selected colour or null
   *
   * @returns {string|null}
   */
  get selectedColour(): string | null {
    return this._selectedColour;
  }

  /**
   * @description sets the selected size in lower case
   *
   * @param val
   */
  set selectedSize(val: string | null) {
    this._selectedSize = val ? val.toLowerCase() : val;
    this.selectedSizeString = val;
  }

  /**
   * @description Gets the current lowercase selected size or null
   *
   * @returns {string|null}
   */
  get selectedSize(): string | null {
    return this._selectedSize;
  }

  /**
   * @description Recalculates the selected variation on get. No set is exposed to ensure the cart Item cannot be
   * forcefully updated
   *
   * @returns {NewProductVariation}
   */
  get selectedVariation(): NewProductVariation | undefined {
    if (!this.hasVariations())
      return this._selectedVariation;

    return this.product.variations.find(variation =>
      (variation.colour || "").toLowerCase() === (this.selectedColour || "")
      && (variation.size || "").toString().toLowerCase() === (this.selectedSize || "")
    )
  }

  /**
   * @description Manually sets the selected variation to the specified value
   *
   * @param val
   */
  set selectedVariation(val: NewProductVariation | undefined) {
    this._selectedVariation = val;
    if (val) {
      this.selectedColour = val.colour;
      this.selectedSize = val.size !== null && val.size !== undefined ? val.size.toString() : null;
    }
  }

  /**
   * @description Centralized method for determining if the current cartItem has a product and variations it can process
   */
  private hasVariations = () => this.product && this.product.variations && this.product.variations.length;

  /**
   * @description Modifies the quantity of items selected. This intentionally forces cooersion so that any form input
   * which changed this value to a string has no effect
   *
   * @param {number} mod A positive or negative integer which should be added to the selected quantity
   */
  public incrementQuantity = (mod: number) => {
    this.selectedQuantity = Number(this.selectedQuantity) + mod;

    if (this.selectedQuantity < 1)
      this.selectedQuantity = 1;
  };

  /**
   * @description Returns a list of each unique size offered on the product attached to the cartItem
   *
   * @returns {Array<string>}
   */
  public getUniqueSizes(): Array<string | number> | undefined {
    if (!this.hasVariations())
      return undefined;

    const result = this.product.variations.map(variation => variation.size)
      .filter((val, idx, self) => val !== null && self.indexOf(val) === idx);

    if (result.length > 1 || (result.length === 1 && result[0].toString().length > 0))
      return result;

    return undefined;
  }

  /**
   * @description Returns a list of each unique colours offered on the product attached to the cartItem
   *
   * @returns {Array<string>}
   */
  public getUniqueColours(): Array<string> | undefined {
    if (!this.hasVariations())
      return undefined;

    const result = this.product.variations.map(variation => variation.colour)
      .filter((val, idx, self) => val !== null && self.indexOf(val) === idx);

    if (result.length > 1 || (result.length === 1 && result[0].toString().length > 0))
      return result;

    return undefined;
  }

  public getUnitPrice(): number | null {
    let count = exactMath.mul(
      Number(this.selectedQuantity),
      (this.selectedPack ? this.selectedPack.itemCount : 1),
    );

    if (this.allocationCollectionConsumption) {
      count = count - this.allocationCollectionConsumption;
    }

    if (count === 0) {
      return 0;
    }

    const total = this.getTotalPrice();

    if (total === null || total === undefined)
      return null;

    return exactMath.div(total, count);
  }

	public getTotalQuantity(): number {
		return exactMath.mul(
      Number(this.selectedQuantity),
      (this.selectedPack ? this.selectedPack.itemCount : 1),
		);
	}

	public isFullyAllocated(): boolean {
		return this.getTotalQuantity() <= (this.allocationCollectionConsumption || 0);
	}

  /**
   * @description estimates the total expected price for this cart item
   *
   * @returns {number|null}
   */
  public getTotalPrice(ignoreAllocation: boolean = false): number {
    if (!this.selectedQuantity || !this.product)
      return 0;

    const price = this.product.price || 0;
    const finalPrice = exactMath.sub(price, this.product.subsidyAmount || 0);

    let itemPrice = exactMath.mul(
			this.getTotalQuantity(),
      finalPrice
    );

    let decorationPrice = 0;

    if (this.selectedDecorations) {
      decorationPrice = exactMath.mul(
        Number(this.selectedQuantity),
        (this.selectedPack ? this.selectedPack.itemCount : 1),
        this.selectedDecorations.reduce((accumulator, val) => exactMath.add(accumulator, val.price || 0), 0)
      );
    }

    if (!ignoreAllocation && this.allocationCollectionConsumption) {
      itemPrice -= exactMath.mul(
        Number(this.allocationCollectionConsumption),
        (this.selectedPack ? this.selectedPack.itemCount : 1),
        finalPrice,
      );

      if (this.selectedDecorations) {
        decorationPrice -= exactMath.mul(
          Number(this.allocationCollectionConsumption),
          (this.selectedPack ? this.selectedPack.itemCount : 1),
          this.selectedDecorations.reduce((accumulator, val) => exactMath.add(accumulator, val.price || 0), 0)
        );
      }
    }

    return exactMath.add(itemPrice, decorationPrice);
  }

  /**
   * Estimates the total expected cost to the account for this cart item
   */
  public getAccountPrice(): number {
    if (!this.selectedQuantity || !this.product)
      return 0;

    const itemQty = Number(this.selectedQuantity) - Number(this.allocationCollectionConsumption || 0);

    let itemPrice = exactMath.mul(
      itemQty,
      (this.selectedPack ? this.selectedPack.itemCount : 1),
      Math.min(this.product.subsidyAmount || 0, this.product.price || 0)
    );

    return itemPrice;
  }

  /**
   * Estimates the total expected cost to the account for this cart item
   */
  public getUserPrice(): number {
    return exactMath.sub(this.getTotalPrice(), this.getAccountPrice());
  }

  /**
   * @description Pushes the currently selected quantity back up to the source server item if applicable
   */
  public pushQuantity() {
    if (!this.source)
      return;

    this.source.quantity = Number(this.selectedQuantity);
  }

  public allocationCollectionId: number | undefined;
  public allocationCollectionConsumption: number | undefined;

}

export enum CartStatus {
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  BACKORDERED = "BACKORDERED",
  APPROVAL = "APPROVAL",
  PENDING = "PENDING"
}

export class Cart {
  id: number;

  attrs: CartAttrs = new CartAttrs();

  itemCount: number = 0;

  items?: ServerCartItem[];
}
