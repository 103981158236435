<div class="main-right-content">
	<!-- Page Breadcrumb start here... -->
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a (click)="getRoute()">Groups
				</a>
			</li>
			<li *ngIf="queryResult" class="breadcrumb-item active" aria-current="page">
				{{ queryResult.name }}
			</li>
		</ol>
	</nav>
	<!-- Page Breadcrumb end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4">
			<h1 class="title mb-0">Acciona MWF Safety</h1>
			<div class="edit-btns w-sm-100">
				<button (click)="deleteGroup(groupId)" class="btn btn-danger w-100" type="button">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M3.25 5.5H4.75H16.75" stroke="white" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path
								d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
								stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
					Remove
				</button>
				<a [routerLink]="['/manage/users/groups', groupId]"
					[queryParams]="{'isCustomer': customerId ? true : false, customerId: customerId}" class="btn btn-primary">
					<span>
						<svg class="d-none d-lg-flex" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
							fill="none">
							<path
								d="M9.25 4H4C3.60218 4 3.22064 4.15804 2.93934 4.43934C2.65804 4.72064 2.5 5.10218 2.5 5.5V16C2.5 16.3978 2.65804 16.7794 2.93934 17.0607C3.22064 17.342 3.60218 17.5 4 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V10.75"
								stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path
								d="M14.875 2.87517C15.1734 2.5768 15.578 2.40918 16 2.40918C16.422 2.40918 16.8266 2.5768 17.125 2.87517C17.4234 3.17354 17.591 3.57821 17.591 4.00017C17.591 4.42213 17.4234 4.8268 17.125 5.12517L10 12.2502L7 13.0002L7.75 10.0002L14.875 2.87517Z"
								stroke="#F8FAFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
						<svg class="d-flex d-lg-none" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
							fill="none">
							<path
								d="M10 16.0002H16.75M13.375 3.62517C13.6734 3.3268 14.078 3.15918 14.5 3.15918C14.7089 3.15918 14.9158 3.20033 15.1088 3.28029C15.3019 3.36024 15.4773 3.47743 15.625 3.62517C15.7727 3.77291 15.8899 3.9483 15.9699 4.14132C16.0498 4.33435 16.091 4.54124 16.091 4.75017C16.091 4.9591 16.0498 5.16599 15.9699 5.35902C15.8899 5.55204 15.7727 5.72743 15.625 5.87517L6.25 15.2502L3.25 16.0002L4 13.0002L13.375 3.62517Z"
								stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
					Edit
				</a>
			</div>
		</div>

		<app-tab-set #tabSet [activeTabIdx]="activeTabIdx" [tabs]="getTabs()" [counts]="count"></app-tab-set>

		<div class="manage-user-list">
			<div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 0 }">
				<app-group-overview *ngIf="queryResult" [group]="queryResult"></app-group-overview>
			</div>
			<div [ngClass]="{ 'd-none': tabSet.activeTabIdx !== 1 }">
				<app-group-collections-overview *ngIf="queryResult" [group]="queryResult"></app-group-collections-overview>
			</div>
		</div>
	</div>
</div>