import { ShippingDetailsClass } from "../model/shippingDetail.model";
import { IShippingDetails } from "../model/shippingDetail.model";

/**
 * Generates easily read display text for any given shipping details item
 * @param details
 * @param includeName
 */
export function addressDisplayText(details: (ShippingDetailsClass & { displayName?: string } | IShippingDetails), includeName = false) {
  return (includeName && (details.addressName || details['displayName']) ? (details['displayName'] || details.addressName) + ': ' : '') +
    (details.streetAddress ? details.streetAddress + ' ' : '') +
    (details.streetAddress2 ? details.streetAddress2 + ' ' : '') +
    (details.suburb ? details.suburb : '');
}