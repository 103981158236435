import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { IQueryFilter } from '../../model/query.filter.class';
import { ActivatedRoute, Router } from '@angular/router';
import { has, isNumber } from 'lodash';
import { NewDecoration, NewProduct, NewProductVariation, ProductCustomer } from '../../model/ddb.model';
import { DecorationOption } from '../../model/decoration.model';
import { DecorationService } from '../../services/decoration.service';
import { FileUploadService } from '../../services/fileUpload.service';
import { UnleashedService } from '../../services/unleashed.service';
import { NotificationsService } from 'angular2-notifications';
import { SelectOption } from 'src/app/types';
import { CustomSelectComponent } from '../../template/custom-select/custom-select.component';

enum LifecycleStatus {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

@Component({
  selector: 'app-decoration-edit',
  templateUrl: './decoration-edit.component.html',
  styleUrls: []
})
export class DecorationEditComponent implements OnInit {
  decoration: NewDecoration = new NewDecoration();
  customerSelectOptions: { id: string, text: string }[] = [];

  /**
   * @description Necessary as a lookup for selected inventory items
   */
  lastUnleashedProductListResult: {
    id: string,
    text: string,
    ProductDescription: string,
    ProductCode: string,
    DefaultSellPrice: number
  }[] = [];
  page: number = 1;

  /**
 * @description contains complete objects representing the selected inventory items. The list must contain
 *              the whole object as an update to the option list after the selection has been made will remove
 *              data which would be required for retrieval if the inventory items are added to the product.
 */
  selectedInventoryItems: {
    id: string,
    text: string,
    ProductDescription: string,
    ProductCode: string,
    DefaultSellPrice: number
  }[] = [];

  selectedCustomer: { id: string, text: string }[] = [];
  public activeClassId: number = 1;

  public query = new IQueryFilter({
    limit: 10,
    sortBy: 'name'
  });
  public term: string | undefined;
  private _currentCustomerPage: number = 1;
  @ViewChild('inventorySelect')
  inventory: CustomSelectComponent;

  @ViewChild('customerData')
  customerViewChild: CustomSelectComponent;

  constructor(
    private customerService: CustomerService,
    public route: ActivatedRoute,
    private decorationService: DecorationService,
    private fileUploadService: FileUploadService,
    private unleashedService: UnleashedService,
    public router: Router,
    public notification: NotificationsService
  ) { }

  ngOnInit() {
    this.handleCustomerListGet();
    this.initInventorySelect();
    this.route.params.subscribe(params => {
      if (has(params, 'id')) {
        this.decoration.id = params.id;
        if (this.decoration.id) {
          this.loadDecoration(this.decoration.id);
        }
        this.loadDecorationDraft(this.decoration.id);
      } else {
        // this.loadDecorationDraft();
      }
    });
  }

  /**
 * @description Turns response from Customer Get into options for select2
 *
 * @param {QueryResult<HasId & NewCustomer>} result
 */
  handleCustomerListGet = (isScroll: boolean = false) => {
    if (isScroll) {
      this._currentCustomerPage = isScroll ? this._currentCustomerPage + 1 : 1;
      this.query.skip = this.query.limit * (this._currentCustomerPage - 1);
    }
    this.customerService.list(this.query)
      .subscribe((result) => {
        const newOptions = result.rows.map(customer => ({ id: customer.id.toString(), text: customer.name }));

        if (isScroll) {
          this.customerSelectOptions.push(...newOptions);
        } else {
          this.customerSelectOptions = newOptions;
        }
        this.customerViewChild.lifecycleStatusSubject.next(LifecycleStatus.LOADED);
      });
  }

  /**
 * @description Loads any drafts of the target product from the database, if applicable
 *
 * @param id
 */
  loadDecorationDraft(id?: number) {
    this.decorationService.getDecorationDraft(id)
      .subscribe(draft => {
        if (!draft?.rows.length)
          return;

        this.decoration = JSON.parse(draft.rows[0].decorationJSON);
      },
        (error) => console.log(error)
      );
  }

  /**
 * @description Loads the existing product data from the database
 */
  loadDecoration(id: number) {
    this.decorationService.get(id)
      .subscribe((decoration: NewDecoration) => {
        this.decoration = decoration;
      });
  }

  /**
 * @description Gets the url of an image for any given colour
 *
 * @param colour
 */
  getImageUrl(colour: string): string | null {
    let existingProductImage = (this.decoration.images || []).find(image => image.name === colour);

    if (existingProductImage)
      return existingProductImage.url;

    return null;
  }

  /**
 * @description Get a list of unique colours in addition to the default (front/back) for product images
 *
 * @returns string[]
 */
  getImageOptions = () => [
    'Front',
    'Back',
    ...(this.decoration.variations || []).map(variation => (variation.colour || '')),
    // When a inventory item is removed but the image definition remains, display it so that the administrator can deal with it
    ...(this.decoration.images || []).map(image => (image.name || ''))
  ].filter((val, idx, self) => val.length && self.indexOf(val) === idx);

  /**
 * @description Persists an image to s3 via file upload then attaches it to the product
 *
 * @param event the HTML event
 * @param colour
 */
  persistS3Image(event: Event, colour: string) {
    let fileInput = event.srcElement;
    // @ts-ignore
    let theFile = fileInput.files[0];
    if (theFile) {
      this.fileUploadService.uploadProductImage(theFile, (err, data) => {
        let s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static.reali.supply/';
        let cfDistribution = 'https://static.reali.supply/';
        let url = data.Location.replace(s3Prefix, cfDistribution);

        let existingProductImage = this.decoration.images.find(image => image.name === colour);
        if (existingProductImage)
          existingProductImage.url = url;
        else
          this.decoration.images.push({
            name: colour,
            url: url
          });
      });
    }
  }

  /**
 * @description Removes a productImage from the product
 */
  deleteImage(colour: string, event) {
    for (let i = 0; i < this.decoration.images.length; i++) {
      if (this.decoration.images[i].name === colour) {
        const image = this.decoration.images[i];
        this.decoration.images.splice(i, 1);

        const isDefault = image.url === this.decoration.imageUrl;
        // If the removed image was the default look for an alternative
        if (isDefault) {
          const anotherDefaultExists = !!this.decoration.images.find(candidate => candidate.url === this.decoration.imageUrl);
          if (!anotherDefaultExists) {
            const replacementDefault = this.decoration.images.find(candidate => candidate.url && candidate.url.length > 0);

            this.decoration.imageUrl = replacementDefault ? replacementDefault.url : '';
          }
        }

        break;
      }
    }
    event.preventDefault();
    event.stopPropagation();
  }

  /**
 * @description Gets the dispay text for any given productCustomer
 *
 * @param productCustomer
 */
  getProductCustomerName(productCustomer: ProductCustomer) {
    if (!this.customerSelectOptions)
      return;

    let customerSelectOption = this.customerSelectOptions.find(selectOption => {
      return selectOption.id === productCustomer.customerId.toString()
    });

    if (!customerSelectOption)
      return "";

    return customerSelectOption.text;
  }

  /**
 * @description Takes the temporarily stored selected customer ID and adds it to the product customers
 *              which will grant explicit access to the product to the customer in question, and if
 *              entered, the custom price for this product for the customer in question
 */
  addSelectedCustomer = () => {
    if (!this.selectedCustomer)
      return;

    this.selectedCustomer.forEach(selectedCustomer => {
      const selectedCustomerOption = selectedCustomer.id;
      if (!this.decoration.customers.find(productCustomer => productCustomer.customerId.toString() == selectedCustomerOption)) {
        this.decoration.customers.push({
          customerId: (+selectedCustomerOption * 1),
          customPrice: null,
          subsidyAmount: 0,
          product: new NewProduct(),
          customer: { name: selectedCustomer.text }
        });
      }
    });
    this.selectedCustomer = [];
    this.customerViewChild.customeSelect.clearModel();
    this.updateQuery('customer');
  };

  /**
 * @description Removes the selected productCustomer from the list productCustomers and re-indexes the
 *              productCustomer array to maintain the existing order
 *
 * @param productCustomer
 */
  removeProductCustomer(productCustomer: ProductCustomer) {
    for (let i = 0; i < this.decoration.customers.length; i++) {
      if (this.decoration.customers[i].customerId === productCustomer.customerId) {
        this.decoration.customers.splice(i, 1);
        break;
      }
    }
  }


  /**
 * @description Transforms the selectedInventoryItems (UnleashedItem[]) array into the correct format as a variation
 *              of any given product. A variation contains each version of any given product such as colour and size
 *              and represents a single physical inventory item.
 */
  addSelectedInventoryItems() {
    this.decoration.variations.push(
      ...this.selectedInventoryItems.map(selectedInventoryItem => {
        let result = {
          guid: selectedInventoryItem.id,
          colour: "",
          name: selectedInventoryItem.ProductDescription,
          productCode: selectedInventoryItem.ProductCode,
          size: "",
          accessMode: 0,
          customers: [],
          displayOrder: 0,
        };

        // Attempt to polyfill the prices from the selectedInventoryItems before we discard them
        // Unleashed has a total of 10 "SellPriceTierN"s, starting from 1

        if (!this.decoration.basePrice && selectedInventoryItem.DefaultSellPrice !== null)
          this.decoration.basePrice = Number(selectedInventoryItem.DefaultSellPrice);

        // Attempt to polyfill the colour and size
        if (result.productCode) {
          let sizeMatches = result.productCode.match(/([^-]+$)/);
          let colourMatches = result.productCode.match(/([^-]+)(?=-[^-]+$)/);
          if (sizeMatches?.length)
            result.size = sizeMatches[0];

          if (colourMatches && colourMatches.length)
            result.colour = colourMatches[0];
        }

        return result;
      })
    );

    this.selectedInventoryItems = [];
    this.inventory.customeSelect.clearModel();
    this.updateQuery('inventory');
  }

  /**
 * @description Allows arbitrarily named fields to be added to a decoration for user input/output
 *
 * @param variation
 */
  addVariationOption() {
    if (!this.decoration.options) {
      this.decoration.options = [];
    }

    this.decoration.options.push(new DecorationOption());
  }

  /**
 * @description Removes a variation from the list of variations on the product
 *
 * @param variation
 */
  removeInventoryItem(variation: NewProductVariation) {
    for (let i = 0; i < this.decoration.variations.length; i++) {
      if (this.decoration.variations[i].guid === variation.guid) {
        this.decoration.variations.splice(i, 1);
        break;
      }
    }
  }

  /**
 * @description Gets the highest price amongst all customers
 */
  getWorstPrice(): number {
    if (this.decoration.customers) {
      return this.decoration.customers.reduce((accumulator, value, idx) => {
        const newVal: number = value.customPrice || 0;
        if (isNumber(newVal) && newVal > accumulator) {
          return newVal;
        }

        return 0;
      }, 0);
    }

    return 0;
  }

  /**
 * @description Guarantees that the price is filled
 */
  polyfillPricing() {
    if (this.decoration.basePrice) {
      this.decoration.basePrice = this.decoration.basePrice;
      return;
    }

    this.decoration.basePrice = this.getWorstPrice();
  }

  /**
 * @description Validate and persist the product in the server, ignoring validating for Draft Products
 *
 * @param isDraft
 */
  saveDecoration(isDraft: boolean = false) {
    if (!isDraft) {
      this.activeClassId = this.activeClassId + 1;
    }
    if (this.activeClassId == 5 || isDraft) {
      this.activeClassId = this.activeClassId - 1;
      this.polyfillPricing();

      this.decoration.isDraft = isDraft;

      if (!this.decoration.name || !this.decoration.name.trim().length) {
        this.activeClassId = 1;
        this.notification.warn('Add Decoration', 'Decoration name is Required');
        return;
      }
      this.decorationService.create(this.decoration)
        .subscribe(() => {
          if (!this.decoration.id) {
            this.router.navigate(['/manage/decorations']);
          }
        },
          err => {
            this.activeClassId = 1;
          });
    }
  }

  setActive(id: number) {
    this.activeClassId = id;
  }

  public updateInventory(data: SelectOption[]) {
    if (!data.length) {
      return;
    }

    for (let i = (this.selectedInventoryItems.length - 1); i >= 0; i--) {
      if (!data.find(item => item === this.selectedInventoryItems[i])) {
        this.selectedInventoryItems.splice(i, 1);
      }
    }

    // Append new inventory items to the selected item array
    for (let i = 0; i < data.length; i++) {
      if (!this.selectedInventoryItems.find(selectedInventoryItem => data[i].id === selectedInventoryItem.id)) {
        let selectedInventoryItem = this.lastUnleashedProductListResult.find(unleashedProduct => unleashedProduct.id === data[i].id);

        if (selectedInventoryItem)
          this.selectedInventoryItems.push(selectedInventoryItem);
        else
          console.error(`Error Finding Inventory Item[${data[i]}] in lastUnleashedProductListResult`);
      }
    }
  }

  updateQuery(type: string) {
    if (type == 'inventory') {
      this.term = undefined;
      this.initInventorySelect();
    }

    if (type == 'customer') {
      delete this.query.filter.name;
      this.handleCustomerListGet();
    }
  }

  loadData(data: { searchTerm?: string, isScroll?: boolean }) {
    if (data.searchTerm) {
      this.term = data.searchTerm == '' ? undefined : data.searchTerm;
      this.page = 1;
      this.lastUnleashedProductListResult = [];
    } else {
      this.term = undefined;
    }

    if (data.isScroll) {
      this.page = this.page + 1;
    } else {
      this.page = 1;
    }
    this.initInventorySelect(data.isScroll);
  }

  loadCusomerData(data: { searchTerm?: string, isScroll?: boolean }) {
    if (data.searchTerm) {
      this.query.filter.name = { $like: '%' + data.searchTerm + '%' };
      this.page = 1;
      this.customerSelectOptions = [];
    } else {
      delete this.query.filter.name;
    }

    this.handleCustomerListGet(data.isScroll);
  }

  initInventorySelect(isScroll?: boolean) {
    const skip = this.page > 1 ? 10 * (this.page - 1) : 0;
    const args: { criteria: string | null | undefined, page: number, skip: number } = {
      criteria: this.term,
      page: this.page,
      skip: skip
    };

    if (this.term) {
      args.criteria = this.term;
    } else {
      delete args.criteria;
    }

    this.unleashedService.getUnleashedProducts(args)
      .subscribe(unleashedProductListResult => {
        const newOptions = unleashedProductListResult.Items.map(data => ({ id: data.Guid, text: data.ProductCode + '-' + data.ProductDescription, ProductDescription: data.ProductDescription, ProductCode: data.ProductDescription, DefaultSellPrice: data.DefaultSellPrice }));

        if (isScroll) {
          this.lastUnleashedProductListResult.push(...newOptions);
        } else {
          this.lastUnleashedProductListResult = newOptions;
        }

        this.inventory.lifecycleStatusSubject.next(LifecycleStatus.LOADED);
      });
  }

  public updateCustomer(data: SelectOption[]) {
    if (!data.length) {
      return;
    }
    this.selectedCustomer = data;
  }
}
