<div class="main-side-content" [class.customer-side]="!this.isAdmin?.isAdmin">
	<div class="side-content">
		<div [class.accordion]="!this.isAdmin?.isAdmin" id="ManageAdmin">
			<div class="accordion-item px-0 border-0">
				<h2 *ngIf="!this.isAdmin?.isAdmin" class="accordion-header">
					<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#Manage"
						aria-expanded="true" aria-controls="Manage">
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
								<path
									d="M4.70209 5.55218C6.5351 4.67552 7.96964 3.18786 8.79317 1.32827C8.26186 0.770398 7.57116 0.371916 6.82732 0.132827L6.42884 0C6.40228 0.0531309 6.37571 0.132831 6.37571 0.185961C5.73814 1.99241 4.43643 3.45351 2.68311 4.27704C1.4611 4.86148 0.557875 5.9241 0.132827 7.19924L0 7.59772C0.0531309 7.62429 0.132829 7.65085 0.185959 7.65085C0.690703 7.83681 1.19545 8.0759 1.67362 8.36812C2.33776 7.17267 3.40038 6.16319 4.70209 5.55218Z"
									fill="black" />
								<path opacity="0.8"
									d="M12.4059 5.6582C11.4229 7.14587 10.0681 8.34132 8.39448 9.13828C7.03964 9.77585 6.00358 10.9713 5.52541 12.4058L5.39258 12.8574C5.89732 13.3091 6.50833 13.6544 7.17246 13.8669L7.57094 13.9998C7.59751 13.9466 7.62407 13.8669 7.62407 13.8138C8.26165 12.0074 9.56336 10.5463 11.3167 9.72272C12.5387 9.13828 13.4685 8.07566 13.867 6.80052L13.9998 6.40204C13.4419 6.24265 12.9106 5.97699 12.4059 5.6582Z"
									fill="black" />
								<path opacity="0.9"
									d="M4.32946 11.4744C4.9936 9.88049 6.21561 8.57878 7.78297 7.83495C9.21751 7.14424 10.413 6.08163 11.2365 4.77992C10.6255 4.19548 10.1207 3.53134 9.74881 2.73438C8.76589 4.54083 7.2251 6.00193 5.33895 6.90516C4.24977 7.4099 3.37311 8.28656 2.8418 9.34918C3.42624 9.90706 3.90442 10.5978 4.27633 11.3681C4.27633 11.3681 4.3029 11.4213 4.32946 11.4744Z"
									fill="black" />
							</svg>
						</span>
						{{ isAdmin?.firstName }}
					</button>
				</h2>
				<h5 *ngIf="this.isAdmin?.isAdmin">Menu</h5>
				<ul *ngIf="this.isAdmin?.isAdmin"
					class="list-unstyled d-flex align-items-start justify-content-start flex-column">
					<li *ngFor="let item of menuItems">
						<a [routerLink]="item.url" role="button" class="btn" [ngClass]="{ active: isActiveMenuItem(item['url']) }"
							*ngIf="item.isAccess">
							<span><svg xmlns="http://www.w3.org/2000/svg" [attr.width]="20" [attr.height]="20" viewBox="0 0 20 20"
									fill="none">
									<ng-container *ngFor="let path of item.paths">
										<path [attr.d]="path.d" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</ng-container></svg></span>
							{{ item.label }}
						</a>
					</li>
				</ul>
				<div *ngIf="this.isAdmin?.isAdmin" class="accordion" id="ManageAdmin">
					<div class="accordion-item px-0 border-0" *ngFor="let accordion of accordions">
						<h2 class="accordion-header" *ngIf="accordion.isAccess">
							<button class="accordion-button" type="button" [attr.data-bs-toggle]="'collapse'"
								[attr.data-bs-target]="'#' + accordion.id" [attr.aria-expanded]="accordion.expanded"
								[attr.aria-controls]="accordion.id">
								{{ accordion.title }}
							</button>
						</h2>
						<div [attr.id]="accordion.id" class="accordion-collapse collapse" [attr.data-bs-parent]="'#ManageAdmin'"
							[ngClass]="{ show: accordion.expanded }">
							<ul class="list-unstyled d-flex align-items-start justify-content-start flex-column">
								<li *ngFor="let subItem of accordion.items">
									<a *ngIf="subItem.isAccess" [routerLink]="subItem.url" role="button" class="btn"
										[ngClass]="{ active: isActiveMenuItem(subItem['url']) }">
										<span><svg xmlns="http://www.w3.org/2000/svg" [attr.width]="20" [attr.height]="20"
												viewBox="0 0 20 20" fill="none">
												<ng-container *ngFor="let path of subItem.paths">
													<path [attr.d]="path.d" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
												</ng-container></svg></span>
										{{ subItem.label }}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div id="Manage" class="accordion-collapse collapse show" data-bs-parent="#ManageAdmin">
					<ul *ngIf="!this.isAdmin?.isAdmin"
						class="list-unstyled d-flex align-items-start justify-content-start flex-column">
						<li *ngFor="let item of menuItems">
							<a [routerLink]="item.url" role="button" class="btn" [ngClass]="{ active: isActiveMenuItem(item['url']) }"
								*ngIf="item.isAccess">
								<span><svg xmlns="http://www.w3.org/2000/svg" [attr.width]="20" [attr.height]="20" viewBox="0 0 20 20"
										fill="none">
										<ng-container *ngFor="let path of item.paths">
											<path [attr.d]="path.d" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
										</ng-container></svg></span>
								{{ item.label }}
							</a>
						</li>
					</ul>

					<h5
						*ngIf="!this.isAdmin?.isAdmin && (canListUsers || canEditBusinessUnits || canEditAddresses || showOwnBalance)">
						Business</h5>

					<ul
						*ngIf="!this.isAdmin?.isAdmin && (canListUsers || canEditBusinessUnits || canEditAddresses || showOwnBalance)"
						class="list-unstyled d-flex align-items-start justify-content-start flex-column">
						<li *ngFor="let item of accountItem">
							<a [routerLink]="item.url" role="button" class="btn" [ngClass]="{ active: isActiveMenuItem(item['url']) }"
								*ngIf="item.isAccess">
								<span><svg xmlns="http://www.w3.org/2000/svg" [attr.width]="20" [attr.height]="20" viewBox="0 0 20 20"
										fill="none">
										<ng-container *ngFor="let path of item.paths">
											<path [attr.d]="path.d" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
										</ng-container></svg></span>
								{{ item.label }}
							</a>
						</li>
					</ul>

					<h5 *ngIf="!this.isAdmin?.isAdmin">Manage</h5>

					<ul *ngIf="!this.isAdmin?.isAdmin"
						class="list-unstyled d-flex align-items-start justify-content-start flex-column">
						<li *ngFor="let item of accountManageItem">
							<a [routerLink]="item.url" role="button" class="btn" [ngClass]="{ active: isActiveMenuItem(item['url']) }"
								*ngIf="item.isAccess">
								<span><svg xmlns="http://www.w3.org/2000/svg" [attr.width]="20" [attr.height]="20" viewBox="0 0 20 20"
										fill="none">
										<ng-container *ngFor="let path of item.paths">
											<path [attr.d]="path.d" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
										</ng-container></svg></span>
								{{ item.label }}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="side-footer d-none">
			<ul class="list-unstyled d-flex align-items-start justify-content-start flex-column">
				<li>
					<a href="#" role="button" class="btn">
						<span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M14.5 7C14.5 5.80653 14.0259 4.66193 13.182 3.81802C12.3381 2.97411 11.1935 2.5 10 2.5C8.80653 2.5 7.66193 2.97411 6.81802 3.81802C5.97411 4.66193 5.5 5.80653 5.5 7C5.5 12.25 3.25 13.75 3.25 13.75H16.75C16.75 13.75 14.5 12.25 14.5 7Z"
									stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M11.2971 16.75C11.1653 16.9773 10.976 17.166 10.7483 17.2971C10.5206 17.4283 10.2624 17.4973 9.99965 17.4973C9.73686 17.4973 9.47869 17.4283 9.25098 17.2971C9.02327 17.166 8.83401 16.9773 8.70215 16.75"
									stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg></span>
						Notifications
					</a>
				</li>
				<li>
					<a href="#" role="button" class="btn">
						<span class="userName" *ngIf="isAdmin">{{ isAdmin.firstName.charAt(0).toUpperCase()
							}}{{ isAdmin.lastName.charAt(0).toUpperCase() }}</span>
						<p class="mb-0" *ngIf="isAdmin">
							{{ isAdmin.firstName }} {{ isAdmin.lastName }}
						</p>
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M10 16C10.4142 16 10.75 15.6642 10.75 15.25C10.75 14.8358 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.8358 9.25 15.25C9.25 15.6642 9.58579 16 10 16Z"
									stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
									stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M10 5.5C10.4142 5.5 10.75 5.16421 10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75C9.25 5.16421 9.58579 5.5 10 5.5Z"
									stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>