<div class="main-right-content">
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a (click)="getRoute()">Groups</a>
			</li>
			<li (click)="getRoute()" class="breadcrumb-item" *ngIf="model.id">
				{{ model.name }}
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ model.id ? "Edit" : "Create" }} Group
			</li>
		</ol>
	</nav>

	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">{{ model.id ? "Edit" : "Create" }} Groups</h1>
		</div>

		<div class="manage-user-list">
			<div class="customer-wizard">
				<div class="wizard-tabs">
					<ul class="nav nav-pills" id="pills-tab" role="tablist">
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(1)" [class.active]="activeClassId == 1"
								[class.complete]="activeClassId >= 2" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 1</h6>
									<h5>Group Details</h5>
								</div>
							</button>
						</li>
						<li class="nav-item" *ngIf="!isCustomer">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation" *ngIf="!isCustomer">
							<button class="nav-link" (click)="setActive(2)" [class.active]="activeClassId == 2"
								[class.complete]="activeClassId >= 3" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16M10.75 6.25C10.75 7.90685 9.40685 9.25 7.75 9.25C6.09315 9.25 4.75 7.90685 4.75 6.25C4.75 4.59315 6.09315 3.25 7.75 3.25C9.40685 3.25 10.75 4.59315 10.75 6.25Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 2</h6>
									<h5>Customers</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="isCustomer ? setActive(2) : setActive(3)"
								[class.active]="isCustomer ? activeClassId == 2 : activeClassId == 3"
								[class.complete]="isCustomer ? activeClassId >= 3 : activeClassId >= 4" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M16.75 12.9999V6.99993C16.7497 6.73688 16.6803 6.47853 16.5487 6.2508C16.417 6.02306 16.2278 5.83395 16 5.70243L10.75 2.70243C10.522 2.57077 10.2633 2.50146 10 2.50146C9.7367 2.50146 9.47803 2.57077 9.25 2.70243L4 5.70243C3.7722 5.83395 3.58299 6.02306 3.45135 6.2508C3.31971 6.47853 3.25027 6.73688 3.25 6.99993V12.9999C3.25027 13.263 3.31971 13.5213 3.45135 13.7491C3.58299 13.9768 3.7722 14.1659 4 14.2974L9.25 17.2974C9.47803 17.4291 9.7367 17.4984 10 17.4984C10.2633 17.4984 10.522 17.4291 10.75 17.2974L16 14.2974C16.2278 14.1659 16.417 13.9768 16.5487 13.7491C16.6803 13.5213 16.7497 13.263 16.75 12.9999Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M3.45215 6.22021L9.99965 10.0077L16.5471 6.22021" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
										<path d="M10 17.56V10" stroke="transparent" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step {{isCustomer ? 2 : 3}}</h6>
									<h5>Collections</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="isCustomer ? setActive(3) : setActive(4)"
								[class.active]="isCustomer ? activeClassId == 3 : activeClassId == 4"
								[class.complete]="isCustomer ? activeClassId >= 4 : activeClassId >= 5" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path d="M10 1.75V18.25" stroke="transparent" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
										<path
											d="M13.75 4.75H8.125C7.42881 4.75 6.76113 5.02656 6.26884 5.51884C5.77656 6.01113 5.5 6.67881 5.5 7.375C5.5 8.07119 5.77656 8.73887 6.26884 9.23116C6.76113 9.72344 7.42881 10 8.125 10H11.875C12.5712 10 13.2389 10.2766 13.7312 10.7688C14.2234 11.2611 14.5 11.9288 14.5 12.625C14.5 13.3212 14.2234 13.9889 13.7312 14.4812C13.2389 14.9734 12.5712 15.25 11.875 15.25H5.5"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step {{isCustomer ? 3 : 4}}</h6>
									<h5>Allocation</h5>
								</div>
							</button>
						</li>
					</ul>
				</div>
				<div class="tab-content" id="pills-tabContent">
					<div class="tab-pane fade" [class.show]="activeClassId == 1" [class.active]="activeClassId == 1">
						<div class="edit-users">
							<div class="edit-card card">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
													fill="url(#paint0_radial_0_17046)" fill-opacity="0.5" />
												<path
													d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
													fill="url(#paint1_radial_0_17046)" fill-opacity="0.5" />
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												<defs>
													<radialGradient id="paint0_radial_0_17046" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
														gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
														<stop stop-color="#8BD6EF" />
														<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
													</radialGradient>
													<radialGradient id="paint1_radial_0_17046" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
														gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
														<stop stop-color="#8BD6EF" />
														<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
													</radialGradient>
												</defs>
											</svg>
										</span>
										Group Details
									</h4>
								</div>
								<div class="card-body">
									<form action="">
										<div class="row">
											<div class="col-12">
												<h5 class="form-title">General information</h5>
											</div>
											<div class="col-12">
												<div class="form-group">
													<label for="GroupName" class="form-label">Group Name</label>
													<input type="text" class="form-control" id="GroupName" name="groupName"
														placeholder="Enter full group name...." [(ngModel)]="model.name" />
												</div>
											</div>
											<div class="col-12">
												<h5 class="form-title">display options</h5>
											</div>
											<div class="col-12">
												<div class="form-group form-check">
													<input type="radio" class="form-check-input" id="Approval1" name="accessMode" [value]="1"
														[(ngModel)]="model.accessMode" />
													<label class="form-check-label" for="Approval1">This group is available to all users
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group form-check">
													<input type="radio" class="form-check-input" id="Approval2" name="accessMode" [value]="0"
														[(ngModel)]="model.accessMode" />
													<label class="form-check-label" for="Approval2">This group is only available to selected
														customers
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="Approval3" name="add-collections"
														[value]="true" [(ngModel)]="model.isRestrictedGroup" />
													<label class="form-check-label" for="Approval3">Restrict Product Access to Relevant*
														Collections
														<span>NB: Each collection has its own security which
															must also be considered</span>
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group form-check">
													<input type="checkbox" class="form-check-input" id="Approval4" name="access-pricing"
														[value]="true" [(ngModel)]="model.isRestrictedPricing" />
													<label class="form-check-label" for="Approval4">Remove Prices (Requires Collection
														Restrictions)
														<span>NB: Prevents Payment Gateway Access</span>
													</label>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="!isCustomer" class="tab-pane fade" [class.show]="activeClassId == 2"
						[class.active]="activeClassId == 2">
						<div class="add-products">
							<div class="form-group">
								<label for="selectCustomer" class="form-label d-none"></label>
								<ng-select id="selectCustomer" #selectedCustomer [virtualScroll]="true"
									(scrollToEnd)="handleCustomerListGet(true)" [searchable]="true" (search)="onCustomerSearch($event)"
									[notFoundText]="noCustomerFoundText" [clearable]="true" [multiple]="true"
									(clear)="onClear('customer')" (change)="onSelectChange(selectedCustomer)">
									<ng-option *ngFor="let data of customerSelectOptions" [value]="data">{{data.text}}</ng-option>
								</ng-select>
							</div>
							<div class="form-group">
								<a class="btn btn-primary" (click)="addSelectedCustomer()" role="button">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
										</svg>
									</span>
									Assign Customer
								</a>
							</div>
						</div>

						<div class="edit-users">
							<div class="edit-card card" *ngFor="let customer of model.customers; let i = index">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
													fill="white" />
												<path
													d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
													fill="white" />
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
										Customer {{ i + 1 }}
									</h4>
									<button class="btn btn-secondary-icon" type="button" (click)="removeCustomer(customer)">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round"></path>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-12">
											<div class="form-group">
												<label for="Name" class="form-label">Name</label>
												<input type="text" class="form-control" id="Name" placeholder="Name" value="{{
														customer.customer
															? customer.customer.name
															: customer.name
													}}" disabled />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" [class.show]="isCustomer ? activeClassId == 2 : activeClassId == 3"
						[class.active]="isCustomer ? activeClassId == 2 : activeClassId == 3">
						<div class="add-products">
							<div class="form-group">
								<label for="selectProduct" class="form-label d-none"></label>
								<ng-select #coll name="collection" [virtualScroll]="true" (scrollToEnd)="handleCollection(true)"
									[searchable]="true" (search)="onCollectionSearch($event)" [notFoundText]="noCollectionFoundText"
									[clearable]="true" [multiple]="true" (clear)="onClear('collection')" (change)="onSelectChange(coll)">
									<ng-option *ngFor="let data of collectionSelectOptions" [value]="data">{{data.text}}</ng-option>
								</ng-select>
							</div>
							<div class="form-group">
								<a class="btn btn-primary" (click)="addSelectedCollection()" role="button">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
										</svg>
									</span>
									Add Collection
								</a>
							</div>
						</div>

						<div class="edit-users">
							<div class="edit-card card" *ngFor="let collection of model.collections; let c = index">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
													fill="white" />
												<path
													d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
													fill="white" />
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
										Collection {{ c + 1 }}
									</h4>
									<button class="btn btn-secondary-icon" type="button" (click)="model.collections!.splice(c, 1)">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round"></path>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-12">
											<div class="form-group">
												<label for="Name" class="form-label">Name</label>
												<input type="text" class="form-control" id="Name" placeholder="Name" value="{{
														collection.collection
															? collection.collection.name
															: collection.name
													}}" disabled />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" [class.show]="isCustomer ? activeClassId == 3 : activeClassId == 4"
						[class.active]="isCustomer ? activeClassId == 3 : activeClassId == 4">
						<div class="add-products">
							<div class="form-group">
								<label for="selectProduct" class="form-label d-none"></label>
								<select class="form-select" aria-label="Default select example" id="selectProduct">
									<option selected="">Select Allocation</option>
									<option value="garment">Product Collection</option>
								</select>
							</div>
							<div class="form-group">
								<a class="btn btn-primary" (click)="addAllocation()" role="button">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
										</svg>
									</span>
									Add Allocation
								</a>
							</div>
						</div>
						<div class="edit-users edit-groups" *ngIf="model.allocations.length === 0">
							<div class="no-content">
								<div class="no-content-icon">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
											<path
												d="M15.25 3.29102H4.75C3.92157 3.29102 3.25 3.96259 3.25 4.79102V15.291C3.25 16.1194 3.92157 16.791 4.75 16.791H15.25C16.0784 16.791 16.75 16.1194 16.75 15.291V4.79102C16.75 3.96259 16.0784 3.29102 15.25 3.29102Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M16.75 12.291L13 8.54102L4.75 16.791" stroke="#666D80" stroke-width="1.5"
												stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M7.375 8.54102C7.99632 8.54102 8.5 8.03734 8.5 7.41602C8.5 6.7947 7.99632 6.29102 7.375 6.29102C6.75368 6.29102 6.25 6.7947 6.25 7.41602C6.25 8.03734 6.75368 8.54102 7.375 8.54102Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</span>
								</div>
								<h4 class="no-content-title">No allocations</h4>
							</div>
						</div>

						<div class="edit-users">
							<div class="edit-card card" *ngFor="let allocation of model.allocations; let i = index">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M10 1.75V18.25" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
												<path
													d="M13.75 4.75H8.125C7.42881 4.75 6.76113 5.02656 6.26884 5.51884C5.77656 6.01113 5.5 6.67881 5.5 7.375C5.5 8.07119 5.77656 8.73887 6.26884 9.23116C6.76113 9.72344 7.42881 10 8.125 10H11.875C12.5712 10 13.2389 10.2766 13.7312 10.7688C14.2234 11.2611 14.5 11.9288 14.5 12.625C14.5 13.3212 14.2234 13.9889 13.7312 14.4812C13.2389 14.9734 12.5712 15.25 11.875 15.25H5.5"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
										Allocation details
									</h4>
									<button class="btn btn-secondary-icon" type="button" (click)="model.allocations.splice(i, 1)"
										*ngIf="!isReadOnly(allocation)">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
									</button>
								</div>
								<div class="card-body">
									<form action="">
										<div class="row">
											<div class="col-12">
												<div class="form-group">
													<label for="Frequency" class="form-label">Frequency</label>
													<select class="form-select" #frequency aria-label="Default select example" id="Frequency"
														(change)="
															changeFrequency(allocation, frequency.value)
														">
														<option *ngFor="
																let frequency of allocationFrequencyOptions
															" [selected]="frequency.key == allocation.frequency">
															{{ frequency.value }}
														</option>
													</select>
												</div>
											</div>

											<div class="col-12 col-xl-8">
												<div class="form-group">
													<label for="Collections" class="form-label">Collections</label>
													<ng-select #collection [multiple]="true" name="collection" [(ngModel)]="selectedItems"
														[virtualScroll]="true" (scrollToEnd)="handleCollection(true)" [searchable]="true"
														(search)="onCollectionSearch($event)" [notFoundText]="noCollectionFoundText"
														[clearable]="true">
														<ng-option *ngFor="let data of collectionSelectOptions"
															[value]="data">{{data.text}}</ng-option>
													</ng-select>
												</div>
											</div>
											<div class="col align-self-end">
												<div class="form-group">
													<button class="btn btn-secondary w-100" type="button" (click)="
															addCollections(allocation.allocationCollections)
														">
														<span>
															<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
																fill="none">
																<path d="M10 4.75V15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
																	stroke-linejoin="round" />
																<path d="M4.75 10H15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
																	stroke-linejoin="round" />
															</svg>
														</span>
														Add collections
													</button>
												</div>
											</div>
											<div class="col-12" *ngIf="allocation.type === 'garment'">
												<h5 class="form-title">allocations</h5>
											</div>
											<div class="col-12 px-0" *ngIf="allocation.type === 'garment'">
												<div class="row" *ngFor="
														let collection of allocation.allocationCollections;
														let c = index
													">
													<div class="col-12 col-lg-5">
														<div class="form-group">
															<label for="Name" class="form-label">Name</label>
															<input type="text" class="form-control" id="Name" placeholder=""
																[value]="collection.name" />
														</div>
													</div>
													<div class="col-12 col-lg-5">
														<div class="form-group">
															<label for="{{ collection.name }}" class="form-label">Allocation</label>
															<input type="text" class="form-control" id="{{ collection.name }}"
																name="{{ collection.name }}" placeholder="" [(ngModel)]="collection.itemCount" />
														</div>
													</div>
													<div class="col align-self-center">
														<button class="btn btn-secondary-icon" type="button" (click)="
																allocation.allocationCollections.length &&
																	allocation.allocationCollections.splice(c, 1)
															">
															<span>
																<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
																	fill="none">
																	<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5"
																		stroke-linecap="round" stroke-linejoin="round" />
																	<path
																		d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
																		stroke="#272835" stroke-width="1.5" stroke-linecap="round"
																		stroke-linejoin="round" />
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="wizard-footer">
					<button class="btn btn-secondary" type="button" [routerLink]="['/manage/users']"
						[queryParams]="{ activeTab: 1 }">
						Cancel
					</button>
					<div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-2">
						<button class="btn btn-primary" type="button" (click)="saveModel()">
							{{ activeClassId >= 4 ? "Save" : "Continue" }}
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039" stroke="white" stroke-width="1.5"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #allocation_confirm_modal let-modal let-c="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-body">
			<button type="button" class="btn btn-icon btn-cancel shadow-none" (click)="d('Cancel')">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</button>
			<div class="delete-content">
				<div>
					<h4 class="title">Add Collection To Group</h4>
					<p class="desc">
						Would you like to attach all collections from the allocations, to
						the group?
					</p>
				</div>
			</div>
			<div class="btns">
				<button type="button" class="btn btn-danger" (click)="c('Delete')">
					Yes
				</button>
				<button type="button" class="btn btn-secondary" (click)="d('Cancel')">
					No
				</button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #customer_confirm_modal let-modal let-c="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-body">
			<button type="button" class="btn btn-icon btn-cancel shadow-none" (click)="d('Cancel')">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</button>
			<div class="delete-content">
				<div>
					<h4 class="title">Add Collection To Customer</h4>
					<p class="desc">
						Would you like to attach all collections from group, to the
						customers in the group?
					</p>
				</div>
			</div>
			<div class="btns">
				<button type="button" class="btn btn-danger" (click)="c('Delete')">
					Yes
				</button>
				<button type="button" class="btn btn-secondary" (click)="d('Cancel')">
					No
				</button>
			</div>
		</div>
	</div>
</ng-template>