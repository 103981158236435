import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { has } from 'lodash';
import { combineLatest } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { ICustomerUser } from 'src/app/main/model/customer.user.model';
import { IDocument, ResourceDocument } from 'src/app/main/model/document.model';
import { IQueryFilter } from 'src/app/main/model/query.filter.class';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { DocumentService } from 'src/app/main/services/document.service';
import { FileUploadService } from 'src/app/main/services/fileUpload.service';
import { SecurityService } from 'src/app/main/services/security.service';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';

@Component({
  selector: 'app-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: []
})
export class DocumentEditComponent implements OnInit, OnDestroy {
  private readonly subscriptionGroup = new SubscriptionGroup();
  public customerId: number | null;
  public readonly: boolean = true;
  public isAdmin: boolean | undefined = false;
  public currentUser: any;
  public docs: ResourceDocument[] = [];
  public customerUsers: ICustomerUser[];
  public documentId: string;

  documentData: IDocument = {
    name: "",
    path: "",
    isApproved: false,
    customerId: null,
    userId: null,
    modifiedById: null,
    createdById: null,
    approvedById: null,
    fileSize: 0,
    mimeType: ""
  };
  public editLink: string;
  public activeTab: number;

  constructor(
    private readonly session: SessionApi,
    private readonly activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private customerUserService: CustomerUserService,
    private fileUploadService: FileUploadService,
    private readonly router: Router,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    const currentUrl = this.activatedRoute.snapshot.url.join('/');

    this.subscriptionGroup.add(
      combineLatest(
        this.activatedRoute.params,
        this.session.$userData,
        this.session.$customerData
      ).subscribe(([params, userData, customerData]) => {
        this.customerId = null;
        this.currentUser = this.session.$userData.value;

        if (userData?.isAdmin) {
          if (has(params, "customerId")) {
            this.customerId = Number(params["customerId"]);
            this.isAdmin = true;
            // this.loadCustomerUsers();
            if (currentUrl.includes('view')) {
              this.editLink = `/manage/customer/view/${this.customerId}`;
              this.activeTab = 4;
            } else {
              this.editLink = `/manage/customer/edit/${this.customerId}`;
              this.activeTab = 9;
            }
          }
        } else if (customerData) {
          this.customerId = customerData.id;
        }

        if (has(params, 'id')) {
          this.documentId = params['id'];
          this.getDocuments()
        }
      })
    );
  }

  getDocuments() {
    this.documentService.get(+this.documentId).subscribe(itm => {
      this.documentData = itm;

      if (itm.path) {
        this.docs.push({
          name: itm.name,
          fileSize: itm.fileSize,
          mimeType: itm.mimeType,
          path: itm.path,
        })
      }
    })
  }

  loadCustomerUsers() {
    let query: IQueryFilter = new IQueryFilter({
      filter: {},
      sortBy: 'first_name',
      include: [{ association: 'user', required: true }],
      limit: 5000
    });
    query.filter['customerId'] = this.customerId;

    this.customerUserService.list(query, { params: { includeAllocationBalance: true } })
      .subscribe(queryResult => {
        this.customerUsers = queryResult.rows;
      });
  }


  /**
 * UI Helper method for getting the list of supporting documents on the current cart
 */
  public getResourceDocuments = () =>
    this.docs ?
      this.docs :
      [];

  /**
   * Uploads Documents
   *
   * @param event
   */
  public persistS3Documents(event) {
    const filesArray: File[] = [];
    const promises: Array<Promise<any>> = [];
    for (let i = 0; i < event.srcElement.files.length; i++) {
      filesArray.push(event.srcElement.files[i]);
      promises.push(
        new Promise((resolve, reject) => {
          this.fileUploadService.uploadDocument(event.srcElement.files[i], 'RESOURCE', (err, data) => {
            if (err)
              reject(err);
            else
              resolve(data);
          });
        }
        ));
    }

    let s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static.reali.supply/';
    let cfDistribution = 'https://static.reali.supply/';

    Promise.all(promises).then((resData) => {

      // if (!this.docs) {
      this.docs = [] as ResourceDocument[];
      // }

      filesArray.forEach((theFile, index) => {
        const url = resData[index].Location.replace(s3Prefix, cfDistribution);

        this.docs.push({
          name: theFile.name,
          fileSize: theFile.size,
          mimeType: theFile.type,
          path: url,
        });
      });

    }).catch((err: any) => {
      console.error('Exception while uploading files', err);
    });
  }



  private readonly createOrUpdate = () => {
    const signature = "DocumentEditComponent.createOrUpdate: ";
    if (!this.documentData.name) {
      this.documentData.name = '';
    }

    if (!this.documentData.id) {
      return this.documentService.create(this.documentData);
    }

    return this.documentService.update(this.documentData.id, this.documentData);
  };


  saveDocumentData() {
    this.documentData.customerId = this.customerId;

    if (this.isAdmin) {
      this.documentData.approvedById = this.customerId;
      this.documentData.createdById = this.documentData.userId;
      this.documentData.modifiedById = this.documentData.userId;
    }

    if (this.docs && this.docs.length) {
      this.documentData.path = this.docs[0].path;
      this.documentData.fileSize = this.docs[0].fileSize;
      this.documentData.mimeType = this.docs[0].mimeType;
    }

    //when user edit
    if (!this.documentData.userId) {
      this.documentData.userId = this.currentUser.id || null;
      // this.documentData.createdById = this.documentData.userId;
      this.documentData.modifiedById = this.documentData.userId;
    }

    if (!this.documentData.name || !this.documentData.name.trim().length) {
      this.notifications.warn('Please Add Name', 'Validation error', 'Name is requied');
      return;
    }

    this.createOrUpdate()
      .subscribe({
        next: () => {
          if (!this.documentData.id) {
            const currentUser = this.session.$userData.value;
            if (currentUser && currentUser.isAdmin) {
              this.router.navigate([this.editLink], {
                queryParams: { activeTab: this.activeTab }
              });
            } else {
              this.router.navigate(['/account/documents']);
            }
          }
        },
        error: err => {
        }
      })
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      if (this.subscriptionGroup) {
        this.subscriptionGroup.unsubscribe();
      }
    }
  }
}
