import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductCustomer } from '../model/ddb.model';
import { createUrl } from './api.util';
import { Observable } from 'rxjs';

// Convenience Type
type T = ProductCustomer;

@Injectable()
export class CustomerProductApi extends GenericApi<T> {
  public path = "product-customer";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getAllProductCustomersByProductId = (productId: number | string): Observable<ProductCustomer[]> => {
    return this.httpClient.get<ProductCustomer[]>(
      createUrl(this.path, 'getAllProductCustomersByProductId'),
      { params: { productId: productId } }
    );
  };
}
