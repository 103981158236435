<div class="product-order">
	<div class="order-img">
		<img src="{{ cartItem.product ? cartItem.product.imageUrl : '' }}" alt="order-1" />
	</div>
	<div class="order-table-content">
		<div>
			<h5 class="title">
				{{ item.product.name }}
			</h5>
			<p class="price" *ngIf="showPrice">
				${{ utils.twoDecimalPlacesString(cartItem.getTotalPrice()) }}
			</p>
		</div>
		<ul class="order-detail-list">
			<li>
				<p>Color:</p>
				<span>
					<span class="color"></span>
					{{ item.variation.colour || "Default" }}</span>
			</li>
			<li>
				<p>Size:</p>
				<span> {{ item.variation.size || "Default" }}</span>
			</li>
			<li>
				<p>Quantity:</p>
				<span> {{ cartItem.totalQuantity }} </span>
			</li>
		</ul>
		<ul class="order-detail-list" *ngIf="item.decorations && item.decorations.length">
			<li class="d-block">
				<p>Decoration:</p>
				<div class="d-flex flex-column gap-1 w-100">
					<span class="flex-wrap row-gap-2" *ngFor="let decoration of item.decorations">
						+
						<span *ngIf="showPrice">\${{
							utils.twoDecimalPlacesString(decoration.decoration.price)
							}}
							-
						</span>
						{{ decoration.decoration.name }}
						<ng-container *ngFor="let option of decoration.options">
							{{ option.decorationOption.name }}:
							<span>{{ option.value }}</span>
						</ng-container>
					</span>
				</div>
			</li>
		</ul>
		<ul class="order-detail-list" *ngIf="item.allocationCollectionId">
			<li>
				<p>Collection:</p>
				<span class="flex-wrap row-gap-2">
					{{ item.allocationCollectionName }}
					<span class="badge bg-aqua-25 ps-3 py-1">
						{{ item.allocationCollectionConsumption }}
						{{item.allocationCollectionConsumption && item.allocationCollectionConsumption > 1 ? 'items' : 'item'}}
					</span>
				</span>
			</li>
		</ul>
	</div>
</div>
