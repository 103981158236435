<div class="main-right-content">
	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">Reports</h1>
			<p *ngIf="selectedCustomerText">
				Customer: {{ getCustomerName() }}
			</p>
		</div>

		<div class="manage-user-list">
			<div class="row">
				<div class="col-12">
					<div class="form-group mb-5">
						<ng-select
							*ngIf="isAdmin"
							id="customer"
							#customer
							[virtualScroll]="true"
							(scrollToEnd)="loadCustomers(true)"
							[searchable]="true"
							(search)="onCustomerSearch($event)"
							(change)="customerChange()"
							[notFoundText]="noCustomerFoundText"
							[clearable]="true"
							(clear)="onClear()"
						>
							<ng-option
								*ngFor="let data of customerSelectOptions"
								[value]="data"
								>{{ data.text }}</ng-option
							>
						</ng-select>
						<div
							*ngIf="isAdmin && !selectedCustomerOption"
							id="requiredHelp"
							class="form-text"
						>
							Please select a customer to view reports.
						</div>
					</div>
				</div>
			</div>
			<div class="edit-users" *ngIf="selectedCustomerOption || !isAdmin">
				<div
					class="edit-card card report-card"
					*ngIf="access.allocationReport.access"
				>
					<div
						[routerLink]="[pathBase + '/reports/userAllocation']"
						[queryParams]="{ customerId: selectedCustomerOption, name: selectedCustomerText }"
						class="card-body"
					>
						<div class="reports">
							<div class="report-dlt">
								<button class="btn btn-secondary-icon" type="button">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M3.25 5.5H4.75H16.75"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
								</button>
							</div>
							<div class="report-content">
								<a role="button" class="btn title">User Allocation Report</a>
								<p class="desc">
									List all users in the account, including business unit, cost
									account, approver, allocation, allocation balance where
									applicable. Includes allocations attached to guest users.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					class="edit-card card report-card"
					*ngIf="access.userListReport.access"
				>
					<div
						[routerLink]="[pathBase + '/reports/userList']"
						[queryParams]="{ customerId: selectedCustomerOption, name: selectedCustomerText }"
						class="card-body"
					>
						<div class="reports">
							<div class="report-dlt">
								<button class="btn btn-secondary-icon" type="button">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M3.25 5.5H4.75H16.75"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
								</button>
							</div>
							<div class="report-content">
								<a role="button" class="btn title">User List Report</a>
								<p class="desc">
									List all users in the account, including all contact details.
									Report can be exported to CSV.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					class="edit-card card report-card"
					*ngIf="access.orderReport.access"
				>
					<div
						class="card-body"
						[routerLink]="[pathBase + '/reports/orders']"
						[queryParams]="{ customerId: selectedCustomerOption, name: selectedCustomerText }"
					>
						<div class="reports">
							<div class="report-dlt">
								<button class="btn btn-secondary-icon" type="button">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M3.25 5.5H4.75H16.75"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
								</button>
							</div>
							<div class="report-content">
								<a role="button" class="btn title">Order details report</a>
								<p class="desc">
									List all orders and extended details in the account. Report
									can be exported to CSV.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					class="edit-card card report-card"
					*ngIf="access.businessUnitReport.access"
				>
					<div
						class="card-body"
						[routerLink]="[pathBase + '/reports/businessUnit']"
						[queryParams]="{ customerId: selectedCustomerOption, name: selectedCustomerText }"
					>
						<div class="reports">
							<div class="report-dlt">
								<button class="btn btn-secondary-icon" type="button">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M3.25 5.5H4.75H16.75"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
								</button>
							</div>
							<div class="report-content">
								<a role="button" class="btn title">Business unit report</a>
								<p class="desc">
									List all business-units, including all contacts and shipping
									details. Report can be exported to CSV.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="edit-card card report-card" *ngIf="isAdmin">
					<div
						class="card-body"
						[routerLink]="[pathBase + '/reports/salesReport']"
						[queryParams]="{ customerId: selectedCustomerOption, name: selectedCustomerText }"
					>
						<div class="reports">
							<div class="report-dlt">
								<button class="btn btn-secondary-icon" type="button">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M3.25 5.5H4.75H16.75"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
								</button>
							</div>
							<div class="report-content">
								<a role="button" class="btn title">Sales Report</a>
								<p class="desc">
									List all sales report related to all business-units, including
									12 months total sales. Report can be exported to CSV.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					class="edit-card card report-card"
					*ngIf="access.overspendReport.access"
				>
					<div
						class="card-body"
						[routerLink]="[pathBase + '/reports/overspend']"
						[queryParams]="{ customerId: selectedCustomerOption, name: selectedCustomerText }"
					>
						<div class="reports">
							<div class="report-dlt">
								<button class="btn btn-secondary-icon" type="button">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M3.25 5.5H4.75H16.75"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
												stroke="#666D80"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
								</button>
							</div>
							<div class="report-content">
								<a role="button" class="btn title">OverSpend Report</a>
								<p class="desc">
									List all orders with OverSpend Ammount and extended details in
									the account. Report can be exported to CSV.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>
