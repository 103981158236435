import { Component, ErrorHandler } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from '../../services/auth.service';
import _ from 'lodash';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: []
})
export class RegisterComponent {
  public secret: string;
  public firstName: string;
  public lastName: string;
  public email: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public notifications: NotificationsService,
    private authService: AuthService,
    private readonly raven: ErrorHandler
  ) {
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('RegisterSecret'))
        this.secret = params['RegisterSecret'];
      else
        this.router.navigate(['/home']);
    })
  }

  registerUser() {
    const obj = { FirstName: this.firstName, LastName: this.lastName, Email: this.email }
    this.notifications.warn("Registering User", "Registering New User");

    const { FirstName, LastName, Email } = obj as { [key: string]: string };

    this.authService.register(FirstName, LastName, Email, this.secret)
      .subscribe(() => {
        this.notifications.success("User Registered", "Login Details Sent");
        this.router.navigate(['/manage/dashboard']);
      },
        err => {
          console.error(err);
          if (_.has(err, ['error', 'message'])) {
            const message = err.error.message;
            const status = err.status;

            if (status === 400) {
              if (message === 'Invalid Registration Secret') {
                this.notifications.error(
                  "Registration Failed",
                  "Invalid or Deactivated Registration Key"
                );

                return;
              }

              if (message === 'Error Validating Email Address') {
                this.notifications.error(
                  "Registration Failed",
                  "Supplied Email Address could not be validated. Please contact support."
                );

                console.error("Supplied Email Address could not be validated. Please contact support.");
                this.raven.handleError(err);

                return;
              }

              if (message === 'Invalid Registration Details') {
                // This occurs when the users email address is not appropriate
                this.notifications.error(
                  "Registration Failed",
                  "Email Address was not valid for this Registration Key"
                );

                return;
              }
            }

            if (status === 404) {
              if (message === 'User with this e-mail already exists.') {
                this.notifications.error(
                  "Registration Failed",
                  "User with this e-mail already exists."
                );

                return;
              }
            }
          }

          this.raven.handleError(err);

          this.notifications.error(
            "Registration Failed",
            "Something Unexpected Happened. Please Contact Support."
          );
        })
  }
}
