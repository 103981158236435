export interface IMightHaveShippingDetails {
  shippingDetails?: IShippingDetails;
}

export interface IShippingDetails {
  id?: number;
  city: string | null;
  country: string | null;
  postalCode: string | null;
  region: string | null;
  streetAddress: string | null;
  streetAddress2: string | null;
  suburb: string | null;
  addressName: string | null;
  isFreeHandling: boolean;
}

export class ShippingDetails {
  Guid: string | null = null;
  AddressName: string | null = null;
  StreetAddress: string | null = null;
  StreetAddress2: string | null = null;
  Suburb: string | null = null;
  City: string | null = null;
  Region: string | null = null;
  PostalCode: string | null = null;
  Country = 'Australia';

  beforeSave = () => {
    Object.keys(this).forEach(k => {
      if (typeof this[k] === 'string' && this[k].length === 0) { this[k] = null; }
    });
  };
}

export class ShippingDetailsClass {
  id?: number;
  addressName: string | null = '';
  city: string | null = '';
  country: string | null = '';
  postalCode: string | null = '';
  region: string | null = '';
  streetAddress: string | null = '';
  streetAddress2: string | null = '';
  suburb: string | null = '';
  isFreeHandling: boolean = false;
}