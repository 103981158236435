import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-tab-set',
  templateUrl: './tab-set.component.html',
  styleUrls: []
})
export class TabSetComponent implements OnChanges {
  @Input()
  activeTabIdx: number;

  @Input()
  tabs: string[] = [];

  @Input()
  counts: any;

  @Output() newItemEvent = new EventEmitter<number>();

  public countsCheck = { Addresses: 0, Users: 0, Products: 0, Document: 0, Collections: 0 };
  ngOnChanges(): void {
    if (this.counts) {
      this.countsCheck.Addresses = this.counts.address?.length;
      this.countsCheck.Users = this.counts.customerUsers?.length;
      this.countsCheck.Products = this.counts.products?.count;
      this.countsCheck.Document = this.counts.alldocuments?.length;
      this.countsCheck.Collections = this.counts.collections?.length
    }
  }

  public isActiveTab(idx: number) {
    return idx === this.activeTabIdx;
  }

  public setActiveTab(idx: number) {
    this.activeTabIdx = idx;
    this.newItemEvent.emit(this.activeTabIdx);
  }
}
