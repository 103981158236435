import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRole } from '../model/role.model';

// Convenience Type
type T = IRole;

@Injectable()
export class AdminRoleApi extends GenericApi<T> {
  public path = "admin-role";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
