import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, filter, first, switchMap, NEVER, of } from 'rxjs';
import { CartApi } from '../../api/cart.api';
import { CartService } from '../../services/cart.service';
import { NotificationsService } from 'angular2-notifications';
import { SessionApi } from '../../api/session.api';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-cart-approve',
  templateUrl: './cart-approve.component.html',
  styleUrls: []
})
export class CartApproveComponent {
  private cartId: number | null = null;
  private secret: string | null = null;

  public isProcessing: Boolean = true;
  public editCartDetail: boolean = false;
  public error = {
    hasError: false,
    missingPassword: false,
    missingCart: false,
    genericError: false
  };

  // TODO: Replace with form controls when angular is updated
  public detailEditForm: { orderReference: string | null, notes: string | null } = {
    orderReference: '',
    notes: ''
  };

  constructor(
    public route: ActivatedRoute,
    public notifications: NotificationsService,
    private cartService: CartService,
    private readonly session: SessionApi,
    private readonly router: Router,
    private cartApi: CartApi,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    window.scroll(0, 0);
    console.log('CartApproveComponent: Processing Cart Approval');
    this.isProcessing = true;
    this.error.hasError = false;
    this.error.genericError = false;
    this.error.missingPassword = false;
    this.error.missingCart = false;

    combineLatest(
      this.route.params,
      this.route.queryParams
    ).subscribe(([params, qParams]) => {
      this.cartId = params.hasOwnProperty('cartId') && params.cartId.length ? Number(params.cartId) : null;
      this.secret = qParams.hasOwnProperty('secret') && qParams.secret.length ? qParams.secret : null;
      this.editCartDetail = qParams.hasOwnProperty('changeDetail') && qParams.changeDetail.length ? qParams.changeDetail === 'true' : false;

      if (!this.cartId || !this.secret) {
        this.isProcessing = false;
        this.error.hasError = true;
        this.error.missingCart = (this.cartId === null);
        this.error.missingPassword = (this.secret === null);
        return;
      }

      if (this.editCartDetail) {
        this.fetchCartDetail();
        return;
      }

      this.approveCart();
    });
  }

  private fetchCartDetail() {
    this.session.$sessionChanged
      .pipe(
        filter(ready => ready),
        first(),
        switchMap(() => {
          if (!this.session.$userData.value) {
            this.authService.setStickyMenu('login');
            this.router.navigate(['/home'], {
              queryParams: {
                target: this.router.url
              }
            });
            // Redirect to auth
            return NEVER;
          }
          return of(true);
        })
      )
      .subscribe({
        next: () => {
          // Begin

          if (this.cartId) {
            this.cartService.getCart(this.cartId).subscribe({
              next: cart => {
                if (cart) {
                  this.detailEditForm.notes = cart.attrs.notes;
                  this.detailEditForm.orderReference = cart.attrs.orderReference;
                }
              }
            })
          }
        }
      });
  }

  public saveCartAttrs() {
    if (this.cartId) {
      this.cartApi.saveCartAttrsAdmin(this.cartId, this.detailEditForm as any).subscribe({
        next: () => {
          this.editCartDetail = false;
          this.approveCart();
        }
      });
    }
  }

  private approveCart() {
    if (this.cartId) {
      this.cartService.approveCart(this.cartId, this.secret || undefined)
        .subscribe(order => {
          // An error has been caught and acknowledged already
          if (!order) {
            return;
          }
          this.isProcessing = false;
          this.error.hasError = false;
        },
          err => {
            this.isProcessing = false;
            this.error.hasError = true;
            this.error.genericError = true;

            console.error(err);
          });
    }
  }
}
