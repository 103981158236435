<section class="loginBanner py-0">
  <div class="max-width-container">
    <div class="bg-design"></div>
    <div class="card login-card">
      <div class="card-body">
        <form action="">
          <h4 class="text-secondary">NEW PASSWORD</h4>
          <div class="form-group">
            <label for="Password1" class="form-label">Password</label>
            <input type="password" class="form-control" id="Password1" placeholder="Enter your password" name="password"
              [(ngModel)]="password" />
          </div>
          <button [disabled]="!password" type="button" class="btn btn-primary w-100" (click)="submit()">
            Save
          </button>
        </form>
        <div class="login-new-acc">
          <a href="#" class="btn btn-light w-100">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</section>