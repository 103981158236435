import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { Brand } from '../model/ddb.model';
import { BrandApi } from '../api/brand.api';

@Injectable()
export class BrandService {
  constructor(
    private notifications: NotificationsService,
    private brandApi: BrandApi
  ) {
  }


  public list(query: IQueryFilter): Observable<any> {
    return apiCallWrapper(
      this.brandApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Brand"
      }
    )
  }

  public create(data: any): Observable<Brand & HasId<string>> {
    return apiCallWrapper(
      this.brandApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Updating Brands"
      }
    )
  }

}
