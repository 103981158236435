import { Component, Input } from "@angular/core";
import { isNullOrUndefined } from "util";
import { NotificationsService } from "angular2-notifications";
import { FileUploadService } from "src/app/main/services/fileUpload.service";
import { EmployeePurchaseMode, UnleashedCustomer } from "src/app/main/model/unleashed.model";

@Component({
	selector: 'purchase-mode-edit',
	styles: [],
	template: `
  <div class="edit-users">
  <div class="edit-card card">
    <div class="card-head">
      <h4 class="head-title">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
              fill="url(#paint0_radial_0_16787)" fill-opacity="0.5" />
            <path
              d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
              fill="url(#paint1_radial_0_16787)" fill-opacity="0.5" />
            <path
              d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
              stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
              <radialGradient id="paint0_radial_0_16787" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
                <stop stop-color="#8BD6EF" />
                <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
              </radialGradient>
              <radialGradient id="paint1_radial_0_16787" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
                <stop stop-color="#8BD6EF" />
                <stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </span>
        Employee Purchase Mode
      </h4>
    </div>
    <div class="card-body">
      <form action="">
        <div class="row">
          <div class="col-12">
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="Approval1" value="cc"
              [checked]="isPurchaseMode('credit-card', true)" (change)="setPurchaseMode('credit-card')"/>
              <label class="form-check-label" for="Approval1">Credit Card: The user may use their
                credit card to pay for their order.
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="Approval2"  value="inv"
              [checked]="isPurchaseMode('invoice')" (change)="setPurchaseMode('invoice')"/>
              <label class="form-check-label" for="Approval2">Invoice: The user may charge their
                order to the customer account.
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="Approval3" value="both"
              [checked]="isPurchaseMode('both')" (change)="setPurchaseMode('both')"/>
              <label class="form-check-label" for="Approval3">Both: The user choose either invoice
                or credit card.
              </label>
            </div>
          </div>
          <div class="col-12">
            <div class="uploader">
              <div>
                <h5 class="form-title">
                  Send form for inventory purchase
                </h5>
                <div class="form-text">
                  The following document will be attached
                  to any outgoing approval email.
                </div>
              </div>
              <div class="form-group w-100">
              <label class="btn btn-secondary w-100">
                Upload documents
                <input class="form-control d-none" #fileSelectInput type="file" (change)="persistS3Documents($event)" id="logoFile" />
              </label>
            </div>
              <div class="col-md-8 pt-1 pb-1" *ngIf="getSupportingDocuments().length">
              <div *ngFor="let document of getSupportingDocuments(); let i = index;">
                <a [href]="document.s3Url" target="_blank">{{document.fileName}}</a>&nbsp;&nbsp;<span
                  class="remove-document-icon" title="Delete" (click)="removeSupportingDocument(i)">&times;</span>
              </div>
            </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
	`
})
export class PurchaseModeEditComponent {
	@Input()
	customer: UnleashedCustomer;

	isUploading: boolean = false;

	constructor(
		public fileUploadService: FileUploadService,
		public notifications: NotificationsService,
	) { }

	/**
	 * UI Helper method for getting the list of supporting documents on the current cart
	 */
	public getSupportingDocuments = () =>
		this.customer &&
			this.customer.supportingDocuments ?
			this.customer.supportingDocuments :
			[];

	isPurchaseMode(mode: EmployeePurchaseMode, onNull: any = false) {
		if (isNullOrUndefined(this.customer.employeePurchaseMode))
			return onNull;

		return this.customer.employeePurchaseMode === mode;
	}

	setPurchaseMode(mode: EmployeePurchaseMode) {
		this.customer.employeePurchaseMode = mode;
	}

	/**
	 * Uploads Supporting Documents to an order
	 *
	 * @param event
	 */
	public persistS3Documents(event) {
		this.isUploading = true;
		const filesArray: File[] = [];
		const promises: Array<Promise<any>> = [];
		for (let i = 0; i < event.srcElement.files.length; i++) {
			filesArray.push(event.srcElement.files[i]);
			promises.push(
				new Promise((resolve, reject) => {
					this.fileUploadService.uploadSupportingDocument(event.srcElement.files[i], 'DOCUMENTATION', (err, data) => {
						if (err)
							reject(err);
						else
							resolve(data);
					});
				}
				));
		}

		let s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static.reali.supply/';
		let cfDistribution = 'https://static.reali.supply/';

		Promise.all(promises).then((resData) => {

			if (!this.customer.supportingDocuments) {
				this.customer.supportingDocuments = [];
			}

			filesArray.forEach((theFile, index) => {
				const url = resData[index].Location.replace(s3Prefix, cfDistribution);

				this.customer.supportingDocuments?.push({
					fileName: theFile.name,
					fileSize: theFile.size,
					mimeType: theFile.type,
					s3Url: url,
					type: "DOCUMENTATION"
				});
			});

			this.isUploading = false;
		}).catch(err => {
			console.error('Exception while uploading files', err);
			this.isUploading = false;
			this.notifications.error('Error', 'Unable to upload files.');
		});
	}



	/**
	 * Removes Supporting Documents from an order
	 * @param index
	 */
	public async removeSupportingDocument(index: number) {
		if (this.customer.supportingDocuments) {
			this.customer.supportingDocuments.splice(index, 1);
		}
	}
}
