export interface DecorationDraft {
  id: number;
  decorationId: number | null;
  decorationJSON: string;
}

export class DecorationOption {
  id: number;
  name: string = '';
  required: boolean = false;
  value: string;
}
