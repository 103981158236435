import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { IJWTPayloadDecoded } from '../model/auth.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IAuthDataResponse, ISessionCustomerData, ISessionUserData } from "../model/session.model";
import { IRole } from '../model/role.model';
import { logger } from '../util/Logger';
import { createUrl } from '../api/api.util';
import { IAuthCustomerUser } from '../model/customer.user.model';
import { IAuthUser } from '../model/user.model';
import { filter, first, map, tap } from "rxjs/operators";
import { has } from "../util/object.util";
import { JwtService } from '../services/jwt.service';
import { generateRandomId } from '../util/string.util';

/**
 * The purpose of the session class is to be the replacement for globals.ts. It should be used to listen to session state changes in order to determine
 * various behaviours between the systems.
 *
 * It should store the current user from userInfo in a strongly typed class as a behaviourSubject and emit when there is a change.
 * This should also apply to roles, etc.
 *
 * These triggers should be notified of data via any auth pathway made available in the various auth services
 * such that this service is autonomous and always up to date.
 *
 * It should be used in place of globals to determine if the user is currently authenticated and to react to authentication
 * status changes. It should not require any other service other than itself
 */

const className = "SessionApi";

@Injectable()
export class SessionApi {

	/**
	 * Subscribe to $userData when you don't need to know the customer of the current user. Null when Unauthenticated
	 */
	public $userData = new BehaviorSubject<ISessionUserData | null>(null);

	/**
	 * Subscribe to $customerData when you need to know the customer of the current user. Null when Admin user or Unauthenticated
	 */
	public $customerData = new BehaviorSubject<null | ISessionCustomerData>(null);

	/**
	 * Subscribe to $customerUser when you need to get the current customer-user data. Null when Admin user or Unauthenticated
	 */
	public $customerUser = new BehaviorSubject<null | IAuthCustomerUser>(null);


	/**
	 * Subscribe to $roleData when you need to know the role of the current user inside the current customer. Null when Admin user or Unauthenticated.
	 */
	public $roleData = new BehaviorSubject<IRole | null>(null);

	/**
	 * Subscribe to $sessionChanged if you need to wait for the session state to settle before obtaining multiple values.
	 * This is what you should typically subscribe to if you want to know stable session data before performing some action on the
	 * above observables. Will stay false until the first time it is called. Essentially, when true, session is ready and all data is ready
	 */
	public $sessionChanged = new BehaviorSubject<boolean>(false);

	/**
	 * Convenience observable for obtaining all currently known customerUsers
	 */
	public $customerUsers = this.$userData.asObservable().pipe(
		map(userData => ( userData ? userData.customerUsers || [] : [] ) )
	);

	private _sessionId: string | null = null;

	constructor(
		private readonly httpClient: HttpClient,
		private readonly jwtService: JwtService
	) { }

	// /**
	//  * @description Called to obtain the auth data for the current user.
	//  */
	private _authDataRequest: BehaviorSubject<IAuthDataResponse> | null = null;
	public readAuthData(): Observable<IAuthDataResponse> {
		const signature = className + ".readAuthData: ";

		if (!this._authDataRequest) {
			logger.debug(signature + `Preparing user data request`);
			this._authDataRequest = new BehaviorSubject<any>(null);

			this.httpClient.get<IAuthDataResponse>(
				createUrl('auth', 'authorization', 'information')
			).subscribe({
				next: (resp) => {
					logger.debug(signature + `Loaded user data`);
					this._authDataRequest?.next(resp);
				},
				error: err => {
					logger.debug(signature + `Error loading user data`);
					this._authDataRequest?.error(err);
				},
				complete: () => {
					if (this._authDataRequest) {
						this._authDataRequest.complete();
						this._authDataRequest = null;
					}
				}
			})
		}

		return this._authDataRequest.pipe(
			filter(resp => !!resp),
		);
	};

	private clearSession() {
		const signature = className + '.clearSession: ';
		logger.warn(signature + `Cleared`);
		this.$userData.next(null);
		this.$customerData.next(null);
		this.$roleData.next(null);
		this.$customerUser.next(null);
		this.$sessionChanged.next(true);
	}

	public init() {
		if( this._sessionId ) return;

		this._sessionId = generateRandomId(16);
		const signature = className + `.init: Id[${this._sessionId}] `;

		logger.silly(signature + 'Creating new Session');
		this.jwtService.currentJwtPayload$.pipe(
			tap(() => logger.silly(signature + 'Payload Changed')),
			filter(jwt => {
				if (!jwt) {
					this.clearSession();
					return false;
				}

				return true;
			}),
			map(jwt => [jwt, this.jwtService.verifyJWT(jwt)] as ([NonNullable<typeof jwt>, boolean])),
			tap(([, verified]) => logger.silly(signature + `Verified JWT[${verified}]`)),
			filter(([, verified]) => {
				if (!verified) {
					// This will cause the currentJwtPayload$ to change, and then cause the session to clear
					this.jwtService.removeJWTData();
					return false;
				}

				return true;
			}),
		)
			.subscribe(([payload]) => {
				// Start the session
				this.readAuthData().pipe(first()).subscribe({
					next: data => {
						this.processAuthDataResponse(data, payload);
					},
					error: err => {
						logger.error(signature + `Error: ` + String(err));
						logger.error(err);

						// This will cause the currentJwtPayload$ to change, and then the session will be cleared
						this.jwtService.removeJWTData();
					}
				});
			});
	}

	private processAuthDataResponse(data: IAuthDataResponse, payload: IJWTPayloadDecoded) {
		const signature = className + ".processAuthResponse: ";

		// Emit the user that we are acting as
		this.$userData.next(data);

		if (!data.isAdmin) {
			logger.silly(signature + `Processing CustomerUser Response`);
			let iCustomerUser: IAuthCustomerUser | null = null;

			if (payload.customerId && data.customerUsers) {
				// Attempt to find the customer in the CustomerUser
				iCustomerUser = data.customerUsers.find(customerUser => customerUser.customerId === payload.customerId) || null;

				if (!iCustomerUser) {
					logger.error(signature + `User[${data.id}] attempted to assume customer[${payload.customerId}] however access will be denied.`);
				}
			}

			if (!iCustomerUser) {
				iCustomerUser = data.customerUsers?.find(customerUser => !customerUser.isGuest) || null;
			}

			this.processAuthCustomerUser(iCustomerUser);
		} else {
			logger.silly(signature + `Processing Admin Response`);
			this.$customerData.next(null);
			this.$roleData.next(data.adminRole);
			this.$sessionChanged.next(true);
		}
	}

	private processAuthCustomerUser(authCustomerUser: IAuthCustomerUser | null) {
		const signature = className + ".processAuthCustomerUser: ";

		if (!authCustomerUser?.customer) {
			// logger.error(signature + "Null customer detected in authCustomerUser");
			this.$customerData.next(null);
			this.$roleData.next(null);
			this.$sessionChanged.next(true);
			return throwError(() => "Null customer detected in authCustomerUser");
		}

		this.$customerData.next(authCustomerUser.customer);

		if (!authCustomerUser.userRole) {
			logger.error(signature + "Null userRole detected in authCustomerUser");
			this.$roleData.next(null);
			this.$sessionChanged.next(true);
			return throwError(() => "Null userRole detected in authCustomerUser");
		}

		this.$roleData.next(authCustomerUser.userRole);
		logger.debug(signature + `User assumed access to Customer[${authCustomerUser.customer.name}] with Role[${authCustomerUser.userRole.name}]`);

		this.$customerUser.next(authCustomerUser);
		this.$sessionChanged.next(true);
	}

	/**
	 * @description Fetches the customer user of the currently authenticated user. Returns null if the user is not authenticated,
	 *  or is not authenticated as a customerUser (implicitly) or in the unlikely event is somehow authenticated to a customer
	 *  they do not have a valid customer-user.
	 * @returns
	 */
	public getCustomerUser(): IAuthCustomerUser | null {
		const authUser = this.$userData.value;
		const authCustomer = this.$customerData.value;

		if (!authUser || !authCustomer) {
			return null;
		}

		return authUser.customerUsers?.find(customerUser => customerUser.customerId === authCustomer.id) || null;
	}

	/**
	 * @description Fetches a property from the user the user currently is acting as or null if the property is not present.
	 * @param {keyof IAuthUser} property
	 * @returns {IAuthUser[property] | null}
	 */
	public getUserProperty<T extends keyof IAuthDataResponse>(property: T): IAuthDataResponse[T] | null {
		if (this.$userData.value && has(this.$userData.value, property)) {
			return this.$userData.value[property];
		}

		return null;
	}
	/**
	 * @description When emulating another user, this will return the original value, not the emulated value. If not emulating a user, this will return data from self.
	 *  This should generally only be used if you intend to penetrate the emulation intentionally, such as emails beinga attached to orders.
	 * @param {keyof IAuthUser} property
	 * @returns {IAuthUser[property] | null}
	 */
	public getActualUserProperty<T extends keyof IAuthUser>(property: T): IAuthUser[T] | null {
		const actualUser = this.getUserProperty('actual');

		if (actualUser && has(actualUser, property)) {
			return actualUser[property];
		}

		return this.getUserProperty(property);
	}
}
