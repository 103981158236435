import { Component } from '@angular/core';
import { CustomerUserService } from '../../services/customerUser.service';
import { ActivatedRoute } from '@angular/router';
import { ICustomerUser } from '../../model/customer.user.model';
import { ShippingDetailsClass } from '../../model/shippingDetail.model';
import { BusinessUnitService } from '../../services/businessUnit.service';

@Component({
  selector: 'app-view-employe',
  templateUrl: './view-employe.component.html',
  styleUrls: []
})
export class ViewEmployeComponent {
  public customerUser: Partial<ICustomerUser>;
  public isProfile: string | null;
  businessUnit: string;

  constructor(
    private customerUserService: CustomerUserService,
    private activatedRoute: ActivatedRoute,
    private businessUnitService: BusinessUnitService
  ) { }

  ngOnInit() {
    this.isProfile = this.activatedRoute.snapshot.queryParamMap.get('isProfile');
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.customerUserService.get(params['id']).subscribe((res) => {
          this.customerUser = res;
          this.loadBusinessUnit();
        })
      }
    })
  }

  loadBusinessUnit() {
    if (this.customerUser.businessUnitId) {
      this.businessUnitService.get(this.customerUser.businessUnitId).subscribe((res) => {
        this.businessUnit = res.code + ' - ' + res.name;
      })
    }
  }
}
