import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: []
})
export class AppFooterComponent {
  checkRoute() {
    window.scroll(0, 0);
  }
}
