import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { IGroup, IUserGroup, IUserGroups } from 'src/app/main/model/group.model';
import { IQueryFilter } from 'src/app/main/model/query.filter.class';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { GroupService } from 'src/app/main/services/group.service';
import { SecurityService } from 'src/app/main/services/security.service';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';

@Component({
  selector: 'app-user-group-edit',
  templateUrl: './user-group-edit.component.html',
  styleUrls: []
})
export class UserGroupEditComponent implements OnInit, OnDestroy {

  customerUserId: number;
  customerId: number;
  private readonly subscriptionGroup = new SubscriptionGroup();
  model: IUserGroups = {
    userGroups: [],
  };
  allGroups: IGroup[];
  selectedGroup: string;
  groupAssign = false;
  customerGroup = false;
  public isRemoved: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public readonly customerUserService: CustomerUserService,
    private readonly securityService: SecurityService,
    private groupService: GroupService,
  ) {
  }

  ngOnInit() {

    this.subscriptionGroup.add(
      this.route.params.subscribe(params => {
        this.customerUserId = Number(params['id']);
        this.customerId = Number(params["customerId"]);

        combineLatest([
          this.groupService.list(new IQueryFilter({
            limit: 1000,
            filter: { customerId: this.customerId }
          })),
          this.getCustomerUser()
        ])
          .subscribe({
            next: ([groups, customerUser]) => {
              this.allGroups = groups.rows;

              if (customerUser.userGroups?.length > 0) {
                this.model.userGroups = customerUser.userGroups;
                this.customerGroup = true;
                this.groupAssign = true;
              }
            }
          });
      })
    );
  }

  /**
 * @description Gets the dispay text for any given productGroup
 *
 * @param modelGroup
 */
  getGroupName() {
    if (!this.allGroups && this.model.userGroups?.[0].groupId)
      return;

    let groupSelectOption = this.allGroups.find(selectOption => {
      return selectOption.id === this.model.userGroups?.[0].groupId;
    });

    if (!groupSelectOption)
      return "";

    return groupSelectOption.name;
  }

  groupChange(val: any) {
    this.model.userGroups?.push({ groupId: +val, isPrimary: true });
  }

  removeGroup() {
    this.groupAssign = false;
    this.isRemoved = true;
    this.model = {
      userGroups: [],
    };
  }

  /**
 * Encapsulates the fetching of the customer user from the known customerUserId
 */
  private getCustomerUser(): Observable<any | null> {
    const filter = new IQueryFilter({
      filter: { id: this.customerUserId },
      include: [{
        association: 'userGroups',
      }]
    });

    return this.customerUserService.list(filter)
      .pipe(
        map(result => {
          if (result.hasOwnProperty('rows') && result.rows.length > 0) {
            return result.rows[0];
          }

          return null;
        })
      )
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }  }

}
