import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { HasId } from '../model/generics';
import { AllocationLogApi } from '../api/allocationLog.api';
import { IAllocationLog } from '../model/allocationLog.model';

@Injectable()
export class AllocationLogService {
  constructor(
    private notifications: NotificationsService,
    private allocationLogApi: AllocationLogApi
  ) {
  }


  public list(query: IQueryFilter): Observable<QueryResult<IAllocationLog & HasId>> {
    return apiCallWrapper(
      this.allocationLogApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching Logs"
      }
    )
  }
}
