import { Component, Input } from '@angular/core';
import { IGroup } from 'src/app/main/model/group.model';

@Component({
  selector: 'app-group-collections-overview',
  templateUrl: './collections-overview.component.html',
  styleUrls: []
})
export class CollectionsOverviewComponent {
  @Input() group: IGroup;
}
