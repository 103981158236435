import { Component } from '@angular/core';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { IQueryFilter, QueryResult, ScopeOption } from '../../model/query.filter.class';
import { IRole } from '../../model/role.model';
import { RolesApi } from '../../api/roles.api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-user-role-list',
  templateUrl: './user-role-list.component.html',
  styleUrls: []
})
export class UserRoleListComponent {
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;

  public query: IQueryFilter = new IQueryFilter({
    sortBy: 'name',
    scope: 'ExcludeArchived'
  });

  public sortOptions = [{
    id: 1,
    text: "Role Name",
    field: "name"
  }];

  readonly scopeOptions = [{
    text: "Exclude Archived (Default)",
    scope: "ExcludeArchived"
  }, {
    text: "Include Archived",
    scope: "IncludeArchived"
  }, {
    text: "Archived Only",
    scope: "ArchivedOnly"
  }]

  public queryResult: QueryResult<IRole> = new QueryResult();

  constructor(
    private roleApi: RolesApi,
    public router: Router,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(queryparams => {
      this.currentPageActive = +queryparams['page'] || 1;
      this.query.limit = +queryparams['pageSize'] || 10;
      this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
      this.loadUserRoles();
    });
    this.search();
  }

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.query.filter.name = { $like: '%' + searchTerm + '%' };
      else
        delete this.query.filter.name;

      this.loadUserRoles();
    });
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadUserRoles()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.loadUserRoles();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadUserRoles();
  }

  updateScopeField(scope: string) {
    this.query.scope = scope as ScopeOption;
    this.loadUserRoles();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  /**
* @description UI helper method for retrieving the text of the selected scope option
* @returns {string | undefined}
*/
  getScopeText = (): string | undefined => {
    if (!this.query.scope)
      return undefined;

    const scopeOption = this.scopeOptions.find(option => option.scope === this.query.scope);

    if (scopeOption)
      return scopeOption.text;
    return undefined;
  };


  loadUserRoles() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.roleApi.list(this.query)
      .subscribe(queryResult => {
        this.totalCountActive = queryResult.count;
        this.queryResult = queryResult;
      });
  }

  deleteRole(id: number) {
    this.roleApi.delete(id).subscribe(() => {
      this.loadUserRoles();
    })
  }

  public openRemoveModal(id, content) {
    this.modalService.open(content, {
      size: 'sm', centered: true, backdrop: true, windowClass: 'deleteModal'
    }).result.then(() => {
      this.notifications.warn('Processing', 'Deleting the user role.');
      this.deleteRole(id);
    }, () => { });
  }
}
