import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { CustomerDomainApi } from '../api/customerDomain.api';
import { ICustomerDomain } from '../model/customerDomain';
import { HasId } from '../model/generics';

@Injectable()
export class CustomerDomainService {
  constructor(
    private notifications: NotificationsService,
    private customerDomainApi: CustomerDomainApi
  ) {
  }

  public create(data: ICustomerDomain): Observable<ICustomerDomain> {
    return apiCallWrapper(
      this.customerDomainApi.create(data),
      {
        notificationsService: this.notifications,
        action: "Creating Customer Domain"
      }
    )
  }

  public update(id: number, data: ICustomerDomain): Observable<ICustomerDomain> {
    return apiCallWrapper(
      this.customerDomainApi.update(id, data),
      {
        notificationsService: this.notifications,
        action: "Updating Customer Domain"
      }
    )
  }

  public getByCustomerId(id: number | string): Observable<ICustomerDomain & HasId>{
    return apiCallWrapper(
      this.customerDomainApi.getByCustomerId(id),
      {
        notificationsService: this.notifications,
        action: "Fetch Customer Domain",
        failTitle: "Customer Domain not found",
      }
    )
  }
}
