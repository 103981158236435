<div class="main-right-content">
	<!-- Page Breadcrumb start here... -->
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a [routerLink]="['/manage/users']" [queryParams]="{ activeTab: 2 }">Admin</a>
			</li>
			<li class="breadcrumb-item" *ngIf="admin.UserID != ''">
				<a [routerLink]="['/manage/users']" [queryParams]="{ activeTab: 2 }">{{ admin.firstName }}</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ adminGuid ? "Edit" : "Add" }}
			</li>
		</ol>
	</nav>
	<!-- Page Breadcrumb end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">{{ adminGuid ? "Edit" : "Add" }} Admin</h1>
		</div>

		<div class="manage-user-list">
			<div class="edit-users">
				<div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
											fill="url(#paint0_radial_0_11441)" fill-opacity="0.5" />
										<path
											d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
											fill="url(#paint1_radial_0_11441)" fill-opacity="0.5" />
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<defs>
											<radialGradient id="paint0_radial_0_11441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
												gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
												<stop stop-color="#8BD6EF" />
												<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
											</radialGradient>
											<radialGradient id="paint1_radial_0_11441" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
												gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
												<stop stop-color="#8BD6EF" />
												<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
											</radialGradient>
										</defs>
									</svg>
								</span>
								User details
							</h4>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<h4 class="form-title">PERSONAL DETAILS</h4>
								</div>
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<label for="FirstName" class="form-label">First name <span class="text-danger">*</span></label>
										<input type="text" class="form-control" id="FirstName" name="firstname" placeholder=""
											[(ngModel)]="admin.firstName" />
									</div>
								</div>
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<label for="lastName" class="form-label">Last name <span class="text-danger">*</span></label>
										<input type="text" class="form-control" id="lastName" name="lastname" placeholder=""
											[(ngModel)]="admin.lastName" />
									</div>
								</div>
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<label for="mailAddress" class="form-label">Email address <span class="text-danger">*</span></label>
										<input type="email" class="form-control" id="mailAddress" name="email" placeholder=""
											[(ngModel)]="admin.email" />
									</div>
								</div>
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<label for="Contact" class="form-label">Contact number <span>(Optional)</span></label>
										<input type="number" class="form-control" id="Contact" name="contactNumber" placeholder=""
											[(ngModel)]="admin.ContactNumber" />
									</div>
								</div>

								<div class="col-12">
									<h4 class="form-title">BUSINESS DETAILS</h4>
								</div>

								<div class="col-12" *ngIf="userRoles">
									<div class="form-group">
										<label for="UserRole" class="form-label">User role</label>
										<select #adminRole class="form-select" aria-label="Default select example" id="UserRole"
											(change)="valueChanged(+adminRole.value)" [disabled]="isSuperAdmin">
											<option value="0">Select User Role</option>
											<option *ngFor="let role of userRoles.rows" [value]="role.id"
												[selected]="admin && role.id == admin.adminRoleId">
												{{ role.name }}
											</option>
										</select>
									</div>
								</div>

								<div class="col-12">
									<div class="form-group form-check">
										<input type="checkbox" class="form-check-input" id="superAdmin" [checked]="isSuperAdmin"
											(change)="isSuperAdminChange()" />
										<label class="form-check-label" for="superAdmin">Is Super Admin
											<span>User has maximum access level</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path
											d="M12.025 5.72578C11.8876 5.86598 11.8106 6.05446 11.8106 6.25078C11.8106 6.44709 11.8876 6.63558 12.025 6.77578L13.225 7.97578C13.3652 8.1132 13.5537 8.19017 13.75 8.19017C13.9463 8.19017 14.1348 8.1132 14.275 7.97578L17.1025 5.14828C17.4796 5.98167 17.5938 6.9102 17.4299 7.81014C17.2659 8.71007 16.8315 9.53866 16.1847 10.1855C15.5379 10.8323 14.7093 11.2667 13.8094 11.4306C12.9094 11.5946 11.9809 11.4804 11.1475 11.1033L5.96501 16.2858C5.66665 16.5841 5.26197 16.7518 4.84001 16.7518C4.41806 16.7518 4.01338 16.5841 3.71501 16.2858C3.41665 15.9874 3.24902 15.5827 3.24902 15.1608C3.24902 14.7388 3.41665 14.3341 3.71501 14.0358L8.89751 8.85328C8.52039 8.01989 8.4062 7.09135 8.57017 6.19142C8.73414 5.29149 9.16848 4.46289 9.8153 3.81607C10.4621 3.16924 11.2907 2.7349 12.1907 2.57094C13.0906 2.40697 14.0191 2.52115 14.8525 2.89828L12.0325 5.71828L12.025 5.72578Z"
											fill="url(#paint0_radial_0_16351)" fill-opacity="0.5" />
										<path
											d="M12.025 5.72578C11.8876 5.86598 11.8106 6.05446 11.8106 6.25078C11.8106 6.44709 11.8876 6.63558 12.025 6.77578L13.225 7.97578C13.3652 8.1132 13.5537 8.19017 13.75 8.19017C13.9463 8.19017 14.1348 8.1132 14.275 7.97578L17.1025 5.14828C17.4796 5.98167 17.5938 6.9102 17.4299 7.81014C17.2659 8.71007 16.8315 9.53866 16.1847 10.1855C15.5379 10.8323 14.7093 11.2667 13.8094 11.4306C12.9094 11.5946 11.9809 11.4804 11.1475 11.1033L5.96501 16.2858C5.66665 16.5841 5.26197 16.7518 4.84001 16.7518C4.41806 16.7518 4.01338 16.5841 3.71501 16.2858C3.41665 15.9874 3.24902 15.5827 3.24902 15.1608C3.24902 14.7388 3.41665 14.3341 3.71501 14.0358L8.89751 8.85328C8.52039 8.01989 8.4062 7.09135 8.57017 6.19142C8.73414 5.29149 9.16848 4.46289 9.8153 3.81607C10.4621 3.16924 11.2907 2.7349 12.1907 2.57094C13.0906 2.40697 14.0191 2.52115 14.8525 2.89828L12.0325 5.71828L12.025 5.72578Z"
											stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<defs>
											<radialGradient id="paint0_radial_0_16351" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
												gradientTransform="translate(10.3759 3.21073) rotate(90) scale(10.3339)">
												<stop stop-color="#8BD6EF" />
												<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
											</radialGradient>
										</defs>
									</svg>
								</span>
								Login security

								<span class="badge bg-alpha-25">2 factory method</span>
							</h4>
							<div class="form-check form-switch">
								<label class="form-check-label" for="enabledSwitch">{{
									secureLoginType !== "none" ? "Enabled" : "Disabled"
									}}</label>
								<input class="form-check-input" type="checkbox" role="switch" id="enabledSwitch"
									[checked]="secureLoginType !== 'none'" (click)="checkSecureLoginType()" />
							</div>
						</div>
						<div class="card-body" *ngIf="secureLoginType !== 'none'">
							<div class="auths">
								<div class="form-group form-check">
									<label class="form-check-label" for="Authentication1"
										[ngClass]="{ active: secureLoginType != 'app' }">
										E-mail
										<span><input class="form-check-input" type="radio" name="Authentications" id="Authentication1"
												[checked]="secureLoginType != 'app'" (click)="checkSecureLoginType('mail')" />
										</span>
									</label>
								</div>
								<div class="form-group form-check">
									<label class="form-check-label" for="Authentication2"
										[ngClass]="{ active: secureLoginType == 'app' }">
										Authenticator app
										<span>
											<input class="form-check-input" type="radio" name="Authentications" id="Authentication2"
												[checked]="secureLoginType == 'app'" (click)="checkSecureLoginType('app')" />
										</span>
									</label>
								</div>
							</div>

							<!-- Auth QR Content start here... -->
							<div class="auth-qr" *ngIf="tfaBlock">
								<div class="auth-img">
									<img src="{{ qrImage }}" alt="qrImage" />
								</div>
								<div class="form-text mt-3">
									{{ secretCode }}
								</div>
							</div>
							<!-- Auth QR Content end here... -->
							<div class="row" *ngIf="tfaVerifyBlock">
								<div class="col-12 col-lg-9">
									<div class="form-group">
										<label *ngIf="!tfaVerificationSuccess" for="secureCode" class="form-label">Secure Code
										</label>
										<input type="number" class="form-control" name="otp" [(ngModel)]="otp"
											*ngIf="!tfaVerificationSuccess" id="secureCode" placeholder="" />
										<div class="form-text text-danger position-absolute" *ngIf="wrongOtp && !tfaVerificationSuccess">
											Secure Code Incorrect.
										</div>
									</div>
								</div>
								<div class="col align-self-end">
									<div class="form-group" *ngIf="!tfaVerificationSuccess">
										<button class="btn btn-primary w-100" type="button" (click)="verifyOtp()">
											Validate
										</button>
									</div>
								</div>
								<div class="col-12">
									<ul class="list-view" [class.mt-5]="wrongOtp && !tfaVerificationSuccess">
										<li>
											<p class="form-text">
												Using an Authenticator app Such as Google Authenticator,
												scan the above QR code.
											</p>
										</li>
										<li>
											<p class="form-text">
												To ensure your 2 Factor Authentication Code is Working
												Correctly, please enter a vaild code now.
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-3">
						<button class="btn btn-primary w-sm-100" type="button" (click)="updateAdmin()">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
										stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</span>
							Save changes
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>