import { GenericApi } from './generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategory } from '../model/ddb.model';

// Convenience Type
type T = ICategory;

@Injectable()
export class CategoryApi extends GenericApi<T> {
  public path = "category";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }
}
