<section class="product-detail-container pt-0">
	<div class="max-width-container">
		<div class="container">
			<div class="success-order-head">
				<div class="d-flex align-items-center gap-4">
					<div class="success-icon">
						<span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.9247 2.32407 11.5752 2.48714 13.0525 3.14539"
									fill="url(#paint0_radial_1_9831)"
									fill-opacity="0.5"
								/>
								<path
									d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
									stroke="#272835"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
									stroke="#272835"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M17.5 4L10 11.5075L7.75 9.2575"
									stroke="#272835"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<defs>
									<radialGradient
										id="paint0_radial_1_9831"
										cx="0"
										cy="0"
										r="1"
										gradientUnits="userSpaceOnUse"
										gradientTransform="translate(4 14.0004) rotate(-30.9638) scale(14.5774)"
									>
										<stop stop-color="#8BD6EF" />
										<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
									</radialGradient>
								</defs>
							</svg>
						</span>
					</div>
					<div>
						<h4 class="title" *ngIf="!pendingApproval">
							This has been processed and we will notify you once the order has
							been dispatched.
						</h4>
						<h4 class="title" *ngIf="pendingApproval">
							Your order was sent for approval and we will notify you once the
							order has been dispatched.
						</h4>
						<p *ngIf="order" class="order-no">
							Order no: {{ order.attrs.orderNumber || "Not Specified" }}
						</p>
					</div>
				</div>
				<a
					[routerLink]="['/home']"
					role="button"
					class="btn btn-secondary gap-0"
				>
					Go to homepage
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
				</a>
			</div>
			<div class="checkout-content success-order" *ngIf="order">
				<div class="card cart-total edit-card">
					<div class="card-head">
						<h4 class="head-title">Details</h4>
					</div>
					<div
						class="balance-content d-flex align-items-center justify-content-between"
					></div>
					<div class="card-body">
						<div class="checkout-detail">
							<p>
								<span class="d-block"> Full name </span>
								{{ order.attrs.contactFirstName || "" }}
								{{ order.attrs.contactLastName || "" }}
							</p>
							<p>
								<span class="d-block"> Email address </span>
								{{ order.attrs.contactEmail || "" }}
							</p>
							<p>
								<span class="d-block"> Mobile phone </span>
								{{ order.attrs.contactPhone || "N/A" }}
							</p>
							<p>
								<span class="d-block"> Address </span>
								{{ order.attrs.shippingDetails.addressName }}
							</p>
							<hr class="bg-dash" />
							<div class="row other-address">
								<div class="col-12 col-lg-6">
									<h5>{{ order.user.firstName }} {{ order.user.lastName }}</h5>
									<p>{{ order.user.email }}</p>
									<p>{{ order.user.contactNumber }}</p>
								</div>
								<div class="col-12 col-lg-6">
									<p>{{ order.attrs.shippingDetails.streetAddress }}</p>
									<p>3{{ order.attrs.shippingDetails.addressName }}</p>
									<p>{{ order.attrs.shippingDetails.city }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="checkout-product">
					<div class="card edit-card">
						<div class="card-head">
							<h4 class="head-title gap-1">
								Products
								<span class="badge bg-alpha-25 py-2 px-3" *ngIf="order.items">{{
									order.items.length
								}}</span>
							</h4>
						</div>
						<div class="card-body">
							<div class="checkout-items">
								<div *ngFor="let item of order.items" class="product-order">
									<div class="order-img">
										<img src="{{ item.product.imageUrl }}" alt="No image" />
									</div>
									<div class="order-table-content">
										<div>
											<h5 class="title">
												{{ item.product.name }}
											</h5>
											<p class="price">
												${{ item.price || item.product.price }}
											</p>
										</div>
										<ul class="order-detail-list">
											<li>
												<p>Color:</p>
												<span>
													<span
														class="color"
														[style.background]="
															generateGradient(item.variation.colour)
														"
													></span>
													{{ item.variation?.colour || "default" }}</span
												>
											</li>
											<li>
												<p>Size:</p>
												<span> {{ item.variation?.size || "default" }}</span>
											</li>
											<li>
												<p>Quantity:</p>
												<span>{{ item.quantity }}</span>
											</li>
										</ul>
										<ul
											class="order-detail-list"
											*ngIf="item.decorations && item.decorations.length"
										>
											<li>
												<p>Decoration:</p>
												<span *ngFor="let decoration of item.decorations">
													{{ decoration.decoration.name }}</span
												>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
