<section class="product-detail-container pt-0">
	<div class="max-width-container">
		<div class="container">
			<!-- ============================ -->
			<!-- paggination start here... -->
			<!-- ============================ -->
			<nav aria-label="breadcrumb" class="pageBreadcrumb d-none d-lg-block">
				<ol class="breadcrumb mb-0">
					<li class="breadcrumb-item">
						<a [routerLink]="['/home']">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M3.25 7.75L10 2.5L16.75 7.75V16C16.75 16.3978 16.592 16.7794 16.3107 17.0607C16.0294 17.342 15.6478 17.5 15.25 17.5H4.75C4.35218 17.5 3.97064 17.342 3.68934 17.0607C3.40804 16.7794 3.25 16.3978 3.25 16V7.75Z"
									stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M7.75 17.5V10H12.25V17.5" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</a>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/Account']"> Account </a>
					</li>
					<li class="breadcrumb-item">
						<span>Accept Invite</span>
					</li>
				</ol>
			</nav>
			<!-- ============================ -->
			<!-- paggination end here... -->
			<!-- ============================ -->

			<div class="row">
				<div class="col-sm-12">
					<div class="d-flex flex-column align-items-center gap-5 justify-content-center error-h-100">
						<div class="cart-error">
							<!-- Accepted start here... -->
							<svg *ngIf="isAccepted" xmlns="http://www.w3.org/2000/svg" version="1.1"
								xmlns:xlink="http://www.w3.org/1999/xlink" width="140" height="140" x="0" y="0" viewBox="0 0 512 512"
								style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
								<g>
									<path
										d="M7.5 323.722v-148.25c0-13.39 7.21-25.73 18.86-32.3l36.41-20.55M449.23 122.622l36.41 20.55a37.076 37.076 0 0 1 18.86 32.3v280.37c0 20.49-16.61 37.08-37.08 37.08H44.58c-20.47 0-37.08-16.59-37.08-37.08v-97.12M171.721 61.138l66.053-37.278a37.115 37.115 0 0 1 36.45 0l66.053 37.278M62.77 231.062V82.982c0-12.06 9.79-21.84 21.86-21.84h287.1"
										style="
											stroke-width: 15;
											stroke-linecap: round;
											stroke-linejoin: round;
											stroke-miterlimit: 10;
										" fill="none" stroke="#0a680a" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"
										stroke-miterlimit="10" data-original="#000000"></path>
									<path
										d="M405.91 61.142h21.48c12.05 0 21.84 9.78 21.84 21.84v148.08M504.496 191.6 319.431 323.721M192.572 323.721 7.508 191.6M7.5 455.842l229.05-163.52c11.64-8.3 27.26-8.3 38.89 0l71.23 50.85M504.5 455.842l-129.62-92.53M262.663 105.051l7.826 15.857a7.435 7.435 0 0 0 5.596 4.066l17.499 2.543c6.096.886 8.53 8.377 4.119 12.676l-12.662 12.343a7.428 7.428 0 0 0-2.137 6.578l2.989 17.428c1.041 6.071-5.331 10.701-10.783 7.834l-15.652-8.229a7.434 7.434 0 0 0-6.917 0l-15.652 8.229c-5.452 2.866-11.824-1.763-10.783-7.834l2.989-17.428a7.434 7.434 0 0 0-2.137-6.578l-12.662-12.343c-4.411-4.3-1.977-11.791 4.119-12.676l17.499-2.543a7.432 7.432 0 0 0 5.596-4.066l7.826-15.857c2.725-5.524 10.601-5.524 13.327 0zM140.342 227.483h231.314M182.424 259.316h147.15"
										style="
											stroke-width: 15;
											stroke-linecap: round;
											stroke-linejoin: round;
											stroke-miterlimit: 10;
										" fill="none" stroke="#0a680a" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"
										stroke-miterlimit="10" data-original="#000000"></path>
								</g>
							</svg>
							<!-- Accepted end here... -->

							<!-- Process start here... -->
							<svg *ngIf="isLoading" xmlns="http://www.w3.org/2000/svg" version="1.1"
								xmlns:xlink="http://www.w3.org/1999/xlink" width="140" height="140" x="0" y="0" viewBox="0 0 48 48"
								style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
								<g>
									<g data-name="Processing information data">
										<path
											d="M43.73 37.66A24.1 24.1 0 0 0 41 7a23.83 23.83 0 0 0-33.28-.61c-3.2.35-3.75.22-4 1s.18 1 1.23 2C-7.24 25.28 4.28 48 24 48a23.86 23.86 0 0 0 17.1-7.16c3.15-.35 3.7-.22 4-1s-.21-1.01-1.37-2.18zm-6.12-5.31c-.78.27-.66.79-1 4A17.62 17.62 0 0 1 9.49 14c.7.7 1 1.12 1.68.88s.65-.77 1-3.91a17.6 17.6 0 0 1 26.94 22.08c-.52-.52-.84-.93-1.5-.7zm.1 8.86A22 22 0 0 1 7 10a1 1 0 0 0 0-1.34c-.21-.22-.72-.11 3.51-.57l-.41 3.66a1 1 0 0 0-1.48.11C-1.6 24.76 7.74 43.62 24 43.62a19.5 19.5 0 0 0 12.26-4.3l-.09.83a1 1 0 0 0 .64 1 1.69 1.69 0 0 0 .9.06zm.59-2.08.41-3.68a1 1 0 0 0 1.39-.24 19.62 19.62 0 0 0-27.61-27.1l.11-1A1 1 0 0 0 11.36 6a21.85 21.85 0 0 1 28.2 2.46 22.1 22.1 0 0 1 2.06 28.73 1 1 0 0 0 .09 1.31c.36.33.81.17-3.41.63z"
											fill="#c67f19" opacity="1" data-original="#000000" class=""></path>
										<path
											d="M33.72 18.57c-.32-.68-.1-.37-5.43-5.69C27.16 11.75 26.58 12 17 12a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3c0-14.19.12-13.59-.28-14.43zM30.59 18H28v-2.59zM31 34H17a1 1 0 0 1-1-1V15a1 1 0 0 1 1-1h9v5a1 1 0 0 0 1 1h5v13a1 1 0 0 1-1 1z"
											fill="#c67f19" opacity="1" data-original="#000000" class=""></path>
										<path
											d="M25 21h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM25 25h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM25 29h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z"
											fill="#c67f19" opacity="1" data-original="#000000" class=""></path>
									</g>
								</g>
							</svg>
							<!-- Process end here... -->

							<!-- Error start here... -->
							<svg *ngIf="!isAccepted && !isLoading" xmlns="http://www.w3.org/2000/svg" version="1.1"
								xmlns:xlink="http://www.w3.org/1999/xlink" width="140" height="140" x="0" y="0" viewBox="0 0 32 32"
								style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
								<g>
									<path
										d="M7 30h18a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H13.83a3 3 0 0 0-2.12.88L4.88 9.71A3 3 0 0 0 4 11.83V27a3 3 0 0 0 3 3zM26 5v22a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V12h5a3 3 0 0 0 3-3V4h11a1 1 0 0 1 1 1zm-14 .41V9a1 1 0 0 1-1 1H7.41z"
										fill="#7f1515" opacity="1" data-original="#000000" class=""></path>
									<path
										d="M14.63 13.88a1 1 0 0 0-1.41 0l-.71.71-.71-.71a1 1 0 0 0-1.41 1.41l.7.71-.7.71a1 1 0 0 0 0 1.41 1 1 0 0 0 1.41 0l.71-.71.71.71a1 1 0 0 0 1.41 0 1 1 0 0 0 0-1.41l-.71-.71.71-.71a1 1 0 0 0 0-1.41zM17.37 18.12a1 1 0 0 0 1.41 0l.71-.71.71.71a1 1 0 0 0 1.41 0 1 1 0 0 0 0-1.41l-.7-.71.7-.71a1 1 0 1 0-1.41-1.41l-.71.71-.71-.71a1 1 0 0 0-1.41 1.41l.71.71-.71.71a1 1 0 0 0 0 1.41zM10.59 22.41a1 1 0 0 0 1.32.5 9.6 9.6 0 0 1 8.18 0 1 1 0 0 0 .41.09 1 1 0 0 0 .41-1.91 11.63 11.63 0 0 0-9.82 0 1 1 0 0 0-.5 1.32z"
										fill="#7f1515" opacity="1" data-original="#000000" class=""></path>
								</g>
							</svg>
							<!-- Error end here... -->
						</div>
						<h2 class="fw-bold" *ngIf="!title">
							<span>PROCESSING</span> your invite!
						</h2>
						<h2 class="fw-bold" *ngIf="title">{{ title }}</h2>
						<p *ngIf="pendingApproval">
							Please wait while we accept your invite.
						</p>
						<p *ngIf="!pendingApproval">
							<span *ngIf="!message">Your invite has been accepted.</span>
							<span *ngIf="message">{{ message }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>