import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { BusinessUnit } from 'src/app/main/model/unleashed.model';
import { CustomerService } from 'src/app/main/services/customer.service';

@Component({
  selector: 'app-business-list',
  templateUrl: './list.component.html',
  styleUrls: []
})
export class ListComponent implements OnInit, OnDestroy {
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  public customerId: number;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  public isCustomer: boolean = false;
  public editLink = '';
  private unsubscribe$ = new Subject<void>();
  public isAdmin: boolean | undefined;
  query: IQueryFilter = new IQueryFilter({
    filter: {},
    sortBy: 'name',
    order: 'desc',
  });

  sortOptions = [{
    id: 1,
    text: "Name",
    field: "name"
  }, {
    id: 2,
    text: "code",
    field: "code"
  }, {
    id: 3,
    text: "Contact Id",
    field: "contactId"
  }];

  queryResult: BusinessUnit[] = [];
  constructor(
    private customerService: CustomerService,
    public route: ActivatedRoute,
    private session: SessionApi,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(queryparams => {
        this.currentPageActive = +queryparams['page'] || 1;
        this.query.limit = +queryparams['pageSize'] || 10;
        if (params['customerId']) {
          this.query.filter['customerId'] = params['customerId'];
          this.customerId = params['customerId'];
          this.loadBusinessUnit();
          this.editLink = `/manage/customer/edit/${this.customerId}/business-unit`;
        }
        this.isAdmin = this.session.$userData.value?.isAdmin;
        this.session.$customerData.pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(customerData => {
          if (customerData) {
            this.query.filter['customerId'] = customerData.id;
            this.customerId = customerData.id;
            this.loadBusinessUnit();
            this.isCustomer = true;
            this.editLink = '/account/business-unit/edit';
          }
        })
        this.search();
      });
    });

  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadBusinessUnit();
  }

  /**
 * @description Ensures the page number is in sync across multiple pagination components
 *
 * @param {number} pageSize Broadcast pageSize value
 */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1; // Reset to the first page when page size changes
    this.loadBusinessUnit();
  }

  loadBusinessUnit() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.customerService.getBusinessUnit(this.customerId, this.query).pipe(
      takeUntil(this.unsubscribe$)
    )
      .subscribe(queryResult => {
        this.totalCountActive = queryResult.count;
        this.queryResult = queryResult.rows;
        this.queryResult.sort((a, b) => {
          if (a.code < b.code) return -1;
          if (a.code > b.code) return 1;
          return 0;
        });

        this.queryResult = queryResult.rows.map(item => {
          return {
            ...item,
            name: `${item.code} - ${item.name}`
          };
        });
      });
  }

  deleteItem(id: number | undefined) {
    if (id) {
      this.customerService.deleteBusinessUnit(this.customerId, id).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        this.loadBusinessUnit();
      })
    }
  }


  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadBusinessUnit();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length){
        this.query.filter['$or'] = [
          { name: { $like: '%' + searchTerm + '%' } },
          { code: { $like: '%' + searchTerm + '%' } },
        ];
      }
      else{
        delete this.query.filter['$or'];
      }

      this.loadBusinessUnit();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openRemoveModal(id, content) {
    this.modalService.open(content, {
      size: 'sm', centered: true, backdrop: true, windowClass: 'deleteModal'
    }).result.then(() => {
      this.deleteItem(id);
    }, () => { });
  }
}
