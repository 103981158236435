import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportApi } from '../../api/report.api';
import { ReportService } from '../../services/report.service'
import { BehaviorSubject, debounceTime } from 'rxjs';
import { CartStatus } from '../../model/cart.model';
import moment from 'moment-mini';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { ICustomerUser } from 'src/app/main/model/customer.user.model';
import { IQueryFilter } from 'src/app/main/model/query.filter.class';
import { SessionApi } from '../../api/session.api';
import { RolesApi } from '../../api/roles.api';
import { DomSanitizer } from '@angular/platform-browser';


type OrdersReportForm = {
  status: { [key: string]: string; };
  approverId: number | null;
};


@Component({
  selector: 'app-orderlist-report',
  templateUrl: './orderlist-report.component.html'
})
export class OrderlistReportComponent {
  private updateOrders$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public pathBase = '/account/reports';
  public customerId: number | undefined | null = null;
  public reportData: any = [];
  public permissions = "order_approver";
  public customerQuery = new IQueryFilter({
    limit: 1000,
    filter: {
      customerId: this.customerId
    },
    include: [{
      association: 'user',
      required: true
    }],
  });

  public orderStatuses = Object.keys(CartStatus)
    .filter(status => status.toLowerCase() !== 'pending');

  public ordersReportForm: OrdersReportForm = {
    status: this.orderStatuses.reduce((self, val) => {
      self[val] = true;
      return self;
    }, {}),
    approverId: null
  };
  public customerUsers: ICustomerUser[];
  name: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    public reportService: ReportService,
    private customerUserService: CustomerUserService,
    private session: SessionApi,
    private userRoleApi: RolesApi,
    private sanitizer: DomSanitizer
  ) {
    if (this.session.$userData.value?.isAdmin) {
      this.pathBase = '/manage/reports';
    }
  }

  ngOnInit() {
    this.customerId = this.activatedRoute.snapshot.queryParams.customerId || null;
    this.name = this.activatedRoute.snapshot.queryParams.name || null;

    this.updateOrders$.pipe(
      debounceTime(250),
    ).subscribe(() => {
      this.loadOrders();
    })

    this.loadCustomerUsers();
  }

  loadOrders() {
    const reportParams = this.getReportParams();

    this.reportService.getOrderListReport(reportParams).subscribe(data => {
      this.reportData = data;
    });
  }

  getProductDescription(description: string){
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  getOrderDate(orderedAt: string | null | undefined): string {
    if (!orderedAt) { return ''; }

    return moment(orderedAt).local().format('DD MMM, YYYY');
  }

  /**
   * @description Exports the current contents of reportData to CSV
   */
  downloadFile() {
    const reportParams = this.getReportParams();

    this.reportService.createCSV({ data: reportParams, type: 'order' }).subscribe();
  }

  HTMLToText(html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  filterChange() {
    this.updateOrders$.next(true);
  }

  async loadCustomerUsers() {
    await Promise.all([
      this.fetchRoleIds(),
    ]);
    this.customerQuery.filter['customerId'] = this.customerId;
    this.customerUserService.list(this.customerQuery).subscribe(res => {
      this.customerUsers = res.rows;
    });
  }

  /**
* Populates the roleIds property (if applicable) with target user roles
*/
  private fetchRoleIds = async () => {
    if (!this.permissions || !this.permissions.length)
      return;

    return new Promise<void>((resolve, reject) => {
      this.userRoleApi.rolesWithPermission(...this.permissions.split(",")).subscribe(roles => {
        const roleIds = roles.map(role => role.id);
        if (roleIds && roleIds.length) {
          this.customerQuery.filter['$or'] = roleIds.map(roleId => ({ userRoleId: roleId }));
        }
        resolve();
      });
    })
  }

  getReportParams() {
    const filterStatus = Object.keys(this.ordersReportForm.status)
      .filter(status => !!this.ordersReportForm.status[status])
      .join(",");
    const reportParams: { customerId: number | null | undefined, status: string, approverId?: number } = {
      customerId: this.customerId,
      approverId: this.ordersReportForm.approverId || undefined,
      status: filterStatus
    };

    if (!reportParams.approverId) delete reportParams.approverId;

    return reportParams;
  }

  fetchColor(status: string) {
    if (status === "PROCESSING") {
      return 'aqua';
    }
    if (status === "COMPLETED") {
      return 'green';
    }
    if (status === "BACKORDERED") {
      return 'purple';
    }
    if (status === "APPROVAL") {
      return 'red';
    }
  }

  getInitials(name: string): string {
    if (!name) return '';

    const nameWithoutDetails = name.split(/[()\s]/)[0].trim();
    const nameParts = nameWithoutDetails.split(' ');
    const initialsArray: string[] = [];
    for (const part of nameParts) {
      const initial = part.charAt(0).toUpperCase();
      initialsArray.push(initial);
    }
    const initials = initialsArray.join('');

    return initials;
  }

}
