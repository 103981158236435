<section class="product-detail-container pt-0">
	<div class="max-width-container">
		<div class="container">
			<!-- ============================ -->
			<!-- paggination start here... -->
			<!-- ============================ -->
			<nav aria-label="breadcrumb" class="pageBreadcrumb d-none d-lg-block">
				<ol class="breadcrumb mb-0">
					<li class="breadcrumb-item">
						<a [routerLink]="['/home']">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M3.25 7.75L10 2.5L16.75 7.75V16C16.75 16.3978 16.592 16.7794 16.3107 17.0607C16.0294 17.342 15.6478 17.5 15.25 17.5H4.75C4.35218 17.5 3.97064 17.342 3.68934 17.0607C3.40804 16.7794 3.25 16.3978 3.25 16V7.75Z"
									stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M7.75 17.5V10H12.25V17.5" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</a>
					</li>
					<li class="breadcrumb-item">
						<a [routerLink]="['/products']"> Product </a>
					</li>
					<li class="breadcrumb-item">
						<span>Delivery & payment</span>
					</li>
				</ol>
			</nav>
			<!-- ============================ -->
			<!-- paggination end here... -->
			<!-- ============================ -->

			<div class="row">
				<div class="col-sm-12">
					<div class="d-flex flex-column align-items-center gap-5 justify-content-center error-h-100">
						<div class="cart-error">
							<img *ngIf="error.hasError" src="../../../../assets/images/cart-error.png" alt="cart-error" />
							<svg *ngIf="!error.hasError" xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 100 100"
								width="140" height="140">
								<path d="M46.667 43.333h6.667v20h-6.667zM33.333 43.333H40v20h-6.667z" fill="#ae2020"
									class="color000 svgShape"></path>
								<path
									d="M90 36.667V30H73.542l-7.279-20L60 12.279 66.448 30h-32.9L40 12.279 33.737 10l-7.282 20H10v6.667h6.667l5.57 33.424c.602 3.617 4.095 6.576 7.764 6.576h27.005C58.548 84.271 65.273 90 73.333 90 82.536 90 90 82.536 90 73.333c0-6.914-4.216-12.845-10.215-15.367l3.548-21.299H90zm-30 6.666v20.094c-1.433 1.924-2.5 4.134-2.995 6.572H30c-.41 0-1.12-.599-1.188-1.006l-5.388-32.328h53.151l-3.333 20.01a16.585 16.585 0 0 0-6.575 1.396v-14.74H60zm22.5 24.365-5.632 5.635 5.632 5.629-3.535 3.538-5.632-5.628-5.628 5.628-3.538-3.532 5.631-5.631-5.631-5.632 3.535-3.538 5.631 5.631 5.632-5.631 3.535 3.531z"
									fill="#ae2020" class="color000 svgShape"></path>
							</svg>
						</div>
						<h2 class="fw-bold" *ngIf="!error.hasError">Cancelling Order</h2>
						<h2 class="fw-bold" *ngIf="error.hasError">ERROR!</h2>
						<p *ngIf="isProcessing">Order cancellation is being processed.</p>
						<p *ngIf="!isProcessing && !error.hasError">
							This order has been cancelled.
						</p>
						<p *ngIf="error.genericError">
							Your cancellation failed. Please contact support for assistance.
						</p>
						<p *ngIf="error.missingPassword">
							Your cancellation password is missing. Please check your approval
							email for your cancellation link.
						</p>
						<p *ngIf="error.missingCart">
							The cart you are trying to cancel is missing. Please check your
							approval email for your cancellation link.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<ng-template #cancel_model let-modal let-c="close" let-d="dismiss" class="deleteModal">
	<div class="modal-header">
		<h1 class="modal-title" id="myListModalLabel">
			Reject Order
		</h1>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<div class="form-group mb-4">
					<label for="reason" class="form-label">Reason:</label>
					<input type="text" name="reason" class="form-control" id="reason" [(ngModel)]="cancelReason" />
				</div>
			</div>
			<div class="col-12">
				<div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-3">
					<a (click)="d('Cross click')" class="btn btn-secondary text-nowrap w-100">
						Cancel
					</a>
					<button (click)="save()" class="btn btn-primary text-nowrap w-100">
						<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<path
									d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
									stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							</svg>
						</span>
						Submit
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>